import React from 'react';

function SidebarBillingIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 22.75C19.8011 22.7498 19.6105 22.6707 19.47 22.53L16 19.06L12.53 22.53C12.3894 22.6705 12.1987 22.7494 12 22.7494C11.8012 22.7494 11.6106 22.6705 11.47 22.53L7.99999 19.06L4.52999 22.53C4.42459 22.635 4.29034 22.7063 4.14433 22.7348C3.99831 22.7633 3.84712 22.7477 3.70999 22.69C3.57317 22.6341 3.45624 22.5385 3.37425 22.4155C3.29227 22.2925 3.24899 22.1478 3.24999 22V2.00001C3.25012 1.85177 3.29417 1.7069 3.37659 1.58368C3.45901 1.46046 3.5761 1.36444 3.71306 1.30773C3.85003 1.25101 4.00072 1.23616 4.14612 1.26505C4.29152 1.29393 4.4251 1.36526 4.52999 1.47001L7.99999 4.94001L11.47 1.47001C11.6106 1.32956 11.8012 1.25067 12 1.25067C12.1987 1.25067 12.3894 1.32956 12.53 1.47001L16 4.94001L19.47 1.47001C19.5749 1.36526 19.7085 1.29393 19.8539 1.26505C19.9993 1.23616 20.1499 1.25101 20.2869 1.30773C20.4239 1.36444 20.541 1.46046 20.6234 1.58368C20.7058 1.7069 20.7499 1.85177 20.75 2.00001V22C20.751 22.1478 20.7077 22.2925 20.6257 22.4155C20.5437 22.5385 20.4268 22.6341 20.29 22.69C20.1983 22.7291 20.0997 22.7495 20 22.75ZM7.99999 17.25C8.09853 17.2495 8.19617 17.2688 8.28718 17.3065C8.37819 17.3443 8.46074 17.3999 8.52999 17.47L12 20.94L15.47 17.47C15.6106 17.3296 15.8012 17.2507 16 17.2507C16.1987 17.2507 16.3894 17.3296 16.53 17.47L19.25 20.19V3.81001L16.53 6.53001C16.3894 6.67046 16.1987 6.74935 16 6.74935C15.8012 6.74935 15.6106 6.67046 15.47 6.53001L12 3.06001L8.52999 6.53001C8.38936 6.67046 8.19874 6.74935 7.99999 6.74935C7.80124 6.74935 7.61061 6.67046 7.46999 6.53001L4.74999 3.81001V20.19L7.46999 17.47C7.53923 17.3999 7.62178 17.3443 7.71279 17.3065C7.80381 17.2688 7.90145 17.2495 7.99999 17.25Z"
        fill="black"
      />
      <path
        d="M16 10.25H8C7.80109 10.25 7.61032 10.171 7.46967 10.0303C7.32902 9.88968 7.25 9.69891 7.25 9.5C7.25 9.30109 7.32902 9.11032 7.46967 8.96967C7.61032 8.82902 7.80109 8.75 8 8.75H16C16.1989 8.75 16.3897 8.82902 16.5303 8.96967C16.671 9.11032 16.75 9.30109 16.75 9.5C16.75 9.69891 16.671 9.88968 16.5303 10.0303C16.3897 10.171 16.1989 10.25 16 10.25Z"
        fill="black"
      />
      <path
        d="M16 15.25H8C7.80109 15.25 7.61032 15.171 7.46967 15.0303C7.32902 14.8897 7.25 14.6989 7.25 14.5C7.25 14.3011 7.32902 14.1103 7.46967 13.9697C7.61032 13.829 7.80109 13.75 8 13.75H16C16.1989 13.75 16.3897 13.829 16.5303 13.9697C16.671 14.1103 16.75 14.3011 16.75 14.5C16.75 14.6989 16.671 14.8897 16.5303 15.0303C16.3897 15.171 16.1989 15.25 16 15.25Z"
        fill="black"
      />
    </svg>
  );
}

export default SidebarBillingIcon;
