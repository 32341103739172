import React, { useState } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai';
import { BiLinkExternal, BiUser } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { MdClose } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const Home = () => {
  const [active, setActive] = useState(1);
  const [toggle, setToggle] = useState(true);
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className=" w-full min-h-screen relative ">
      <div className=" w-full bg-black py-4 relative z-30">
        <div className="container">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <div className="inline">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tourversity
                </h1>
                <h3 className="font_uber text-sm cursor-pointer text-white opacity-70">
                  by Tour
                </h3>
              </div>
              <div className=" pl-10 flex items-center justify-center gap-8">
                <Link
                  to="/"
                  className=" flex items-center justify-center gap-1 text-white font-light "
                >
                  <CgMenuGridO /> Home
                </Link>
              </div>
            </div>
            <div className=" flex items-center gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div className=" items-center justify-between flex lg:hidden px-2">
            <div className=" flex items-center">
              <h1 className="font_uber text-2xl cursor-pointer text-white">
                Tour
              </h1>
            </div>
            <div className=" flex items-center gap-2">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser className=" cursor-pointer text-white w-5 h-5" />
              </Link>
              <Link
                to="/signup"
                className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
              {toggle ? (
                <VscThreeBars
                  onClick={() => setToggle(false)}
                  className=" cursor-pointer text-white w-5 h-5"
                />
              ) : (
                <MdClose
                  onClick={() => setToggle(true)}
                  className=" cursor-pointer text-white w-5 h-5"
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={
            !toggle
              ? {
                  height: '200px',
                  borderTop: '1px solid white',
                  background: '#000',
                }
              : { height: '0px', overflow: ' hidden' }
          }
          className="absolute top-20 left-0 transition-all ease-linear duration-300   w-full z-40 block lg:hidden"
        >
          <Link
            to="/"
            className=" flex items-center text-2xl  justify-center gap-1 text-white font-light pt-10"
          >
            Product
          </Link>
          <Link
            to="/results"
            className=" flex items-center  text-2xl justify-center gap-1 text-white font-light "
          >
            Results
          </Link>
        </div>
      </div>
      <div className=" relative">
        <video
          className=" relative "
          width="100%"
          style={{ objectFit: 'cover', height: '450px' }}
          loop={true}
          autoPlay={true}
          muted={true}
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/leasemagnets---dummy-db.appspot.com/o/community%2F31%2Fmagnet%2F0d8aa956-2d8d-4254-b018-15c1672c5d9a%2Fintro_Main_Intro_2%2FSimple_tour_video_ad__masked_shirt_2_mp4.mp4?alt=media&token=cc19a462-6e80-49cd-ba2c-e68b1c2f2967?&coconut_id=sT527RCVNJNgri"
            type="video/mp4"
          />
        </video>

        <div
          className=" absolute top-0 left-0 z-10 w-full h-full flex items-center justify-start"
          style={{ background: 'rgba(0,0,0,0.7)' }}
        >
          <div className=" container pl-4 lg:pl-0">
            <h1 className=" text-4xl text-white font_uber text-left">
              Welcome to Tourversity
            </h1>
            <div className=" lg:pt-10 pt-6 flex items-center gap-3 text-white text-xl font_uber">
              <AiFillPlayCircle className=" w-6 h-6" /> The best place for you
              to learn how to build a great tour
            </div>
            <a href="https://tourversity.com/">
              <button className=" mt-4 px-3 font-light text-sm py-3 flex items-center gap-2 hover:bg-hvr bg-pr rounded-sm text-white">
                <BiLinkExternal /> Visit Tourversity
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className=" container px-2 lg:px-0">
        <iframe
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          style={{
            width: '100%',
            marginTop: '-150px',
            height: '1000px',
            border: '2px solid #ccc',
            borderRadius: '10px',
            padding: 'none',
          }}
          src="https://v1.embednotion.com/embed/ccdc0be00c2449a8a35a3920d477c364"
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
