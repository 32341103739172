// snackbar for the alerts imports
import { MenuItem, Snackbar, TextField } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DynamicIframdialogue from '../../components/DynamicIframdialogue';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import Customchart from '../../components/Reusable/CustomChart';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import { getMagnetData } from '../../store/actions/magnetActions';
import firebase from '../../utils/base';
import { uploadBlobToStorage } from '../../utils/files';
import { TOUR_HOST } from '../../utils/request';
import styles from './MagnetSummary.module.css';
import MagnetSummaryRow from './MagnetSummaryRow.jsx';
import SendRecording from './SendRecording';
import TemplatePreviewMessage from './TemplatePreviewMessage';
//import {Customchart} from '../tour-magnet-tabs/Analytics'
//import 'tabler-react/dist/Tabler.css';
// import Chart from 'react-apexcharts';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

/*

This page lists out all the Screens, Categories and new Screen items on the dashboard.

Important notes:
- In order to switch back from Upload page to the main summary screen, we use a string called 'screenToEdit'.
If 'screenToEdit' is empty we then we return to the main page
*/

export const uploadVideoFromLocalUrl = async (localUrl) => {
  let url;
  let uploadedToFirebase = false;
  const validUrl = new RegExp(
    /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );
  if (validUrl.test(localUrl)) {
    // if we uploaded from a url
    url = localUrl;
  } else {
    // if we need to upload to firebase
    // console.log('cloudlibrary', shareCloud[0]);
    // _dispatch({
    //   type: 'SET_CURR_UPLOADING_VIDEO',
    //   payload: cloudLibrary[i].name,
    // });
    url = await uploadBlobToStorage(
      localUrl,
      'test_video_TYG',
      '/route/TYG/',
      'video/mp4',
      'test_video_TYG.mp4',
      // shareCloud[0].dataURL,
      // shareCloud[0].name,
      // "/route/TYG/",
      // shareCloud[0].type,
      // shareCloud[0].origName,
      (progress, timeestimate) => {
        // progress updates
        //
        console.log('progress....', progress);
        console.log('time estimate', timeestimate);
      }
    );
    uploadedToFirebase = true;
  }

  console.log('new video url = ', url, uploadedToFirebase);
  return url;
};

const MagnetSummary = ({
  communityName,
  communityId,
  currVid,
  setCurrVid,
  reorderCategories,
  setOpenCategoryDialog,
}) => {
  const {
    encodingQ,
    magnetId,
    magnetType,
    loadScreen,
    accessLevel,
    previewTemplate,
  } = useSelector((state) => state.magnetStates);

  const { template, magnetLoading } = useSelector((state) => state.getMagnet);
  const _dispatch = useDispatch();
  const [analyticsloading, setanalyticsloading] = useState(true);
  const [refreshanalyticsloading, setrefreshanalyticsloading] = useState(false);
  const [analyticsdata, setanalyticsdata] = useState();

  // SNACKBAR 2
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarColor, setSnackbarColor] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbar = (message, alertColor) => () => {
    setSnackbarMessage(message);
    setSnackbarColor(alertColor);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const [cardChart1Data, setcardChart1Data] = useState({
    series: [
      {
        name: 'Profits',
        data: [],
      },
    ],
    height: '100%',
    type: 'area',
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      xaxis: {
        type: 'datetime',
      },
      labels: [],
      //colors: [colors.blue],
      fill: {
        opacity: 0.16,
      },
      sparkline: {
        enabled: true,
      },
    },
  });

  // HANDLE SHARE SNACKBAR
  const [openShare, setOpenShare] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const [searchFilter, setSearchFilter] = useState({
    query: '',
    minPrice: 0,
    maxPrice: Infinity,
    fromDate: '',
    toDate: moment().format('YYYY-MM-DD'),
  });
  console.log('magnetId', magnetId);
  // const updateScreenToEditOnHashChange = () => {
  //   if (!window.location.hash) {
  //     setScreenToEdit("");
  //   }
  // }

  // window.removeEventListener("hashchange", updateScreenToEditOnHashChange(), false);

  // function locationHashChanged() {
  //   console.log('updated hash : ', window.location.hash);

  //   if (window.location.hash === '') {
  //     console.log('lets use hash to set screentoEdit');
  //   }
  // }

  // React.useEffect(() => {
  //   window.addEventListener('hashchange', console.log('window location hash: ', window.location.hash));

  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener('hashchange', locationHashChanged);
  //   };
  // }, []);

  const { showScreenRecording } = useSelector((state) => state.generalReducers);
  const [shareCloud, setShareCloud] = useState([]);
  const [introVideo, setIntroVideo] = useState('');
  const [hyperIframe, setHyperFrame] = useState('');

  const [shareName, setShareName] = useState('');
  const [agentName, setAgentName] = useState(0);
  const [customTourStep, setCustomTourStep] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMove, setOpenMove] = useState(false);

  const _templates = [
    {
      name: 'Student Housing Template',
      image: require('../../assets/template.png').default,
      community_id: 250,
      url: 'https://tour.video/dashboard/250/videos#',
    },
    {
      name: 'Apartment Template',
      image: 'https://i.imgur.com/qg7KV7S.png',
      community_id: 31,
      url: 'https://tour.video/dashboard/31/videos#',
    },
    {
      name: 'Senior Living Template',
      image: 'https://i.imgur.com/GVFFbJ6.png',
      community_id: 211,
      url: 'https://tour.video/dashboard/211/videos#',
    },
    {
      name: 'Coliving Template',
      image: 'https://i.imgur.com/WEb3nBi.png',
      community_id: 20,
      url: 'http://tour.video/dashboard/20/videos#',
    },
    {
      name: 'Accelerator Template',
      image: 'https://i.imgur.com/WEb3nBi.png',
      community_id: 194,
      url: 'https://tour.video/dashboard/194/videos#',
    },
    {
      name: 'Testimonial & Courses Template',
      image: 'https://i.imgur.com/8Vzobp5.png',
      community_id: 333,
      url: 'https://tour.video/dashboard/333/videos#',
    },
  ];

  const [category, setCategory] = useState('');
  // Add new video category
  // function addNewCategory() {
  //   if (category && category.length >= 3) {
  //     _dispatch({
  //       type: 'addCategory',
  //       payload: {
  //         category_title: category,
  //         magnet_id: magnetId,
  //         magnet_type: magnetType,
  //       },
  //     });
  //   }
  // }

  // COPY TO CLIPBOARD
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);
  const refreshanalyticsdata = async () => {
    const authToken = await firebase.user().getIdToken(true);
    setrefreshanalyticsloading(true);
    try {
      const currDate = new Date();
      currDate.setDate(currDate.getDate() - 89);
      const end = new Date();
      end.setDate(end.getDate() + 1);
      const { data } = await axios.get(
        //`https://api.leasemagnets.com/custom_event?magnet_uuid=${magnetId}&selectedlength=90&start=2022-01-02T12:00:40.895&end=2022-04-01T12:00:41.168`,
        `https://api.leasemagnets.com/custom_event?refreshcache=true&magnet_uuid=${magnetId}&selectedlength=90&start=${currDate
          .toISOString()
          .replace('Z', '')}&end=${end.toISOString().replace('Z', '')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      console.log('test_datass', data);
      setanalyticsdata(data.response);
      setrefreshanalyticsloading(false);

      //InDataTime = data;
    } catch (error) {
      console.log('errord', error);
    }
  };

  useEffect(() => {
    const count_details_from_rows_bigquery = async (magnetuuid) => {
      try {
        const { data } = await axios.post(
          `https://api.leasemagnets.com/count_details_from_rows`,
          {
            magnet_uuid: magnetuuid,
          }
        );
        console.log('got count_details_from_rows', data);
        let screenobj = {};
        if (data?.res) {
          data?.res?.map((item) => {
            let category_name = [item[0].split('.')[0]];
            let screen_name = [item[0].split('.')[1]];
            if (screenobj[category_name]) {
              if (screenobj[category_name][screen_name]) {
                screenobj[category_name][screen_name] =
                  screenobj[category_name][screen_name]['count'] + item[1];
              } else {
                screenobj[category_name][screen_name] = { count: item[1] };
              }
            } else {
              screenobj[category_name] = { screen_name: { count: item[1] } };
            }
          });
        }
        setanalyticsdata({ response: { category: screenobj } });
      } catch (error) {
        console.log('errror', error);
      }
    };
    const getanalyticsdata = async () => {
      const authToken = await firebase.user().getIdToken(true);
      try {
        const currDate = new Date();
        currDate.setDate(currDate.getDate() - 89);
        // 89 + 1 = 90
        const end = new Date();
        end.setDate(end.getDate() + 1);
        const { data } = await axios.get(
          `https://api.leasemagnets.com/custom_event?magnet_uuid=${magnetId}&selectedlength=90&start=${currDate
            .toISOString()
            .replace('Z', '')}&end=${end.toISOString().replace('Z', '')}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
          }
        );
        console.log('test_datass', data);
        setanalyticsdata(data.response);
        setanalyticsloading(false);

        //InDataTime = data;
      } catch (error) {
        console.log('errord', error);
      }
    };
    if (magnetId) {
      //getanalyticsdata();
      count_details_from_rows_bigquery(magnetId);
    }
  }, [magnetId]);

  useEffect(() => {
    // const getanalyticsdata = async () => {
    //   const authToken = await firebase.user().getIdToken(true);
    //   try {
    //     const { data } = await axios.get(
    //       //`https://api.leasemagnets.com/custom_event?magnet_uuid=${magnetId}&selectedlength=90&start=2022-01-02T12:00:40.895&end=2022-04-01T12:00:41.168`,
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           Authorization: authToken,
    //         },
    //       }
    //     );
    //     console.log('test_data', data);
    //     setanalyticsdata(data.response);
    //     setanalyticsloading(false);

    //     //InDataTime = data;
    //   } catch (error) {
    //     console.log('errord', error.response);
    //   }
    // };
    // getanalyticsdata();
    if (previewTemplate.enablePreview) {
      _dispatch(getMagnetData(previewTemplate.selectedTemplate.community_id));
    } else {
      _dispatch(getMagnetData(communityId));
    }
  }, [previewTemplate]);

  const [librarydata, setlibrarydata] = useState([]);
  const getlibraryvideos = async () => {
    console.log('calledus');
    try {
      const { data } = await axios.get(
        `https://api.directual.com/good/api/v5/data/video_library__tyg/getAndPostVideoLibraryItem?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=&linkId=${communityId}`
      );
      console.log('datarra', data.payload);
      setlibrarydata(data.payload);
    } catch (error) {
      console.log('errord', error);
    }
  };

  const postlibraryvideo = async (url) => {
    console.log('calledus');
    try {
      const { data } = await axios.post(
        'https://api.directual.com/good/api/v5/data/video_library__tyg/getAndPostVideoLibraryItem?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=',
        {
          linkId: communityId,
          metadata: {
            video: url,
            name: 'recording',
            dateCreated: new Date(),
          },
        }
      );
      console.log('datarra', JSON.parse(data.payload));
      getlibraryvideos();
    } catch (error) {
      console.log('errord', error);
    }
  };

  const deletelibraryvideo = async (index) => {
    console.log('calledus');
    try {
      const item = librarydata[index];
      console.log('item', item);
      //deletevideo(JSON.parse(item.metadata).video)
      //console.log('datarra',JSON.parse(data.payload))
      //getlibraryvideos()
    } catch (error) {
      console.log('errord', error);
    }
  };

  useEffect(() => {
    getlibraryvideos();
  }, []);

  const copyToClipboard = React.useCallback((e) => {
    navigator.clipboard.writeText(
      `${TOUR_HOST}/share?magnet_uuid=${magnetId}&share=${selectedVideos.join(
        ','
      )}&agent=${encodeURI(agentName)}&name=${encodeURI(
        shareName
      )}&intro_video=${encodeURI(introVideo)}&hyper_iframe=${hyperIframe}`
    );
    setCopySuccess(1);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  });

  const copyToClipboard2 = React.useCallback((e) => {
    navigator.clipboard.writeText(
      `https://share.tour.video/link?magnet_uuid=${magnetId}&share=${selectedVideos.join(
        ','
      )}&agent=${encodeURI(agentName)}&name=${encodeURI(
        shareName
      )}&intro_video=${encodeURI(introVideo)}&hyper_iframe=${hyperIframe}`
    );
    setCopySuccess(2);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  });

  const PrettyPrintJson = ({ data }) => {
    // (destructured) data could be a prop for example
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };
  const [open, setOpen] = React.useState(false);
  const [openrecorder, setOpenrecorder] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [openiframedialogue, setopeniframedialogue] = useState(false);
  const [selectoption, setselectoption] = useState('recorder');
  const onCancel = () => {
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: '',
    });
    window.history.pushState({}, `View ${videoName}`, `#`);
  };
  console;

  const [chartlist, setchartlist] = useState([]);
  useEffect(() => {
    // if (magnetId) {
    //   setchartlist([
    //     {
    //       Heading: 'Tour interaction',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "open_tour" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //     {
    //       Heading: 'Business Hour tour interaction',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND EXTRACT(HOUR from `utc_time`) between 7 and 19 AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "open_tour" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //     {
    //       Heading: 'Special Offer interaction',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "form_interaction" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //     {
    //       Heading: 'Special Offer Completions',
    //       showDropdown: false,
    //       value: 90,
    //       queryp1:
    //         'SELECT timestamp_trunc(`default.events`.`_timestamp`, day) AS `_timestamp`, count(*) AS `count` FROM `default.events` WHERE `default.events`.`_timestamp` >= timestamp_trunc(timestamp_add(current_timestamp(), INTERVAL -',
    //       queryp2:
    //         ' day), day) AND EXTRACT(HOUR from `utc_time`) between 7 and 19 AND magnet_uuid=' +
    //         `"${magnetId}"` +
    //         ' AND `default.events`.`event_type` = "form_submission" GROUP BY `_timestamp` ORDER BY `_timestamp` ASC',
    //     },
    //   ]);
    // }
  }, [magnetId]);

  return (
    <div class="magnet-sumary">
      <div className="sumary-content flex flex-col">
        {/* Template Preview Block */}

        <div className="prev-msg">
          <TemplatePreviewMessage onCancel={onCancel} />
        </div>
        <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
          {chartlist.map((item, index) => (
            <Customchart
              Heading={item.Heading}
              index={index}
              setDropdown={() => {
                chartlist[index]['showDropdown'] =
                  !chartlist[index]['showDropdown'];
                setchartlist([...chartlist]);
              }}
              setdays={(val) => {
                chartlist[index]['value'] = val;
                setchartlist([...chartlist]);
              }}
              showDropdown={item.showDropdown}
              query={item.queryp1 + item.value + item.queryp2}
            />
          ))}
        </div>
        <DynamicIframdialogue
          open={openiframedialogue}
          setOpen={setopeniframedialogue}
          hyperIframeId={hyperIframe}
          updateIframe={(value) => {
            setHyperFrame(value);
          }}
        />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarColor}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <div className="summary-hdr flex aic">
          <div className="left flex aic">
            <div className="video-search-bar">
              <input
                type="text"
                className="cleanbtn iput font s15 c333"
                placeholder="Search for your videos"
                value={searchFilter.query}
                onChange={(newValue) =>
                  setSearchFilter({
                    ...searchFilter,
                    query: newValue.target.value,
                  })
                }
              />
            </div>
            {/* <button
              onClick={() => {
                handleOpen();
                _dispatch({
                  type: 'SHOW_SCREEN_RECORDING',
                  payload: true,
                });
              }}F
              class="ml-2 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded "
            >
              +
            </button> */}
          </div>
          {!openrecorder && (
            <AnimatedDialog
              open={open}
              onClose={handleClose}
              fullWidth
              maxWidth={'lg'}
              fullHeight
            >
              <div className="w-full h-full">
                <div className="flex justify-center mb-4	">
                  <p
                    onClick={() => {
                      setselectoption('recorder');
                      handleOpen();
                      _dispatch({
                        type: 'SHOW_SCREEN_RECORDING',
                        payload: true,
                      });
                    }}
                    className={`mr-3 cursor-pointer	 bg-blue ${
                      selectoption == 'recorder' &&
                      'border-b-4 border-indigo-500'
                    }`}
                  >
                    Record Video
                  </p>
                  <p
                    onClick={() => {
                      setselectoption('library');
                    }}
                    className={`bg-blue cursor-pointer	 ${
                      selectoption == 'library' &&
                      'border-b-4 border-indigo-500'
                    }`}
                  >
                    Video Library
                  </p>
                </div>
                <div className="flex 	overflow-scroll justify-center h-screen		">
                  {open && selectoption == 'recorder' && (
                    <SendRecording
                      currVid={currVid}
                      inline={true}
                      onVideoUrl={(url) => {
                        console.log('urll', url);
                        postlibraryvideo(url);
                      }}
                      closeDialogue={handleClose}
                    />
                  )}
                  {/* <div onClick={() => setAddopen(true)}> */}
                  {selectoption == 'library' && (
                    <div className="flex">
                      {librarydata.map((item, index) => (
                        <div className=" ml-2  px-3 py-3 h-52 w-50 mb-6 cursor-pointer bg-gray-200	 ">
                          <video
                            //ref={vidRef}
                            className="item-video cursor-pointer h-52 w-50 "
                            //style={{ height: '50px' }}
                            loop
                          >
                            <source
                              src={JSON.parse(item.metadata).video}
                              type="video/mp4"
                            />
                            Your browser does not support HTML5 Video.
                          </video>
                          <span
                            onClick={() => deletelibraryvideo(index)}
                            className="ico icon-delete1 b6 s16"
                          ></span>
                          {/* <div className=" absolute bottom-3 left-3 z-20">
                {play.id === ind &&
                play.status === true &&
                play.ref === true ? (
                  <AiOutlinePause
                    onClick={() => {
                      handlePauseVideo();
                      setPlay({
                        id: ind,
                        status: false,
                        ref: false,
                      });
                    }}
                    className=" w-7 h-7 text-white cursor-pointer"
                  />
                ) : (
                  <FiPlay
                    onClick={() => {
                      handlePlayVideo();
                      setPlay({
                        id: ind,
                        status: true,
                        ref: true,
                      });
                    }}
                    className=" w-7 h-7 text-white cursor-pointer"
                  />
                )}
              </div> */}

                          <h1 className=" font-semibold text-lg pr-4">
                            Name : {JSON.parse(item.metadata).name}
                          </h1>
                          <h1 className=" font-semibold text-lg pr-4">
                            Date : {JSON.parse(item.metadata).dateCreated}
                          </h1>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* </div> */}
                </div>
              </div>
            </AnimatedDialog>
          )}
          <div className="right flex aic">
            <button
              className={`cleanbtn see-temp-btn flex aic font s15 color ${
                !previewTemplate.enablePreview ? 'sho' : 'hid'
              }`}
              onClick={() => {
                // refreshanalyticsdata();
                navigator.clipboard.writeText(
                  `https://embed.tour.video?uuid=${magnetId}&screen=tree.main&inline=true`
                );
                toast('Copied to clipboard', {
                  position: 'top-right',
                  type: 'success',
                  autoClose: 1500,
                });
              }}
            >
              <i class="fa fa-share-alt"></i>
            </button>
            {/* See template button */}
            <button
              className={`cleanbtn see-temp-btn flex aic font s15 color ml-2 ${
                !previewTemplate.enablePreview ? 'sho' : 'hid'
              }`}
              onClick={() => setOpenCategoryDialog(true)}
            >
              <p style={{ fontSize: '24px', marginTop: '-5px' }}>+ </p> Add
              Category
            </button>
          </div>
        </div>
        <div className="content-body pb-5">
          <div
            key={
              encodingQ.join('-') +
              '-' +
              currVid?.map((v) => v.source).join('-')
            }
          >
            {magnetLoading || accessLevel == null ? (
              <>
                <div className="sumary-body holders">
                  <div className="row-title holder" />
                  <div className="sumary-holders-wrapper">
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                  </div>
                  <div className="row-title holder" />
                  <div className="sumary-holders-wrapper">
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                    <div className="magnet-item  holder" />
                  </div>
                </div>
              </>
            ) : (
              template?.category_keys?.map((category_key, category_idx) => (
                <MagnetSummaryRow
                  communityId={communityId}
                  analyticsdata={analyticsdata}
                  handleSnackbar={handleSnackbar}
                  selectedVideos={selectedVideos}
                  setSelectedVideos={setSelectedVideos}
                  category_key={category_key}
                  searchFilter={searchFilter}
                  dragItem={reorderCategories}
                  index={category_idx}
                  key={category_idx}
                  reorderCategories={reorderCategories}
                  openMove={openMove}
                  magnetLoading={magnetLoading}
                  currVid={currVid}
                  setCurrVid={setCurrVid}
                />
              ))
            )}
          </div>

          {/* <button onClick={() => console.log(selectedVideos)}>
          print selected Videos
        </button> */}
          <div
            className={styles.share_popup_overlay}
            style={{ display: openShare ? 'flex' : 'none' }}
          >
            <div className={styles.share_popup_container}>
              <div className={styles.share_popup_header}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '25px',
                    top: '25px',
                  }}
                  onClick={() => {
                    setOpenShare(false);
                    setCustomTourStep(1);
                    setCopySuccess(false);
                  }}
                >
                  <path
                    d="M2 2L15.9901 16"
                    stroke="black"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 2L2 15.9901"
                    stroke="black"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <svg
                  width="46"
                  height="7"
                  viewBox="0 0 46 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: '6.5px' }}
                  transform={customTourStep === 2 ? 'rotate(180)' : ''}
                >
                  <rect width="31" height="7" rx="3.5" fill="#347DEF" />
                  <circle cx="42.5" cy="3.5" r="3.5" fill="#C4C4C4" />
                </svg>
              </div>
              <div className={styles.share_popup_title}>
                <span style={{ color: '#347DEF' }}>Share</span> this Tour
              </div>
              <div
                className={styles.share_popup_title}
                style={{ fontSize: '14px', margin: '0' }}
              >
                {customTourStep === 1
                  ? 'Who are you sharing the customized tour with?'
                  : 'Copy the link to share'}
              </div>
              {customTourStep === 1 && (
                <>
                  <div className={styles.share_popup_dropdown}>
                    Agent Name:
                    <TextField
                      id="outlined-basic"
                      select
                      label=""
                      variant="outlined"
                      value={agentName}
                      onClick={(e) => {
                        console.log('agent selector: ', e.target.value);
                        const eTarget = e.target.value;
                        if (eTarget !== undefined) {
                          setAgentName(eTarget);
                        }
                      }}
                      style={{ width: '100%', marginTop: '5px' }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {template &&
                        template?.magnetSettings?.agents &&
                        template?.magnetSettings.agents.map(
                          (agent, agent_idx) => {
                            return (
                              <MenuItem key={agent.name} value={agent_idx}>
                                {typeof agent.name == 'object'
                                  ? 'object'
                                  : agent.name}
                              </MenuItem>
                            );
                          }
                        )}
                    </TextField>
                  </div>

                  <div className={styles.share_popup_dropdown}>
                    Lead Name: (required)
                    <DebouncedTextField
                      id="outlined-basic"
                      label=""
                      placeholder="ie: Jim"
                      variant="outlined"
                      value={shareName}
                      onChange={setShareName}
                      style={{ width: '100%', marginTop: '5px' }}
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  {openrecorder && !open && showScreenRecording && (
                    <SendRecording
                      cloudLibrary={shareCloud}
                      setCloudLibrary={setShareCloud}
                      currVid={currVid}
                    />
                  )}

                  <div className="advanced-upload-btns ">
                    <button
                      className="cleanbtn record-new-video-btn flex aic"
                      style={{ width: '100%' }}
                      onClick={() => {
                        setOpenrecorder(true);
                        _dispatch({
                          type: 'SHOW_SCREEN_RECORDING',
                          payload: true,
                        });
                      }}
                    >
                      <div
                        className={`${
                          shareCloud.length >= 1
                            ? 'grey-record-icon'
                            : 'record-icon'
                        } `}
                      />
                      <div className="font s14 b5 c333">
                        {shareCloud.length >= 1
                          ? `${shareCloud.length} video recorded`
                          : 'Record personal video (optional)'}
                      </div>
                    </button>
                  </div>
                  <div
                    className={styles.share_popup_next}
                    style={{
                      background:
                        agentName !== '' && shareName !== ''
                          ? '#347DEF'
                          : '#8498A4',
                      cursor:
                        agentName !== '' && shareName !== ''
                          ? 'pointer'
                          : 'not-allowed',
                    }}
                    onClick={
                      agentName !== undefined &&
                      shareName &&
                      (() => setCustomTourStep(2))
                    }
                  >
                    Next
                  </div>
                </>
              )}
              {customTourStep === 2 && (
                <>
                  <div className={styles.share_popup_dropdown}>
                    Start sharing your tour by copying the web link below:
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label=""
                        placeholder="ie: Jim"
                        variant="outlined"
                        value={`https://app.usetour.com/customtour?magnet_uuid=${magnetId}&share=${selectedVideos.join(
                          ','
                        )}&agent=${encodeURI(agentName)}&name=${encodeURI(
                          shareName
                        )}&intro_video=${encodeURI(
                          introVideo
                        )}&hyper_iframe=${hyperIframe}`}
                        style={{ width: '65%' }}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        ref={textAreaRef}
                        disabled
                      />
                      {document.queryCommandSupported('copy') && (
                        <div
                          className={styles.share_popup_next}
                          style={{
                            background:
                              copySuccess == 1 ? '#EEF1F2' : '#347DEF',
                            color: copySuccess == 1 ? 'black' : 'white',
                            margin: 0,
                          }}
                          onClick={copyToClipboard}
                        >
                          {copySuccess == 1 ? 'Copied!' : 'Copy'}
                        </div>
                      )}
                    </div>
                    <br />
                    Instagram stories
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        label=""
                        placeholder="ie: Jim"
                        variant="outlined"
                        value={`https://share.tour.video/link?magnet_uuid=${magnetId}&share=${selectedVideos.join(
                          ','
                        )}&agent=${encodeURI(agentName)}&name=${encodeURI(
                          shareName
                        )}&intro_video=${encodeURI(
                          introVideo
                        )}&hyper_iframe=${hyperIframe}`}
                        style={{ width: '65%' }}
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        ref={textAreaRef}
                        disabled
                      />
                      {document.queryCommandSupported('copy') && (
                        <div
                          className={styles.share_popup_next}
                          style={{
                            background:
                              copySuccess == 2 ? '#EEF1F2' : '#347DEF',
                            color: copySuccess == 2 ? 'black' : 'white',
                            margin: 0,
                          }}
                          onClick={copyToClipboard2}
                        >
                          {copySuccess == 2 ? 'Copied!' : 'Copy'}
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.share_popup_next}`}
                      style={{
                        background: copySuccess == 2 ? '#EEF1F2' : '#EEF1F2',
                        color: copySuccess == 2 ? 'black' : 'black',
                        margin: 0,
                        width: '80%',
                        marginTop: '5px',
                      }}
                      onClick={() => setopeniframedialogue(true)}
                    >
                      + Generate intro video with text
                    </div>
                  </div>
                  {shareCloud.length >= 1 && (
                    <div>
                      <video
                        controls
                        autoPlay
                        src={shareCloud[0].dataURL}
                        style={{ width: '100%' }}
                      />
                      <button
                        onClick={async () => {
                          const localUrl = shareCloud[0].dataURL;
                          const firebaseUrl = await uploadVideoFromLocalUrl(
                            localUrl
                          );
                          console.log('firebaseURL Test: ', firebaseUrl);
                          await setShareCloud([
                            {
                              dataURL: firebaseUrl,
                              name: 'test_video_TYG',
                              prefix: '/route/TYG/',
                              type: 'video/mp4',
                              origName: 'test_video_TYG.mp4',
                            },
                          ]);
                          await setIntroVideo(firebaseUrl);
                        }}
                      >
                        + add the video to shared tour page
                      </button>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginTop: '50px',
                    }}
                  >
                    <span
                      style={{ color: '#8498A4', cursor: 'pointer' }}
                      onClick={() => setCustomTourStep(1)}
                    >
                      Previous Step
                    </span>
                    <div
                      className={styles.share_popup_next}
                      style={{
                        float: 'none',
                        margin: 0,
                        background: copySuccess ? '#347DEF' : '#8498A4',
                      }}
                      onClick={() => {
                        setOpenShare(false);
                        setSelectedVideos([]);
                        setCustomTourStep(1);
                        setCopySuccess(false);
                      }}
                    >
                      Done!
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* Share Footer bar */}
          <div
            className={`share-footer-container ${
              selectedVideos.length > 0 && !openShare ? 'open' : 'close'
            }`}
          >
            <div className="selected-videos flex aic">
              <div className="ico icon-check1 s16 black" />
              <div className="font s14 b5 black">
                {selectedVideos.length} video
                {selectedVideos.length !== 1 && 's'} selected
              </div>
            </div>
            <div
              className="action-btn flex flex-col aic"
              onClick={() => setOpenShare(true)}
            >
              <div className="ico icon-share2 anim" />
              <div className="lbl font s14 black anim">Share</div>
            </div>
            {/* <div
              className="action-btn  flex flex-col aic"
              onClick={() => setOpenMove(true)}
            >
              <div className="ico icon-move anim" />
              <div className="lbl font s14 black anim">Move</div>
            </div> */}
            <div
              className="cross-btn anim font"
              onClick={() => {
                setSelectedVideos([]);
                setOpenMove(false);
              }}
            >
              &times;
            </div>
          </div>
        </div>
      </div>

      {/* Delete Screen Dialog */}
      <DeleteScreenDialog
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        selectedVideos={selectedVideos}
      />
      {/* See Templates Dialogbox */}
      <TemplateDialogbox
        _templates={_templates}
        previewTemplate={previewTemplate}
      />
      {/* Show Confirm Template Dialog */}
      <ConfirTemplateDialog previewTemplate={previewTemplate} />
    </div>
  );
};

export default MagnetSummary;

const DeleteScreenDialog = React.memo(({ openDelete, setOpenDelete }) => {
  const { magnetId, magnetType } = useSelector((state) => state.magnetStates);
  const _dispatch = useDispatch();
  {
    /* Show Confirm Template Dialog */
  }
  return (
    <AnimatedDialog
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      fullWidth
      maxWidth="xs"
    >
      <div className="add-category-dialog">
        <div className="hdr flex aic">
          <div className="title font s18 b6 c000">Delete Screen</div>
          <button
            className="cross-btn cleanbtn fontr"
            onClick={() => setOpenDelete(false)}
          >
            &times;
          </button>
        </div>
        <div className="wrap flex flex-col">
          <div className="txt font s15 black">
            Are you sure you want to delete this screen?
          </div>
        </div>
        <div className="ftr flex aic">
          <button
            className="cleanbtn add-cata font s15 cfff"
            onClick={() => {
              selectedVideos.map((video) => {
                const screen_info = video.split('.');
                _dispatch({
                  type: 'removeScreen',
                  payload: {
                    screen_key: screen_info[1],
                    category_key: screen_info[0],
                    magnet_id: magnetId,
                    magnet_type: magnetType,
                  },
                });
              });
              setOpenDelete(false);
            }}
          >
            Confirm
          </button>
          <button
            className="cleanbtn add-cata cancel font s15 c333"
            onClick={() => setOpenDelete(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
});

const TemplateDialogbox = React.memo(({ _templates, previewTemplate }) => {
  const _dispatch = useDispatch();
  {
    /* Show Confirm Template Dialog */
  }
  return (
    <AnimatedDialog
      open={previewTemplate.showTemplatesDialog}
      onClose={() => {
        _dispatch({
          type: 'PREVIEW_TEMPLATE',
          payload: { ...previewTemplate, showTemplatesDialog: false },
        });
      }}
    >
      <div className="see-temp-section flex flex-col rel">
        <div className="hdr flex aic sticky">
          <div className="title font s16 b6 c000">Select a template</div>
          <div
            className="cross-btn flex aic s24 c333 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: { ...previewTemplate, showTemplatesDialog: false },
              });
            }}
          >
            &times;
          </div>
        </div>
        <div className="temp-content flex aic">
          {_templates.map((item, idx) => (
            <div
              key={idx}
              className="temp-item"
              onClick={() => {
                _dispatch({
                  type: 'PREVIEW_TEMPLATE',
                  payload: {
                    ...previewTemplate,
                    showConfirmTemplateDialog: true,
                    selectedTemplate: item,
                  },
                });
              }}
            >
              <div className="media rel">
                <div
                  className="image"
                  style={{ backgroundImage: `url(${item.image})` }}
                />
                <div className="overylay abs flex aic anim">
                  <div className="txt font s13 cfff anim">Select Template</div>
                </div>
              </div>
              <div className="meta">
                <div className="lbl font s14 b5 c333 wordwrap">{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </AnimatedDialog>
  );
});

const ConfirTemplateDialog = React.memo(({ previewTemplate }) => {
  const _dispatch = useDispatch();
  {
    /* Show Confirm Template Dialog */
  }
  return (
    <AnimatedDialog
      open={previewTemplate.showConfirmTemplateDialog}
      onClose={() => {
        _dispatch({
          type: 'PREVIEW_TEMPLATE',
          payload: { ...previewTemplate, showConfirmTemplateDialog: false },
        });
      }}
    >
      <div className="confirm-temp-sec rel">
        <div className="hdr flex aic sticky">
          <div className="title font s16 b6 c000">Confirm Template</div>
          <div
            className="cross-btn flex aic s22 c333 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: {
                  ...previewTemplate,
                  showConfirmTemplateDialog: false,
                },
              });
            }}
          >
            &times;
          </div>
        </div>
        <div className="content">
          <div className="font s15 c333">
            Are you sure you want to clone of{' '}
            <span className="b6">{previewTemplate.selectedTemplate?.name}</span>{' '}
            template ?
          </div>
        </div>
        <div className="actions flex aic">
          <button
            className="btn cancel font s15 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: {
                  ...previewTemplate,
                  showConfirmTemplateDialog: false,
                  showTemplatesDialog: true,
                  enablePreview: false,
                },
              });
            }}
          >
            Cancel
          </button>
          <button
            className="btn font s15 anim"
            onClick={() => {
              _dispatch({
                type: 'PREVIEW_TEMPLATE',
                payload: {
                  ...previewTemplate,
                  showTemplatesDialog: false,
                  showConfirmTemplateDialog: false,
                  enablePreview: true,
                },
              });
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
});
