import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './css/App.scss';
import * as splashLoader from './lottie/splash_loader.json';
import AuthRoutes from './routes/AuthRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import firebase from './utils/base';

const stripePromise = loadStripe(
  'pk_test_51K5VDmCgQX5AncW3mJMyiUlA5qwmYiGaWsQMjlOutWk6a1iGZi4OEfwnyVmOH4JH3eRtR8GbC1zZidNlvEeSOTP700ZnJSphhp'
);

function App() {
  const [appLoading, setAppLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth.onAuthStateChanged((u) => {
      setUser(u);
      setAppLoading(false);
    });
  }, []);

  const _splashLoader_ = {
    loop: true,
    autoplay: true,
    animationData: splashLoader.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  useEffect(() => {
    AOS.init({
      duration: 3000,
    });
  }, []);
  return (
    <Elements stripe={stripePromise}>
      {appLoading ? (
        <div className="splash">
          <div className="loader flex aic rel">
           
          <div className="logo-txt font_uber font s28 b6 c000">RPM Joy</div>
          
          </div>
        </div>
      ) : (
        <div className="App">
          <ToastContainer />
          <Router>{user ? <ProtectedRoutes /> : <AuthRoutes />}</Router>
        </div>
      )}
    </Elements>
  );
}

export default App;
