import axios from 'axios';
import { API_HOST, post } from '../../utils/request';
import { createCategoryKey } from '../../views/manage-magnets/CreateKeys';

const getMagnet = (
  state = {
    magnetData: {},
    template: {},
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case 'GET_MAGNET_REQUEST':
      return {
        magnetLoading: true,
        magnetData: {},
        template: {},
      };

    case 'updateScreen':
      // console.log(payload.type, payload.screen);
      // ADD ITEM TO SCREEN KEYS
      const screenToUpdate = payload.screen;

      const screenKeyToUpdate = payload.screen_key;
      const screenCategoryToUpdate = payload.category_key;
      // // INSERT SCREEN INTO THE RIGHT CATEGORY

      // //VALIDATION CHECKS
      // // NO PERIOD IN TITLE OR CATEGORY NAME, NO WORD NEW IN SCREEN TITLE, NO DUPLICATE SCREEN NAME IN THE SAME CATEGORY, ENSUR EIT HAS ALL THE ITEM IN SCREEN TO ADD
      // // ADD ITEM TO SCREEN KEYS
      // // INSERT SCREEN INTO THE RIGHT CATEGORY

      // //check if an existing key is part of the dictionary

      // //check if screenToAdd is already a part of the current state
      // // if (state.categories[screenCategoryToUpdate].screen_keys.includes(screenKeyToUpdate)) {
      // //   throw "screen key already exists, try again with a different screen key";
      // // }
      console.log('updateScreen payload that we got', payload);
      console.log('update_screen_JSON', {
        screen_key: screenKeyToUpdate,
        category_key: screenCategoryToUpdate,
        screen: screenToUpdate,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });

      // If screen_key is new do not create the screen
      if (screenKeyToUpdate === 'new') {
        return;
      }

      post('/magnets/template/screen/update', {
        screen_key: screenKeyToUpdate,
        category_key: screenCategoryToUpdate,
        screen: screenToUpdate,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      }).then((res) => console.log('REsults are = ', res));
      console.log('modifying template stateee', {
        template: {
          ...state.template,
          categories: {
            ...state.template.categories,
            [screenCategoryToUpdate]: {
              ...state.template.categories[screenCategoryToUpdate],
              screen_keys: [
                ...state.template.categories[screenCategoryToUpdate]
                  .screen_keys,
                screenKeyToUpdate,
              ],
              screens: {
                ...state.template.categories[screenCategoryToUpdate].screens,
                [screenKeyToUpdate]: screenToUpdate,
              },
            },
          },
        },
      });

      return {
        ...state,
        template: {
          ...state.template,
          categories: {
            ...state.template.categories,
            [screenCategoryToUpdate]: {
              ...state.template.categories[screenCategoryToUpdate],
              screen_keys: [
                ...state.template.categories[screenCategoryToUpdate]
                  .screen_keys,
              ].includes(screenKeyToUpdate)
                ? [
                    ...state.template.categories[screenCategoryToUpdate]
                      .screen_keys,
                  ]
                : [
                    ...state.template.categories[screenCategoryToUpdate]
                      .screen_keys,
                    screenKeyToUpdate,
                  ],
              screens: {
                ...state.template.categories[screenCategoryToUpdate].screens,
                [screenKeyToUpdate]: screenToUpdate,
              },
            },
          },
        },
      };

    case 'removeScreen':
      // return {
      //   ...state,
      //   [sectionKey]: {
      //     ...state[sectionKey],
      //     screens: [...state[sectionKey].screens].splice(index, 1),
      //   },
      // };
      // console.log(payload.type, payload.screen);
      //console.log(payload.type, payload.screen);
      // ADD ITEM TO SCREEN KEYS

      const screenKeyToDelete = payload.screen_key;
      const screenCategoryToDelete = payload.category_key;
      // // INSERT SCREEN INTO THE RIGHT CATEGORY

      console.log('screenDelete /magnets/template/screen/delete', {
        screen_key: screenKeyToDelete,
        category_key: screenCategoryToDelete,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });

      // //VALIDATION CHECKS
      // // NO PERIOD IN TITLE OR CATEGORY NAME, NO WORD NEW IN SCREEN TITLE, NO DUPLICATE SCREEN NAME IN THE SAME CATEGORY, ENSUR EIT HAS ALL THE ITEM IN SCREEN TO ADD
      // // ADD ITEM TO SCREEN KEYS
      // // INSERT SCREEN INTO THE RIGHT CATEGORY

      // //check if an existing key is part of the screen key dictionary within a specific category
      var temp = {
        ...state.template,
        categories: {
          ...state.template.categories,
          [screenCategoryToDelete]: {
            ...state.template.categories[screenCategoryToDelete],
            screen_keys: [
              ...state.template.categories[
                screenCategoryToDelete
              ].screen_keys.filter(
                (screen_key) => screen_key !== screenKeyToDelete
              ),
            ],
          },
        },
      };
      console.log('temp', temp);
      //console.log('state = ', state);
      // Delete all video files
      const filesToDelete =
        state.template.categories[screenCategoryToDelete].screens[
          screenKeyToDelete
        ].cloudLibrary;

      console.log('filesToDelete', filesToDelete);

      if (filesToDelete) {
        filesToDelete.map((video) => {
          if (!video.origName) {
            axios({
              method: 'POST',
              url: API_HOST + `/delete_file?old_file=` + video.dataURL,
              headers: {
                'Content-Type': 'application/json',
              },
            });
          }
        });
      }

      // loop through all buttons and ensure that button is deleted from all other screens
      Object.keys(temp.categories).map((category, index1) =>
        Object.keys(temp.categories[category].screens).map((screen, index) => {
          temp.categories[category].screens[screen].links = temp?.categories[
            category
          ]?.screens[screen]?.links?.filter((link, idx) => {
            if (link?.route) {
              return (
                link.route != `${screenCategoryToDelete}.${screenKeyToDelete}`
              );
            }
            return true;
          });
        })
      );
      if (
        temp?.categories[screenCategoryToDelete]?.screens[screenKeyToDelete]
      ) {
        delete temp?.categories[screenCategoryToDelete]?.screens[
          screenKeyToDelete
        ];
      }

      post('/magnets/template/screen/delete', {
        screen_key: screenKeyToDelete,
        category_key: screenCategoryToDelete,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });
      console.log('delete template after processing', temp);
      return {
        ...state,
        template: temp,
      };

    case 'addCategory':
      /* USAGE:
              dispatch({
                  type: 'addCategory',
                  category_title: "Personal Help" (new category title),
              })}
              */
      const categoryTitleToAdd = payload.category_title.trim();
      const categoryKeyToAdd = createCategoryKey(categoryTitleToAdd);
      console.log('category', categoryKeyToAdd);
      // ensure that new category key is not already in the category list
      if (state.template.category_keys.includes(categoryKeyToAdd)) {
        return state;
      }
      console.log('state', state);
      var temp_newcategory = {
        ...state.template,
        categories: {
          ...state.template.categories,
          [categoryKeyToAdd]: {
            title: categoryTitleToAdd,
            screens: {},
            screen_keys: [],
          },
        },
        category_keys: [...state.template['category_keys'], categoryKeyToAdd],
      };

      // // console.log(temp_newcategory);

      post('/magnets/template/category/new', {
        category_key: categoryKeyToAdd,
        category_title: categoryTitleToAdd,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });

      return {
        ...state,
        template: temp_newcategory,
      };

    case 'addScreen':
      // ADD ITEM TO SCREEN KEYS
      const screenToAdd = payload.screen;
      const screenKeyToAdd = payload.screen_key;
      const screenCategory = payload.category_key;
      // INSERT SCREEN INTO THE RIGHT CATEGORY

      // VALIDATION CHECKS
      // NO PERIOD IN TITLE OR CATEGORY NAME, NO WORD NEW IN SCREEN TITLE, NO DUPLICATE SCREEN NAME IN THE SAME CATEGORY, ENSUR EIT HAS ALL THE ITEM IN SCREEN TO ADD
      // ADD ITEM TO SCREEN KEYS
      // INSERT SCREEN INTO THE RIGHT CATEGORY
      // ENSURE that there is a video in screen

      // check if screenToAdd is already a part of the current state
      // if (state.categories[screenCategory].screen_keys.includes(screenKeyToAdd)) {
      //   throw "screen key already exists, try again with a different screen key";
      // }

      console.log('add screen func...', payload.screen);
      console.log('addScreen payload that we got', payload);
      console.log(
        'addNewScreenKey, newScreenKey ',
        payload.screen_key,
        screenKeyToAdd
      );
      console.log(
        'addNewCategoryScreen, addCategoryScreen ',
        payload.category_key,
        screenCategory
      );
      // console.log('creating an new screen template: ', new_template);

      // We should not add any screen if the screen key is 'new'
      if (screenKeyToAdd === 'new') {
        return;
      }

      post('/magnets/template/screen/new', {
        screen_key: screenKeyToAdd,
        category_key: screenCategory,
        screen: screenToAdd,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      }).then((res) => console.log('REsults are = ', res));

      return {
        ...state,
        template: {
          ...state.template,
          categories: {
            ...state.template.categories,
            [screenCategory]: {
              ...state.template.categories[screenCategory],
              screens: {
                ...state.template.categories[screenCategory].screens,
                [screenKeyToAdd]: screenToAdd,
              },
              screen_keys: [
                ...state.template.categories[screenCategory].screen_keys,
                screenKeyToAdd,
              ],
            },
          },
        },
      };

    case 'updateState':
      console.log('updating state', payload, 'template: ', state);
      return { ...state, template: payload };

    case 'updateCategory':
      /* USAGE:
              dispatch({
                  type: 'updateCategory',
                  category_key: "floor_plans" (ie: category key to update),
                  category_new_title: "New book type" (category new title)
              })}
              */

      // check that a category key exists and a new title
      if (!('category_key' in payload) || !('category_new_title' in payload)) {
        return state;
      }

      // create a new category key based on the new title
      const categoryKeyToUpdate = payload.category_key;
      const categoryNewTitle = payload.category_new_title;

      var updated_template = {
        ...state.template,
        categories: {
          ...state.template.categories,
          [categoryKeyToUpdate]: {
            ...state.template.categories[categoryKeyToUpdate],
            title: categoryNewTitle,
          },
        },
      };

      post('/magnets/template/category/update', {
        category_key: categoryKeyToUpdate,
        category_title: categoryNewTitle,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });

      // console.log(updated_cat_obj);
      return { ...state, template: updated_template };

    case 'reorderCategory':
      /* USAGE:
            dispatch({
                type: 'reorderCategory',
                categoryKeysReordered: ["intro", "main"] (ie: category keys to update),
            })}
            */

      const categoryKeysReordered = payload.category_keys_reordered;
      var temp_reordercategory = {
        ...state.template,
        category_keys: [...categoryKeysReordered],
      };

      //console.log(temp_reordercategory);

      post('/magnets/template', {
        template: temp_reordercategory,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });

      //console.log('reordered');

      return { ...state, template: temp_reordercategory };

    case 'loadExisting':
      return state;

    case 'removeCategory':
      /* USAGE:
              dispatch({
                  type: 'removeCategory',
                  category_key: "Personal Help" (ie: key of the  title to remove),
              })}
              */
      if (!('category_key' in payload)) {
        return state;
      }
      const categoryKeyToDelete = payload.category_key;

      const temp3 = state;

      if (!(categoryKeyToDelete in temp3.template.categories)) {
        return { template: temp3 };
      }

      console.log('templatee', state.template);

      const screensToDelete =
        state.template.categories[categoryKeyToDelete].screen_keys;

      screensToDelete.map((screen) => {
        const filesToDelete =
          state.template.categories[categoryKeyToDelete].screens[screen]
            .cloudLibrary;

        console.log('files to deleted are ', filesToDelete);
        if (filesToDelete) {
          filesToDelete.map((video) => {
            if (!video.origName) {
              axios({
                method: 'POST',
                url: API_HOST + `/delete_file?old_file=` + video.dataURL,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
            }
          });
        }
      });

      let category_list_filtered = state.template?.category_keys;
      category_list_filtered = category_list_filtered.filter(
        (category_k) => category_k !== categoryKeyToDelete
      );

      //console.log('removed category from list: ', category_list_filtered);

      var filtered_out_categories = {};

      Object.keys(state.template.categories).map((tk) => {
        // console.log('indi: ', tk);

        if (tk !== categoryKeyToDelete) {
          filtered_out_categories[tk] = state.template.categories[tk];
        }
      });

      //console.log('filtered out a category: ', filtered_out_categories);

      Object.keys(filtered_out_categories).map((category, index1) =>
        Object.keys(filtered_out_categories[category].screens).map(
          (screen, index) => {
            filtered_out_categories[category].screens[screen].links =
              filtered_out_categories[category]?.screens[screen].links?.filter(
                (link, idx) => {
                  if (link?.route) {
                    //console.log('link new: ', link);
                    let [categoryk, screenk] = link.route.split('.');

                    return categoryk !== categoryKeyToDelete;
                  }

                  return true;
                }
              );
          }
        )
      );

      //console.log('updated keys in category:  ', filtered_out_categories);

      var temp_removed_category = {
        ...state.template,
        categories: {
          ...filtered_out_categories,
        },
        category_keys: category_list_filtered,
      };

      //console.log('final res: ', temp_removed_category);

      console.log('/magnets/template/category/delete', {
        category_key: categoryKeyToDelete,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });
      post('/magnets/template/category/delete', {
        category_key: categoryKeyToDelete,
        magnet_uuid: payload.magnet_id,
        magnet_type: payload.magnet_type,
      });

      // console.log(updated_cat_obj);
      return { ...state, template: temp_removed_category };
    case 'GET_MAGNET_SUCCESS':
      return {
        ...state,
        magnetLoading: false,
        magnetData: payload,
        template: payload.magnets[0].magnet_details.template,
      };
    case 'GET_MAGNET_FAIL':
      return {
        ...state,
        magnetLoading: false,
        magnetError: payload,
      };
    default:
      return state;
  }
};

const magnetStates = (
  state = {
    accessLevel: null,
    screenToEdit: '',
    encodingQ: [],
    magnetId: null,
    magnetType: null,
    newVideoTitle: '',
    loadScreen: [],
    currVid: [],
    currentVideo: '',
    showProgressBar: false,
    potentiallyUploading: 0,
    newScreenCloudLibrary: [],
    newScreenVideoCurrVid: [],
    newScreenFormTemplate: {
      enabled: false,
      inputs: [],
      text_data: {
        title: '',
        body_text: '',
        submit_success: 'Your submission has been received!',
        submit_fail:
          'Oops! Something went wrong while submitting the form. Please try again in a moment.',
        submit_body:
          'You can share this tour with a friend using the buttons below.',
      },
      contact: {
        enabled: false,
      },
      opacity: 0.5,
      text_color: 'black',
    },
    mewScreenIframeOption: {
      enabled: false,
      src: '',
      icon_color: '#ffffff',
      background_color: '#000000',
    },
    previewTemplate: {
      showTemplatesDialog: false,
      showConfirmTemplateDialog: false,
      selectedTemplate: '',
      enablePreview: false,
    },
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_ACCESS_LEVEL':
      return { ...state, accessLevel: payload };
    case 'SCREEN_TO_EDIT':
      return { ...state, screenToEdit: payload };
    case 'ENCODING_Q':
      return { ...state, encodingQ: payload };
    case 'MAGNET_ID':
      return { ...state, magnetId: payload };
    case 'MAGNET_TYPE':
      return { ...state, magnetType: payload };
    case 'NEW_VIDEO_TITLE':
      return { ...state, newVideoTitle: payload };
    case 'ADD_SCREEN':
      return { ...state, loadScreen: payload };
    case 'SHOW_PROGRESS_BAR':
      return { ...state, showProgressBar: payload };
    case 'SET_CURR_UPLOADING_VIDEO':
      return { ...state, currentVideo: payload };
    case 'LOAD_SCREEN':
      const thepayload = state.loadScreen.map((item) => {
        if (item.name == payload.name) {
          return {
            ...item,
            progress: payload.progress,
            timeestimate: payload.timeestimate,
            screen_name: payload.screen_name,
            screen_id: payload.screen_id,
          };
        } else {
          return item;
        }
      });
      return { ...state, loadScreen: thepayload };
    case 'CURR_VID':
      return { ...state, currVid: payload };
    case 'POTENTIALLY_UPLOADING':
      return { ...state, potentiallyUploading: payload };
    case 'PREVIEW_TEMPLATE':
      return { ...state, previewTemplate: payload };
    case 'NEW_SCREEN_VIDEO_CLOUD_LIBRSRY':
      return { ...state, newScreenCloudLibrary: payload };
    case 'NEW_SCREEN_VIDEOS_CURRVID':
      return { ...state, newScreenVideoCurrVid: payload };
    case 'NEW_SCREEN_FORM_TEMPLATE':
      return { ...state, newScreenFormTemplate: payload };
    case 'NEW_SCREEN_IFRAME_OPTION':
      return { ...state, newScreenIframeOption: payload };
    default:
      return state;
  }
};

export { getMagnet, magnetStates };
