import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useRef, useState } from 'react';
import ButtonDialog from '../../components/Reusable/ButtonDialog';
// get entrata alerts
import { post } from '../../utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function EmailListIntegration({
  uuid,
  integrationKey,
  emails,
  setEmails,
  title,
  note,
  icon,
  image,
  color,
  includeStartDateTime,
  sendTestEmail,
}) {
  const classes = useStyles();
  const [currEmail, setCurrEmail] = useState();
  const [emailError, setEmailError] = useState();

  const emailRef = useRef();
  function handleSubmitEmail(email = currEmail) {
    const re = /^[^\s@]+@[^\s@]+$/;
    if (emails.team_email?.includes(email.toLowerCase())) {
      setEmailError('Email already entered');
    } else if (re.test(email)) {
      setEmailError();
      let temp = emails.team_email.split(',').filter((o) => o);
      temp.push(email.toLowerCase());
      setEmails({ ...emails, team_email: temp.join(',') });
      setCurrEmail('');
      emailRef.current.value = '';
    } else {
      setEmailError('Please enter a valid email address');
    }
  }

  const [selectedDate, setSelectedDate] = useState(
    new Date('2014-08-18T21:11:54')
  );

  function handleDateChange(date) {
    setSelectedDate(date);
    setEmails({ ...emails, date_time: date.toISOString() });
  }

  useEffect(() => {
    if (includeStartDateTime) {
      if (emails.date_time) {
        setSelectedDate(emails.date_time);
      } else {
        const today = new Date();
        setSelectedDate(today);
      }
    }
  }, [emails?.date_time]);

  return (
    <ButtonDialog
      title={title}
      image={image}
      icon={icon}
      width={'150px'}
      height={'150px'}
      color={color}
      customButtonText="Save"
      customButtonFunction={async () => {
        setEmailError();
        const res = await post('/integration/details', {
          magnet_uuid: uuid,
          data: {
            [integrationKey]: emails,
          },
        });
        if (res.error) {
          setEmailError('Unable to update integrations please try again');
          return true;
        } else {
          return false;
        }
      }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item xs={12}>
          {note}
          <br />
          {emails.team_email
            ?.split(',')
            .filter((o) => o)
            .map((email, idx) => (
              <Chip
                color="primary"
                style={{ marginLeft: 5, marginTop: 5 }}
                label={email}
                onDelete={() => {
                  let temp = emails.team_email.split(',');
                  temp = temp.filter((val, i) => i !== idx);
                  setEmails({ ...emails, team_email: temp.join(',') });
                }}
              />
            ))}
        </Grid>
        <Grid item xs={12}>
          <Paper component="form" className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Email"
              inputRef={emailRef}
              onChange={(e) => {
                setCurrEmail(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmitEmail(e.target.value);
                }
              }}
              inputProps={{ 'aria-label': 'Add email' }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={() => {
                handleSubmitEmail();
              }}
              aria-label="directions"
            >
              <AddIcon />
            </IconButton>
          </Paper>
          {emailError && <Alert severity="error">{emailError}</Alert>}
        </Grid>
        {includeStartDateTime && (
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                fullWidth
                label="Select first occurrence"
                value={selectedDate}
                onChange={(date) => handleDateChange(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={emails.live}
                onChange={(event) =>
                  setEmails({
                    ...emails,
                    live: event.target.checked,
                  })
                }
                name="checkedLive"
                inputProps={{ 'aria-label': 'live checkbox' }}
              />
            }
            label="Go Live"
          />
          {sendTestEmail && (
            <Button onClick={() => sendTestEmail(emails.team_email)}>
              Send test email
            </Button>
          )}
        </Grid>
      </Grid>
    </ButtonDialog>
  );
}
