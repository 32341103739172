import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

/*
Description: Clickable list of large rectangular buttons, that are used to list the communities
Usage: 
pass in communityInfo which is a {} containing "name" & "url" & "id"
 - the page will redirect to  "redirectRoute" + id  (ie: /dashboard/132142-1231-123123) 

*/

const Community = (props) => {
  const {
    communityInfo = { id: '', title: '', url: '', img_url: '' },
    id,
    redirectRoute = '/dashboard/',
    redirectRouteBack = '/videos/',
    back = '',
    allowRedirect = true,
  } = props;

  const [image, setImage] = useState('');

  useEffect(() => {
    // fetch(
    //   `https://api.opengraphr.com/v1/og?api_token=zOoUHUX4mLywRUgL3UocGf7cPkyjKbuM4BPZi3wr0AxrjcRcqcX6aaKKIlDB8ms5cXqL0soSsn70FhMm&url=${communityInfo.url}/&tailgraph=1`
    // )
    //   .then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw response;
    //   })
    //   .then((data) => {
    //     // console.log('open: ', data);
    //     setImage(data?.image);
    //   });
  }, []);

  // useEffect(() => {
  //   async function getImagUrl() {
  //     const iframelyObj = await axios.get(
  //       `https://iframe.ly/api/oembed?url=${communityInfo.url}&api_key=6b1098ee260e0d21f9c7e3`
  //     );
  //     if (iframelyObj.data.thumbnail_url) {
  //       setImage(iframelyObj.data.thumbnail_url);
  //     }
  //   }
  //   getImagUrl();
  // }, []);

  return (
    <div key={id} className="cumunity-card flex flex-col">
      <Link
        to={
          allowRedirect &&
          `${redirectRoute + communityInfo.id}${redirectRouteBack}`
        }
        style={{ opacity: 1 }}
      >
        <div className="graphic relative">
          <div
            className="img"
            style={{
              backgroundImage: `url(${communityInfo.img_url})`,
              objectFit: 'cover',
            }}
          />
          <div
            style={{ background: 'rgba(0,0,0,0.4)' }}
            className=" w-full h-full top-0 left-0 graphic_hover z-10 absolute flex items-center justify-center"
          >
            <button className=" px-4 border py-2 text-sm rounded-3xl hover:bg-white hover:text-black text-gray-50 font-semibold">
              View Tour
            </button>
          </div>
        </div>
        <div className="meta flex flex-col">
          <div className="lbl font s15 b6 c000 wordwrap">
            {communityInfo.name}
          </div>
          <div className="url font s14 wordwrap">{communityInfo.url}</div>
        </div>
      </Link>
      <div className="ftr flex aic">
        <Link
          to={
            allowRedirect &&
            `${redirectRoute + communityInfo.id}/videos/?back=${back}`
          }
          className="ftr-btn font s13 b5 c000 anim"
        >
          Videos
        </Link>
        <Link
          to={
            allowRedirect &&
            `${redirectRoute + communityInfo.id}/settings/?back=${back}`
          }
          className="ftr-btn font s13 b5 c000 anim"
        >
          Manage
        </Link>
        <Link
          to={
            allowRedirect &&
            `${redirectRoute + communityInfo.id}/settings/?back=${back}`
          }
          className="ftr-btn font s13 b5 c000 anim"
        >
          Settings
        </Link>
      </div>
    </div>

    // <>
    //   <Card style={{ border: '1px solid rgb(0 0 0 / 5%)' }}>
    //     <CardActionArea
    //       className={classes.root}
    //       onClick={() => openDashboard(communityInfo.id)}
    //     >
    //       <CardMedia
    //         className={classes.media}
    //         image="/static/images/cards/contemplative-reptile.jpg"
    //         title="Contemplative Reptile"
    //       />
    //       <CardContent>
    //         <Typography gutterBottom variant="h5" component="h2">
    //           {communityInfo.name}
    //         </Typography>
    //         <Typography variant="body2" color="textSecondary" component="span">
    //           <a href={communityInfo.url} target="_blank" rel="noreferrer">
    //             {communityInfo.url}
    //           </a>
    //         </Typography>
    //       </CardContent>
    //       {/* <CardContent>
    //         <Typography gutterBottom variant="h5" component="h2">
    //           10 Leads
    //         </Typography>
    //         <Typography variant="body2" color="textSecondary" component="span">
    //           this week
    //         </Typography>
    //       </CardContent> */}
    //     </CardActionArea>
    //     {allowRedirect && (
    //       <CardActions>
    //         <Button
    //           size="small"
    //           color="primary"
    //           onClick={() => openCommunityTab('videos')}
    //         >
    //           videos
    //         </Button>
    //         <Button
    //           size="small"
    //           color="primary"
    //           onClick={() => openCommunityTab('settings')}
    //         >
    //           Manage
    //         </Button>
    //         <Button
    //           size="small"
    //           color="primary"
    //           onClick={() => openCommunityTab('settings')}
    //         >
    //           Settings
    //         </Button>
    //       </CardActions>
    //     )}
    //   </Card>
    //   <br />
    // </>
  );
};

export default Community;
