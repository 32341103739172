import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));
const ManageChampionMember = () => {
  const [adduserbadgeOpen, setAdduserbadgeOpen] = useState(false);
  const [addsubcategoryOpen, setAddsubcategoryOpen] = useState(false);
  const [addcategoryOpen, setAddcategoryOpen] = useState(false);
  const [addquestionOpen, setaddquestionOpen] = useState(false);
  const [reviewPostModel, setReviewPostModel] = useState({
    name: '',
    email: '',
    rating: '',
    badge: [],
    text: [],
    video: [],
  });
  const { community_id } = useParams();

  const [text, setText] = useState([
    {
      text: '',
      index: null,
    },
  ]);
  const [newquestion, setnewquestion] = useState({
    question: '',
    inputvideo: true,
    required: false,
  });
  console.log('newquestion', newquestion);
  const [selectedchampions, setselectedchampions] = useState('');

  const [directualCommunity, setDirectualCommunity] = useState({
    // id: 'temporary',
    name: 'Marshall Lousville',
    website: 'https://themarshalllouisville.com/', //(optional)
    communitybadges: [
      //   { name: 'Greate amendies', category: '' },
    ],

    champions: [
      // { profile: '', name: 'amulya', email: 'parmar@gmai.com' }
    ],

    //credits: 0,
    settings: {
      logo: 'https://i.imgur.com/NrZ3kph.png',
      address: '', //(optional)
      phone: '', // (optional),
      championssettings: {
        Bedroom: [{ subcategory: 'Workers' }, { subcategory: 'family' }],
        Dogs: [],
      },
      reviewSettings: {
        googleReviews:
          'https://search.google.com/local/writereview?placeid=ChIJsbivTn4F9YgR2YmRg350ljg&pli=1',
        form: {
          rating: false,
          video: true,
          text: true,
          name: true,
          email: true,
          questions: [], // if empt
        },
        goals: {
          CurrentReviewCount: 2,
          CurrentRating: 2,
          GoalRating: 2,
          ReviewScoreExpected: 5,
          GoalNumberofReviews: 2,
        },
      },
      referralSettings: {
        demographics: {},
      },
    },
  });
  const updatequestions = () => {
    if (directualCommunity.questions) {
      setDirectualCommunity({
        ...directualCommunity,
        questions: [...directualCommunity.questions, { ...newquestion }],
      });
    } else {
      setDirectualCommunity({
        ...directualCommunity,
        questions: [{ ...newquestion }],
      });
    }
    setnewquestion({
      question: '',
      inputvideo: true,
      inputtext: true,
      required: false,
    });
    setaddquestionOpen(false);
  };
  console.log('directualCommunity', directualCommunity);
  const [badgeName, setBadgeName] = useState('');
  const [newcategory, setnewcategory] = useState('');
  const [newsubcategory, setnewsubcategory] = useState('');
  const addBadge = (e) => {
    setAdduserbadgeOpen(false);
    //e.preventDefault();
    setDirectualCommunity({
      ...directualCommunity,
      communitybadges: [
        ...directualCommunity.communitybadges,
        { name: badgeName, category: '' },
      ],
    });
    setBadgeName('');
  };
  const addcategory = (e) => {
    setAddcategoryOpen(false);
    e.preventDefault();
    setDirectualCommunity({
      ...directualCommunity,
      settings: {
        ...directualCommunity.settings,
        championssettings: {
          ...directualCommunity.settings.championssettings,
          [newcategory]: [],
        },
      },
    });
    setnewcategory('');
  };
  const addsubcategory = (e) => {
    setAddsubcategoryOpen(false);
    e.preventDefault();
    setDirectualCommunity({
      ...directualCommunity,
      settings: {
        ...directualCommunity.settings,
        championssettings: {
          ...directualCommunity.settings.championssettings,
          [selectedchampions]: [
            ...directualCommunity.settings.championssettings[selectedchampions],
            { subcategory: newsubcategory },
          ],
        },
      },
    });
  };
  // console.log(
  //   'championssettings',
  //   directualCommunity?.settings?.championssettings[selectedchampions]
  // );
  console.log('championssettings', selectedchampions);
  const updatecommunity = async () => {
    try {
      let postData = {
        id: community_id,
        ...directualCommunity,
        communitybadges: {
          communitybadges: directualCommunity.communitybadges,
        },
        team: {
          team: directualCommunity.team,
        },
        reviews: {
          reviews: directualCommunity.reviews,
        },
        champions: {
          champions: directualCommunity.champions,
        },
        questions: {
          questions: directualCommunity.questions,
        },
      };
      console.log('postData ', postData);
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
    } catch (error) {
      console.log('error', error);
      console.log('error', error.response);
    }
  };
  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res);
    // console.log(
    //   'response',
    //   JSON.parse(res.data?.payload[0].settings)
    // );

    if (res.data) {
      try {
        let thejson = {};
        if (res.data?.payload[0]?.communitybadges) {
          thejson.communitybadges = JSON.parse(
            res.data?.payload[0].communitybadges
          ).communitybadges;
        }
        if (res.data?.payload[0]?.champions) {
          thejson.champions = JSON.parse(
            res.data?.payload[0].champions
          ).champions;
        }
        if (res.data?.payload[0]?.reviews) {
          thejson.reviews = JSON.parse(res.data?.payload[0].reviews).reviews;
        }
        if (res.data?.payload[0]?.settings) {
          thejson.settings = JSON.parse(res.data?.payload[0]?.settings);
        }
        if (res.data?.payload[0]?.team) {
          thejson.team = JSON.parse(res.data?.payload[0].team).team;
        }
        if (res.data?.payload[0]?.questions) {
          thejson.questions = JSON.parse(
            res.data?.payload[0].questions
          ).questions;
        }
        // let thejson = {
        //   ...res.data?.payload[0],
        //   champions: JSON.parse(res.data?.payload[0].champions).champions,
        //   communitybadges: JSON.parse(res.data?.payload[0].communitybadges)
        //     .communitybadges,
        //   reviews: JSON.parse(res.data?.payload[0].reviews).reviews,
        //   settings: JSON.parse(res.data?.payload[0].settings),
        //   team: JSON.parse(res.data?.payload[0].team).team,
        //   questions: JSON.parse(res.data?.payload[0].questions).questions,
        // };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        console.log('thejson', thejson);
        setDirectualCommunity({ ...thejson });
        console.log('after', directualCommunity);
        // setcreatecommunityenable(true);
        // setloading(false);
      } catch (error) {
        console.log('errr', error);
      }
    } else {
      console.log('response_empty');
    }
  };
  useEffect(() => {
    getcommunity();
  }, []);

  const [index, setIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const handleChangetext = (ind) => {
    if (text.find((e) => e.index !== ind)) {
      setSelectedIndex([...selectedIndex, ind]);
    }
  };

  const [rating, setRating] = useState(3.5);

  const Badge = ({ item }) => {
    console.log(item);
    const [selectBadge, setSelectBadge] = useState(false);
    return (
      <p
        onClick={() => setSelectBadge(!selectBadge)}
        className={
          selectBadge === false
            ? 'bg-gray-200 cursor-pointer px-3 text-xs py-2 rounded-3xl flex items-center gap-1'
            : 'bg-pr text-white cursor-pointer px-3 text-xs py-2 rounded-3xl flex items-center gap-1'
        }
      >
        {selectBadge === false ? (
          <AiOutlinePlus className=" w-2 h-2" />
        ) : (
          <IoCheckmarkOutline />
        )}{' '}
        {item}
      </p>
    );
  };

  return (
    <div className=" w-full flex items-center justify-center min-h-screen">
      <div className=" w-full lg:w-96  border-2 border-gray-500 rounded-md p-3 shadow-lg bg-white">
        <div className=" w-full">
          <p className=" text-sm font-semibold">Champions settings :</p>
          <div className="flex flex-row">
            <p className=" text-sm font-semibold">Categories :</p>
            <Select
              //   labelId="select "
              //   id="select screen"
              value={selectedchampions}
              placeholder="Select category"
              onChange={(event) => {
                setselectedchampions(event.target.value);
                // setDefaultEmbedFields({
                //   ...defaultEmbedFields,
                //   showButton: event.target.value,
                // });
              }}
              //   disableUnderline
              style={{
                border: '1px solid #ddd',
                padding: '0px 4px 0px 4px',
                borderRadius: '4px',
              }}
            >
              {directualCommunity?.settings?.championssettings
                ? Object.keys(
                    directualCommunity?.settings?.championssettings
                  )?.map((item, index) => (
                    <MenuItem
                      //key={index}
                      value={item}
                      style={{ backgroundColor: '#fff' }}
                    >
                      {item}
                    </MenuItem>
                  ))
                : ''}
            </Select>
            <div className="w-full flex flex-end items-end gap-2">
              {!addcategoryOpen ? (
                <button
                  onClick={() => setAddcategoryOpen(true)}
                  className=" bg-gray-100 border rounded-sm p-2 flex items-center gap-2"
                >
                  <p className=" text-sm font-bold"> Add new category hh</p>{' '}
                  <AiOutlinePlus />
                </button>
              ) : (
                <form className=" flex" onSubmit={(e) => addcategory(e)}>
                  <input
                    type="text"
                    autoFocus
                    onChange={(e) => setnewcategory(e.target.value)}
                    required
                    className=" py-1 border rounded-sm px-2 w-32"
                  />
                </form>
              )}
            </div>
          </div>

          {directualCommunity?.settings?.championssettings
            ? directualCommunity?.settings?.championssettings[
                selectedchampions
              ]?.map((item, ind) => (
                <button
                  key={ind}
                  className=" bg-gray-100 rounded-sm button_hover p-2 flex items-center gap-2"
                >
                  <p className=" text-sm text-gray-900">{item.subcategory}</p>
                  <p
                    onClick={() => {
                      let communitybadges = directualCommunity?.communitybadges;
                      communitybadges.splice(ind, 1);
                      setDirectualCommunity({
                        ...directualCommunity,
                        communitybadges,
                      });
                    }}
                    className="  border-l cursor-pointer text-sm show_element bg-gray-600 text-white p-1 rounded-full"
                  >
                    <IoMdClose className=" w-4 h-4" />
                  </p>
                </button>
              ))
            : ''}
          {selectedchampions && (
            <div className="w-full flex items-end gap-2">
              {!addsubcategoryOpen ? (
                <button
                  onClick={() => setAddsubcategoryOpen(true)}
                  className=" bg-gray-100 border rounded-sm p-2 flex items-center gap-2"
                >
                  <p className=" text-sm font-bold"> Add new subcategory</p>{' '}
                  <AiOutlinePlus />
                </button>
              ) : (
                <form className=" flex" onSubmit={(e) => addsubcategory(e)}>
                  <input
                    type="text"
                    autoFocus
                    onChange={(e) => setnewsubcategory(e.target.value)}
                    required
                    className=" py-1 border rounded-sm px-2 w-32"
                  />
                </form>
              )}
            </div>
          )}
          <div className="flex">
            <button
              onClick={updatecommunity}
              className="content-end	 bg-gray-100 border rounded-sm p-2 flex items-center gap-2"
            >
              <p className=" text-sm font-bold"> Save Settings</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageChampionMember;
