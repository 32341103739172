import React, { useEffect, useState } from 'react';
//Tabler
import Navbar from '../../components/Navbar';
import firebase from '../../utils/base';
import { uploadFile } from '../../utils/files';
import './Settings.css';

export default function ResetPassword() {
  return (
    <>
      <Navbar />
      <Profile />
    </>
  );
}

function Profile() {
  // design of the GET http request for querying communities given a UID
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [user, setUser] = useState();
  const [passwordUpdate, setPasswordUpdate] = useState(false);

  // User Input States for update
  const [fullName, setFullName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [progress, setProgress] = useState('');

  const [errorPhone, setErrorPhone] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  useEffect(() => {
    firebase.db
      .collection('users')
      .doc(firebase.uid())
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUser(doc.data());
          setFullName(doc.data().name);
          setEmail(doc.data().email);
          setImage(doc.data().image ? doc.data().image : '');
          setRole(doc.data().role ? doc.data().role : '');
          setPhone(doc.data().phone ? doc.data().phone : '');
        }
      })
      .catch((err) => {
        console.log('Error getting document:', err);
      });
  }, []);

  //Update my profile
  async function onUpdate(e) {
    e.preventDefault();
    setProgress('');
    var userRef = await firebase.db.collection('users').doc(firebase.uid()); //current user collection

    if (!passwordUpdate && password) {
      //Check passwords match
      if (password !== confirmPassword) {
        setErrorPassword("Password and confirm password don't match");
      } else {
        firebase
          .user()
          .updatePassword(password)
          .then(function () {
            console.log('Updated password!');
            setPasswordUpdate(true);
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            setErrorPassword(error.message);
            console.error('Error updating password: ', error);
          });
      }
    } else {
      setErrorPassword('');
      let error_ = false;
      const details = {};
      if (fullName && fullName !== user.name) {
        details.name = fullName;
      }
      if (role && role !== user.role) {
        details.role = role;
      }
      if (email && email !== user.email) {
        const emailPattern = /^\S+@\S+\.\S+/;
        //Check email format is correct
        if (!emailPattern.test(email)) {
          setErrorEmail('Invalid email address');
          error_ = true;
        } else {
          details.email = email;
          setErrorEmail('');
        }
      }
      if (phone && phone !== user.phone) {
        const phonePattern =
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;
        //Check phone format is correct
        if (!phonePattern.test(phone)) {
          setErrorPhone('Invalid phone number');
          error_ = true;
        } else {
          setErrorPhone('');
          details.phone = phone;
        }
      }
      if (image) {
        details.image = image;
      }

      if (error_) {
      } else if (fullName && email && phone && role && image) {
        details.old_user = 1;
        await userRef
          .update(details)
          .then(function () {
            console.log('Updated user!');
            window.location.reload();
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error('Error updating user: ', error);
            error_ = true;
          });
      } else {
        alert('Please fill all details.');
      }
    }
  }
  const handleUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const url = await uploadFile(
          e.target.files[0],
          setProgress,
          'firebaseUser',
          `images/` + firebase.uid()
        );
        if (url) {
          console.log('upload url....', url);
          setImage(url);
          setProgress(null);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="magnet-profile-page">
      {user && (
        <div className="content">
          {!passwordUpdate ? (
            <>
              <div className="hdr">
                <div className="title font">Reset Password</div>
                <div className="txt font">
                  Welcome to LeaseMagnets{user ? `, ${user.name}!` : '!'}
                </div>
              </div>
              <div className="form flex flex-col">
                <div className="field">
                  <div className="lbl font">Password</div>
                  <input
                    placeholder="Enter your new password"
                    className="iput font"
                    type="password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="field">
                  <div className="lbl font">Confirm Password</div>
                  <input
                    placeholder="Enter Confirm new password"
                    className="iput font"
                    type="password"
                    name="confirm-password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                <button className="form-btn font" onClick={onUpdate}>
                  Save
                </button>
                <div className="error-msg font">{errorPassword}</div>
              </div>
            </>
          ) : (
            <>
              <div className="hdr">
                <div className="title font">Update Profile</div>
                <div className="txt font">
                  Welcome to LeaseMagnets{user ? `, ${user.name}!` : '!'}
                </div>
              </div>
              <div className="user-dp rel">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${
                      image ? image : require('../../assets/ava.png').default
                    })`,
                  }}
                />
                <button
                  className="cleanbtn img-btn font"
                  onClick={() => document.getElementById('_file_input').click()}
                  disabled={progress}
                >
                  {`${progress ? 'Uploading...' : 'Change Image'}`}
                </button>
                <input
                  type="file"
                  accept="image/*"
                  id="_file_input"
                  className="hide-element"
                  onChange={handleUpload}
                />
              </div>
              <div className="form flex flex-col">
                <div className="field">
                  <div className="lbl font">Name</div>
                  <input
                    placeholder="Enter your Full Name"
                    className="iput font"
                    name="fullname"
                    type="text"
                    defaultValue={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="field">
                  <div className="lbl font">Email</div>
                  <input
                    placeholder="Enter Your Email"
                    className="iput font"
                    name="email"
                    type="email"
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errorEmail && <div className="msg font">{errorEmail}</div>}
                </div>
                <div className="field">
                  <div className="lbl font">Phone Number</div>
                  <input
                    placeholder="Enter Phone Number"
                    className="iput font"
                    type="tel"
                    defaultValue={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {errorPhone && <div className="msg font">{errorPhone}</div>}
                </div>
                <div className="field">
                  <div className="lbl font">Role</div>
                  <input
                    placeholder="Enter Role"
                    className="iput font"
                    name="role-in-org"
                    type="text"
                    defaultValue={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                  <div style={{ color: 'red' }}> {errorPhone} </div>
                </div>
                <div className="field">
                  <div className="lbl font">Image Url</div>
                  <input
                    placeholder="Enter Image Url"
                    className="iput font"
                    name="image"
                    placeholder="Image"
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  />
                  <div style={{ color: 'red' }}> {errorPhone} </div>
                </div>

                <button className="form-btn font" onClick={onUpdate}>
                  Save
                </button>
                <div className="error-msg font">{errorPassword}</div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
