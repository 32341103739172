import { TextField } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import debounce from '../../utils/debounce';

const DEBOUNCE = 500; // ms

// Note that `onChange` receives the new value, NOT the event
const DebouncedTextField = ({ value, onChange, ...props }) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => setInternalValue(value), [value]);
  // Yes, React doesn't understand all the useCallback dependencies, but that's OK,
  // we know that `debounce` won't change
  // If we ever add ESLint, we can disable react-hooks/exhaustive-deps for this line
  const debouncedOnChange = useCallback(
    onChange && debounce(onChange, DEBOUNCE),
    [onChange]
  );
  return (
    <TextField
      value={internalValue || ''}
      onChange={(event) => {
        // Gotta get the target.value here, otherwise React v16's event pooling
        // clears the data before the debounced function runs async'ly
        const newValue = event.target.value;
        setInternalValue(newValue);
        onChange && debouncedOnChange(newValue);
      }}
      {...props}
    />
  );
};

export default DebouncedTextField;
