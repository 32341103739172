import { MdClose } from 'react-icons/md';
import tygImage from '../../assets/videos/website-icon-TYG.svg';
const VideoSidebar = ({
  screensCollection,
  currentVideoIndex,
  onVideoChange,
  onClose,
}) => {
  return (
    <div className=" h-screen overflow-y-scroll w-full bg-gray-100 p-4">
      <div className="md:hidden relative w-full flex items-end justify-end mb-2">
        <button
          className="right-0 cursor-pointer"
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          <MdClose className="w-6 h-6" />
        </button>
      </div>
      <div className=" w-full  h-screen overflow-y-scroll ">
        <p className=" text-sm pb-2 font-medium">
          👋{new URLSearchParams(window.location.search).get('name')} We built
          this tour for you
        </p>
        {screensCollection?.map((screen, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                if (onVideoChange) onVideoChange(index);
              }}
              className={`flex cursor-pointer mt-2  md:flex-col pb-2 pt-4 items-center  rounded overflow-hidden bg-white ${
                index === currentVideoIndex ? 'border-2 border-black' : ''
              }`}
            >
              <img
                className=" w-full h-16 object-contain"
                src={!screen?.img ? tygImage : screen?.img}
              />
              <p className="p-2 text-sm">{screen?.title?.slice(0, 16)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VideoSidebar;
