import React, { useEffect, useState } from 'react';
import { AiFillEye, AiOutlineSearch } from 'react-icons/ai';
import { BiFilterAlt } from 'react-icons/bi';
import {
  MdDownload,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import ReactPaginate from 'react-paginate';
const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const ComunityBilling = () => {
  const [active, setActive] = useState(0);
  const [down, setDown] = useState(false);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + 5;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 5));
  }, [itemOffset, 5]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 5) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className=" w-full">
      <div className=" pb-2 flex items-center justify-between">
        <h1 className=" text-xl">Community 1</h1>
        {down === false ? (
          <MdKeyboardArrowDown
            onClick={() => setDown(true)}
            className=" w-9 h-9 cursor-pointer"
          />
        ) : (
          <MdKeyboardArrowUp
            onClick={() => setDown(false)}
            className=" w-9 h-9 cursor-pointer"
          />
        )}
      </div>
      {down === false && (
        <>
          <div className=" bg-blue-100 w-full  flex items-center gap-8 px-3">
            <p
              onClick={() => setActive(0)}
              className={
                active === 0
                  ? 'py-3 font-medium text-black cursor-pointer'
                  : 'py-3 cursor-pointer text-gray-500'
              }
            >
              Billing
            </p>
            <p
              onClick={() => setActive(1)}
              className={
                active === 1
                  ? 'py-3 font-medium text-black cursor-pointer'
                  : 'py-3 cursor-pointer text-gray-500'
              }
            >
              Invoice
            </p>
            <p
              onClick={() => setActive(2)}
              className={
                active === 2
                  ? 'py-3 font-medium text-black cursor-pointer'
                  : 'py-3 cursor-pointer text-gray-500'
              }
            >
              Add Payment +
            </p>
          </div>
          <div className=" w-full flex items-center gap-3 justify-end pt-3">
            <div className=" flex items-center relative">
              <input
                style={{ padding: '6px' }}
                type="text"
                placeholder="Search by ref no"
                className=" w-full border text-xs bg-transparent rounded-sm font-semibold"
              />

              <AiOutlineSearch className=" text-gray-500 z-10 absolute top-2 right-1 cursor-pointer" />
            </div>
            <div className=" flex items-center relative">
              <input
                style={{ padding: '6px' }}
                type="text"
                placeholder="Filter by Month"
                className=" w-full border text-xs pl-4 bg-transparent rounded-sm font-semibold"
              />

              <BiFilterAlt className=" text-gray-500 z-10 absolute top-2 left-1 cursor-pointer" />
            </div>
          </div>

          <div className=" w-full    mt-4 overflow-x-scroll lg:overflow-hidden border rounded-md">
            <table className="min-w-full ">
              <thead className="bg-gray-50 border-b">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left    tracking-wider"
                  >
                    Invoice Ref#
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Billing Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Billing Month
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-2 text-left  tracking-wider"
                  >
                    Invoice
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white  ">
                {currentItems &&
                  currentItems.map((item) => (
                    <tr className=" border-b">
                      <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
                        <p>{item}</p>
                      </td>
                      <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
                        <p>$45</p>
                      </td>
                      <td
                        style={{ color: '#2BAE0A' }}
                        className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap"
                      >
                        <p>Paid</p>
                      </td>

                      <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
                        <p>06/04/2023</p>
                      </td>

                      <td className="px-4 py-3 text-gray-600 font-medium  whitespace-nowrap">
                        <p>July</p>
                      </td>
                      <td className="px-4 py-3 text-gray-600 font-medium  whitespace-nowrap flex items-center gap-4">
                        <AiFillEye className="text-pr w-6 h-6 cursor-pointer" />
                        <MdDownload className="text-pr w-6 h-6 cursor-pointer" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className=" flex items-center justify-end paginate_react mt-3">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ComunityBilling;
