import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import TheCustomTour from '../components/CustomTour/TheCustomTour';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import { getIsUserNewUser } from '../utils/routes';
import { SignOut, SignUp } from '../views';
import ManageCommunities from '../views/account-homepage/ManageCommunities';
import Messenger from '../views/account-homepage/Messenger';
import ResetPassword from '../views/account-homepage/ResetPassword';
import Settings from '../views/account-homepage/Settings';
import UpdateUser from '../views/account-homepage/UpdateUser';
import AnalyticsList from '../views/analytics/AnalyticsList';
import Billing from '../views/billing/Billing';
import ManageMagnet from '../views/manage-magnets/ManageMagnet';
import Upload from '../views/manage-magnets/Upload';
import RewardFundingRequest from '../views/rewards/RewardFundingRequests';
import ApartmentView from '../views/tour-magnet-tabs/ApartmentView';
import BigVideo from '../views/tour-magnet-tabs/BigVideo';
import BookingSchedule from '../views/tour-magnet-tabs/BookingSchedule';
import EmbedReview from '../views/tour-magnet-tabs/EmbedReview';
import PhoneAuthTest from '../views/tour-magnet-tabs/PhoneAuthTest';
import AnalyticsV from '../views/videoembed/Analytics';
import UnprotectedRoutes from './UnprotectedRoutes';

// View
const ProtectedRoutes = () => {
  // forces a new user to reset their password & complete their "profile" before accessing their dashboard, etc
  // can also be used to set up an onboarding process
  const [isNewUser, setIsNewUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    async function fetchData() {
      setIsNewUser(await getIsUserNewUser());
    }
    const queryParams = new URLSearchParams(location.search);
    if (location.pathname === '/signin' && queryParams.has('next')) {
      window.location.replace(queryParams.get('next'));
    } else {
      fetchData();
    }
  }, [location.pathname, location.search]);

  if (isNewUser) {
    return (
      <Switch>
        <Route path="/signout" exact>
          <SignOut />
        </Route>
        <Route path="/">
          <ResetPassword />
        </Route>
      </Switch>
    );
  }
  return (
    <>
      <Switch>
        <Route path="/vidanalytics" exact>
          <AnalyticsV />
        </Route>
        <Route path="/" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/billing" exact>
          <Billing />
        </Route>
        <Route path="/analytics" exact>
          <AnalyticsList />
        </Route>
        <Route path="/dashboard" exact>
          <ManageCommunities />
        </Route>
        <Route path="/signin" exact>
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/settings" exact>
          <Settings />
        </Route>
        <Route path="/update-user/:community_id/:userId" exact>
          <UpdateUser />
        </Route>
        <Route path="/messages" exact>
          <Messenger />
        </Route>
        <Route path="/dashboard/:community_id/:magnet_tab" exact>
          <ApartmentView />
        </Route>
        <Route path="/dashboard/:community_id/:magnet_tab/:options" exact>
          <ApartmentView />
        </Route>
        <Route path="/community/:community_id/:magnet_tab/:options" exact>
          <EmbedReview />
        </Route>
        <Route
          path="/dashboard/:community_id/:magnet_tab/:aptName/:staffName"
          exact
        >
          <ApartmentView />
        </Route>

        <Route path="/phone" exact>
          <PhoneAuthTest />
        </Route>
        <Route path="/scheduler" exact>
          <BookingSchedule />
        </Route>
        <Route path="/customtour" exact>
          <TheCustomTour />
        </Route>
        <Route path="/video_test" exact>
          <BigVideo />
        </Route>
        <Route path="/magnets/new" exact>
          <ManageMagnet />
        </Route>
        <Route path="/magnets/new/upload" exact>
          <Upload />
        </Route>
        <Route path="/magnets/:magnet_id/edit" exact>
          <ManageMagnet />
        </Route>
        <Route path="/signup/apartments" exact>
          <SignUp signupStep={2} />
        </Route>
        <Route path="/signup/billing" exact>
          <SignUp signupStep={3} />
        </Route>
        <Route path="/signout" exact>
          <SignOut />
        </Route>
        <Route path="/rewards/funding_requests" exact>
          <RewardFundingRequest />
        </Route>
        <Route path="/reset_password" exact>
          <ResetPassword />
        </Route>
        <UnprotectedRoutes authenticated={true} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default ProtectedRoutes;
