import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import { post } from '../../utils/request';
import MagnetScreenItem from './MagnetScreenItem';
import NewScreenButton from './NewScreenButton';
export default function MagnetSummaryRow({
  handleSnackbar,
  selectedVideos,
  setSelectedVideos,
  category_key,
  searchFilter,
  communityId,
  dragItem,
  index,
  key,
  reorderCategories,
  openMove,
  currVid,
  setCurrVid,
  analyticsdata,
}) {
  const { encodingQ, magnetId, magnetType, previewTemplate, screenToEdit } =
    useSelector((state) => state.magnetStates);

  const { template, magnetLoading } = useSelector((state) => state.getMagnet);
  const _dispatch = useDispatch();

  const category = template?.categories[category_key];
  const [screenKeys, setScreenKeys] = useState(category['screen_keys']);
  const stateRef = useRef();
  stateRef.current = [...screenKeys];

  const [mode, setMode] = useState('view');

  console.log(category_key, analyticsdata?.response?.category?.[category_key]);
  //console.log(category)

  const [newCategory, setNewCategory] = useState(category.title);

  useEffect(() => {
    setScreenKeys(category['screen_keys']);
  }, [category]);

  // Reorder a category
  async function reorderCategory(newIndex, screenKey) {
    const res = await post('/magnets/template/screen/reorder', {
      screen_key: screenKey,
      category_key: category_key,
      new_index: newIndex,
      magnet_uuid: magnetId,
      magnet_type: magnetType,
    });
    //console.log('results = ', res);
  }

  // Update a category
  function updateCategory() {
    _dispatch({
      type: 'updateCategory',
      payload: {
        category_key: category_key,
        category_new_title: newCategory,
        magnet_id: magnetId,
        magnet_type: magnetType,
      },
    });
    setMode('view');
  }

  // Delete a category
  function deleteCategory() {
    _dispatch({
      type: 'removeCategory',
      payload: {
        magnet_id: magnetId,
        magnet_type: magnetType,
        category_key: category_key,
      },
    });
    setMode('view');
  }

  const moveMagnetScreenItem = useCallback(
    (dragIndex, hoverIndex) => {
      const screenKeysTemp = [...stateRef.current];
      const dragMagnetScreenItem = screenKeysTemp[dragIndex];
      screenKeysTemp.splice(dragIndex, 1);
      screenKeysTemp.splice(hoverIndex, 0, dragMagnetScreenItem);
      stateRef.current = [...screenKeysTemp];
      reorderCategory(hoverIndex, dragMagnetScreenItem);

      setScreenKeys(screenKeysTemp);
    },
    [screenKeys]
  );

  const ref = useRef(null);

  return (
    <div ref={ref} className="sumary-row">
      {mode === 'view' && (
        <>
          <button
            onClick={() => setMode('edit')}
            className={`section-title cleanbtn font${
              previewTemplate.enablePreview ? 'disabled' : ''
            }`}
            disabled={previewTemplate.enablePreview}
          >
            {category.title}
            {/* <SettingsIcon fontSize="medium" style={{ color: '#000' }} /> */}
            <div className="title-icon icon-gear2" />
          </button>
          <div className="row-items-block">
            <NewScreenButton
              category={category.title}
              screen_keys={category['screen_keys']}
              screen_id={`${category_key}.new`}
              handleSnackbar={handleSnackbar}
              categoryTitle={category.title}
              currVid={currVid}
              setCurrVid={setCurrVid}
              key={
                `${category_key}.new` +
                encodingQ.includes(`${category}.new`).toString()
              }
            />
            {screenKeys
              .filter((screen_key) => {
                // SEARCH FILTER
                const screen = category.screens[screen_key];
                let searchResults = false;
                //console.log(`${category_key}.${screen_key} original obj`);

                // BASIC SEARCH QUERY not found
                if (
                  screen?.title
                    ?.toLowerCase()
                    .includes(searchFilter.query.toLowerCase())
                ) {
                  // console.log(
                  //   `screen key ${category_key}.${screen_key} missing: query`
                  // );
                  searchResults = true;
                }

                // BASIC SEARCH CAPTION not found
                if (
                  screen?.caption
                    ?.toLowerCase()
                    .includes(searchFilter.query.toLowerCase())
                ) {
                  // console.log(
                  //   `screen key ${category_key}.${screen_key} missing: caption`
                  // );
                  searchResults = true;
                }

                if (screen?.additionalInfo) {
                  // DETAILED DESCRIPTION
                  if (
                    screen?.additionalInfo.detailedDescription &&
                    screen?.additionalInfo.detailedDescription
                      .toLowerCase()
                      .includes(searchFilter.query.toLowerCase())
                  ) {
                    // console.log(
                    //   `screen key ${category_key}.${screen_key} missing: detailedDescription`
                    // );
                    searchResults = true;
                  }

                  // BASIC CAPTION SEARCH IF ADDITIONAL INFO IS VALID
                  if (screen?.additionalInfo.features) {
                    for (
                      let i = 0;
                      i < screen?.additionalInfo.features.length;
                      i++
                    ) {
                      if (
                        screen?.additionalInfo.features[i]
                          .toLowerCase()
                          .includes(searchFilter.query.toLowerCase())
                      ) {
                        searchResults = true;
                      }
                    }
                  }
                }

                return searchResults;
                // let priceRangeSearchFound = true;
                // // PRICE RANGE SEARCH will be by default true, and if proven inaccurate will return false & if unknown then will return true by default
                // if (screen?.additionalInfo) {
                //   console.log(
                //     searchFilter.minPrice,
                //     searchFilter.maxPrice,
                //     screen?.additionalInfo.price
                //   );
                //   if (searchFilter.minPrice && searchFilter.maxPrice) {
                //     if (
                //       !(
                //         searchFilter.minPrice <= screen?.additionalInfo.price &&
                //         searchFilter.minPrice >= screen.additionalInfo.price
                //       )
                //     ) {
                //       console.log('price range does not fit');
                //       priceRangeSearchFound = false;
                //     }
                //   }
                // }

                // if (screen?.additionalInfo) {
                //   console.log(
                //     searchFilter.minPrice,
                //     searchFilter.maxPrice,
                //     screen?.additionalInfo.price
                //   );
                //   if (searchFilter.minPrice && searchFilter.maxPrice) {
                //     if (
                //       !(
                //         searchFilter.minPrice <= screen?.additionalInfo.price &&
                //         searchFilter.minPrice >= screen.additionalInfo.price
                //       )
                //     ) {
                //       console.log('price range does not fit');
                //       priceRangeSearchFound = false;
                //     }
                //   }
                // }

                // let dateRangeSearchFound = true;
                // if (screen?.additionalInfo) {
                //   console.log(
                //     searchFilter.fromDate,
                //     searchFilter.toDate,
                //     screen?.additionalInfo.moveInDate
                //   );
                //   if (searchFilter.fromDate && searchFilter.toDate) {
                //     if (
                //       !(
                //         moment(searchFilter.fromDate) <=
                //           moment(screen?.additionalInfo.moveInDate) &&
                //         moment(searchFilter.toDate) >=
                //           moment(screen?.additionalInfo.moveInDate)
                //       )
                //     ) {
                //       console.log('price range does not fit');
                //       dateRangeSearchFound = false;
                //     }
                //   }
                // }

                // console.log(
                //   `screen key ${category_key}.${screen_key} bool results`,
                //   priceRangeSearchFound,
                //   searchResults
                // );

                // if (
                //   priceRangeSearchFound &&
                //   searchResults &&
                //   dateRangeSearchFound
                // ) {
                //   return true;
                // }
                // return false;
              })
              .map((screen_title, screen_idx) => {
                const screen = category.screens[screen_title];

                function addVideo(video_id) {
                  setSelectedVideos([...selectedVideos, video_id]);
                }

                function removeVideo(video_id_to_remove) {
                  setSelectedVideos(
                    selectedVideos.filter(
                      (video_id) => video_id !== video_id_to_remove
                    )
                  );
                }

                return (
                  <MagnetScreenItem
                    analyticsdata={
                      analyticsdata?.response?.category?.[category_key]
                    }
                    the_screen={screen}
                    video={screen?.video}
                    name={screen?.title}
                    screen_id={`${category_key}.${screen_title}`}
                    screen_title={screen_title}
                    handleSnackbar={handleSnackbar}
                    addVideo={addVideo}
                    removeVideo={removeVideo}
                    selectedVideos={selectedVideos}
                    key={
                      screen_idx +
                      encodingQ.includes(`${category}.${screen}`).toString()
                    }
                    encoding={encodingQ.includes(`${category}.${screen}`)}
                    dragItem={moveMagnetScreenItem}
                    index={screen_idx}
                    category={category_key}
                    openMove={openMove}
                  />
                );
              })}
          </div>
        </>
      )}
      {mode === 'edit' && (
        <div className="summary-row-edit-blk flex flex-col">
          <button
            className="cleanbtn font cancel-btn s15 b5 black flex aic"
            onClick={() => setMode('view')}
          >
            <div className="ico icon-arrow-left" />
            <span>Cancel</span>
          </button>
          <div className="edit-row-content flex aic">
            <div className="form flex aic">
              <div className="field">
                <DebouncedTextField
                  label="Edit category title"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={newCategory}
                  onChange={setNewCategory}
                />
              </div>
              <div className="actions flex aic">
                <button
                  className="update-btn cleanbtn action-btn font"
                  onClick={updateCategory}
                  disabled={newCategory ? false : true}
                >
                  <SaveIcon /> {'  '} Update
                </button>
                <button
                  className="cleanbtn action-btn font"
                  onClick={() => setMode('delete')}
                >
                  <DeleteIcon /> {'  '} Delete
                </button>
              </div>
            </div>
            <div className="up-down-btns flex aix">
              {index !== 0 && (
                <IconButton
                  aria-label="checkmark button"
                  onClick={() => {
                    if (index !== 0) {
                      const temp_category_keys = [...template?.category_keys];
                      temp_category_keys[index] = temp_category_keys[index - 1];
                      temp_category_keys[index - 1] = category_key;
                      console.log(
                        'cat key: ',
                        temp_category_keys,
                        category_key
                      );
                      reorderCategories(temp_category_keys);
                    }
                  }}
                >
                  <ArrowUpwardIcon style={{ color: 'grey' }} />
                </IconButton>
              )}

              {index < template?.category_keys.length - 1 && (
                <IconButton
                  aria-label="checkmark button"
                  onClick={() => {
                    if (index < template?.category_keys.length) {
                      const temp_category_keys = [...template?.category_keys];
                      temp_category_keys[index] = temp_category_keys[index + 1];
                      temp_category_keys[index + 1] = category_key;
                      console.log(
                        'cat key: ',
                        temp_category_keys,
                        category_key
                      );
                      reorderCategories(temp_category_keys);
                    }
                  }}
                >
                  <ArrowDownwardIcon style={{ color: 'grey' }} />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      )}

      {mode === 'delete' && (
        <div className="summary-row-edit-blk delete flex flex-col">
          <div className="del-cata-lbl font s18 b6 c222">
            Are you sure you want to delete this category and all of the videos
            in this category?
          </div>
          <div className="edit-row-content flex aic">
            <div className="form flex aic">
              <div className="actions flex aic">
                <button
                  className="update-btn cleanbtn action-btn font"
                  onClick={() => setMode('view')}
                >
                  <ClearIcon />
                  No! I want to keep these videos.
                </button>
                <button
                  className="cleanbtn action-btn font"
                  onClick={deleteCategory}
                >
                  <DeleteForeverIcon /> Yes, I want to delete the entire
                  category.
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="divider" />
    </div>
  );
}
