// import {GoogleAuth} from 'google-auth-library';
// import {google} from 'googleapis';
import axios from 'axios';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { post } from './request';

const { GoogleSpreadsheet } = require('google-spreadsheet');

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// https://medium.com/@joeponzio/the-right-way-to-add-firebase-to-your-react-project-using-react-hooks-a32db20bf1a0
class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth_main = app.auth;
    this.auth = app.auth();
    this.db = app.firestore();
    this.firestore = app.firestore;
    this.storage = app.storage();

    // need to use a secondaryApp to allow new-user creation without logging out existing user
    this.secondaryApp = app.initializeApp(config, 'Secondary');

    this.user = this.user.bind(this);
    this.uid = this.uid.bind(this);
    this.signOut = this.signOut.bind(this);
    this.createUser = this.createUser.bind(this);
    this.removeAccess = this.removeAccess.bind(this);
    this.configureCaptcha = this.configureCaptcha.bind(this);
    this.phoneAuth = this.phoneAuth.bind(this);
  }

  user() {
    return this.auth.currentUser;
  }

  uid() {
    return this.user() && this.user().uid;
  }

  signOut() {
    this.auth.signOut();
  }

  googleProvider() {
    return this.auth().GoogleAuthProvider();
  }

  configureCaptcha(submit, id) {
    window.recaptchaVerifier = new app.auth.RecaptchaVerifier(
      'captcha_container',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          submit();
        },
      }
    );
    return window.recaptchaVerifier;
  }

  phoneAuth(phoneNumber, appVerifier) {
    return new Promise((resolve, reject) => {
      app
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          resolve(confirmationResult);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  letsConnectNewUser(
    data,
    toastNotification,
    handleCloseLoginDialog,
    setCurrentUser,
    setLoading,
    setCredits
  ) {
    console.log(data);
    setLoading(true);
    return this.db
      .collection('connectedUsers')
      .where('email', '==', data?.email.replace(/\s+/g, ''))
      .where('password', '==', data?.password.replace(/\s+/g, ''))
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, ' => ', doc.data());
        });
        console.log('querySnapshot', {
          querySnapshot,
          length: querySnapshot.docs.length,
        });
        if (querySnapshot.docs.length > 0) {
          console.log('Email is already exist');
          toastNotification('Email is already exist', 'error');
          setLoading(false);
          return;
        }
        this.db
          .collection('connectedUsers')
          .doc(data?.email.replace(/\s+/g, ''))
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log('Email is already exist');
              toastNotification('Email is already exist', 'error');
              setLoading(false);
              return;
            }
            this.db
              .collection('connectedUsers')
              .doc(data?.email.replace(/\s+/g, ''))
              .set(data);
            setLoading(false);
            data.id = data.email;
            localStorage.setItem('letsConnect', JSON.stringify(data));
            toastNotification('You are connected now', 'success');
            setCurrentUser(data);
            localStorage.setItem('credits', 5);
            localStorage.setItem('time', Date.now());
            setCredits(localStorage.getItem('credits'));
            handleCloseLoginDialog();
          });
      });
  }

  letsConnectLogin(
    data,
    toastNotification,
    handleCloseLoginDialog,
    setCurrentUser,
    setLoading,
    setCredits
  ) {
    console.log(data);
    setLoading(true);
    return this.db
      .collection('connectedUsers')
      .where('email', '==', data?.email.replace(/\s+/g, ''))
      .where('password', '==', data?.password.replace(/\s+/g, ''))
      .get()
      .then((querySnapshot) => {
        let responseData = [];
        querySnapshot.forEach((doc) => {
          let d = doc.data();
          d.id = doc.id;
          responseData.push(d);
        });
        console.log('responseData', responseData);
        if (responseData.length > 0) {
          localStorage.setItem('letsConnect', JSON.stringify(responseData[0]));
          toastNotification('You are connected now', 'success');
          setCurrentUser(data);
          handleCloseLoginDialog();
          setLoading(false);
          localStorage.setItem('credits', 5);
          localStorage.setItem('time', Date.now());
          setCredits(localStorage.getItem('credits'));
        } else {
          console.log('Invalid Email or password');
          toastNotification('Invalid Email or password', 'error');
          setLoading(false);
        }
      });
  }

  emailPhoneVerify(email, phone) {
    return this.db
      .collection('verification')
      .doc(email + phone)
      .get();
  }
  verify(email, phone, parameter) {
    return this.db
      .collection('verification')
      .doc(email + phone)
      .update({
        [parameter]: true,
      });
  }

  async createUser(user, community_id, community_name) {
    const currUser = await this.db.collection('users').doc(this.uid()).get();
    this.secondaryApp
      .auth()
      .createUserWithEmailAndPassword(user.email, 'welcome-to-leasemagnets!')
      .then((userRecord) => {
        // See the UserRecord reference doc for the contents of userRecord.
        const data = {
          to: user.email,
          subject: `${
            currUser.data()['name']
          } just invited you to join ${community_name} at LeaseMagnets!`,
          message: `Hey ${user.name}, welcome to LeaseMagnets! <br/><br/>We can't wait to help you get started on your property tour; to activate your account, visit <u>https://tour.video/signin</u> and sign in with the credentials: <br/>Email: ${user.email} <br/>Password: welcome-to-leasemagnets! <br/> <br/> With love, <br/> Team LeaseMagnets`,
        };

        post('/email/plain', data, { auth: false })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error.message);
          });
        const doc = {
          name: user.name,
          email: user.email,
          shared_access: {
            [community_id]: user.access_level,
          },
        };
        if (user.image !== '') {
          doc['image'] = user.image;
        }
        console.log(doc);

        this.db.collection('users').doc(userRecord.user.uid).set(doc);
        this.secondaryApp.auth().signOut();
      })
      .catch((error) => {
        this.db
          .collection('users')
          .where('email', '==', user.email)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.db
                .collection('users')
                .doc(doc.id)
                .update({
                  ['shared_access.' + community_id]: user.access_level,
                });
              const data = {
                to: user.email,
                subject: `${
                  currUser.data()['name']
                } just invited you to join ${community_name} at LeaseMagnets!`,
                message: `Hey ${user.name}, welcome to LeaseMagnets! <br/><br/>We can't wait to help you get started on your property tour; visit <u>https://tour.video/signin</u> to join your community! <br/> <br/> With love, <br/> Team LeaseMagnets`,
              };

              post('/email/plain', data, { auth: false })
                .then((data) => {
                  console.log(data);
                })
                .catch((error) => {
                  console.log(error.message);
                });
            });
          })
          .catch((error) => {
            console.log('Error getting documents: ', error);
          });
      });
  }

  removeAccess(user, community_id) {
    this.db
      .collection('users')
      .where('email', '==', user.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.db
            .collection('users')
            .doc(doc.id)
            .update({
              ['shared_access.' + community_id]:
                this.firestore.FieldValue.delete(),
            });
        });
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  }
}

export const creategooglesheet = async (
  magnet_uuid = '844f6551-ea6c-4d16-9a62-f49fd79d0306'
) => {
  try {
    const sheetids = [
      '1KI3u65O2osILnmEqwkLicWF08aRQxr1F26GK-xYzMXM',
      '1KqPS8VHVakhLuoZYdA21yI3KVxQtbRcFisbsidYoFPc',
      '1ClO3BjHjRHqbTHJEfxi213cPIvlY_m3HPlh2WzwVXbo',
      '1nUj_v_Sn3nzanIPI5BCjcWp-JqcCjcyPStdoZesrQkY',
      '1u59dKrc3Gsu81fxYgvBGf4O-CscuXHes0iQbULn_UVY',
      '1puHKBwO1YDuovnItY8ZM-9Qd1d-6bUy4j4OKz9ARZi4',
      '1vVmpyLj3WWTXheEdJ6DHUJmoobFVVDW-9OG_DTb-508',
      '1bPStTvvQ0exIemvWSYtg2_qjL3wL1Ld9N6Ow9FjcLm8',
      '1wbzsWLQj1Bf6sd8N_yZjrFlGGS4ddVULGqbZzH6HOpA',
      '1UKe_gPR_p_FLwWOTn1Xrx6tb5TjYSYfsYHhyjL0F0hI',
    ];
    const sheetid = sheetids[Math.floor(Math.random() * 10 + 1)];
    const doc = new GoogleSpreadsheet(sheetid);
    await doc.useServiceAccountAuth(creds);
    console.log(doc.spreadsheetId);
    const res = await axios.get(
      `https://api.leasemagnets.com/leads?${magnet_uuid}`
    );
    const values = res.data.leads.map((item) => {
      return {
        Name: item.name,
        Email: item.email,
        Time: item.time,
        Phone: item.phone,
        Location: `${item.visitor.location?.city}-${item.visitor.location?.country}-${item.visitor.location?.state}`,
        Leased: item.leased,
      };
    });
    // console.log(values)
    await doc.loadInfo(); // loads document properties and worksheets
    const sheet = doc.sheetsByTitle['Sheet1']; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    await sheet.clear(); // clear previous data
    await sheet.setHeaderRow([
      'Name',
      'Email',
      'Time',
      'Phone',
      'Location',
      'Leased',
    ]);
    await sheet.addRows(values);
    //console.log(doc)
    return window.open(
      `https://docs.google.com/spreadsheets/d/${sheetid}/edit#gid=0`,
      '_blank'
    );
  } catch (error) {
    console.log(error);
  }
};

const firebase = new Firebase();

export default firebase;
export const auth = firebase.auth;
export const google_provider = new firebase.auth_main.GoogleAuthProvider();
