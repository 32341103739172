import React, { useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsCameraVideoFill, BsGlobe2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { HiArrowRight, HiOutlineArrowRight } from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import c1 from '../assets/home/c1.png';
import c2 from '../assets/home/c2.png';
import c3 from '../assets/home/c3.png';
import c4 from '../assets/home/c4.png';
import c5 from '../assets/home/c5.png';
import c6 from '../assets/home/c6.png';
import en from '../assets/home/en.png';
import google from '../assets/home/google.png';
import hy from '../assets/home/hy.png';
import inta from '../assets/home/inta.png';
import ss from '../assets/home/ss.png';
import t1 from '../assets/home/t1.png';
import t2 from '../assets/home/t2.jpeg';
import t3 from '../assets/home/t3.jpeg';
import t4 from '../assets/home/t4.jpeg';
import t5 from '../assets/home/t5.jpeg';
import t6 from '../assets/home/t6.png';
import tl1 from '../assets/home/tl1.png';
import tl2 from '../assets/home/tl2.png';
import tl3 from '../assets/home/tl3.png';
import tl4 from '../assets/home/tl4.png';
import tl5 from '../assets/home/tl5.svg';
import tl6 from '../assets/home/tl6.png';
import track from '../assets/home/track.png';
import tt from '../assets/home/tt.png';
import yar from '../assets/home/yar.png';
import GlobNavbar from '../components/GlobNavbar';

const Home1 = () => {
  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', `https://embed.tour.video/leasemagnets.js`);
    s.setAttribute('async', 'async');
    s.setAttribute('defer', 'defer');

    s.addEventListener(
      'load',
      () =>
        window.LeaseMagnets &&
        window.LeaseMagnets({
          uuid: '7c54522f-2ee7-451d-a2c6-56edee14c1d9',
          layout: 'left',
          // primaryColor: '#ff2d55',
          widgetType: 'circle',
        })
    );
    s.addEventListener('error', (e) => console.error(e));

    document.body.appendChild(s);

    return () => {
      window.destroyLeaseMagnets && window.destroyLeaseMagnets();
      document.body.removeChild(s); // Clean up
    };
  }, []);

  const [active, setActive] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [tactive, setTactive] = useState(false);
  const communitySliderData = [
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655083024/Brand%20Agent%20Mugshots/Marshall%20Louisville%20-%20Bryce.png',
      caption: '25k Tours',
      communityName: 'Marshall Louisville',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141998/039-the_marshall_louisville_7_20_2020-internetquality_result_uatfof.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655084266/Brand%20Agent%20Mugshots/Hawks_Landing_-_Alex_s4v1rx.png',
      caption: '1.4k International Tours',
      communityName: 'Hawks Landing',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655144118/5bf47a057e8680.26594087778_cj5vxo.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655084693/Brand%20Agent%20Mugshots/Campus_Walk_-_Haley_tlyqfi.png',
      caption: '$70k+ Revenue Generated from Toru',
      communityName: 'Campus Walk',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143786/5f12048951fce3.10347949895_rkjyga.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085097/Brand%20Agent%20Mugshots/Latitude_-_Bri_Anna_wqjgui.png',
      caption: '21k Tours',
      communityName: 'Latitude',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143730/5e62ab48abb332.43947600854_jd0d3b.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085639/Brand%20Agent%20Mugshots/Noble_2500_-_Zac_b1pbiy.png',
      caption: '$85k Revenue Generated',
      communityName: 'Noble 2500',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143677/NOB_Exterior_2K_spksk6_lmg5xy.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655086010/Brand%20Agent%20Mugshots/CastleRock_San_Marcos_-_Kiara_p5mslg.png',
      caption: '15k Tours + $300k Revenue Generated',
      communityName: 'CastleRock San Marcos',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143615/625da1f4b79446.32908626135_tetzrg.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655086409/Brand%20Agent%20Mugshots/Paloma_-_Miranda_tcxe4b.png',
      caption: '3k Tours +  $220k Revenue Generated',
      communityName: 'Paloma West Midtown',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143560/603812302e0b73.51177528925_c7hi1j.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655087084/Brand%20Agent%20Mugshots/One_Park_-_Taryn_opdnaw.png',
      caption: '7.4k Tours + $850k Revenue Generated',
      communityName: 'One Park',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143522/One-Park-Condos-DMG-Investments_zqdzch.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655087284/Brand%20Agent%20Mugshots/Lodges_at_777_-_Taylor_l1okvc.png',
      caption: '18k Tours',
      communityName: 'Lodges at 777',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143465/pool2_y9jy52.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655087850/Brand%20Agent%20Mugshots/HWH_-_Kerri_nrbstd.png',
      caption: '12k Tours + $550k Revenue Generated',
      communityName: 'HWH Luxury Living',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143435/community-amenities_ixpxu5.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655088633/Brand%20Agent%20Mugshots/Douglas_-_Kaila_yzn0vr.png',
      caption: '125% increase in conversion',
      communityName: 'Douglas Heights',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143398/5f5f947526b515.79558872329_bklu00.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655089783/Brand%20Agent%20Mugshots/Arba_-_Amy_xrmkh5.png',
      caption: '150% increase in conversion',
      communityName: 'Arba',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143366/5f4420df516003.24687965551_ebdwuf.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655089656/Brand%20Agent%20Mugshots/The_Rowan_-_Karitza_mkkfci.png',
      caption: '$129k in Revenue generated',
      communityName: 'The Rowan',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143305/rockland-3_v43vos.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655089289/Brand%20Agent%20Mugshots/The_Rockland_mfqxuv.png',
      caption: '120% Conversion Rate',
      communityName: 'The Rockland',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143305/rockland-3_v43vos.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655088986/Brand%20Agent%20Mugshots/The_Hue_-_Sam_fprgrp.png',
      caption: '$40k Revenue Generated',
      communityName: 'The Hue',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143264/6283a5a96fc603.51172537792_g8gios.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655096786/Brand%20Agent%20Mugshots/AOC_-_Leset_uuklce.png',
      caption: '5.5k Tours + $40k in Leadgen',
      communityName: 'The Academy on Charles',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143216/0001_0_Tenant-Placement-Baltimore-2-Edit-1-562.5x0-c-default_bcp926.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655097353/Brand%20Agent%20Mugshots/The_VIllages_Chandler_-_Andrew_y1am3x.png',
      caption: '6.2k Tours + $241k in Leadgen',
      communityName: 'The Village at Chandler Crossings',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141919/the-village-at-chandler-crossings-east-lansing-mi-building-photo_nm67in.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655097571/Brand%20Agent%20Mugshots/Edge_on_Euclid_-_Jacob_wjm6oh.png',
      caption: '6.2k Tours + 340 5-Star Testimonials',
      communityName: 'The Edge on Euclid',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143123/5c756332559276.63022649488_phtrkl.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085400/WXJi5BW_vgc3e6.jpg',
      caption: '$400k Revenue from Tour',
      communityName: 'Desai Accelerator',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143090/Desai-new-space-2_fxhgt5.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655096128/HCC_-_Tacara_mgysmu.png',
      caption: '$200k Revenue from Tour',
      communityName: 'HCC College',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143050/Large-Brandon_2018-001529_thf9fp.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655099031/Forward_Medical_quw70u.png',
      caption: '$240k generated + 340 Reviews',
      communityName: 'Forward Medical',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143019/why-forward-body-scanner-male-doctor-female-member-min_tacedd.webp',
      tourLink: '',
    },
    {
      Bonus:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085332/angelaTYG_loknqp.png',
      agent:
        'https://coursereport-s3-production.global.ssl.fastly.net/uploads/school/logo/408/original/Bloom_Institute_of_Technology_logo.png',
      caption: '210% Conversion Rate & Hired 2 Folks',
      communityName: 'Lambda School/ Bloomtech',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142865/617b1ac6907260434b4793bb_615de902d7456701a5899fcf_5e4c592dccf29d1e8548503b_higher-starting-salary-02_nicwsd.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://is3-ssl.mzstatic.com/image/thumb/Purple124/v4/b1/42/67/b14267fc-6c0b-e555-2cef-dbca440d1140/source/512x512bb.jpg',
      caption: '150% increase in Conversion',
      communityName: 'PNOE',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142821/PNOE_Cover_rlsjc8_cybj4k.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10442.png',
      caption: '8k Onboarded',
      communityName: 'DESO',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142781/DESO_Cover_v81txv_njqijb.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655100533/Creator.App_-_Kevin_k6nutl.png',
      caption: '140%+ Onboarding increase + 215.7k Followers',
      communityName: 'Creator.app',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142749/Creator.App_Cover_qoidph_drqhaf.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/sshwc4pe2wfsfsm102id',
      caption: '210% increase in conversion',
      communityName: 'RAMP',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142710/ramp-og-all-pages_vss8z8.webp',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655101468/WeWork_Mugshot_ktju6g.png',
      caption: 'Onboarded 15k new Engineers',
      communityName: 'WeWork',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142676/Web_150DPI-DSCF1370_lnzzw6.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655102122/UMich_-_Mugshot_ae0tgz.png',
      caption: '124%+ in Conversion',
      communityName: 'University of Michigan',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142643/UMich_-_Cover_dfbv3k_dldp7c.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655102768/Saastr_Mug_noca16.png',
      caption: 'Onboarded 7k Community',
      communityName: 'SaaStr',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142604/SaaStr-Home-Featured_lcfbui.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655103105/Brand%20Agent%20Mugshots/X_Chicago_-_Edmund_yqfa5f.png',
      caption: '150% in Conversion + $440k in Sales',
      communityName: 'X Communities',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141934/x-chicago-chicago-il-primary-photo_dgsnqk.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655103726/Brand%20Agent%20Mugshots/Two_East_Oak_bsqhr8.png',
      caption: '12k Tours + $440k in Sales',
      communityName: 'Two East Oak',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141940/two-east-oak-chicago-il-primary-photo_xpnyv3.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655103841/YC_Mug_tcaykh.png',
      caption: '150% in Onboarding Conversion',
      communityName: 'YC',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142555/80-50fb305324734ba4162e0e275dc1ff18486cd0d661aa58c36fc5b911b718b60f_dn04nm.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655104767/Brand%20Agent%20Mugshots/IV_Oxford_Mug_gu0bjt.png',
      caption: '$1.2m in Tour-driven Sales',
      communityName: 'Independence Village Oxford',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142445/IV_Oxford_Cover_s5xvjh_heir2q.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655106327/Brand%20Agent%20Mugshots/The_George_Mug_twntlg.png',
      caption: '4k Tours and $800k in Tour-driven Sales',
      communityName: 'The George',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142379/MI_AnnArbor_TheGeorge_p0943625_49_DSC0478_1_PhotoGallery_iyamqv.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655106533/Brand%20Agent%20Mugshots/Park_Place_Mug_upgmin.png',
      caption: '2k Tours and $750k in Tour-driven Sales',
      communityName: 'Park Place',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141946/park-place-northville-northville-mi-primary-photo_rnswzg.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent: 'https://speechify.com/wp-content/uploads/2022/05/Cliff.png',
      caption: '89%+ in Conversion',
      communityName: 'Speechify',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143118/Speechify_Cover_gdzyyl.png',
      tourLink: '',
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeBtn, setActiveBtn] = useState(0);
  React.useEffect(() => {
    let header = document.getElementById('showheader');
    document.getElementById('header_box').onmouseover = function callheader() {
      header.style.display = 'block';
    };

    document.getElementById('showheader').onmouseover = function callheader() {
      header.style.display = 'block';
    };
    document.getElementById('showheader').onmouseout = function callheader() {
      header.style.display = 'none';
    };

    document.getElementById('header_box').onmouseout = function callheader() {
      header.style.display = 'none';
    };
  }, []);

  let testimonials = [
    {
      name: 'Brian Nalezny',
      position: 'Regional Manager, PeakMade',
      review:
        'Tour is easy to work with and extremely receptive. They took my suggestion to add a Mandarin tour option and immediately came back with an updated product for us. We are in a beneficial partnership that has translated to an immediate increase in leads at properties where we use the tool',
      image: t1,
      cimg: tl1,
    },
    {
      name: 'Andrea Arbuckle',
      position: 'Regional Leasing/Marketing Manager, Landmark Properties',
      review:
        "Easy to use and effective. I feel better knowing that I'm in control of who can see my data.",
      image: t2,
      cimg: tl2,
    },
    {
      name: 'Amanda Kopko',
      position: 'McKinney Properties',
      review:
        '...This is what we use Tour for. It helps with the introduction to our website and allows someone browsing apartments at 1 am to feel like they are actually walking in the door. It gives them an in person experience before they even pick up the phone.',
      image: t3,
      cimg: tl3,
    },
    {
      name: 'KrisAnn Kizerr',
      position: 'Director, Pierce Education Properties',
      review:
        '“Tour is a great way to offer a creative solution to that problem. We now have properties where 30-40% of the people that sign have never toured in person. Their first time seeing the property will be move-in day.”',
      image: t4,
      cimg: tl4,
    },
    {
      name: 'David Ramos',
      position: 'DMG Investments',
      review:
        '“Huge fan of what you guys are building and think there’s a lot of fit outside of the property management space. I could see luxury realtors/brokers using the tool!”',
      image: t5,
      cimg: tl5,
    },
    {
      name: 'TjChambers',
      position: 'Chambers REA / EVP at Asset Living',
      review:
        '“These guys are building the tour video digital marketing platform of the future — I’ve recommended them to countless clients already!”',
      image: t6,
      cimg: tl6,
    },
  ];

  const [imgActive, setImgActive] = useState(0);
  return (
    <div className=" w-full min-h-screen relative overflow-x-hidden">
      <div className=" w-full bg-black  relative z-30">
        <div className="container relative">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <Link to="/">
                <div>
                  <h1 className="font_uber py-4 text-2xl inline items-center cursor-pointer text-white">
                    {/* leasemag
                  <img src={noto_magnet} className="w-4 mt-1 h-4" alt="" />
                  ets */}
                    Tour
                  </h1>
                  <h3 className="font_uber text-white font-size-12 opacity-60">
                    for Real Estate
                  </h3>
                </div>
              </Link>
              <div className=" relative  pl-10 flex items-center justify-center gap-8">
                <Link
                  to="/"
                  id="header_box"
                  className=" flex items-center py-4  relative justify-center gap-1 text-white font-light "
                >
                  <CgMenuGridO /> Product
                </Link>
                <Link
                  to="/results"
                  className=" flex items-center py-4 justify-center gap-1 text-white font-light "
                >
                  Results
                </Link>
              </div>
            </div>
            <div className=" flex items-center py-4 gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white font-semibold hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div id="showheader" className=" absolute w-full  top-16 left-0">
            <GlobNavbar />
          </div>
          {toggle && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={
            !toggle
              ? {
                  height: '100vh',
                  borderTop: '1px solid rgba(255,255,255,0.3)',
                  background: '#000',
                  overflowY: 'scroll',
                  zIndex: '9999999999',
                }
              : { height: '0px', overflow: ' hidden' }
          }
          className="fixed top-0  bg-black left-0 transition-all  ease-linear   duration-300  w-full block lg:hidden"
        >
          {toggle === false && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
          <div className=" px-5">
            <div
              onClick={() => setTactive(!tactive)}
              className=" flex cursor-pointer items-center justify-between w-full pb-3 text-3xl     gap-1 text-white font_uber pt-10"
            >
              Product
              {tactive === false ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
            {tactive && (
              <div className=" w-full mt-3">
                <div className=" w-full  shadow-2xl h-full flex flex-col py-4 gap-10">
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      USE CASES
                    </h6>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img src={tt} className=" w-7 h-7 object-cover" alt="" />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Tour & Triggers{' '}
                        </h6>
                        <p className="text-xs text-gray-200">
                          Make it easy to build an embeddable & shareable tour
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--engagement-bc48a36991ab0b915c148bd61c6c1d06.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Trust & Testimonials
                        </h6>
                        <p className="text-xs text-gray-200">
                          Build more trust through testimonials
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--marketing-0fc6ec0aeab50485489ad1f6da1a1e5d.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Targeting & Retargeting
                        </h6>
                        <p className="text-xs text-gray-200">
                          Create targeted specials and online campaigns
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      LEARN
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Welcome Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Tour Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Analytics</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Retargeting</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> CTA’s and Triggers</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Integrations</Link>
                    </h6>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      WATCH A DEMO
                    </h6>
                    <div className=" mt-3">
                      <img src={ss} className=" h-20 object-cover " alt="" />
                    </div>
                    <p className=" text-gray-200 text-sm py-2">
                      Learn from a professional what makes <br />
                      Tour work behind the scenes
                    </p>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform font-medium flex items-center gap-24 transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <Link to="/"> BOOK A DEMO</Link>
                      <HiArrowRight className=" w-5 h-5" />
                    </h6>
                  </div>
                </div>
              </div>
            )}
            <Link
              style={{ borderTop: '1px solid rgba(255,255,255,0.3)' }}
              to="/results"
              className=" flex items-center  text-3xl d justify-between pt-3  gap-1 text-white font_uber "
            >
              Results
              <p></p>
            </Link>
          </div>
        </div>
      </div>
      <div className=" container">
        <div className=" relative w-full h-full  rounded-2xl overflow-hidden">
          {/* <img src="https://res.cloudinary.com/kingdomkid/image/upload/v1655051910/build_tour_sample_TYG_2_uwvm0a.png"
        className=" relative "
        width="100%"
        style={{ objectFit: 'cover', height: '669.71px' }}
         /> */}

          <div style={{ background: '#F5F5F5' }}>
            <div className=" container">
              <div className="  pl-4 lg:pl-0 grid grid-cols-1 items-center gap-10 lg:grid-cols-12">
                <div className=" lg:col-span-7">
                  <h1 className=" text-4xl  font_uber text-left">
                    Transform your website
                    <br />
                    into your best leasing agent
                  </h1>
                  <p className="  text-sm pt-2">
                    <h2 className="text-lg mt-[-15px] mb-2	">
                      Convert 85% more of your prospects{' '}
                      <span className=" underline">
                        in-person tour, renewals, and leases!{' '}
                      </span>
                    </h2>
                  </p>

                  <div className=" flex items-start lg:items-center lg:gap-3 text-xl font_uber flex-col lg:flex-row">
                    <Link to="/book-demo">
                      <button className=" mt-4 px-3 text-sm py-3 flex items-center gap-2 hover:bg-rr bg-rr rounded-sm text-white">
                        <BsCameraVideoFill /> Book personalized 1-1 demo
                      </button>
                    </Link>
                    <Link to="/signin">
                      <button className=" mt-4 px-3 font-light text-sm text-black py-3 flex items-center gap-2 hover:bg-gray-200 bg-gray-200 rounded-sm ">
                        <img
                          src={google}
                          className=" w-6 h-6 object-cover"
                          alt=""
                        />{' '}
                        Try Tour for 30 days
                      </button>
                    </Link>
                  </div>
                  <div className=" mt-16 flex items-center gap-4">
                    <div className=" flex items-center gap-0">
                      {communitySliderData.slice(0, 6).map((item, ind) => (
                        <div
                          className={
                            ind !== 0
                              ? ' w-7 h-7 rounded-full relative overflow-hidden  -ml-1'
                              : ' w-7 h-7 overflow-hidden relative rounded-full '
                          }
                        >
                          <img
                            className=" w-full h-full object-cover"
                            src={item?.agent}
                            alt=""
                          />
                          <div
                            className=" absolute top-0 left-0 w-full h-full rounded-full z-20"
                            style={{ background: 'rgba(0,0,0,0.3)' }}
                          ></div>
                        </div>
                      ))}
                    </div>
                    <p className=" ">
                      Loved by 500+ Communities & Property Managers
                    </p>
                  </div>
                </div>
                <div className=" lg:col-span-5">
                  {/* <img
                    src={men}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    alt=""
                  /> */}
                  <video
                    muted
                    autoPlay
                    loop
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                    src="https://res.cloudinary.com/dlmowqiof/video/upload/v1656623177/Screen_recording_2022-06-30_5.04.04_PM_n6almp.webm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-6 py-10 ">
          <img
            src={c1}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c2}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c3}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c4}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c5}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c6}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
        </div>
      </div>

      <div className=" container">
        <div className=" pt-10 pb-6 px-3 lg:px-0">
          <h1 className=" font_uber text-3xl">
            LeaseMagnets has transformed virtual leasing, with 4 simple steps.
          </h1>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-4">
            <div
              onMouseOver={() => setImgActive(0)}
              className={
                imgActive === 0
                  ? ' px-3 py-6 border rounded-2xl cursor-pointer'
                  : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
              }
            >
              <button
                className={
                  imgActive === 0
                    ? ' bg-black text-white font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                    : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                }
              >
                Concierge not Chatbot
              </button>
              <p className=" text-gray-500 text-xs pt-3">
                Deliver a 1:1 personally guided tour with your best leasing
                consultant, right from your homepage.
              </p>
              {imgActive === 0 && (
                <div className="flex items-center justify-between text-sm pt-5 font-medium">
                  Start converting more of your visitors
                  <HiOutlineArrowRight className="w-5 h-5" />
                </div>
              )}
            </div>
            <div
              onMouseOver={() => setImgActive(3)}
              className={
                imgActive === 3
                  ? ' px-3 py-6 border rounded-2xl cursor-pointer'
                  : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
              }
            >
              <button
                className={
                  imgActive === 3
                    ? ' bg-black text-white font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                    : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                }
              >
                Targeting & Tour Analytics
              </button>
              <p className=" text-gray-500 text-xs pt-3">
                Deliver a 1:1 personally guided tour with your best leasing
                consultant, right from your homepage.
              </p>
              {imgActive === 3 && (
                <div className="flex items-center justify-between text-sm pt-5 font-medium">
                  Start converting more of your visitors
                  <HiOutlineArrowRight className="w-5 h-5" />
                </div>
              )}
            </div>
            <div
              onMouseOver={() => setImgActive(1)}
              className={
                imgActive === 1
                  ? ' px-3 py-6 border rounded-2xl cursor-pointer'
                  : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
              }
            >
              <button
                className={
                  imgActive === 1
                    ? ' bg-black text-white font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                    : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                }
              >
                Targeting & Tour Analytics
              </button>
              <p className=" text-gray-500 text-xs pt-3">
                Deliver a 1:1 personally guided tour with your best leasing
                consultant, right from your homepage.
              </p>
              {imgActive === 1 && (
                <div className="flex items-center justify-between text-sm pt-5 font-medium">
                  Start converting more of your visitors
                  <HiOutlineArrowRight className="w-5 h-5" />
                </div>
              )}
            </div>
            <div
              onMouseOver={() => setImgActive(2)}
              className={
                imgActive === 2
                  ? ' px-3 py-6 border rounded-2xl cursor-pointer'
                  : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
              }
            >
              <button
                className={
                  imgActive === 2
                    ? ' bg-black text-white font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                    : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
                }
              >
                Targeting & Tour Analytics
              </button>
              <p className=" text-gray-500 text-xs pt-3">
                Deliver a 1:1 personally guided tour with your best leasing
                consultant, right from your homepage.
              </p>
              {imgActive === 2 && (
                <div className="flex items-center justify-between text-sm pt-5 font-medium">
                  Start converting more of your visitors
                  <HiOutlineArrowRight className="w-5 h-5" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ minHeight: '500px' }}>
          {imgActive === 0 && (
            <img
              className="min-h-min transition-opacity ease-in duration-700 opacity-100 "
              src={
                'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515249/Group_113_1_bbf1oc.png'
              }
              alt=""
            />
          )}
          {imgActive === 1 && (
            <img
              className="min-h-min transition-opacity ease-in duration-700 opacity-100 "
              src={
                'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515023/Group_107_z5avdz.png'
              }
              alt=""
            />
          )}
          {imgActive === 2 && (
            <img
              className="min-h-min transition-opacity ease-in duration-700 opacity-100 "
              src={
                'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515008/Group_109_emiky0.png'
              }
              alt=""
            />
          )}
          {imgActive === 3 && (
            <img
              className="min-h-min transition-opacity ease-in duration-700 opacity-100 "
              src={
                'https://res.cloudinary.com/dlmowqiof/image/upload/v1656514993/Group_112_gvajzn.png'
              }
              alt=""
            />
          )}
        </div>
      </div>
      <div className=" w-full relative h-full mt-96 lg:mt-10  pt-10 pb-10  ">
        <div className=" inline-flex gap-2 animation_right">
          {communitySliderData.slice(13).map((item) => (
            <div
              style={{ width: '370px' }}
              className=" overflow-hidden relative  rounded-2xl  h-40"
            >
              <img
                className=" w-full h-full object-cover"
                src={item?.cover}
                alt=""
              />
              <div
                className=" absolute top-0 left-0 w-full h-full rounded-2xl flex items-end gap-3 justify-start p-3"
                style={{ background: 'rgba(255,255,255,0.3)' }}
              >
                <div className=" flex items-center justify-center gap-3">
                  <img
                    src={item?.agent}
                    className=" w-16 h-16 rounded-full object-cover"
                    alt=""
                  />
                  <div className=" text-white">
                    <h3 className=" font-bold">{item?.communityName}</h3>
                    <p className=" text-sm">{item?.caption}</p>
                  </div>
                </div>
              </div>
              <div
                className=" absolute top-0 left-0 z-20 w-full h-full"
                style={{ background: 'rgba(255,255,255,0.5)' }}
              ></div>
            </div>
          ))}
        </div>
        <div className=" inline-flex gap-2 animation_left">
          {communitySliderData.slice(0, 12).map((item, idx) => (
            <div
              style={{ width: '370px' }}
              className=" overflow-hidden  rounded-2xl relative h-40"
            >
              <img
                className=" w-full h-full object-cover"
                src={item?.cover}
                alt=""
              />
              <div
                className=" absolute top-0 left-0 w-full h-full rounded-2xl flex items-end gap-3 justify-start p-3"
                style={{ background: 'rgba(0,0,0,0.3)' }}
              >
                <div className=" flex items-center justify-center gap-3">
                  <img
                    src={item?.agent}
                    className=" w-16 h-16 rounded-full object-cover"
                    alt=""
                  />
                  <div className=" text-white">
                    <h3 className=" font-bold">{item?.communityName}</h3>
                    <p className=" text-sm">{item?.caption}</p>
                  </div>
                </div>
              </div>
              <div
                className=" absolute top-0 left-0 z-20 w-full h-full"
                style={{ background: 'rgba(255,255,255,0.5)' }}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className=" container px-2 lg:px-0  ">
        <div className="  relative w-full  z-20 ">
          <div className=" container  relative mt-32">
            <div className=" px-5 lg:px-10 py-24  bg-rr w-full  mt-10 lg:mt-0 rounded-md   gap-5 lg:gap-16 font_uber ">
              <h1 className=" text-center w-full text-3xl text-white pt-4">
                Communties with Tour outperform:
              </h1>
              <div className=" flex items-start justify-evenly lg:justify-between w-full pt-4">
                <div className=" flex items-center justify-center flex-col text-white">
                  <h1 className=" text-4xl font_uber">2.8x</h1>
                  <p className=" text-sm font_uber">conversion rate</p>
                </div>

                <div className=" flex items-center justify-center flex-col font_uber text-white">
                  <h1 className=" text-4xl font_uber">3.5x</h1>
                  <p className=" text-sm text-center font_uber">
                    website engagement <br /> time
                  </p>
                </div>
                <div className=" flex items-center justify-center flex-col  text-white ">
                  <h1 className=" text-4xl font_uber">3x</h1>
                  <p className=" text-sm text-center font_uber">
                    leasing & sales <br /> efficiency
                  </p>
                </div>
              </div>
            </div>
            <div className=" absolute left-0 -top-40 z-20 flex items-center w-full h-full justify-center">
              <img src={ss} className=" w1/2 h-52 object-contain" alt="" />
            </div>
          </div>
        </div>
        <div className="  py-10 ">
          <div className=" block lg:hidden w-full px-2 py-10">
            <Slider {...settings}>
              {testimonials.map((item, ind) => (
                <div
                  key={ind}
                  className=" bg-white  p-3 rounded-2xl shadow-lg w-full"
                >
                  <p className="  text-sm text-gray-700 h-36 overflow-y-scroll">
                    {item.review}
                  </p>
                  <div className="flex items-center justify-between">
                    <div className=" pt-4 flex items-center gap-3">
                      <img
                        className=" w-10 h-10 rounded-full object-cover"
                        src={item.image}
                        alt=""
                      />
                      <div>
                        <h6 className=" text-gray-800 text-sm">{item.name}</h6>
                        <p className=" text-xs text-gray-600">
                          {item.position}
                        </p>
                      </div>
                    </div>
                    <img
                      className="  h-10 w-24 mt-4 object-contain"
                      src={item.cimg}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className=" my-10 w-full  justify-center gap-3 flex-wrap hidden lg:flex">
            {/* <Slider {...settings}>
              {[1, 2, 4, 5, 6, 7, 8, 8].map((item) => (
                <div key={item} className=" bg-white p-3 rounded-2xl shadow-sm">
                  <p className=" font-light text-lg text-gray-700">
                    The explanations are clear, the teachers are responsible and
                    friendly, and the homework is real practice.
                  </p>
                  <div className="flex items-center justify-between">
                    <div className=" pt-4 flex items-center gap-3">
                      <img
                        className=" w-10 h-10 rounded-full object-cover"
                        src="https://assets.website-files.com/617fa48948c7ab24b715140e/617fa55023c6a906cc955301_customers_ava-02.jpg"
                        alt=""
                      />
                      <div>
                        <h6 className=" text-gray-800 text-sm">Rob Cuzer</h6>
                        <p className=" text-xs text-gray-600">CEO</p>
                      </div>
                    </div>
                    <img className="  h-10 mt-4 object-cover" src={c1} alt="" />
                  </div>
                </div>
              ))}
            </Slider> */}
            {testimonials.map((item, ind) => (
              <div
                key={ind}
                className=" bg-white p-3 rounded-2xl shadow-sm w-full"
                style={{ width: '300px' }}
              >
                <p className="  text-sm text-gray-700 h-36 overflow-y-scroll">
                  {item.review}
                </p>
                <div className="flex items-center justify-between">
                  <div className=" pt-4 flex items-center gap-3">
                    <img
                      className=" w-10 h-10 rounded-full object-cover"
                      src={item.image}
                      alt=""
                    />
                    <div>
                      <h6 className=" text-gray-800 text-sm">{item.name}</h6>
                      <p className=" text-xs text-gray-600">{item.position}</p>
                    </div>
                  </div>
                  <img
                    className="  h-10 w-24 mt-4 object-contain"
                    src={item.cimg}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
          <div className=" flex w-full items-center justify-center gap-5 ">
            <img
              src={en}
              style={{ height: '100px', width: '200px', objectFit: 'contain' }}
              alt=""
              srcset=""
            />
            <img
              src={yar}
              style={{ height: '80px', width: '200px', objectFit: 'contain' }}
              alt=""
              srcset=""
            />
            <img
              src={hy}
              style={{ height: '80px', width: '200px', objectFit: 'contain' }}
              alt=""
              srcset=""
            />
          </div>
          <div className=" my-20 relative">
            <img
              src={inta}
              className=" w-full h-full object-cover"
              alt=""
              srcset=""
            />
            <div className=" w-full h-full z-20 absolute top-0 left-0 flex items-center justify-center flex-col">
              <p className=" text-white font_uber  ">
                I’m interested in LeaseMagnets for
              </p>
              <div className=" flex items-center gap-2 flex-wrap w-1/3 mt-4 justify-center">
                {[
                  'Student Living',
                  'Senior Living',
                  'Universities',
                  'Coworking',
                  'Coliving',
                ].map((item, ind) => (
                  <button
                    onClick={() => setActiveBtn(ind)}
                    className={
                      activeBtn === ind
                        ? '  font-medium bg-gray-50  px-4 py-2 rounded-3xl'
                        : '  font-medium bg-gray-400 hover:bg-gray-50 px-4 py-2 rounded-3xl'
                    }
                  >
                    {item}
                  </button>
                ))}
              </div>
              <button className=" mt-20 px-3 font-thin text-sm py-3 flex items-center gap-2  bg-rr rounded-sm text-white">
                <BsCameraVideoFill /> Book personalized 1-1 demo
              </button>
            </div>
          </div>
          {/* <div
            // style={{
            //   backgroundImage:
            //     'linear-gradient(180deg, rgba(0, 0, 0, 0) 0, #000000 76.56%)',
            // }}
            className="  py-10    w-full  lg:bg-transparent  left-0 z-20"
          >
            <div className="container relative">
              <h1 className=" text-2xl pt-10 block lg:hidden text-center">
                Build a great tour for:
              </h1>
              <h1 className="  text-2xl pt-10 lg:block hidden">
                Build a great tour for:
              </h1>
              <div className=" py-4 flex items-center gap-5 flex-col lg:flex-row">
                <h1
                  onClick={() => setPerson(false)}
                  className={
                    person
                      ? ' text-lg font_uber text-gray-500 relative cursor-pointer border_parent'
                      : ' text-lg  font_uber cursor-pointer text-black relative '
                  }
                >
                  🏢 👩 In-Person businesses
                  {person === false ? (
                    <div className=" border_bottom absolute bg-black z-20  left-0 -bottom-2"></div>
                  ) : (
                    <div className=" border_hover absolute bg-black z-20  left-0 -bottom-2"></div>
                  )}
                </h1>{' '}
                <p className=" text-xs text-black">or</p>
                <h1
                  onClick={() => setPerson(true)}
                  className={
                    person
                      ? ' text-lg b font_uber text-black relative '
                      : ' border_parent text-lg b font_uber text-gray-500 relative '
                  }
                >
                  💻👨‍ Digital businesses
                  {person === true ? (
                    <div className=" border_bottom absolute bg-black z-20  left-0 -bottom-2"></div>
                  ) : (
                    <div className=" border_hover absolute bg-black z-20  left-0 -bottom-2"></div>
                  )}
                </h1>
              </div>
              <div
                className={`flex   items-center justify-center relative lg:justify-start flex-wrap w-full  lg:px-0 lg:w-2/3 `}
              >
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                  onClick={() => {
                    console.log('attempt to open student living tour TYG');
                    window.tempLeaseMagnets({
                      showButton: false,
                      startOpen: true,
                      layout: 'center',
                      primaryColor: '#872322',
                      startScreen: 'intro.main',
                      buttonLabel: 'Take a virtual tour & $250 off',
                      backgroundOpacity: '0.7',
                      desktopWidth: '80',
                      widgetType: 'circle',
                      uuid: '7c54522f-2ee7-451d-a2c6-56edee14c1d9',
                      blockEvents: 'keydown',
                    });
                  }}
                >
                  Student Living
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Restaurants
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Multifamily Apartments
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Senior Living
                </button>

                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                  onClick={() => {
                    console.log('attempt to open university tour TYG');
                    window.tempLeaseMagnets({
                      showButton: false,
                      startOpen: true,
                      layout: 'center',
                      primaryColor: '#872322',
                      startScreen: 'intro.main',
                      buttonLabel: 'Take a virtual tour & $250 off',
                      backgroundOpacity: '0.7',
                      desktopWidth: '80',
                      widgetType: 'circle',
                      uuid: 'cedee600-ec79-4b1e-a159-892c4fd58761',
                      blockEvents: 'keydown',
                    });
                  }}
                >
                  Universities
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Coworking
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Coliving
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === false ? 'button_animation' : ' hidden'
                  }`}
                >
                  Healthcare
                </button>
              </div>

              <div
                className={`flex relative  items-center  flex-wrap justify-center lg:justify-start w-full  lg:px-0 lg:w-2/3 `}
              >
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  E-Learning
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Bootcamps
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  E-Commerce
                </button>

                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Product Demo
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Onboarding
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Hiring
                </button>
                <button
                  className={` sm_bg m-2 text-pr font-medium sm_bg_hvr px-4 py-2 rounded-3xl ${
                    person === true ? 'button_animation' : ' hidden'
                  }`}
                >
                  Creators
                </button>
              </div>
            </div>
          </div> */}
        </div>

        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-20 lg:gap-4 items-center my-20">
          <div id="else">
            <h1 data-aos="fade-up" className=" text-5xl font_uber">
              Track the analytics <br /> behind each tour
            </h1>
            <p data-aos="fade-up" className=" font_uber text-sm pt-3">
              Register every tour click as actionable data for you to make
              marketing <br /> decisions as well. Yes like Segment but video
              tours.
            </p>
          </div>
          <div className=" relative ">
            <img
              src={track}
              style={{ objectFit: 'contain', height: '100%', width: '100%' }}
              alt=""
            />
            <div className=" absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <video
                className=" relative "
                style={{
                  objectFit: 'cover',
                  height: '520px',
                  width: '340px',
                  borderRadius: '20px',
                }}
                loop={true}
                autoPlay={true}
                muted={true}
              >
                <source
                  src="https://assets.website-files.com/5e45b17b74507185e599a039/5f95fe348b955b74d1fb8704_commons-intro-transcode.webm"
                  type="video/mp4"
                />
              </video>
              <div className=" absolute top-20 left-0 h-full w-full z-20 flex flex-col gap-4 items-center justify-center">
                <button
                  className=" px-5 py-3 text-xl font-bold text-white rounded-3xl cursor-pointer home_border"
                  style={{ background: 'rgba(0,0,0,0.2)' }}
                >
                  {' '}
                  <span
                    style={{ background: '#df4343' }}
                    className=" px-2 py-1 rounded-full"
                  >
                    A
                  </span>{' '}
                  Live Example
                </button>
                <button
                  className=" px-5 py-3 text-xl font-bold text-white rounded-3xl cursor-pointer home_border"
                  style={{ background: 'rgba(0,0,0,0.2)' }}
                >
                  {' '}
                  <span
                    style={{ background: '#df4343' }}
                    className=" px-2 py-1 rounded-full"
                  >
                    B
                  </span>{' '}
                  How It Works
                </button>
                <button
                  className=" px-5 py-3 text-xl font-bold text-white rounded-3xl cursor-pointer home_border"
                  style={{ background: 'rgba(0,0,0,0.2)' }}
                >
                  {' '}
                  <span
                    style={{ background: '#df4343' }}
                    className=" px-2 py-1 rounded-full"
                  >
                    C
                  </span>{' '}
                  Book A Demo
                </button>
              </div>
              <div className=" rounded-full hidden lg:block w-44 h-44 overflow-hidden absolute bottom-10 right-10 z-30 border-pr border-4">
                <video
                  className=" relative "
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                  loop={true}
                  autoPlay={true}
                  muted={true}
                >
                  <source
                    src="https://assets.website-files.com/5e45b17b74507185e599a039/5f95fe348b955b74d1fb8704_commons-intro-transcode.webm"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className=" rounded-full block lg:hidden w-24 h-24 overflow-hidden absolute top-0 left-32 z-30 border-pr border-4">
                <video
                  className=" relative "
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                  loop={true}
                  autoPlay={true}
                  muted={true}
                >
                  <source
                    src="https://assets.website-files.com/5e45b17b74507185e599a039/5f95fe348b955b74d1fb8704_commons-intro-transcode.webm"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-4 items-start my-10">
          <div>
            <h1 data-aos="fade-up" className=" text-5xl font_uber">
              Set the standard <br /> for virtual tours.
            </h1>
            <p data-aos="fade-up" className=" font_uber text-sm pt-3">
              The best-in-class asset owners and property managers trust us with
              their <br /> virtual leasing process.
            </p>
          </div>
          <div className=" grid grid-cols-2 gap-2 lg:gap-8 ">
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611525f6cd52a76e755963c3_one-park-full-p-500.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115221d8fac2db0afe20ef1_opc-logo-header-10.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115264bf1c289a47e7040c0_indepence%20village.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611521e5829dd3158899a9cb_indepence-village.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/61152743829dd3c32999d79a_hwh-luxury-living-topshot.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6114eca70aadb7684e9fe904_hwh-logo-5-p-500.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32"
                  alt=""
                />
              </div>
            </div>
            <div>
              <div className=" h-80 overflow-hidden rounded-xl">
                <img
                  className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611528465c747345a36a8bb9_edge-scene2-p-500.jpeg"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                  alt=""
                />
              </div>
              <div className=" w-full flex items-center justify-center pt-2">
                <img
                  src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115211ad2468230384ebd1f_PeakMadeRealEstate-Logo-Horizontal-RGB-01-e1601937465577-p-500.png"
                  style={{ objectFit: 'contain' }}
                  className=" w-32 "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------Skill ------------------------- */}
      </div>
      <div className=" bg-black mt-20 py-16">
        <h1 className=" text-center text-3xl text-white w-full font_uber">
          Don’t wait for your competitor <br />
          to build a better tour
        </h1>
        <p className=" text-sm pt-2 text-white text-center ">
          Use guided video, CTA’s and special offers, and <br />
          testimonials to increase conversion by 150% today
        </p>
        <div className=" flex  justify-center items-center lg:gap-3 text-white text-xl  font_uber w-full flex-col lg:flex-row">
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2  bg-rr rounded-sm text-white">
            <BsCameraVideoFill /> Book personalized 1-1 demo
          </button>
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2 hover:bg-gray-800 bg-black rounded-sm text-white">
            <img src={google} className=" w-6 h-6 object-cover" alt="" /> Build
            your tour for free
          </button>
        </div>
        <div className=" container pt-16 pb-4">
          <div className=" grid grid-cols-1 gap-5 pl-5 lg:pl-0 lg:grid-cols-4">
            <div className=" lg:col-span-2">
              <h1 className=" text-2xl text-white">Tour</h1>
              <p className=" text-gray-400 text-sm">39135 Zofia Avenue</p>
              <p className=" text-gray-400 text-sm">
                Sterling Heights MI 48313
              </p>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">🏢 Business</h6>
              <Link className=" text-gray-400 text-sm font_uber mt-2" to="/">
                Terms and conditions
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Reviews
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Contact
              </Link>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">👋 Support</h6>
              <p className=" text-gray-400 text-sm pt-2">
                📞 +1 (586) 258-8588
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home1;
