import React, { useState } from 'react';
import './animations.css';
import './common.css';
import { ArrowIcon } from './Icons';
import './NavigationLinks.css';

// Settings for nav link wrapping. Units in %
const NUM_COLS = 2;
const GAP = 2;
const FLEX_BASIS = Math.floor((100 - (NUM_COLS - 1) * GAP) / NUM_COLS);
const FLEX_GROW = true; // Requires >= 2 columns to see the difference

const NavigationLinks = ({
  links,
  onClickLink,
  title,
  fadeDirection,
  isIframe = false,
}) => {
  // 2 animations are occuring here:
  // * Fading in/out of the entire nav links container on VideoMagnet open/close (with fadeDirection)
  // * Collapsing nav links to only show 1 using the buttonCollapsed/Collapsing states
  const collapsible = links?.length > 0;
  const [buttonsCollapsed, setButtonsCollapsed] = useState(false);
  const [buttonsCollapsing, setButtonsCollapsing] = useState(false);

  const collapseButtons = () => {
    if (!buttonsCollapsed) {
      setButtonsCollapsing(true);
      // Wait for collapse animation to finish
      setTimeout(() => {
        setButtonsCollapsing(false);
        setButtonsCollapsed(true);
      }, 500);
    }
  };

  return (
    // The presence of navigation-links-collapsed/open signals to the video shade
    // to change the background gradient size
    <div
      className={`navigation-links-container${
        isIframe ? '-iframe' : ''
      } fade-${fadeDirection} navigation-links-${
        buttonsCollapsed || buttonsCollapsing ? 'collapsed' : 'open'
      }`}
    >
      <div
        className={`collapse-button title ${
          buttonsCollapsed ? '' : 'fade-in-up'
        } ${buttonsCollapsing ? 'fade-out-down' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if (collapsible) {
            if (!buttonsCollapsed) {
              collapseButtons();
            } else {
              setButtonsCollapsed(false);
            }
          }
        }}
      >
        {collapsible && (
          <ArrowIcon
            style={{
              transform:
                buttonsCollapsed || buttonsCollapsing ? 'rotate(180deg)' : '',
            }}
          />
        )}
        {title}
      </div>
      <div
        className={`nav-inside-container ${
          buttonsCollapsing ? 'without-scrollbar' : 'with-scrollbar'
        }`}
        style={{ columnGap: `${GAP}%` }}
      >
        {(buttonsCollapsed ? [] : links).map((link, i) => (
          <button
            type="button"
            key={link.name + link.route}
            className={`navigation-link ${
              buttonsCollapsed ? '' : 'fade-in-up'
            } ${buttonsCollapsing ? 'fade-out-down' : ''}`}
            style={{ flexBasis: `${FLEX_BASIS}%`, flexGrow: FLEX_GROW ? 1 : 0 }}
            onClick={(e) => {
              e.stopPropagation();
              onClickLink(link);
            }}
          >
            <span className="letter">{String.fromCharCode((i % 26) + 65)}</span>
            <span className="content">{link.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default NavigationLinks;
