import { Divider, FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { memo } from 'react';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import NewQuestion, { UNIQUE_TYPES } from './NewQuestion';
import './Settings.css';

const FormSettings = ({ formTemplate, setFormTemplate }) => {
  const usedUniqueTypes = formTemplate?.inputs
    ?.map((question) => question?.type)
    .filter((type) => UNIQUE_TYPES.includes(type));

  const contact = formTemplate?.contact || {}; // `formTemplate.contact` may be undefined
  const setContact = (newContact) =>
    setFormTemplate((formTemplate) => ({
      ...formTemplate,
      contact: newContact,
    }));

  function setQuestions(updatedFormQuestions) {
    setFormTemplate((formTemplate) => ({
      ...formTemplate,
      inputs: updatedFormQuestions,
    }));
  }

  // Handle add question button click
  function addQuestion() {
    const existingIds = formTemplate?.inputs.map((input) => input.id);
    let newId = undefined;
    while (!newId || existingIds.includes(newId)) {
      newId = Math.floor(Math.random() * 7237 * (Math.random() * 23));
    }

    const newQuestion = {
      label: '',
      type: 'text',
      id: newId,
      // Note that some types are locked to "required" or "not required" (text isn't)
      required: false,
      options: undefined,
    };

    setQuestions([...formTemplate.inputs, newQuestion]);
  }

  return (
    <div
      className="advanced-settings-form"
      key={`${formTemplate?.text_data?.title}${formTemplate?.text_data?.body_text} ${formTemplate?.opacity} ${formTemplate?.text_color}`}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={formTemplate.enabled}
                name="isFormTemplateEnabled"
                onChange={(e) => {
                  setFormTemplate({
                    ...formTemplate,
                    enabled: e.target.checked,
                  });
                }}
              />
            }
            label="Enable form on this video"
          />
        </Grid>

        {/* Form Basic settings like Title, Body, opacity, etc */}
        <Grid item xs={12}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Form Title</div>
            <DebouncedTextField
              id="form-title"
              placeholder="Form title"
              variant="outlined"
              size="small"
              value={formTemplate?.text_data?.title}
              onChange={(newValue) => {
                setFormTemplate({
                  ...formTemplate,
                  text_data: {
                    ...formTemplate.text_data,
                    title: newValue,
                  },
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={8}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Form Body</div>
            <DebouncedTextField
              id="form-body"
              placeholder="Details (optional)"
              variant="outlined"
              size="small"
              value={formTemplate?.text_data?.body_text}
              onChange={(newValue) => {
                setFormTemplate({
                  ...formTemplate,
                  text_data: {
                    ...formTemplate.text_data,
                    body_text: newValue,
                  },
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={6} sm={2}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Opacity</div>
            <DebouncedTextField
              id="form-opacity"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 1, step: 0.1 }}
              size="small"
              value={formTemplate?.opacity}
              onChange={(newValue) => {
                setFormTemplate({
                  ...formTemplate,
                  opacity: newValue,
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={6} sm={2}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Text Color</div>
            <DebouncedTextField
              id="form-text-color"
              type="color"
              variant="outlined"
              value={formTemplate?.text_color}
              size="small"
              onChange={(newValue) => {
                setFormTemplate({
                  ...formTemplate,
                  text_color: newValue,
                });
              }}
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>
      </Grid>

      <br />
      <Divider />
      <br />

      {formTemplate?.inputs?.map(
        (question, idx) =>
          question && (
            <NewQuestion
              question={question}
              usedUniqueTypes={usedUniqueTypes}
              setQuestion={(updatedQuestion) => {
                const temp = formTemplate.inputs;
                temp[idx] = updatedQuestion;
                setQuestions(temp);
              }}
              deleteQuestion={() => {
                const temp = formTemplate.inputs.filter((_, i) => i !== idx);
                setQuestions(temp);
              }}
              key={question.id}
            />
          )
      )}

      <button onClick={addQuestion}>+ Add new question</button>

      <br />
      <Divider />
      <br />
      {/* Contact info for confrence TODO either remove or make much better */}
      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={contact?.enabled}
                name="isContactEnabled"
                onChange={(e) => {
                  setFormTemplate({
                    ...formTemplate,
                    contact: {
                      ...contact,
                      enabled: e.target.checked,
                    },
                  });
                }}
              />
            }
            label="BETA: Enable contact download on form submission"
          />
        </Grid>
        {contact.enabled && (
          <>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact name"
                placeholder="Required: Name (or Full Name)"
                variant="outlined"
                value={contact?.name}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    name: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="profile picture"
                placeholder="Profile Picture (URL)"
                variant="outlined"
                size="small"
                value={contact?.profile_picture}
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    profile_picture: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact email"
                placeholder="Email"
                variant="outlined"
                size="small"
                value={contact?.email}
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    email: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact phone"
                placeholder="Phone"
                variant="outlined"
                value={contact?.phone}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    phone: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact website"
                placeholder="Website"
                variant="outlined"
                value={contact?.website}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    website: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact linkedin"
                placeholder="LinkedIn (url)"
                variant="outlined"
                value={contact?.linkedin}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    linkedin: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
            <Grid item xs={12}>
              <DebouncedTextField
                id="contact company"
                placeholder="Company"
                variant="outlined"
                value={contact?.org}
                size="small"
                onChange={(newValue) =>
                  setContact({
                    ...contact,
                    org: newValue,
                  })
                }
                style={{ width: 'calc(100%)' }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default memo(FormSettings);
