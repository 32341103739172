import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AiFillStar, AiOutlinePlus } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { IoQrCodeSharp } from 'react-icons/io5';
import { RiPencilLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TargetIcon } from '../../../assets/svg';
import { Header } from '../../../components';
import EditReview from '../../../components/createReview/EditReviewSettings';
import AnimatedDialog from '../../../components/Reusable/AnimatedDialog';
import ManageChampionMember from '../../../components/Reviews/ManageChampionMember';
import ManageTeamMembers from '../../../components/Reviews/ManageTeamMembers';
import { generateID, isValidEmail } from '../../../core';
import { fakeReviewData } from '../../../fakedata/review';
import { RESIDENT_DASHBOARD, useRequest } from '../../../utils/request.js';
import { slugToString } from '../../../utils/slug';
import CreateReview, {
  EditReviewModal,
} from './../../../components/createReview/CreateReview';
import MultiStepForm from './MultiStepForm';
import ReviewCard from './ReviewCard';

function parseJsonPayloadHorizontally(dataArr = null) {
  return dataArr.map((data, i) => {
    let tempObj = {};
    Object.keys(data).map((k, idx) => {
      console.log('response jsonp: ', k, data[k]);
      try {
        let t1 = JSON.parse(data[k]);
        tempObj[k] = t1;
        return t1;
      } catch (e) {
        console.log(e);
      }
      tempObj[k] = data[k];
      return data[k];
    });
    console.log('response jsonfull: ', tempObj);
    return tempObj || {};
  });
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
}));

const Review = ({ name, url, community_id, title }) => {
  const { screenToEdit } = useSelector((state) => state.magnetStates);
  const _magnetData = useSelector((state) => state.getMagnet);
  const { template } = _magnetData;
  const [filtersaved, setfiltersaved] = useState(false);
  //const [filterall,setfilterall] = useState('off')

  const [category] = useMemo(() => screenToEdit.split('.'), [screenToEdit]);
  var categoryTitle = useMemo(
    () => (category ? template?.categories[category]?.title : ''),
    [category, template]
  );
  const [value, setValue] = React.useState(2);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [addopen, setAddopen] = useState(false);
  const [addopen2, setAddopen2] = useState(false);

  const [wallfeedback, setWallfeedback] = useState([]);
  useEffect(() => {
    setWallfeedback(fakeReviewData.feedbacktype);
  }, []);

  const filterFeedbacTypeName = (item_name) => {
    const filterData = wallfeedback.filter((item) => item.name !== item_name);
    setWallfeedback(filterData);
  };
  const params = useParams();
  console.log('params', params.community_id);
  const [adduserbadgeOpen, setAdduserbadgeOpen] = useState(false);
  const [badgeName, setBadgeName] = useState('');
  const [communityData, setCommunityData] = useState(null);
  const [communityResponse, setCommunityResponse] = useState(null);

  const _dispatch = useDispatch();
  let requestID = generateID(3, 4);
  const msgRef = useRef();
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [leadName, setLeadName] = useState('');
  const [askGoogleReview, setAskGoogleReview] = useState(0);
  const [type, setType] = useState('SMS');
  const [requestSending, setRequestSending] = useState(false);
  const [error, data, makeRequest] = useRequest({ loading: false });

  const addBadge = (e) => {
    setAdduserbadgeOpen(false);
    //e.preventDefault();
    setDirectualCommunity({
      ...directualCommunity,
      communitybadges: [
        ...directualCommunity.communitybadges,
        { name: badgeName, category: '' },
      ],
    });
    setBadgeName('');
  };

  const [goalopen, setGoalopen] = useState(false);
  const [reviewsTeamMemberOpen, setReviewsTeamMemberOpen] = useState(false);
  const [openSetupDialog, setOpenSetupDialog] = useState(false);
  const [showStepForm, setShowStepForm] = useState(false);

  console.log('openSetupDialog', openSetupDialog);
  const countthebadge = (name) => {
    let count = 0;
    directualCommunity?.reviews.map((item) => {
      item.selectcommunityBadges.map((item2) => {
        if (item2.name == name) {
          count++;
        }
      });
    });
    return count;
  };

  const managesavedreviews = (index, bool) => {
    console.log('managereview', index, bool);
    directualCommunity.reviews[index].saved = bool;
    setDirectualCommunity({ ...directualCommunity });
  };

  const [directualCommunity, setDirectualCommunity] = useState({
    // id: 'temporary',
    name: 'Marshall Lousville',
    website: 'https://themarshalllouisville.com/', //(optional)
    communitybadges: [],
    team: [],
    reviews: [],
    champions: [],
    credits: 0,
    settings: {
      logo: 'https://i.imgur.com/NrZ3kph.png',
      address: '', //(optional)
      phone: '', // (optional),

      reviewSettings: {
        googleReviews:
          'https://search.google.com/local/writereview?placeid=ChIJsbivTn4F9YgR2YmRg350ljg&pli=1',
        form: {
          rating: false,
          video: true,
          text: true,
          name: true,
          email: true,
          questions: [], // if empt
        },
        goals: {
          CurrentRating: 3.5,
          CurrentReviewCount: 399,
          GoalNumberofReviews: 265.55555555555543,
          GoalRating: 4.1,
          ReviewScoreExpected: 5,
        },
      },
      referralSettings: {
        demographics: {},
      },
    },
  });
  console.log('directualCommunity', directualCommunity);
  const createcommunity = async () => {
    try {
      // to avoiding to upload same file again
      console.log('createcommunity');
      let postData = {
        id: params.community_id,
        ...directualCommunity,
        communitybadges: {
          communitybadges: directualCommunity.communitybadges,
        },
        team: {
          team: directualCommunity.team,
        },
        reviews: {
          reviews: directualCommunity.reviews,
        },
        champions: {
          champions: directualCommunity.champions,
        },
      };
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
    } catch (err) {
      console.log('err', err);
      console.log('err', err.response);
    }
  };

  const deletereviewbyindex = async (index, setAddopen, setloading) => {
    try {
      // to avoiding to upload same file again
      const reviews = directualCommunity.reviews;
      reviews.splice(index, 1);
      console.log('reviews');
      let postData = {
        id: params.community_id,
        reviews: {
          reviews: reviews,
        },
      };
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
      setloading(false);
      setAddopen(false);
      getcommunity();
    } catch (err) {
      console.log('err', err);
      console.log('err', err.response);
    }
  };

  console.log(
    'CurrentReviewCount',
    directualCommunity?.reviews?.length ||
      0 + parseInt(directualCommunity?.settings?.goals?.CurrentReviewCount || 0)
  );
  const updatecommunity = async (community_obj) => {
    console.log('directual_community', community_obj);
    let postData = {
      id: params.community_id,
      ...community_obj,
      communitybadges: {
        communitybadges: community_obj.communitybadges,
      },
      team: {
        team: community_obj.team,
      },
      reviews: {
        reviews: community_obj.reviews,
      },
      champions: {
        champions: community_obj.champions,
      },
    };
    console.log('postData from Review', postData);
    toastNotification('Success fully saved)', 'success');
    try {
      console.log('postData', postData);
      const community = await axios.post(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        postData
      );
      console.log('userUpdated after review TYG: ', community);
    } catch (e) {
      console.log(e.response);
    }
  };

  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res, res.data.payload);
    let temp = parseJsonPayloadHorizontally(res.data.payload);
    console.log('response2', temp);
    temp && temp.length > 0 && setCommunityResponse(temp[0]);

    if (temp?.settings?.goals) {
      setgoals({ ...temp.settings.goals });
    }
    if (res.data.payload.length > 0) {
      setCommunityData({
        id: res.data?.payload?.[0]?.id,
        name: res.data?.payload?.[0]?.name,
        nameClean: cleaner(res.data?.payload?.[0]?.name),
        team: res.data?.payload?.[0]?.team
          ? JSON.parse(res.data?.payload?.[0]?.team).team
          : [],
        logo: res.data?.payload?.[0]?.settings
          ? JSON.parse(res.data?.payload?.[0]?.settings)?.logo
          : '',
      });

      try {
        console.log(
          'thejson commbadges: ',
          temp?.[0]?.communitybadges?.communitybadges || []
        );
        let thejson = {
          ...temp[0],
          champions: temp?.[0]?.champions?.champions || [],
          communitybadges: temp?.[0]?.communitybadges?.communitybadges || [],
          reviews: temp?.[0]?.reviews?.reviews || [],
          settings: temp?.[0]?.settings || {},
          team: temp?.[0]?.team?.team || [],
        };
        //console.log('response',JSON.parse(res.data?.payload[0].champions))
        console.log('thejson', thejson);
        setDirectualCommunity({ ...thejson });
        console.log('after', directualCommunity);
        setcreatecommunityenable(true);
        setloading(false);
      } catch (error) {
        console.log('thejson errr', error);
      }
    } else {
      setShowStepForm(true);
      console.log('thejson response_empty');
    }
  };

  useEffect(() => {
    getcommunity();
  }, []);

  const [createcommunityenable, setcreatecommunityenable] = useState(false);
  useEffect(() => {
    if (createcommunityenable) {
      createcommunity();
    }
  }, [directualCommunity]);
  const [openeditreview, setopeneditreview] = useState(false);
  const [teamMemberName, setTeamMemberName] = useState('');
  const [selectedTeamMemberIndex, setSelectedTeamMemberIndex] = useState(null);
  const teamMemberIndex = useMemo(() => {
    let i;
    communityData?.team.forEach((item, index) => {
      if (item?.name === teamMemberName) {
        i = index;
      }
    });
    console.log(i);
    return i;
  }, [teamMemberName]);

  const handleTypeSwitcher = (type) => {
    if (type === 'SMS') {
      setType('SMS');
      setEmail('');
    } else {
      setType('email');
      setPhone('');
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + match[2] + match[3];
    }
    return false;
  }

  const toastNotification = (message, type) => {
    toast(message, {
      position: 'top-right',
      type: type,
      autoClose: 5000,
    });
  };

  function getAptSlug() {
    // Split URL by '/'
    const url = window.location.href.split('/');
    return url[url.length - 2];
  }

  function cleaner(str) {
    if (str) {
      var strLower = str.toLowerCase();
      return strLower.replace(/\W/g, '');
    }

    return false;
  }
  // Handle send message
  function handleSubmit(e) {
    setRequestSending(true);
    e.preventDefault();
    // Create data object

    let data = {};

    if (type === 'SMS') {
      const phoneNumber = formatPhoneNumber(phone);
      if (phoneNumber === false) {
        toast('Phone number is invalid', {
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
        setRequestSending(false);
        return null;
      }

      data = {
        message: {
          to: {
            phone_number: '+1' + phoneNumber,
          },
          template: 'ZHWWQ62XKN4NRCJWZHD3GBP1Z5KZ',
          data: {
            name: leadName,
            message: msgRef.current.innerText,
          },
        },
      };
      console.log('requestSend SMS', data);

      var config = {
        method: 'post',
        url: 'https://api.leasemagnets.com/send_courier',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Text sent successfully :)', 'success');
          // if (localStorage.getItem('credits')) {
          //   let credits = parseInt(localStorage.getItem('credits'));
          //   localStorage.setItem('credits', `${--credits}`);
          //   setCredits(localStorage.getItem('credits'));
          // }
          setRequestSending(false);
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred :)', 'error');
          setRequestSending(false);
        });
    } else {
      // type === email
      if (!email || !isValidEmail(email)) {
        toast('Please enter a valid email', {
          position: 'top-center',
          type: 'error',
          autoClose: 5000,
        });
        setRequestSending(false);
        return null;
      }
      data = {
        to: email,
        aptName: slugToString(getAptSlug()),
        leadName: name,
        reviewLink: `${RESIDENT_DASHBOARD}/survey/${getAptSlug()}?request=${requestID}`,
        message: msgRef.current.innerText,
      };

      data = {
        message: {
          to: {
            email: email,
          },
          template: 'BBYS20X8VX4674JN7JCFJM5V5YWZ',
          data: {
            subjectLine:
              (teamMemberName ? teamMemberName : communityData?.name) +
              ' has requested a review',
            fromName:
              `Hey 👋 ${leadName}` +
              (teamMemberName ? '' : `,  ${teamMemberName} left you a message`),
            fromPic:
              'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/153/magnet/category/b1_2_bed_2_bath/4_bed___4_bath_mp4.gif',
            message: msgRef.current.innerText,
          },
        },
      };

      console.log('requestSend SMS', data);

      var config = {
        method: 'post',
        url: 'https://api.leasemagnets.com/send_courier',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data);

          toastNotification('Email sent successfully :)', 'success');
          // if (localStorage.getItem('credits')) {
          //   let credits = parseInt(localStorage.getItem('credits'));
          //   localStorage.setItem('credits', `${--credits}`);
          //   setCredits(localStorage.getItem('credits'));
          // }
          setRequestSending(false);
        })
        .catch(function (error) {
          console.log(error.message);

          toastNotification('An error occurred :)', 'error');
          setRequestSending(false);
        });
    }
    // const route = type === 'SMS' ? '/send_sms' : '/send_email';

    // let requestHistory = {
    //   id: requestID,
    //   type: 'review_request',
    //   src_community_id: community_id,
    //   src_user_id: '',
    //   reviewId: '',
    //   data: {
    //     requesterName: '',
    //     requesterEmail: '',
    //     requesterPhone: '',
    //     leadName: teamMemberName, // requster name
    //     leadPhone: phone, // requster phone
    //     leadEmail: email, // requster email
    //     leadUserId: generateID(3, 4), // requster user
    //     staffId: '',
    //     defaultReviewStars: 4,
    //     contextId: '',
    //     completedBonusReview: false,
    //     specialOffer: '',
    //   },
    // };

    // _dispatch(createRequestHistory(requestHistory));
  }
  const [isform, setIsform] = useState(true);
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AnimatedDialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'lg'}
      >
        <div
          className="text-3xl font-thin absolute right-2 lga:right-4 right-4 top-0 lg:top-2 text-black cursor-pointer"
          onClick={handleClose}
        >
          &times;
        </div>
        <EditReviewModal open={openeditreview} setOpen={setopeneditreview} />

        <div className="w-full px-0 py-0 lg:py-14 lg:px-8 flex flex-col-reverse items-center justify-center lg:flex-row lg:justify-start lg:items-start">
          <div class="flex-1">
            <div className=" w-full flex items-center gap-6 justify-center pb-3">
              <h6
                onClick={() => setIsform(true)}
                className={
                  isform
                    ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                    : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                }
              >
                <FaWpforms /> Form
              </h6>
              <h6
                onClick={() => setIsform(false)}
                className={
                  isform === false
                    ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                    : ' font-medium cursor-pointer text-gray-600 flex items-center gap-1 '
                }
              >
                <IoQrCodeSharp />
                QR code
              </h6>
            </div>
            {isform && (
              <CreateReview
                hideReviewSettings={false}
                personalizedShareRequestor={teamMemberName}
                requestGoogleReview={true}
                tempaskGoogleReview={askGoogleReview}
                tempselectedTeamMemberIndex={selectedTeamMemberIndex}
                url={
                  directualCommunity?.settings?.reviewSettings?.googleReviews
                }
              />
            )}
          </div>
          <div class="flex-1 w-full mb-5 lg:mb-0">
            <div className="flex items-center w-full mb-4">
              <div className="flex-1 font-bold">Request Review via Link</div>
              <div
                onClick={() => setopeneditreview(true)}
                className="flex items-center cursor-pointer font-size-12"
              >
                Settings&nbsp;
                <FiSettings />
              </div>
            </div>
            <span className=" font-size-sm">
              Priority Review Platform Link (ie: Google Review)
            </span>
            <div className=" w-full mb-4 border rounded-sm py-2 flex items-center justify-between px-2">
              <p className="w-full wordwrap mr-1">
                {directualCommunity?.settings?.reviewSettings?.googleReviews}
              </p>
              <a
                href={`${directualCommunity?.settings?.reviewSettings?.googleReviews}`}
                target="_blank"
              >
                <HiOutlineExternalLink className=" w-6 h-6 cursor-pointer" />
              </a>
            </div>
            <div className=" my-2 font-medium text-sm mb-2">
              Request Review via Personalized Message (Recommended)
            </div>
            <div
              className=" rounded-xl bg-pr p-3.5 text-white text-sm mb-4"
              ref={msgRef}
            >
              <div contentEditable>
                Hi {leadName || 'XXXX'}, it's {teamMemberName} from{' '}
                {communityData?.name}! I was hoping you could do me a favor and
                leave my team and me a review? Thank you in advance.
              </div>
              <p className=" text-white text-sm mt-3">
                {`${RESIDENT_DASHBOARD}/surveys/${community_id}/@${communityData?.nameClean}`}
                ?{teamMemberIndex >= 0 && <>u={teamMemberIndex}</>}
                {askGoogleReview ? <>&#38;g={askGoogleReview}</> : ''}
              </p>
            </div>
            <div className="flex items-center">
              <label class="inline-flex items-center mr-4">
                <input
                  type="checkbox"
                  class="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                  value="SMS"
                  onChange={(e) => handleTypeSwitcher(e.target.value)}
                  checked={type === 'SMS'}
                />
                <span class="ml-2 text-sm">Send SMS</span>
              </label>
              <label class="inline-flex items-center">
                <input
                  type="checkbox"
                  class="w-5 h-5 border-gray-300 rounded-full focus:ring-indigo-500 text-indigo-600"
                  value="email"
                  onChange={(e) => handleTypeSwitcher(e.target.value)}
                  checked={type === 'email'}
                />
                <span class="ml-2 text-sm">Send Email</span>
              </label>
            </div>
            <div className=" w-full flex flex-col gap-1 mt-3">
              <div className="mb-2">
                {communityData?.team && communityData?.team.length && (
                  <Autocomplete
                    id="combo-box-demo"
                    options={communityData?.team?.map((item, ind) => {
                      return { name: item.name, id: ind };
                    })}
                    onChange={(event, value) => {
                      setSelectedTeamMemberIndex(value.id);
                      setTeamMemberName(value.name);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Team Member (Optional)"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </div>
              <div className="grid grid-cols-2 gap-1">
                <div>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    placeholder=""
                    defaultValue={leadName}
                    onChange={(e) => setLeadName(e.target.value)}
                    fullWidth
                  />
                </div>
                <div>
                  {type === 'SMS' && (
                    <TextField
                      type="number"
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                      defaultValue={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      fullWidth
                    />
                  )}
                  {type === 'email' && (
                    <TextField
                      type="email"
                      id="outlined-basic"
                      label="Email address"
                      variant="outlined"
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                    />
                  )}
                </div>
              </div>

              <div class="block">
                <div class="mt-2">
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 border-0 focus:ring-0 "
                      onClick={() => {
                        console.log(
                          'attempt to click review, ',
                          askGoogleReview
                        );
                        if (askGoogleReview > 0) {
                          setAskGoogleReview(0);
                        } else {
                          setAskGoogleReview(1);
                        }
                      }}
                    />
                    <span class="ml-2 mr-3">Ask for Google Review</span>
                    <div class="flex justify-center">
                      <select
                        class="form-select
      appearance-none
      block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Disabled select example"
                        disabled={askGoogleReview == 0}
                        onSelect={(e) => {
                          console.log('selected: ', e.target.value);
                          setAskGoogleReview(e.target.value);
                        }}
                        value={askGoogleReview}
                      >
                        <option value={0}>Open this select menu</option>
                        <option value={1}>Popup with Confirmation</option>
                        <option value={2}>Redirect without Confirmation</option>
                      </select>
                    </div>
                  </label>
                </div>
              </div>

              <div>
                <button
                  className=" bg-pr px-3 mt-4 py-2.5 rounded-md flex items-center justify-center text-white font-light"
                  onClick={handleSubmit}
                >
                  {requestSending ? 'Request Sending....' : 'Request Review'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </AnimatedDialog>

      <AnimatedDialog
        open={openSetupDialog || showStepForm}
        onClose={() => {
          setOpenSetupDialog(false);
          setShowStepForm(false);
        }}
        maxWidth={'sm'}
      >
        <MultiStepForm
          getcommunity={getcommunity}
          setOpenSetupDialog={setOpenSetupDialog}
          communityResponse={communityResponse}
          community_id={community_id}
          onClose={() => {
            setOpenSetupDialog(false);
            setShowStepForm(false);
          }}
          websiteUrl={url}
          communityName={name}
        />
      </AnimatedDialog>

      <div className=" w-full min-h-screen">
        {/*Manage team members / Add team member(s)*/}
        {!loading && (
          <ManageTeamMembers
            saveCommunityTeam={(team) => {
              console.log('team', team);
              setDirectualCommunity({ ...directualCommunity, team: team });
              updatecommunity({ ...directualCommunity, team: team });
            }}
            setAddopen={setAddopen}
            addopen={addopen}
            initialTeamState={directualCommunity?.team}
            community_id={community_id}
          />
        )}
        {/* Manage Champion program */}
        {!loading && (
          <ManageChampionMember
            saveCommunityChampions={(champions) => {
              setDirectualCommunity({
                ...directualCommunity,
                champions: champions,
              });
              updatecommunity({ ...directualCommunity, champions: champions });
            }}
            community_id={community_id}
            setAddopen2={setAddopen2}
            addopen2={addopen2}
            initialChampionState={directualCommunity?.champions}
            updateChampionSetting={(championssettings, championHeaders) => {
              setDirectualCommunity({
                ...directualCommunity,
                settings: {
                  ...directualCommunity.settings,
                  championssettings,
                  ...championHeaders,
                },
              });
              updatecommunity({
                ...directualCommunity,
                settings: {
                  ...directualCommunity.settings,
                  championssettings,
                  ...championHeaders,
                },
              });
            }}
            badges={directualCommunity?.settings?.championssettings}
            communityName={communityData.name}
            championHeaderTitle={
              directualCommunity?.settings?.championHeaderTitle
            }
            championHeaderSubtitle={
              directualCommunity?.settings?.championHeaderSubtitle
            }
          />
        )}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={goalopen}
          onClose={() => setGoalopen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={goalopen}>
            <GoalComponent
              goalsState={directualCommunity?.settings?.goals}
              onSave={(goals) => {
                setDirectualCommunity({
                  ...directualCommunity,
                  settings: { ...directualCommunity.settings, goals },
                });
                updatecommunity({
                  ...directualCommunity,
                  settings: { ...directualCommunity.settings, goals },
                });
              }}
              teamMemberCt={directualCommunity?.team?.length}
              closeModal={() => setGoalopen(false)}
            />
          </Fade>
        </Modal>
        <Header>
          <div className="dashboard-hdr flex aic w-full">
            <div className="left flex aic">
              <a href={url} target="_blank">
                <OpenInNewIcon />
              </a>
              &nbsp;&nbsp;
              <Link to="/" className="item flex aic">
                <div className="txt font s14 b3 anim">{name}</div>
                {name ? <span className="slash">&nbsp;/&nbsp;</span> : null}
              </Link>
              <div className="item flex aic">
                <div
                  onClick={() => {
                    _dispatch({
                      type: 'SCREEN_TO_EDIT',
                      payload: '',
                    });
                    window.history.pushState({}, `View ${community_id}`, `#`);
                  }}
                  className="txt font s14 b5 black"
                >
                  Reviews
                </div>
                {categoryTitle && <span className="slash">&nbsp;/&nbsp;</span>}
              </div>
              {categoryTitle && (
                <div className="item flex aic">
                  <div className="txt font s14 b5 black">{categoryTitle}</div>
                </div>
              )}
              {/* <div className="right flex aic">
                <div className="right flex aic">
                  <button
                    onClick={() => {
                      setOpenSetupDialog(true);
                    }}
                    className="add-credits-btn cleanbtn font s15 b4 color"
                  >
                    <button class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
                      Trust Setup
                    </button>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </Header>
        {
          <>
            <div className=" w-full grid grid-cols-1 lg:grid-cols-2 mt-3 px-4">
              <div>
                <div className=" flex items-center gap-4 flex-wrap">
                  <h1 className=" text-3xl font-semibold ">
                    {directualCommunity?.reviews?.length}

                    <span className=" text-3xl font-light "> reviews</span>
                  </h1>
                  <h1 className=" text-sm font-semibold pt-1">
                    {parseInt(
                      directualCommunity?.settings?.goals?.CurrentReviewCount
                    )
                      ? parseInt(
                          directualCommunity?.settings?.goals
                            ?.CurrentReviewCount
                        )
                      : 0}

                    <span className=" text-sm font-medium "> goal reviews</span>
                  </h1>
                </div>

                <div className=" flex items-center flex-wrap  gap-2 w-full">
                  <div className=" bg-blue-50 rounded-3xl mt-2 px-2  flex gap-1 items-center  py-1">
                    <Rating
                      name="simple-controlled"
                      value={fakeReviewData.rating}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                    />
                    <p className="text-xs text-gray-700">
                      {fakeReviewData.rating} out of 5
                    </p>
                  </div>
                  <button
                    className=" flex items-center gap-1 cursor-pointer"
                    onClick={() => setGoalopen(true)}
                    onMouseEnter={() => setReviewsTeamMemberOpen(true)}
                    onMouseLeave={() => setReviewsTeamMemberOpen(false)}
                  >
                    <div className=" h-5 text-pr">
                      <TargetIcon />
                    </div>
                    <div>
                      <p className=" text-pr text-xs">
                        {' '}
                        {directualCommunity?.settings?.goals
                          ?.GoalNumberofReviews ? (
                          <>
                            {directualCommunity?.team?.length == 0 ||
                            reviewsTeamMemberOpen ? (
                              <>
                                {Math.ceil(
                                  directualCommunity?.settings?.goals
                                    ?.GoalNumberofReviews
                                )}{' '}
                                reviews needed in total
                              </>
                            ) : (
                              <>
                                {Math.ceil(
                                  directualCommunity?.settings?.goals
                                    ?.GoalNumberofReviews /
                                    directualCommunity.team.length
                                )}{' '}
                                reviews needed per team member
                              </>
                            )}
                          </>
                        ) : (
                          <> Set your community goal</>
                        )}{' '}
                      </p>
                      <div className="flex">
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <AiFillStar className=" text-yellow-400" />
                        <RiPencilLine className=" text-gray-600 ml-1" />
                      </div>
                    </div>
                  </button>
                </div>
                <div className=" my-3 ">
                  <button
                    onClick={handleOpen}
                    className=" py-2 px-3 text-white flex  items-center rounded-3xl w-full lg:w-40 bg-pr justify-between"
                  >
                    <p className=" text-sm">Request review</p>
                    <BsArrowRight className=" w-5 h-5" />
                  </button>
                </div>

                {/* review form BEGIN */}

                {/* review form END */}
              </div>

              {/* second row */}

              <div>
                <div className="">{AddTeamMemberAndChampions()}</div>

                {AddBadgesButtons()}
              </div>

              {/* end second row */}
            </div>

            <div className=" w-full grid grid-cols-1 lg:grid-cols-2 mt-3 px-4">
              <div className="w-full px-4 pt-2 ">
                <h1 className=" font-bold text-2xl mb-2">Collect Reviews</h1>
                <div className="w-full px-0 py-0 lg:py-14 lg:px-8 flex flex-col-reverse items-start justify-start lg:flex-row lg:justify-start lg:items-start">
                  <div class="flex-1">
                    <div className=" w-full flex items-start gap-6 justify-start pb-3">
                      <h6
                        onClick={() => setIsform(true)}
                        className={
                          isform === true
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                        }
                      >
                        <FaWpforms /> Form
                      </h6>
                      <h6
                        onClick={() => setIsform(false)}
                        className={
                          isform === false
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600 flex items-center gap-1 '
                        }
                      >
                        <IoQrCodeSharp />
                        QR code
                      </h6>
                      <h6
                        onClick={() => setIsform('settings')}
                        className={
                          isform === 'settings'
                            ? ' font-medium cursor-pointer text-pr border-b flex items-center gap-1 border-pr'
                            : ' font-medium cursor-pointer text-gray-600  flex items-center gap-1'
                        }
                      >
                        <FiSettings /> Settings
                      </h6>
                    </div>
                    {isform === true && (
                      <CreateReview
                        hideReviewSettings={false}
                        personalizedShareRequestor={teamMemberName}
                        requestGoogleReview={true}
                        tempaskGoogleReview={askGoogleReview}
                        tempselectedTeamMemberIndex={selectedTeamMemberIndex}
                        url={
                          directualCommunity?.settings?.reviewSettings
                            ?.googleReviews
                        }
                      />
                    )}

                    {isform === 'settings' && <EditReview />}
                  </div>
                </div>
              </div>

              <div className=" flex items-start mt-2 gap-3 flex-wrap justify-between ">
                <h1 className=" font-bold text-2xl">Wall of Feedback™️</h1>

                <div className="flex flex-row">
                  <div className="w-8 h-8 bg-gray-200 hover:bg-gray-300  items-start rounded-sm cursor-pointer ml-2">
                    <Link to={'/community/' + community_id + '/reviews/embed'}>
                      <i className="fa fa-share text-xl align-middle p-1"></i>
                    </Link>
                  </div>
                </div>
                <div className=" flex items-center gap-5">
                  <div className=" flex items-center gap-1 text-sm">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      checked={filtersaved == false}
                      onClick={(e) => setfiltersaved(false)}
                    />
                    <label htmlFor="push-notifications">All</label>
                  </div>
                  <div className=" flex items-center gap-1 text-sm">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      checked={filtersaved == true}
                      onClick={(e) => setfiltersaved(true)}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="push-notifications">Saved</label>
                  </div>
                </div>

                <div className=" mt-3 w-full grid  grid-cols-1 md:grid-cols-2 gap-5">
                  {!directualCommunity?.reviews?.length &&
                    directualCommunity?.reviews.length == 0 && (
                      <ReviewCard
                        key={0}
                        item={{
                          type: 1,
                          feedback:
                            'No reviews found yet. Request a review to get started',
                          name: 'No reviews found yet. Request a review to get started',
                          rating: 5,
                        }}
                        ind={0}
                      />
                    )}
                  {filtersaved
                    ? directualCommunity?.reviews
                        // ?.slice(0)
                        // .reverse()
                        .map((item2, ind) => {
                          if (item2.saved) {
                            return (
                              <ReviewCard
                                key={ind}
                                item={item2?.question?.[0]}
                                ind={ind}
                                item2={item2}
                                managesavedreviews={managesavedreviews}
                                deletereviewbyindex={deletereviewbyindex}
                              />
                            );
                          }
                        })
                    : directualCommunity?.reviews
                        // ?.slice(0)
                        // .reverse()
                        .map((item2, ind) => (
                          <ReviewCard
                            key={ind}
                            item={item2?.question?.[0]}
                            ind={ind}
                            item2={item2}
                            managesavedreviews={managesavedreviews}
                            deletereviewbyindex={deletereviewbyindex}
                          />
                        ))}
                  {/* {directualCommunity.reviews.map((item2, ind) =>
                          item2.question.map((item, index) => (
                            <ReviewCard key={index} item={item} ind={index} item2={item2} />
                          ))
                        )} */}
                </div>
              </div>
            </div>
          </>
        }

        {/* {showStepForm && <MultiStepForm community_id={community_id} />} */}
      </div>
    </>
  );

  function AddBadgesButtons() {
    return (
      <div className=" w-full flex items-center gap-3 flex-wrap px-0 mt-3">
        {directualCommunity?.communitybadges &&
          directualCommunity.communitybadges.map((item, ind) => (
            <button
              key={ind}
              className=" bg-gray-100 rounded-sm button_hover p-2 flex items-center gap-2"
            >
              <p className=" text-sm text-gray-900">{item.name}</p>
              <p className=" px-2 border-l text-sm hide_element border-gray-600">
                {countthebadge(item.name)}
              </p>
              <p
                onClick={() => {
                  let communitybadges = directualCommunity.communitybadges;
                  communitybadges.splice(ind, 1);
                  setDirectualCommunity({
                    ...directualCommunity,
                    communitybadges,
                  });
                }}
                className="  border-l cursor-pointer text-sm show_element bg-gray-600 text-white p-1 rounded-full"
              >
                <IoMdClose className=" w-4 h-4" />
              </p>
            </button>
          ))}
        {adduserbadgeOpen === false ? (
          <button
            onClick={() => setAdduserbadgeOpen(true)}
            className=" bg-gray-100 border rounded-sm p-2 flex items-center gap-2"
          >
            <p className=" text-sm font-bold"> Add Badge</p> <AiOutlinePlus />
          </button>
        ) : (
          <form className=" flex" onSubmit={(e) => addBadge(e)}>
            <input
              type="text"
              autoFocus
              onChange={(e) => setBadgeName(e.target.value)}
              required
              className=" py-1 border rounded-sm px-2 w-32"
            />
          </form>
        )}
        {badgeName && (
          <button
            onClick={() => addBadge()}
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded"
          >
            Save
          </button>
        )}
      </div>
    );
  }

  function AddTeamMemberAndChampions() {
    return (
      <div className=" grid grid-cols-2">
        <div>
          <div className=" flex items-center gap-4">
            <h1 className=" text-xl font-semibold">
              {directualCommunity?.team?.length
                ? directualCommunity?.team?.length
                : 'Add'}{' '}
              <span className=" text-sm">Team Members</span>
            </h1>
          </div>

          <div className=" flex items-center ">
            {directualCommunity?.team &&
              directualCommunity?.team.map((item, ind) => (
                <div
                  key={ind}
                  className=" p-2 text-2xl uppercase -ml-2  bg-gray-300 font-medium rounded-full border-2 border-white"
                >
                  {item?.name?.slice(0, 2)}
                </div>
              ))}

            <div
              onClick={() => setAddopen(true)}
              className=" p-2 text-2xl -ml-2  bg-black font-medium rounded-full border-2 border-white text-white cursor-pointer"
            >
              <AiOutlinePlus className=" w-4 h-4" />
            </div>
          </div>
        </div>

        <div className=" ">
          <div className=" flex items-center gap-4">
            <h1 className=" text-xl font-semibold">
              {directualCommunity?.champions?.length
                ? directualCommunity?.champions?.length
                : 'Add'}{' '}
              <span className=" text-sm">Champions</span>
            </h1>
          </div>
          <button
            onClick={() => setAddopen2(true)}
            className="    flex items-center rounded-3xl mt-1 w-full gap-4 "
          >
            <div className=" flex items-center ">
              {directualCommunity?.champions &&
                [
                  ...(directualCommunity.champions || []),
                  { name: '' },
                  { name: '' },
                  { name: '' },
                  { name: '' },
                ]
                  .slice(0, 4)
                  .map((item, ind) => (
                    <div
                      key={ind}
                      className=" p-2 text-sm uppercase text-white  -ml-2  bg-black font-medium rounded-full border-2 border-white"
                    >
                      {item.name ? (
                        item.name.slice(0, 2)
                      ) : (
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      )}
                    </div>
                  ))}
              <div
                onClick={() => setAddopen2(true)}
                className=" p-2 text-sm -ml-2  bg-black font-medium rounded-full border-2 border-white text-white cursor-pointer"
              >
                <AiOutlinePlus className=" w-5 h-5" />
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }

  function GoalComponent({
    goalsState,
    onSave,
    teamMemberCt = 0,
    closeModal = (e) => {},
  }) {
    console.log('goals state: ', goalsState);
    const [goals, setgoals] = useState(
      goalsState
        ? goalsState
        : {
            CurrentReviewCount: 40,
            CurrentRating: 3.2,
            GoalRating: 4.1,
            ReviewScoreExpected: 5,
            GoalNumberofReviews: 2,
          }
    );
    console.log('goals state: ', goals);
    // goals: {
    //   CurrentReviewCount: 2,
    //   CurrentRating: 2,
    //   GoalRating: 2,
    //   ReviewScoreExpected: 5,
    //   GoalNumberofReviews: 2,
    //
    // },

    function calculateNumberOfReviewsNeededToReachGoal(
      currReviewCt,
      currReviewRating,
      goalRating,
      expectedIndivRating
    ) {
      let goalReviewCt =
        Math.ceil(
          Number(currReviewCt) * Number(currReviewRating) -
            Number(goalRating) * Number(currReviewCt)
        ) /
        (Number(goalRating) - Number(expectedIndivRating));

      return goalReviewCt;
    }

    function displayStars(rating) {
      let stars = '';
      for (let i = 0; i < rating; i++) {
        stars = stars + '⭐';
      }
      return stars;
    }

    return (
      <div className={classes.paper + ' w-full lg:w-2/4 bg-white'}>
        <h6 className=" font-medium w-full pb-2 border-b">
          Update Your Goals for your community
        </h6>
        {goals.CurrentReviewCount && (
          <div className="mt-3">Current Review Count</div>
        )}
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Current Review Count"
          value={goals?.CurrentReviewCount}
          onChange={(e) => {
            if (Number(e.target.value) || e.target.value == '') {
              setgoals({ ...goals, CurrentReviewCount: e.target.value });
            }
          }}
        />

        {goals.CurrentRating && <div className="mt-1">Current Rating</div>}
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Current Rating"
          value={goals?.CurrentRating}
          onChange={(e) => {
            if (
              (Number(e.target.value) && Number(e.target.value) <= 10) ||
              e.target.value == ''
            ) {
              setgoals({ ...goals, CurrentRating: e.target.value });
            }
          }}
        />

        {goals.GoalRating && (
          <div className="mt-1">
            Goal Rating{' '}
            {goals?.CurrentRating >= goals?.GoalRating && (
              <span className=" text-red-500">
                Please ensure that Goal Rating &gt; Current Rating
              </span>
            )}{' '}
          </div>
        )}
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Goal Rating"
          value={goals?.GoalRating}
          onChange={(e) => {
            if (
              (Number(e.target.value) && Number(e.target.value) <= 10) ||
              e.target.value == ''
            ) {
              setgoals({ ...goals, GoalRating: e.target.value });
            }
          }}
        />

        <span>
          {goals?.ReviewScoreExpected && (
            <div className="mt-1">
              Each score is expected to be at least{' '}
              {displayStars(goals?.ReviewScoreExpected)}{' '}
              {goals?.ReviewScoreExpected <= goals?.GoalRating && (
                <span className=" text-red-500">
                  Please ensure that Expected Review Score &gt; Goal Rating
                </span>
              )}
            </div>
          )}
        </span>
        <input
          type="text"
          className=" p-2 w-full border outline-none my-2 rounded-sm font-light"
          placeholder="Each review expected to be a least X stars"
          value={goals?.ReviewScoreExpected}
          onChange={(e) => {
            if (
              (Number(e.target.value) && Number(e.target.value) <= 10) ||
              e.target.value == ''
            ) {
              setgoals({ ...goals, ReviewScoreExpected: e.target.value });
            }
          }}
        />

        <h6 className=" text-sm mt-3 mb-1">
          Reviews needed: To get to {goals?.GoalRating} stars, you will need:{' '}
          {calculateNumberOfReviewsNeededToReachGoal(
            goals?.CurrentReviewCount,
            goals?.CurrentRating,
            goals?.GoalRating,
            goals?.ReviewScoreExpected
          )}{' '}
          {displayStars(goals?.ReviewScoreExpected)} reviews <br />
          <br />
          {teamMemberCt > 1 && (
            <span>
              Each of the {teamMemberCt} team members is responsible for{' '}
              {calculateNumberOfReviewsNeededToReachGoal(
                goals?.CurrentReviewCount,
                goals?.CurrentRating,
                goals?.GoalRating,
                goals?.ReviewScoreExpected
              ) / teamMemberCt}{' '}
              reviews
            </span>
          )}
        </h6>
        <div className=" w-full grid grid-cols-2 mt-2 gap-2">
          <button
            className=" bg-pr w-full py-2 text-white font-light"
            onClick={() => {
              onSave({
                ...goals,
                GoalNumberofReviews: calculateNumberOfReviewsNeededToReachGoal(
                  goals?.CurrentReviewCount,
                  goals?.CurrentRating,
                  goals?.GoalRating,
                  goals?.ReviewScoreExpected
                ),
              });
              closeModal();
            }}
          >
            save button
          </button>
          <button className=" border w-full py-2" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
};

export default Review;
