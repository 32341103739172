import Box from '@material-ui/core/Box';
//Material UI
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  SidebarAdminIcon,
  SidebarAnalyticsIcon,
  SidebarEmbedIcon,
  SidebarReviewsIcon,
  SidebarTeamIcon,
  SidebarVideosIcon,
} from '../../assets/svg';
import { AppSidebar } from '../../components';
import CreateReview from '../../components/createReview/CreateReview';
import { AccessLevelFunction } from '../../components/Reusable/AccessLevelWrapper';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import { listCommunityDetial } from '../../store/actions/communitiesActions';
import { ACCESS_LEVEL } from '../../utils/constants.js';
import { RESIDENT_HOST, useRequest } from '../../utils/request.js';
import Embed from '../manage-magnets/Embed';
import MagnetAdminSettings from '../manage-magnets/MagnetAdminSettings';
import MagnetSettings from '../manage-magnets/MagnetSettings';
import MagnetTeam from '../manage-magnets/MagnetTeam';
import ManageMagnet from '../manage-magnets/ManageMagnet';
import { Employee } from '../reviews/dashboard';
import Review from '../reviews/main/Review';
import TalkAndTriggers from '../talk-and-triggers';
import Analytics from './Analytics';
import Analytics2 from './Analytics2';
import Referral from './Referral';
import Rewards from './Rewards';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ApartmentView(props) {
  // Theme
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#3898ec',
      },
      secondary: {
        main: '#8cc6d9',
      },
      error: {
        main: '#ff0033',
      },
    },
  });

  const { community_id, magnet_tab, options, aptName, staffName } = useParams();
  // accessLevel for community
  const {
    accessLevel,
    loadScreen,
    showProgressBar,
    currentVideo,
    magnetId,
    magnetType,
  } = useSelector((state) => state.magnetStates);

  const [community, setCommunity] = useState();
  const [customHomepage, setCustomHomepage] = useState('/');
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [category, setCategory] = useState('');

  const _dispatch = useDispatch();
  const { data, error, loading } = useSelector(
    (state) => state.listCommunityDetial
  );

  const [apartNav, setApartNav] = useState([
    { label: 'Videos', slug: 'videos' },
    { label: 'Reviews', slug: 'reviews' },
    { label: 'Rewards', slug: 'rewards' },
    { label: 'Analytics', slug: 'analytics' },
    { label: 'Settings', slug: 'settings' },
    { label: 'Embed', slug: 'embed' },
    { label: 'Team', slug: 'team' },
    { label: 'Admin', slug: 'admin' },
  ]);

  const videoNav = [
    {
      label: 'Tour',
      slug: 'videos',
      icon: <SidebarVideosIcon />,
    },
    {
      label: 'Tracking',
      slug: 'analytics',
      icon: <SidebarAnalyticsIcon />,
    },

    // {
    //   label: 'Trust & Testimonials',
    //   slug: 'reviews',
    //   icon: <span className="icon-user-plus" />,
    // },
    // {
    //   label: 'Create Review',
    //   slug: 'create-review',
    //   icon: <span className="icon-user-plus" />,
    // },
    // {
    //   label: 'Referral',
    //   slug: 'referral',
    //   icon: <span className="icon-user-plus" />,
    // },
    {
      label: 'Triggers',
      slug: 'triggers',
      icon: <SidebarReviewsIcon />,
    },
    {
      label: 'Embed & Preview',
      slug: 'embed',
      icon: <SidebarEmbedIcon />,
    },
    // {
    //   label: 'Thank you playbooks',
    //   slug: 'rewards',
    //   icon: <SidebarRewardsIcon />,
    // },

    {
      label: 'Settings',
      slug: 'settings',
      icon: <span className="icon-gear2 " />,
    },
    {
      label: 'Team',
      slug: 'team',
      icon: <SidebarTeamIcon />,
    },
    {
      label: 'Admin',
      slug: 'admin',
      icon: <SidebarAdminIcon />,
    },
  ];

  const getVideosToUploadCount = () => {
    return loadScreen.filter(
      (load_screen_status) => load_screen_status.progress !== 100
    ).length;
  };

  //State of which is active tab
  const activeTabs = {
    videos: 0,
    reviews: 1,
    analytics: 2,
    settings: 3,
    embed: 4,
    team: 5,
    admin: 6,
  };
  const [value, setValue] = useState(activeTabs[magnet_tab] || 0);

  const handleChange = (event, newValue) => {
    const url = `/dashboard/${community_id}`;
    // prevent changes to community while disabled by global_admin
    if (community && community['hidden']) {
      setValue(activeTabs['admin']);
      if (newValue !== activeTabs['admin']) {
        alert('Please re-enable the community to access the magnet');
      }
      return;
    }
    setValue(newValue);
    let foundKey = Object.keys(activeTabs).find(
      (key) => activeTabs[key] === newValue
    );
    if (!foundKey) {
      setValue(0);
      foundKey = 'videos';
    }
    const hash = window?.location?.hash?.split('#')[1];
    window.history.pushState(
      {},
      '',
      `${url}/${foundKey}${hash ? `#${hash}` : ''}`
    );
  };

  const makeRequest = useRequest({ loading: false })[3];

  function getApartment(setState, slug) {
    // fetch(`http://localhost:8080/apartments/${slug}`)
    makeRequest(`/apartments/the-george-ann-arbor`, 'GET', null, {
      auth: false,
      host: RESIDENT_HOST,
    })
      .then((response) => {
        return response;
      })
      .then((data) => {
        setState(data.apartment);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(() => {
    _dispatch(listCommunityDetial(community_id));
  }, [community_id]);

  useEffect(() => {
    const getAccessLevel = async () => {
      const tempAccessLevel = await AccessLevelFunction(community_id);
      _dispatch({
        type: 'SET_ACCESS_LEVEL',
        payload: tempAccessLevel,
      });
      if (
        data[0] &&
        data[0]['hidden'] &&
        tempAccessLevel === ACCESS_LEVEL['global']
      ) {
        handleChange(null, activeTabs['admin']);
      } else if (
        (value === activeTabs['team'] &&
          tempAccessLevel < ACCESS_LEVEL['property']) ||
        (value === activeTabs['admin'] &&
          tempAccessLevel < ACCESS_LEVEL['global'])
      ) {
        handleChange(null, 0);
      }
      // else if (!activeTabs[magnet_tab]) {
      //   handleChange(null, 0); // TODO: fix /videos redirect
      // }
    };
    if (!loading && data && data[0]) {
      setCommunity(data[0]);
      getAccessLevel();
    }
  }, [data]);

  useEffect(() => {
    if (accessLevel === 0) {
      props.history.push('/dashboard');
    }
  }, []);

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
  };

  const addNewCategory = () => {
    if (!category) {
      toast('Please enter category name.', {
        position: 'top-right',
        type: 'error',
      });
    } else if (category.length < 3) {
      toast('Category name should be at least 3 characters', {
        position: 'top-right',
        type: 'error',
      });
    }
    if (category && category.length >= 3) {
      _dispatch({
        type: 'addCategory',
        payload: {
          category_title: category,
          magnet_id: magnetId,
          magnet_type: magnetType,
        },
      });
      setOpenCategoryDialog(false);
    }
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        {/* prevents access to community user doesn't have access to */}
        <div className="apartment-page sidebar-space sidebar-with-sub-menu rel">
          <AppSidebar videosTab={true} subMenus={true}>
            <div className="apartment-nav flex flex-col aic">
              {videoNav.map((item, idx) => (
                <>
                  {item.slug === 'team' ? (
                    accessLevel >= ACCESS_LEVEL['property'] && (
                      <Link
                        key={idx}
                        to={`/dashboard/${community_id}/${item.slug}`}
                        className={`lin flex aic ${
                          magnet_tab === item.slug ? 'active' : ''
                        }`}
                        exact
                      >
                        <div className="ico rel">{item.icon}</div>
                        <div className="lbl font s14 b3">{item.label}</div>
                      </Link>
                    )
                  ) : item.slug === 'admin' ? (
                    accessLevel === ACCESS_LEVEL['global'] && (
                      <Link
                        key={idx}
                        to={`/dashboard/${community_id}/${item.slug}`}
                        className={`lin flex aic ${
                          magnet_tab === item.slug ? 'active' : ''
                        }`}
                        exact
                      >
                        <div className="ico rel">{item.icon}</div>
                        <div className="lbl font s14 b3">{item.label}</div>
                      </Link>
                    )
                  ) : (
                    <Link
                      key={idx}
                      to={`/dashboard/${community_id}/${item.slug}`}
                      className={`lin flex aic ${
                        magnet_tab === item.slug ? 'active' : ''
                      }`}
                      exact
                    >
                      <div className="ico rel">{item.icon}</div>
                      <div className="lbl font s14 b3">{item.label}</div>
                    </Link>
                  )}
                </>
              ))}
            </div>
          </AppSidebar>

          <div className="apart-container wrapWidth">
            <div className="apart-content">
              {magnet_tab === 'videos' && (
                <ManageMagnet
                  name={community?.name || null}
                  url={community?.url || ''}
                  community_id={community_id}
                  title="Virtual leasing made human | LeaseMagnets™"
                  magnetLoading={loading}
                  setOpenCategoryDialog={setOpenCategoryDialog}
                />
              )}
              {/* {magnet_tab === 'reviews' && !aptName && !staffName && (
                <ReviewDashboard
                  getApartment={getApartment}
                  magnetId={magnetId}
                  options={options}
                  name={community?.name || null}
                  url={community?.url || ''}
                  community_id={community_id}
                  magnetLoading={loading}
                  setOpenCategoryDialog={setOpenCategoryDialog}
                />
              )} */}
              <>
                {magnet_tab === 'reviews' && !aptName && !staffName && (
                  <Review
                    name={community?.name || null}
                    url={community?.url || ''}
                    community_id={community_id}
                    title="Trust & Testimonials"
                  />
                )}
              </>
              {magnet_tab === 'create-review' && !aptName && !staffName && (
                <CreateReview />
              )}
              {magnet_tab === 'reviews' && aptName && staffName && (
                <Employee
                  setCustomHomepage={setCustomHomepage}
                  aptName={aptName}
                  name={community?.name || null}
                  url={community?.url || ''}
                />
              )}
              {magnet_tab === 'referral' && (
                <Referral
                  name={community ? community.name : null}
                  community_id={community_id}
                  url={community?.url || ''}
                />
              )}
              {magnet_tab === 'rewards' && (
                <Rewards
                  name={community ? community.name : null}
                  community_id={community_id}
                  url={community?.url || ''}
                />
              )}
              {magnet_tab === 'analytics' && (
                <Analytics
                  name={community ? community.name : null}
                  community_id={community_id}
                  url={community?.url || ''}
                  title="Analytics"
                />
              )}
              {magnet_tab === 'analytics2' && (
                <Analytics2
                  name={community ? community.name : null}
                  community_id={community_id}
                  url={community?.url || ''}
                  title="Analytics"
                />
              )}
              {magnet_tab === 'triggers' && (
                <TalkAndTriggers
                  name={community ? community.name : null}
                  community_id={community_id}
                  url={community?.url || ''}
                  magnet_uuid={magnetId}
                  title="Talk & Triggers"
                />
              )}
              {magnet_tab === 'settings' && (
                <MagnetSettings
                  name={community ? community.name : null}
                  url={community?.url || ''}
                  community_id={community_id}
                  title="Settings"
                />
              )}

              {magnet_tab === 'embed' && (
                <Embed
                  name={community ? community.name : null}
                  community_id={community_id}
                  url={community?.url || ''}
                  title="Embed"
                />
              )}
              {magnet_tab === 'team' && (
                <MagnetTeam
                  name={community ? community.name : null}
                  url={community?.url || ''}
                  community_id={community_id}
                  title="Team"
                />
              )}
              {magnet_tab === 'admin' && (
                <MagnetAdminSettings
                  name={community ? community.name : null}
                  url={community?.url}
                  img_url={community?.img_url}
                  community_id={community_id}
                  community_hidden={community?.hidden || false}
                  title="Admin"
                />
              )}
            </div>
          </div>
          {/* Videos Uploading prograss */}
          <div
            className={`upload-toast fixed flex aic ${
              getVideosToUploadCount() > 0 && !showProgressBar ? 'sho' : 'hid'
            }`}
          >
            <div className="lef-blk flex flex-col aic">
              <div className="no font s26 b6 cfff">
                {getVideosToUploadCount()}
              </div>
              <span className="lbl font s13 cfff">videos to upload</span>
            </div>
          </div>
          {showProgressBar && (
            <>
              {loadScreen.map((item, index) => {
                if (item.name == currentVideo) {
                  return (
                    <div className="upload-toast fixed flex aic">
                      <div className="lef-blk flex flex-col aic">
                        <div className="no font s26 b6 cfff">
                          {item.progress ? item.progress : 0}%
                        </div>
                        <div className="esti font s13 b3 cfff">
                          est:
                          {
                            // minutes
                            item.timeestimate
                              ? item.timeestimate !== Infinity
                                ? (item.timeestimate / 60).toFixed(0) > 0 &&
                                  `${(item.timeestimate / 60).toFixed(0)}m`
                                : ''
                              : ''
                          }
                          {
                            // seconds
                            item.timeestimate
                              ? item.timeestimate !== Infinity
                                ? item.timeestimate.toFixed(0) % 60 > 0 &&
                                  `${item.timeestimate.toFixed(0) % 60}s`
                                : ''
                              : ''
                          }
                        </div>
                      </div>
                      <div className="rit-blk flex flex-col">
                        <div className="meta flex aic">
                          <div className="nam font s14 b6 color">
                            {item.name.split('-')[0]}
                          </div>
                          <div className="size font s14 b6">
                            {item.size.toFixed(2)} MB
                          </div>
                        </div>
                        <div className="prograss-bar rel">
                          <div
                            className="prograss abs anim"
                            style={{
                              width: `${item.progress ? item.progress : 0}%`,
                            }}
                          />
                        </div>
                        <div className="ftr flex aic">
                          <div className="nam font s14 b6 c333">
                            {item.route}-{item.screen_name}
                          </div>
                          <div className="more font s14 b6 c333 rel">
                            {loadScreen.length - (index + 1) != 0 && (
                              <span>
                                +{loadScreen.length - (index + 1)} more
                              </span>
                            )}
                            <div className="more-list abs flex flex-col anim">
                              {loadScreen.map((item, nindex) => {
                                if (nindex > index) {
                                  return (
                                    <div className="itm font b3 cfff wordwrap">
                                      {item.name.split('-')[0]} (
                                      {item.size.toFixed(2)}mb)
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
        <AnimatedDialog
          open={openCategoryDialog}
          onClose={handleCloseCategoryDialog}
          fullWidth
          maxWidth="xs"
        >
          <div className="add-category-dialog">
            <div className="hdr flex aic">
              <div className="title font s18 b6 c000">Add Category</div>
              <button
                className="cross-btn cleanbtn fontr"
                onClick={handleCloseCategoryDialog}
              >
                &times;
              </button>
            </div>
            <div className="wrap flex flex-col">
              <input
                className="cleanbtn iput font s15 c333"
                placeholder="Category Name"
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div className="ftr flex aic">
              <button
                onClick={addNewCategory}
                className="cleanbtn add-cata font s15 cfff"
              >
                Add Category
              </button>
              <button
                onClick={handleCloseCategoryDialog}
                className="cleanbtn add-cata cancel font s15 c333"
              >
                Cancel
              </button>
            </div>
          </div>
        </AnimatedDialog>
      </MuiThemeProvider>
    </>
  );
}

export default ApartmentView;

//Tab panels
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ backgroundColor: '#FFF' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
