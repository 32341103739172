import React, { useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { MdClose } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import c1 from '../assets/home/c1.png';
import c2 from '../assets/home/c2.png';
import c3 from '../assets/home/c3.png';
import c4 from '../assets/home/c4.png';
import c5 from '../assets/home/c5.png';
import c6 from '../assets/home/c6.png';

const BookDemo = () => {
  const [toggle, setToggle] = useState(true);

  const [scrollPosition, setScrollPosition] = useState(0);
  window.addEventListener('scroll', (e) => {
    setScrollPosition(window.scrollY);
  });
  console.log('scroll', scrollPosition);
  return (
    <div className=" w-full min-h-screen relative ">
      <div className=" w-full bg-black py-4 relative z-30">
        <div className="container">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <h1 className="font_uber text-2xl cursor-pointer text-white">
                Tour
              </h1>
              <div className=" pl-10 flex items-center justify-center gap-8">
                <Link
                  to="/"
                  className=" flex items-center justify-center gap-1 text-white font-light "
                >
                  <CgMenuGridO /> Product
                </Link>
                <Link
                  to="/results"
                  className=" flex items-center justify-center gap-1 text-white font-light "
                >
                  Results
                </Link>
              </div>
            </div>
            <div className=" flex items-center gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div className=" items-center justify-between flex lg:hidden px-2">
            <div className=" flex items-center">
              <h1 className="font_uber text-2xl cursor-pointer text-white">
                Tour
              </h1>
            </div>
            <div className=" flex items-center gap-2">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser className=" cursor-pointer text-white w-5 h-5" />
              </Link>
              <Link
                to="/signup"
                className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
              {toggle ? (
                <VscThreeBars
                  onClick={() => setToggle(false)}
                  className=" cursor-pointer text-white w-5 h-5"
                />
              ) : (
                <MdClose
                  onClick={() => setToggle(true)}
                  className=" cursor-pointer text-white w-5 h-5"
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={
            !toggle
              ? {
                  height: '200px',
                  borderTop: '1px solid white',
                  background: '#000',
                }
              : { height: '0px', overflow: ' hidden' }
          }
          className="absolute top-20 left-0 transition-all ease-linear duration-300   w-full z-40 block lg:hidden"
        >
          <Link
            to="/"
            className=" flex items-center text-2xl  justify-center gap-1 text-white font-light pt-10"
          >
            Product
          </Link>
          <Link
            to="/results"
            className=" flex items-center  text-2xl justify-center gap-1 text-white font-light "
          >
            Results
          </Link>
        </div>
      </div>

      <div className=" container px-2 lg:px-0 h-screen ">
        <div className=" grid  grid-cols-1 lg:grid-cols-2 gap-4 items-start my-10">
          <div className=" relative mt-30">
            <div
              className={
                scrollPosition < 1560
                  ? ' block lg:fixed top-30 left-0 z-10 w-full lg:w-1/2 lg:px-4'
                  : ' w-full h-full'
              }
            >
              <h1
                data-aos="fade-up"
                className=" text-3xl lg:text-6xl font-light font_uber"
              >
                Book a demo & <br />
                tour our product
              </h1>
              <div className=" my-4">
                <video
                  className=" relative w-full lg:w-5/6 rounded-xl overflow-hidden"
                  style={{ objectFit: 'cover', height: '300px' }}
                  controls={true}
                >
                  <source
                    src="https://firebasestorage.googleapis.com/v0/b/leasemagnets---dummy-db.appspot.com/o/community%2F31%2Fmagnet%2F0d8aa956-2d8d-4254-b018-15c1672c5d9a%2Fintro_Main_Intro_2%2FSimple_tour_video_ad__masked_shirt_2_mp4.mp4?alt=media&token=cc19a462-6e80-49cd-ba2c-e68b1c2f2967?&coconut_id=sT527RCVNJNgri"
                    type="video/mp4"
                  />
                </video>
              </div>
              <p className=" text-sm font_uber">
                Learn why best-in-class asset owners and property managers trust
                us with their virtual leasing process.
              </p>
              <p className=" text-sm font_uber pt-5">
                Learn how you can: <br />
                - Virtually sell the apartment as well as you did in person,
                post COVID <br />- Outperform your competition virtually, by
                booking twice the number of tours and leases from your website
              </p>
            </div>
          </div>
          <div>
            <iframe
              className=" h-screen"
              style={{ width: '100%' }}
              src="https://calendly.com/leasemagnetsteam/30min?embed_domain=hi.tour.video&embed_type=Inline&month=2022-06"
              frameborder="0"
            ></iframe>
            <div className=" grid grid-cols-2 gap-2 lg:gap-8 ">
              <div>
                <div className=" h-80 overflow-hidden rounded-xl ">
                  <img
                    className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611525f6cd52a76e755963c3_one-park-full-p-500.jpeg"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div className=" w-full flex items-center justify-center pt-2">
                  <img
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115221d8fac2db0afe20ef1_opc-logo-header-10.png"
                    style={{ objectFit: 'contain' }}
                    className=" w-32"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className=" h-80 overflow-hidden rounded-xl">
                  <img
                    className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115264bf1c289a47e7040c0_indepence%20village.jpeg"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div className=" w-full flex items-center justify-center pt-2">
                  <img
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611521e5829dd3158899a9cb_indepence-village.png"
                    style={{ objectFit: 'contain' }}
                    className=" w-32"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className=" h-80 overflow-hidden rounded-xl">
                  <img
                    className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/61152743829dd3c32999d79a_hwh-luxury-living-topshot.jpeg"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div className=" w-full flex items-center justify-center pt-2">
                  <img
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6114eca70aadb7684e9fe904_hwh-logo-5-p-500.png"
                    style={{ objectFit: 'contain' }}
                    className=" w-32"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className=" h-80 overflow-hidden rounded-xl">
                  <img
                    className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611528465c747345a36a8bb9_edge-scene2-p-500.jpeg"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div className=" w-full flex items-center justify-center pt-2">
                  <img
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6115211ad2468230384ebd1f_PeakMadeRealEstate-Logo-Horizontal-RGB-01-e1601937465577-p-500.png"
                    style={{ objectFit: 'contain' }}
                    className=" w-32 "
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className=" h-80 overflow-hidden rounded-xl">
                  <img
                    className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611526f15c74732cf06a83ae_xdenver-p-500.jpeg"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div className=" w-full flex items-center justify-center pt-2">
                  <img
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6114ee1de088dd04b02cc0ad_x%20social%20community%20-%20logo-p-500.jpeg"
                    style={{ objectFit: 'contain' }}
                    className=" w-32 "
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className=" h-80 overflow-hidden rounded-xl">
                  <img
                    className=" transform hover:scale-110 transition duration-300 ease-linear cursor-pointer"
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611527890a94947c608abd54_we-work-sample-p-500.jpeg"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                    }}
                    alt=""
                  />
                </div>
                <div className=" w-full flex items-center justify-center pt-2">
                  <img
                    src="https://assets.website-files.com/6114bc308fac2d36f9df818c/6114eac934645f1ab1e5cadd_wework-logo-p-500.png"
                    style={{ objectFit: 'contain' }}
                    className=" w-32 "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ----------------------Skill ------------------------- */}
        <div className=" grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-6 pb-10 mt-40 ">
          <img
            src={c1}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c2}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c3}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c4}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c5}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
          <img
            src={c6}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default BookDemo;
