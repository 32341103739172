import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import React, { memo, useState } from 'react';
import Slider from 'react-slick';
import calendly from '../../../assets/calendly.png';
import metaport from '../../../assets/metaport.png';
import s1 from '../../../assets/s1.png';
import s2 from '../../../assets/s2.png';
import s3 from '../../../assets/s3.png';
import youtube from '../../../assets/youtube.png';
import AnimatedDialog from '../../../components/Reusable/AnimatedDialog';
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import './Settings.css';
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const iframeData = [
  {
    name: 'Youtube Video',
    src: 'https://www.youtube.com/embed/YPulKPClHWI',
    desc: 'lorem ipsum door simet to do what you want to do',
  },
  {
    name: 'Metterport',
    src: 'https://my.matterport.com/show/?m=FaevZd7HepL&play=1',
    desc: 'lorem ipsum door simet to do what you want to do',
  },
  {
    name: 'Calendly',
    src: ' https://calendly.com/amulyaparmar/15min',
    desc: 'lorem ipsum door simet to do what you want to do',
  },
];

const IframeSettings = ({ iframeOptions, setIframeOptions }) => {
  const [open, setOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  return (
    <Grid container spacing={1}>
      <AnimatedDialog
        title="Embed Matterport Videos"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
      >
        <ModalForPreview modalDetails={modalDetails} />
      </AnimatedDialog>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Switch name="isIframe" />}
          style={{ fontSize: '12px' }}
          className="  text-gray-500"
          label="Enables users to add/insert various website pages/tools/applications within the virtual tour."
          checked={iframeOptions.enabled}
          onChange={(e) =>
            setIframeOptions({ ...iframeOptions, enabled: e.target.checked })
          }
        />
      </Grid>
      <div className=" w-full my-4">
        <Slider {...settings}>
          {iframeData.map((item) => (
            <div className=" w-full border p-3 rounded-xl">
              <div className=" flex items-center gap-2">
                {item.name === 'Youtube Video' && (
                  <img
                    src={youtube}
                    className=" w-8 h-8 object-contain"
                    alt=""
                  />
                )}
                {item.name === 'Matterport' && (
                  <img
                    src={metaport}
                    className=" w-8 h-8 object-contain"
                    alt=""
                  />
                )}
                {item.name === 'Calendly' && (
                  <img
                    src={calendly}
                    className=" w-8 h-8 object-contain"
                    alt=""
                  />
                )}
                <p>{item.name}</p>
              </div>
              <p className=" py-3 text-sm text-gray-500">{item.desc}</p>
              <div className=" flex items-center justify-end gap-3">
                <button
                  onClick={() => {
                    setModalDetails(item);
                    setOpen(true);
                  }}
                  className=" border px-2 py-2 text-sm text-gray-400 rounded-md"
                >
                  Setup
                </button>
                <button
                  onClick={() => {
                    setIframeOptions({
                      ...iframeOptions,
                      enabled: true,
                      src: item.src,
                    });
                  }}
                  className=" bg-pr px-2 py-2 text-sm text-white rounded-md"
                >
                  See Demo
                </button>
              </div>
            </div>
          ))}
          <div className=" w-full border p-3 rounded-xl ">
            <div className=" flex items-center gap-2">
              <img src={metaport} className=" w-8 h-8 object-contain" alt="" />
              <p>Matterport</p>
            </div>
            <p className=" py-3 text-sm text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            </p>
            <div className=" flex items-center justify-end gap-3">
              <button
                onClick={() => setOpen(true)}
                className=" border px-2 py-2 text-sm text-gray-400 rounded-md"
              >
                Setup
              </button>
              <button
                onClick={() => {
                  setIframeOptions({
                    ...iframeOptions,
                    enabled: true,
                    src: 'https://my.matterport.com/show/?m=FaevZd7HepL&play=1',
                  });
                }}
                className=" bg-pr px-2 py-2 text-sm text-white rounded-md"
              >
                See Demo
              </button>
            </div>
          </div>
          <div className=" w-full border p-3 rounded-xl ">
            <div className=" flex items-center gap-2">
              <img src={calendly} className=" w-8 h-8 object-contain" alt="" />
              <p>Calendly</p>
            </div>
            <p className=" py-3 text-sm text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
            </p>
            <div className=" flex items-center justify-end gap-3">
              <button
                onClick={() => setOpen(true)}
                className=" border px-2 py-2 text-sm text-gray-400 rounded-md"
              >
                Setup
              </button>
              <button
                onClick={() => {
                  setIframeOptions({
                    ...iframeOptions,
                    enabled: true,
                    src: 'https://calendly.com/amulyaparmar/15min',
                  });
                }}
                className=" bg-pr px-2 py-2 text-sm text-white rounded-md"
              >
                See Demo
              </button>
            </div>
          </div>
        </Slider>
      </div>

      {iframeOptions && (
        <Grid item xs={12}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">iFrame Src</div>
            <DebouncedTextField
              id="form-title"
              placeholder="e.g. https://www.youtube.com/embed/YPulKPClHWI"
              variant="outlined"
              className="text-gray-400"
              value={iframeOptions.src || ''}
              size="small"
              onChange={(newValue) =>
                setIframeOptions({ ...iframeOptions, src: newValue })
              }
              fullWidth
            />
          </div>
          <div className=" grid grid-cols-2 gap-5 w-full ">
            <div className="form-group flex flex-col">
              <div className="feild-title font s15 b6 c000">
                Background Color
              </div>

              {/* <input id="bg_color" name='bg_color' className=' hidden' type="color"  value={iframeOptions.background_color}   onChange={(newValue) =>
           setIframeOptions({
             ...iframeOptions,
             background_color: newValue,
           })
         } /> */}
              <DebouncedTextField
                id="form-text-color"
                name="form-text-color"
                type="color"
                variant="outlined"
                style={{ opacity: '0', width: '0', height: '0' }}
                value={iframeOptions.background_color}
                size="small"
                onChange={(newValue) =>
                  setIframeOptions({
                    ...iframeOptions,
                    background_color: newValue,
                  })
                }
                fullWidth
              />
              <label
                htmlFor="form-text-color"
                className=" w-full cursor-pointer p-2 border rounded-md flex items-center justify-between"
              >
                <div
                  style={{ background: iframeOptions.background_color }}
                  className=" w-5 h-5 rounded-full"
                ></div>
                <p className=" text-pr text-sm">Change color</p>
              </label>
            </div>

            <div className="form-group flex flex-col">
              <div className="feild-title font s15 b6 c000">Icon Color</div>
              <DebouncedTextField
                id="form-text-color-2"
                name="form-text-color-2"
                type="color"
                style={{ opacity: '0', width: '0', height: '0' }}
                variant="outlined"
                value={iframeOptions.icon_color}
                size="small"
                onChange={(newValue) =>
                  setIframeOptions({
                    ...iframeOptions,
                    icon_color: newValue,
                  })
                }
                fullWidth
              />
              <label
                htmlFor="form-text-color-2"
                className=" w-full cursor-pointer p-2 border rounded-md flex items-center justify-between"
              >
                <div
                  style={{ background: iframeOptions.icon_color }}
                  className=" w-5 h-5 rounded-full"
                ></div>
                <p className=" text-pr text-sm">Change color</p>
              </label>
            </div>
          </div>
          {/* <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://www.youtube.com/embed/YPulKPClHWI',
              })
            }
          >
            YouTube
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://my.matterport.com/show/?m=FaevZd7HepL&play=1',
              })
            }
          >
            Matterport
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://calendly.com/amulyaparmar/15min',
              })
            }
          >
            Calendly
          </Button>
          <Button
            variant="outlined"
            onClick={() =>
              setIframeOptions({
                ...iframeOptions,
                src: 'https://calendly.com/amulyaparmar/15min',
              })
            }
          >
            External Video
          </Button> */}
        </Grid>
      )}
    </Grid>
  );
};

const ModalForPreview = ({ modalDetails }) => {
  const [details, setDetails] = useState(true);
  return (
    <div className=" w-full  bg-white p-3">
      <h1 className=" text-md text-gray-700">Embed Matterport Videos</h1>
      <div className=" mb-2 mt-4 bg-blue-100 w-full  flex items-center gap-4 px-4">
        <p
          onClick={() => setDetails(true)}
          className={
            details
              ? 'text-black text-sm py-2 border-b-2 border-black cursor-pointer'
              : 'text-gray-500 cursor-pointer text-sm py-2'
          }
        >
          Details
        </p>
        <p
          onClick={() => setDetails(false)}
          className={
            !details
              ? 'text-black text-sm py-2 border-b-2 border-black cursor-pointer'
              : 'text-gray-500 cursor-pointer text-sm py-2'
          }
        >
          Tutorials
        </p>
      </div>
      <div className=" pt-2 grid gap-8 grid-cols-1 lg:grid-cols-11 w-full">
        <div className=" lg:col-span-5">
          <iframe
            width="100%"
            height="315"
            src={modalDetails?.src}
            title="YouTube video player"
            frameborder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen={true}
          />
        </div>
        <div className=" lg:col-span-6">
          <p className=" text-sm font-medium">{modalDetails?.name} Link :</p>
          <div className=" mt-1 w-full p-2 rounded-md border text-gray-400 text-sm">
            {modalDetails?.src}
          </div>
          <p className=" text-sm font-medium mt-2">Description :</p>
          <div className=" mt-1 w-full p-2 rounded-md border text-gray-400 text-sm">
            {modalDetails?.desc}
          </div>

          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4">
            <div>
              <img src={s1} className=" w-10 h-10 object-contain" alt="" />
              <p className=" text-xs text-gray-600 font-medium py-2">
                Capture the way you want
              </p>
              <p className=" text-xs text-gray-400">
                Lorem ipsum dolor sutit amet, consectetur adipiscing elidasasdt,
                sedLorem ipsum dolor sits amet, consectetur{' '}
              </p>
            </div>
            <div>
              <img src={s2} className=" w-10 h-10 object-contain" alt="" />
              <p className=" text-xs text-gray-600 font-medium py-2">
                Connect to our mobile app
              </p>
              <p className=" text-xs text-gray-400">
                Lorem ipsum dolor sutit amet, consectetur adipiscing elidasasdt,
                sedLorem ipsum dolor sits amet, consectetur{' '}
              </p>
            </div>
            <div>
              <img src={s3} className=" w-10 h-10 object-contain" alt="" />
              <p className=" text-xs text-gray-600 font-medium py-2">
                Manage your 3D models
              </p>
              <p className=" text-xs text-gray-400">
                Lorem ipsum dolor sutit amet, consectetur adipiscing elidasasdt,
                sedLorem ipsum dolor sits amet, consectetur{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(IframeSettings);
