import { useState } from 'react';
import firebase from './base';

const ENV = process.env.NODE_ENV || 'development';
export const HOST =
  ENV === 'development'
    ? 'http://api.leasemagnets.com'
    : 'https://api.leasemagnets.com';

export const API_HOST =
  ENV === 'development'
    ? 'http://api.leasemagnets.com'
    : 'https://api.leasemagnets.com';

export const DASHBOARD_HOST =
  ENV === 'development'
    ? 'https://my.leasemagnets.com'
    : 'https://my.leasemagnets.com';

export const TOUR_HOST =
  ENV === 'development' ? 'https://tour.video' : 'https://tour.video';

export const EMBED_HOST =
  ENV === 'development' ? 'http://localhost:3000' : 'https://embed.tour.video';

// Residents base routes
export const RESIDENT_HOST =
  ENV === 'development'
    ? 'https://api.residents.me'
    : 'https://api.residents.me';
export const RESIDENT_DASHBOARD =
  ENV === 'development' ? 'http://localhost:3000' : 'https://feedbackjoy.com';

if (ENV === 'development-live') {
  HOST = 'https://api.leasemagnets.com';
  API_HOST = 'https://api.leasemagnets.com';
  DASHBOARD_HOST = 'https://my.leasemagnets.com';
  TOUR_HOST = 'https://tour.video';
}

const request = async (route, method, data, requestOptions) => {
  const { auth = true, host = HOST, ignoreHost = false } = requestOptions || {};

  let json = null;

  const headers = new Headers();
  if (method === 'POST') {
    headers.append('Content-Type', 'application/json');
  }

  if (auth) {
    try {
      const authToken = await firebase.user().getIdToken(true);
      headers.append('Authorization', authToken);
    } catch (err) {
      return { status: 'fail', error: 'Not authenticated' };
    }
  }

  const options = {
    method,
    headers,
    credentials: 'include',
  };

  if (method === 'POST') {
    options.body = JSON.stringify(data);
  }

  try {
    let res = {};
    if (!ignoreHost) res = await fetch(`${host}${route}`, options);
    if (ignoreHost) res = await fetch(`${route}`, options);
    try {
      const text = await res.text();
      json = JSON.parse(text);
    } catch (err) {
      //console.log('err: ', text);
      json = { status: 'fail', error: `Request returned invalid JSON: ${err}` };
    }
  } catch (err) {
    json = { status: 'fail', error: `Failed to make request: ${err}` };
  }

  console.log(`gotJson | ${route} | ${json.status}`);
  console.log(json);
  return json;
};

export const useRequest = (defaults) => {
  const { loading: defaultLoading = false, error: defaultError = null } =
    defaults;
  const [loading, setLoading] = useState(defaultLoading);
  const [error, setError] = useState(defaultError);
  const [requestData, setRequestData] = useState(null);

  const makeRequest = async (route, method, data, options) => {
    setLoading(true);

    const res = await request(route, method, data, options);
    console.log(res.error);
    if (res.error) {
      console.log(res.error);
      setError(res.error);
    } else {
      setError(null);
      setRequestData(res);
    }
    setLoading(false);
    return res;
  };
  return [loading, error, requestData, makeRequest];
};

export const get = (route, opts) => request(route, 'GET', null, opts);
export const post = (route, data, opts) => request(route, 'POST', data, opts);

// import { useState } from 'react';
// import firebase from './base';

// const ENV = process.env.NODE_ENV || 'development';
// const HOST =
//   ENV === 'development'
//     ? 'http://localhost:8080'
//     : 'https://api.leasemagnets.com';

// export const API_HOST =
//   ENV === 'development'
//     ? 'http://localhost:8080'
//     : 'https://api.leasemagnets.com';

// export const DASHBOARD_HOST =
//   ENV === 'development'
//     ? 'http://localhost:3001'
//     : 'https://my.leasemagnets.com';

// export const TOUR_HOST =
//   ENV === 'development' ? 'http://localhost:3001' : 'https://tour.video';

// // Note that testing this locally may not work correctly without manually changing the
// // HOST to embed.tour.video (instead of localhost) or running the VideoMagnet locally
// // in addition to the dashboard (need to be able to find the /leasemagnets.js script
// // and have a magnet UUID that's present in the database referenced by the /leasemagnets.js
// // script, and the production script references the production database)
// export const EMBED_HOST =
//   ENV === 'development' ? 'http://localhost:3000' : 'https://embed.tour.video';

// const request = async (route, method, data, requestOptions) => {
//   const { auth = true, host = HOST } = requestOptions || {};

//   let json = null;

//   const headers = new Headers();
//   if (method === 'POST') {
//     headers.append('Content-Type', 'application/json');
//   }

//   if (auth) {
//     try {
//       const authToken = await firebase.user().getIdToken(true);
//       headers.append('Authorization', authToken);
//     } catch (err) {
//       return { status: 'fail', error: 'Not authenticated' };
//     }
//   }

//   const options = {
//     method,
//     headers,
//     credentials: 'include',
//   };

//   if (method === 'POST') {
//     options.body = JSON.stringify(data);
//   }

//   try {
//     const res = await fetch(`${host}${route}`, options);
//     try {
//       const text = await res.text();
//       json = JSON.parse(text);
//     } catch (err) {
//       json = { status: 'fail', error: `Request returned invalid JSON: ${err}` };
//     }
//   } catch (err) {
//     json = { status: 'fail', error: `Failed to make request: ${err}` };
//   }

//   console.log(`gotJson | ${route} | ${json.status}`);
//   return json;
// };

// export const useRequest = (defaults) => {
//   const { loading: defaultLoading = false, error: defaultError = null } =
//     defaults;
//   const [loading, setLoading] = useState(defaultLoading);
//   const [error, setError] = useState(defaultError);
//   const [requestData, setRequestData] = useState(null);

//   const makeRequest = async (route, method, data, options) => {
//     setLoading(true);
//     const res = await request(route, method, data, options);
//     if (res.error) {
//       setError(res.error);
//     } else {
//       setError(null);
//       setRequestData(res);
//     }
//     setLoading(false);
//     return res;
//   };

//   return [loading, error, requestData, makeRequest];
// };

// export const get = (route, opts) => request(route, 'GET', null, opts);
// export const post = (route, data, opts) => request(route, 'POST', data, opts);
