import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Transition } from 'react-transition-group';
import './animations.css';
import { analyticsMetadata, buttonClick } from './api';
import Form from './Form';
import { PauseIcon, ThumbIcon, VideoPlayFillIcon } from './Icons';
import Iframe from './Iframe';
import MagnetContext from './MagnetContext';
import NavigationLinks from './NavigationLinks';
import ScrollableContainer from './ScrollableContainer';
import ShareOverlay from './ShareOverlay';
import { replaceUserInfo } from './util';
import Video from './Video';
import './VideoMagnet.css';
import VideoMagnetControls from './VideoMagnetControls';
import VideoMagnetProgress from './VideoMagnetProgress';

// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
const IS_IOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const UI_STATE = Object.freeze({
  HIDDEN: 0,
  SHOWN: 1,
  REHIDDEN: 2,
});

const LocationCard = ({ location }) =>
  location?.trim() ? (
    <div className="location-card">
      <img alt="location pin" src="https://i.imgur.com/wfVLPAg.png" />{' '}
      {location}
    </div>
  ) : null;

const PauseButton = ({ playing, text }) => {
  const inLineText = 'pause-text-blk';

  return !playing ? (
    <div className="pause-icon">
      {text ? (
        <>
          <div className={inLineText}>
            {inLineText === 'thumb-text-blk' ? (
              <ThumbIcon />
            ) : (
              <VideoPlayFillIcon />
            )}
            <div className="pause-text">{text}</div>
          </div>
        </>
      ) : (
        <PauseIcon />
      )}
    </div>
  ) : null;
};

const Branding = ({ url, img }) => (
  <a
    className="branding-logo"
    href={url || 'http://tour.video/'}
    target="_blank"
    rel="noreferrer"
  >
    <img alt="logo" src={img || 'https://i.imgur.com/vXltBn7.png'} />
  </a>
);

const VideoMagnet = ({
  show: showVideoMagnet,
  hideNavigationButtons,
  inline: isInline,
  inlineCTA,
  showLinksInline: showLinksInlineMutedAutoplay,
  backgroundOpacity,
  magnetSettings,
  screen,
  getNextVideo,
  getNewPage,
  startTour,
  restartTour,
  onClose: onVideoMagnetClose,
}) => {
  const { uuid, currentCategory, currentScreen } = useContext(MagnetContext);
  const {
    // Note that we're using "caption", not "title"
    caption = '',
    // eslint-disable-next-line camelcase
    location_card,
    show_title_time: showTitleTime = 0.5,
    hide_title_time: hideTitleTime = 5,
    show_nav_links_time: showNavLinksTime = 1,
    video,
    share_info: shareInfo,
    form,
    iframe,
    links: _links,
    bottomLinks: _bottomLinks = [],
    centerLinks: _centerLinks = [],
  } = screen;
  // TODO: useMemo() for all user data replacement
  const textData = { caption, location_card };
  const textDataReplaced = useMemo(
    () =>
      textData
        ? Object.fromEntries(
            Object.entries(textData).map(([key, value]) => [
              key,
              replaceUserInfo(value),
            ])
          )
        : {},
    [screen]
  );

  const { caption: title, location_card: locationCard } = textDataReplaced;

  const links = useMemo(
    () =>
      _links.map((link) => ({
        ...link,
        name: replaceUserInfo(link.name),
      })),
    [screen]
  );
  // const links = _links;

  const containerRef = useRef();

  const [isClosing, setClosing] = useState(false);

  // Keep the video source URL as a separate state so that we can start loading the next video
  // without having to wait for animations to finish
  const [videoSource, setVideoSource] = useState(video);
  useEffect(() => setVideoSource(video), [video]);

  const [isPlaying, setIsPlaying] = useState(!isInline);
  const [hasVideoPlayed, setHasVideoPlayed] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const progress = Math.round((100 * currentTime) / duration) || 0; // `|| 0` to catch NaNs from 0 / 0
  // Used to update the video time. >0 indicates that the <Video> should use this
  // time instead of the current time. Kinda hacky, but not sure there's a better way.
  const [newVideoTime, setNewVideoTime] = useState(-1);

  // Array of previously visited routes
  const [history, setHistory] = useState([]);

  const [isMuted, toggleIsMuted] = useReducer((prev) => !prev, false);
  // 0.75x, 1x (default), 1.25x, 1.5x, and 2x
  const [playbackRate, togglePlaybackRate] = useReducer((prev) => {
    if (prev === 1) {
      return 1.25;
    }
    if (prev === 1.25) {
      return 1.5;
    }
    if (prev === 1.5) {
      return 2;
    }
    if (prev === 2) {
      return 0.75;
    }

    return 1;
  }, 1);

  // When inline, video will autoplay but stay muted
  // isPlaying and isMuted states will be false, but will be overridden by this state
  const [inlineMutedAutoplay, disableInlineMutedAutoPlay] = useReducer(
    () => false,
    isInline
  );

  // Special case to handle fullscreen on iOS (!playsInline triggers fullscreen)
  // TODO: Maybe can just be an isFullscreen state? That seems to be how we use it
  const [playsInline, setPlaysInline] = useState(true);

  const [titleDisplayState, setTitleDisplayState] = useState(UI_STATE.HIDDEN);
  const [navLinksDisplayState, setNavLinksDisplayState] = useState(
    UI_STATE.HIDDEN
  );

  useEffect(() => {
    if (
      navLinksDisplayState === UI_STATE.HIDDEN &&
      currentTime > showNavLinksTime
    ) {
      setNavLinksDisplayState(UI_STATE.SHOWN);
    }

    // TODO: What if show time == hide time?
    if (titleDisplayState === UI_STATE.HIDDEN && currentTime > showTitleTime) {
      setTitleDisplayState(UI_STATE.SHOWN);
    } else if (
      titleDisplayState === UI_STATE.SHOWN &&
      currentTime > hideTitleTime
    ) {
      setTitleDisplayState(UI_STATE.REHIDDEN);
    }
  }, [
    currentTime,
    hideTitleTime,
    showNavLinksTime,
    showTitleTime,
    titleDisplayState,
    navLinksDisplayState,
  ]);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [iframeClosed, setIframeClosed] = useState(false);
  const [showShare, setShowShare] = useState(false);

  const showForm = form?.enabled && !formSubmitted;
  const showIframe = iframe?.enabled && !iframeClosed;
  const showChapterButton = _centerLinks?.links && _centerLinks?.buttonEnabled;
  const showChapterPage = _centerLinks?.links && _centerLinks?.pageEnabled;
  const backgroundWhite = _centerLinks?.links && _centerLinks?.backgroundWhite;
  const extraPadding = _bottomLinks?.links && _bottomLinks?.extraPadding;
  const backgroundEnabled =
    _bottomLinks?.links && _bottomLinks?.invisibleBackground;
  const roundedVideoPlayer =
    _bottomLinks?.links && _bottomLinks?.roundedVideoPlayer;
  // If there is a video, show it if form is not shown or if form is transparent
  // and if Iframe is not shown
  const showVideo =
    video &&
    // Can't just check for `form.opacity`, since 0 is false-y
    (!showForm ||
      (showForm && form.opacity !== undefined && form.opacity !== 1.0)) &&
    !showIframe;

  // The VideoMagnet component is re-used by the parent Widget, so we need to reset
  // some states when `currentRoute` changes or when the VideoMagnet closes
  const resetUI = () => {
    setFormSubmitted(false);
    setIframeClosed(false);
    setNavLinksDisplayState(UI_STATE.HIDDEN);
    setTitleDisplayState(UI_STATE.HIDDEN);
    setCurrentTime(0);
  };

  useEffect(resetUI, [video]);

  const isDocumentFullscreen = () =>
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement;

  const toggleFullscreen = (event) => {
    if (IS_IOS) {
      // Special case for iOS: if we disable playInline and then play it, video will open as fullscreen
      // Tested with https://zjwcq.csb.app/ on iPhone
      setIsPlaying(false);
      setPlaysInline(false); // Trigger fullscreen
      setTimeout(() => setIsPlaying(true), 100);
      setTimeout(() => setPlaysInline(true), 500);
    } else if (isDocumentFullscreen()) {
      setPlaysInline(true);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      setPlaysInline(false);
      const el = containerRef.current || {};
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen();
      }
    }
    if (event) event.stopPropagation();
  };

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  const onClickLink = (link) => {
    if (inlineMutedAutoplay) {
      disableInlineMutedAutoPlay();
    }
    if (link?.updateTime) {
      setNewVideoTime(link?.updateTime);
    } else if (link.route) {
      if (
        link.route.split('.').toString() ===
        [currentCategory, currentScreen].toString()
      ) {
        return;
      }
      setTitleDisplayState(UI_STATE.REHIDDEN);
      setNavLinksDisplayState(UI_STATE.REHIDDEN);
      const newRoute = link.route.split('.');
      setVideoSource(getNextVideo(newRoute)); // Start loading the next video before the animation finishes

      // if we have a new route time then we want to pause video from playing
      if (link?.routeTime) {
        setNewVideoTime(link?.routeTime);

        setIsPlaying(false);

        // Wait for nav links and title to animate
        setTimeout(() => {
          setHistory([...history, [currentCategory, currentScreen]]);
          getNewPage(newRoute);
          setNewVideoTime(link?.routeTime);
          setIsPlaying(true);
        }, 500);
      } else {
        setIsPlaying(true);

        // Wait for nav links and title to animate
        setTimeout(() => {
          setHistory([...history, [currentCategory, currentScreen]]);
          getNewPage(newRoute);
        }, 500);
      }
    } else if (link.href) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      buttonClick([currentCategory, currentScreen], link.href);
      setIsPlaying(false);
      window.open(link.href, '_blank').focus();
    }
    setTesterBool(!testerBool);
  };

  // Close form or Iframe
  const onOverlayClose = () => {
    if (links.length === 1) {
      // Go to first link if exactly one link is available
      onClickLink(links[0]);
    } else if (links.length === 0 || !video) {
      restartTour();
    } // else {  // >1 links
    // Links are shown
    // }
  };

  const onClose =
    !isInline &&
    ((event) => {
      // setNavLinksDisplayState(UI_STATE.REHIDDEN);
      // setTitleDisplayState(UI_STATE.REHIDDEN);
      if (isDocumentFullscreen()) toggleFullscreen();
      // Partially wait for nav links and title to hide
      setTimeout(() => {
        setClosing(true);
        // Wait for VideoMagnet container to slide away
        setTimeout(() => {
          onVideoMagnetClose();
          setClosing(false);
          setIsPlaying(!isInline); // Reset so it will autoplay on reopen if paused
        }, 500);
      }, 500 / 2);
      event.stopPropagation();
    });

  const onIframeClose = () => {
    setIframeClosed(true);
    onOverlayClose();
  };

  const onFormClose = () => {
    setFormSubmitted(true);
    onOverlayClose();
  };

  const onBackClick =
    history.length > 0 &&
    ((event) => {
      const [backCategory, backScreen] = history[history.length - 1];
      setHistory(history.filter((_, i) => i !== history.length - 1));
      getNewPage([backCategory, backScreen]);
      setIsPlaying(true);
      if (event) event.stopPropagation();
    });

  const togglePlay = () => {
    // When inline, startTour analytics event is fired on video play (since the video doesn't autoplay)
    if (isInline) startTour();
    if (inlineMutedAutoplay) {
      setNewVideoTime(0);
      setTimeout(disableInlineMutedAutoPlay, 100); // Terrible timeout hack to reset the video time before unmuting
    }
    if (!hasVideoPlayed) setHasVideoPlayed(true);
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  const onTimeUpdate = (newCurrentTime, newDuration) => {
    setCurrentTime(newCurrentTime);
    analyticsMetadata.videoTime = newCurrentTime;
    setDuration(newDuration || 0);
  };

  const movies1 = useMemo(
    () => [
      {
        id: '1',
        img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg',
      },
      {
        id: '2',
        img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg',
      },
      {
        id: '3',
        img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg',
      },
      {
        id: '4',
        img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg',
      },
      {
        id: '5',
        img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg',
      },
      {
        id: '6',
        img: 'https://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg',
      },
    ],
    []
  );

  // const movies2 = useMemo(() => ([]), []);
  // const movies3 = useMemo(() => {
  //   const linksEnriched = links.map((val, idx) => {
  //     return {...val, img: "http://storage.googleapis.com/leasemagnets---dummy-db.appspot.com/community/31/magnet/category/game_room/tokeneyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZWRpYV9pZCI6ImM2ZTA0ZTU4LTBiZDEtNDdkYy04MGEyLTQ2ZmFiM2EyM2Q1MCIsImV4cCI6MTY0MzU1NDI3M30.jpg" }
  //   })
  //   return linksEnriched
  // }, []);
  // const [testerBool, setTesterBool] = useState(true);
  const bottomButtonScrollableContainer = useMemo(() => {
    return (
      <ScrollableContainer
        fadeTransition={
          navLinksDisplayState === UI_STATE.SHOWN ||
          UI_STATE.HIDDEN ||
          showIframe
            ? 'fade-in-up'
            : 'fade-out-down'
        }
        bottomLinks={_bottomLinks}
        onClickLink={onClickLink}
        extraPadding={extraPadding}
      />
    );
  }, [_bottomLinks, navLinksDisplayState]);

  return (
    <div>
      <Transition
        in={showVideoMagnet && !isClosing}
        timeout={1000} // Timeouts must be synced with CSS animation durations
      >
        {(transitionStatus) => (
          <>
            {/* Outside of the showVideoMagnet conditional render to make the background f  e animation smoother */}

            {/* Without this check, the magnet is always rendered, which causes the video to start playing */}
            {showVideoMagnet && (
              <div
                ref={containerRef}
                className={`videomagnet videomagnet-${
                  isInline ? 'entered' : transitionStatus
                }`}
                onClick={togglePlay}
              >
                {showVideo && (
                  <>
                    {/* <ScrollableContainer /> */}
                    <Video
                      roundedVideoPlayer={roundedVideoPlayer}
                      source={videoSource}
                      isPlaying={isPlaying || inlineMutedAutoplay}
                      setIsPlaying={setIsPlaying}
                      isMuted={isMuted || inlineMutedAutoplay}
                      newVideoTime={newVideoTime}
                      setNewVideoTime={setNewVideoTime}
                      playbackRate={playbackRate}
                      onTimeUpdate={onTimeUpdate}
                      playsInline={playsInline}
                      loop={showForm || inlineMutedAutoplay}
                    />
                  </>
                )}
                <VideoMagnetControls
                  showChapterButton={showChapterButton}
                  progress={progress}
                  duration={duration}
                  isMuted={isMuted}
                  toggleIsMuted={toggleIsMuted}
                  isFullScreen={!playsInline}
                  toggleFullscreen={toggleFullscreen}
                  // Always go "back", close form/iframe/share overlay or go to previous screen
                  onBackClick={
                    showShare ? () => setShowShare(false) : onBackClick
                  }
                  // If an overlay is open, close it. Else, close the entire VideoMagnet
                  onClose={
                    showForm
                      ? onFormClose
                      : showIframe
                      ? onIframeClose
                      : showShare
                      ? () => setShowShare(false)
                      : onClose
                  }
                  showShare={showShare}
                  toggleShowShare={
                    // Some screens don't have shareInfo, forms use shareInfo on the "thank you" screen after submit
                    shareInfo &&
                    !showForm &&
                    (() => setShowShare((show) => !show))
                  }
                  playbackRate={playbackRate}
                  togglePlaybackRate={togglePlaybackRate}
                  showDuration={!showForm && !showIframe && !showShare}
                  showSettings={!showForm && !showIframe && !showShare}
                />

                {!showForm && !showIframe && !showShare && (
                  <>
                    {bottomButtonScrollableContainer}

                    <VideoMagnetProgress
                      progress={progress}
                      setProgress={(percent) =>
                        setNewVideoTime(percent * duration)
                      }
                    />
                    {(titleDisplayState !== UI_STATE.HIDDEN || !isPlaying) && (
                      <div
                        className={`title-container ${
                          titleDisplayState === UI_STATE.SHOWN || !isPlaying
                            ? 'fade-in-down'
                            : 'fade-out-up'
                        }`}
                      >
                        <LocationCard location={locationCard} />
                      </div>
                    )}
                    <div className="video-center-icons">
                      <PauseButton
                        playing={isPlaying}
                        text={inlineCTA}
                        // text={isInline && !hasVideoPlayed && inlineCTA}
                      />
                    </div>
                    {!hideNavigationButtons
                      ? navLinksDisplayState !== UI_STATE.HIDDEN &&
                        (!inlineMutedAutoplay ||
                          showLinksInlineMutedAutoplay) && (
                          <NavigationLinks
                            links={links}
                            onClickLink={onClickLink}
                            title={title}
                            fadeDirection={
                              navLinksDisplayState === UI_STATE.SHOWN
                                ? 'in-up'
                                : 'out-down'
                            }
                          />
                        )
                      : null}
                  </>
                )}
                {showForm && (
                  <Form
                    uuid={uuid}
                    formTemplate={form}
                    shareInfo={shareInfo}
                    onFormClose={onFormClose}
                  />
                )}
                {showIframe && (
                  <>
                    {/* <ScrollableContainer movies={ testerBool ? movies1 : movies2 } onClickLink={function() { console.log('bottom button click: '); onClickLink({"route": "intro.main"});  }} /> */}
                    {/* { useMemo( () => <ScrollableContainer movies={ testerBool ? movies1 : movies2 } onClickLink={function() { console.log('bottom button click: '); onClickLink({"route": "intro.main"}); setTesterBool(!testerBool)  }} /> , [ movies1, movies2, testerBool ]  )}  */}
                    {bottomButtonScrollableContainer}

                    <Iframe
                      src={iframe.src}
                      backgroundColor={iframe.background_color}
                      iconColor={iframe.icon_color}
                    />
                    <NavigationLinks
                      links={links}
                      onClickLink={onClickLink}
                      title={title}
                      fadeDirection="in-up"
                    />
                  </>
                )}
                {showChapterPage && (
                  <ShareOverlay
                    location_card={location_card}
                    title={title}
                    type="chapter"
                    shareInfo={shareInfo}
                    onClickLink={onClickLink}
                    setShowShare={setShowShare}
                    links={_centerLinks?.links}
                    backgroundWhite={backgroundWhite}
                  />
                )}
                {showShare && (
                  <ShareOverlay
                    location_card={location_card}
                    title={title}
                    type="share"
                    shareInfo={shareInfo}
                    onClickLink={onClickLink}
                    setShowShare={setShowShare}
                    links={links}
                  />
                )}
                <div
                  className={`videomagnet-footer ${
                    showIframe ? 'black-footer' : ''
                  }`}
                  // Don't play/pause background video for forms
                  onClick={(e) => e.stopPropagation()}
                >
                  <Branding
                    url={magnetSettings?.customBranding?.url}
                    img={magnetSettings?.customBranding?.img}
                  />
                </div>
                <div
                  className="video-shade"
                  style={{
                    background: backgroundEnabled
                      ? ''
                      : 'linear-gradient( 180deg, transparent 0%, rgba(0, 0, 0, 0.85) 35%, black 100%)',
                  }}
                />
              </div>
            )}
          </>
        )}
      </Transition>

      {/* <ScrollableContainer /> */}
    </div>
  );
};

export default VideoMagnet;
