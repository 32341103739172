import { Typography } from '@material-ui/core';
import MatGrid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from '../../utils/base';
import { font } from '../../utils/constants';

const PersonalInfoSignup = () => {
  // onboarding step
  const [onboarding, setOnboarding] = useState(1);
  // User Input States
  const [fullName, setFullName] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [orgRole, setOrgRole] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [image, setImage] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorAccount, setErrorAccount] = useState('');

  const [displayError, setDisplayError] = useState(false);

  const history = useHistory();

  // Validate Inputs
  const isInvalid =
    !fullName ||
    !email ||
    !password ||
    !confirmPassword ||
    !organizationName ||
    !orgRole;
  // !orgRole ||
  // !phoneNumber;

  const onSignUp = async () => {
    const phonePattern =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;

    // if (!phonePattern.test(phoneNumber)) {
    //   setErrorPersonal('Personal Phone number is invalid. Please try again');
    //   isError = true;
    // }
    const cred = await firebase.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    console.log(cred);
    if (!cred.user && !cred.user.uid) {
      setErrorAccount('error');
      console.log('error');
    }
    console.log(cred.user.uid);

    const profile_image = image
      ? image
      : 'https://image.flaticon.com/icons/png/512/1077/1077114.png';

    const doc = {
      name: fullName,
      email: email,
      // phone: phoneNumber,
      orgName: organizationName,
      role: orgRole,
      shared_access: {},
      image: profile_image,
      old_user: 1,
    };
    console.log(doc);

    await firebase.db
      .collection('users')
      .doc(cred.user.uid)
      .set(doc)
      .then((msg) => {
        history.push('/signup/apartments');
        history.go();
      });
  };

  const emailPattern = /^\S+@\S+\.\S+/;

  useEffect(() => {
    console.log(errorAccount);
  }, [errorAccount]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  // return (
  //   <div className="signup-personal-info flex flex-col">
  //     {onboarding === 1 ? (
  //       <>
  //         <div className="hdr">
  //           <div className="meta flex flex-col">
  //             <div className="step s22 b7 font lightBlack">Step 1:</div>
  //             <div className="title s18 b6 black font">
  //               Lets setup the new you!
  //             </div>
  //             <div className="text font s14 b3 black">
  //               Update your default password below
  //             </div>
  //           </div>
  //         </div>
  //         <div className="form flex flex-col">
  //           <input type="text" className="iput font" />
  //           <input type="text" className="iput font" />
  //           <button className="next-btn font">Next</button>
  //         </div>
  //       </>
  //     ) : onboarding === 2 ? (
  //       <>
  //         <div className="hdr">
  //           <div className="meta flex flex-col">
  //             <div className="step s22 b7 font lightBlack">Step 2:</div>
  //             <div className="title s18 b6 black font">
  //               Lets setup the new you!
  //             </div>
  //             <div className="text font s14 b3 black">
  //               Update your default password below
  //             </div>
  //           </div>
  //         </div>
  //         <div className="form flex flex-col">
  //           <input type="text" className="iput font" placeholder="Name" />
  //           <input type="text" className="iput font" placeholder="Email" />
  //           <input
  //             type="text"
  //             className="iput font"
  //             placeholder="Phone Number"
  //           />
  //           <input type="text" className="iput font" placeholder="Role" />
  //           <button className="next-btn font">Next</button>
  //         </div>
  //       </>
  //     ) : null}
  //   </div>
  // );

  return (
    <>
      <MatGrid container item xs={12} direction="column">
        {onboarding === 1 && (
          <>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
            >
              Create an account{' '}
            </Typography>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle1'}
              style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
            >
              Use LeaseMagnets to manage your Communities
            </Typography>{' '}
            <br />
            <input
              type="text"
              name="login"
              id="login"
              tabindex="1"
              class="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Full Name"
              style={{
                backgroundColor: fullName ? 'rgb(226, 244, 253)' : '#F3F3F4',
              }}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </>
        )}
        {onboarding === 2 && (
          <>
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
            >
              Hello, {fullName}!{' '}
            </Typography>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle1'}
              style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
            >
              Welcome to the LeaseMagnets family.
            </Typography>{' '}
            <br />
            <input
              type="email"
              name="login"
              id="login"
              tabindex="1"
              class="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Email"
              style={{
                backgroundColor:
                  email && emailPattern.test(email)
                    ? 'rgb(226, 244, 253)'
                    : '#F3F3F4',
              }}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              name="login"
              id="login"
              tabIndex="1"
              className="text-input"
              autoCorrect="off"
              autoCapitalize="off"
              placeholder="Password"
              style={{
                backgroundColor:
                  password.length > 5 ? 'rgb(226, 244, 253)' : '#F3F3F4',
              }}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <input
              type="password"
              name="login"
              id="login"
              tabindex="1"
              class="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Confirm Password"
              style={{
                backgroundColor:
                  confirmPassword && confirmPassword === password
                    ? 'rgb(226, 244, 253)'
                    : '#F3F3F4',
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              invalid={password !== confirmPassword}
              required
            />
          </>
        )}
        {onboarding === 3 && (
          <>
            <Typography
              variant={isMobile ? 'h6' : 'h4'}
              style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
            >
              Hello, {fullName}!{' '}
            </Typography>
            <Typography
              variant={isMobile ? 'subtitle2' : 'subtitle1'}
              style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
            >
              We'd like to get to know you better ;)
            </Typography>{' '}
            <br />
            <input
              type="text"
              name="login"
              id="login"
              tabindex="1"
              class="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Organization"
              style={{
                backgroundColor: organizationName
                  ? 'rgb(226, 244, 253)'
                  : '#F3F3F4',
              }}
              onChange={(e) => setOrganizationName(e.target.value)}
              required
            />
            <input
              type="text"
              name="login"
              id="login"
              tabindex="1"
              class="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Organizational Role"
              style={{
                backgroundColor: orgRole ? 'rgb(226, 244, 253)' : '#F3F3F4',
              }}
              onChange={(e) => setOrgRole(e.target.value)}
              required
            />
            <input
              type="url"
              name="login"
              id="login"
              tabindex="1"
              class="text-input"
              autocorrect="off"
              autocapitalize="off"
              placeholder="Profile Image (url, optional)"
              style={{
                backgroundColor: image ? 'rgb(226, 244, 253)' : '#F3F3F4',
              }}
              onChange={(e) => setImage(e.target.value)}
              required
            />
          </>
        )}
      </MatGrid>
      <MatGrid
        container
        item
        xs={12}
        style={{ marginTop: '10px', display: displayError ? 'block' : 'none' }}
      >
        {onboarding === 1 && fullName === '' && (
          <Typography class="error_text" variant="caption">
            →Please enter your full name
            <br />
          </Typography>
        )}
        {onboarding === 2 && (email === '' || !emailPattern.test(email)) && (
          <Typography class="error_text" variant="caption">
            →Please enter your email address, we promise we don't spam
            <br />
          </Typography>
        )}
        {onboarding === 2 && password.length < 6 && (
          <Typography class="error_text" variant="caption">
            →Password should be at least 6 characters long
            <br />
          </Typography>
        )}
        {onboarding === 2 && (password !== confirmPassword || password === '') && (
          <Typography class="error_text" variant="caption">
            →Passwords do not match
            <br />
          </Typography>
        )}
        {onboarding === 3 && organizationName === '' && (
          <Typography class="error_text" variant="caption">
            →Please enter your organization's name
            <br />
          </Typography>
        )}
        {onboarding === 3 && orgRole === '' && (
          <Typography class="error_text" variant="caption">
            →Please enter your organizational role
            <br />
          </Typography>
        )}
      </MatGrid>
      <MatGrid container item xs={12} justify="flex-start">
        {onboarding === 1 && (
          <>
            <button
              className="button signin"
              onClick={() => {
                if (fullName) {
                  setOnboarding(2);
                  setDisplayError(false);
                } else {
                  setDisplayError(true);
                }
              }}
            >
              Continue →
            </button>
            <button
              className="button secondary"
              onClick={() => window.location.replace('/')}
            >
              I already have an account
            </button>
          </>
        )}
        {onboarding === 2 && (
          <>
            <button
              className="button signin"
              onClick={() => {
                if (
                  email &&
                  emailPattern.test(email) &&
                  password.length > 5 &&
                  password === confirmPassword
                ) {
                  setOnboarding(3);
                  setDisplayError(false);
                } else {
                  setDisplayError(true);
                }
              }}
            >
              Continue →
            </button>
            <button
              className="button secondary"
              onClick={() => window.location.replace('/')}
            >
              I already have an account
            </button>
          </>
        )}
        {onboarding === 3 && (
          <>
            <button
              className="button signin"
              onClick={() => {
                if (organizationName && orgRole) {
                  onSignUp();
                  setDisplayError(false);
                } else {
                  setDisplayError(true);
                }
              }}
            >
              Continue →
            </button>
            <button
              className="button secondary"
              onClick={() => window.location.replace('/')}
            >
              I already have an account
            </button>
          </>
        )}
      </MatGrid>
    </>
  );
};

export default PersonalInfoSignup;
