import { TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormIcon, IframeIcon, VideoIcon } from '../../assets/svg';
import NewQuestion, { UNIQUE_TYPES } from './advanced-settings/NewQuestion';
import classes from './ManageMagnet.module.css';

const NewScreenButton = ({
  category,
  screen_keys,
  screen_id,
  categoryTitle,
}) => {
  const { encodingQ, loadScreen, currentVideo } = useSelector(
    (state) => state.magnetStates
  );

  const _dispatch = useDispatch();

  useEffect(() => {
    _dispatch({
      type: 'NEW_SCREEN_VIDEO_CLOUD_LIBRSRY',
      payload: [],
    });
  }, []);

  return (
    <figure>
      {encodingQ.includes(screen_id) ? (
        <>
          <div className={classes.magnetScreenItem}>
            <div className={classes.loader}></div>
            {loadScreen.map((item) => {
              if (item.name == currentVideo) {
                return (
                  <span className={classes.loadingText}>
                    {item.progress ? item.progress : 0}%
                  </span>
                );
              }
            })}
          </div>
        </>
      ) : (
        // TODO: replace "Video 1" placeholder with correct video number from Rutvi's backend code
        <NewVideoPopup
          defaultTitle={getDefaultTitle(category, screen_keys)}
          screen_id={screen_id}
          categoryTitle={categoryTitle}
        />
      )}
    </figure>
  );
};

export default NewScreenButton;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function NewVideoPopup({ defaultTitle, screen_id, categoryTitle }) {
  const {
    screenToEdit,
    loadScreen,
    showProgressBar,
    previewTemplate,
    potentiallyUploading,
    newScreenCloudLibrary,
    newScreenFormTemplate,
    newScreenIframeOption,
  } = useSelector((state) => state.magnetStates);
  const _dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const nextButtonRef = React.useRef(null);

  const [category, screen] = screen_id.split('.');
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [alert, setAlert] = useState();

  const [selectedScreenType, setSelectedScreenType] = useState('New Video');
  const screenTypes = [
    {
      label: 'New Video',
      text: 'Makeup your virtual tour',
      icon: <VideoIcon />,
    },
    {
      label: 'New Iframe',
      text: 'Embed video in a website',
      icon: <IframeIcon />,
    },
    {
      label: 'New Form',
      text: 'Build a form in the video',
      icon: <FormIcon />,
    },
  ];

  const [tempNewVideoTitle, setTempNewVideoTitle] =
    React.useState(defaultTitle);

  const handleClickOpen = () => {
    setOpen(true);
    cleanNewScreenStates();
  };

  const handleClose = () => {
    setOpen(false);
    setShowUploadDialog(false);
  };

  const [step, setStep] = useState(1);
  // Convert uploaded file to data URL
  function readFile(e) {
    e.preventDefault();
    // let reader = new FileReader();
    const files = e.target.files;
    setStep(2);

    if (!potentiallyUploading) {
      _dispatch({ type: 'POTENTIALLY_UPLOADING', payload: 0 });
    }

    //loop through all the dropped files
    for (let i = 0; i < files.length; ++i) {
      //Get video name
      const name = files[i].name;
      const type = files[i].type;
      _dispatch({
        type: 'ADD_SCREEN',
        payload: [
          ...loadScreen,
          {
            name: files[i].name,
            type: files[i].type,
            size: files[i].size / 1000000,
            route: `${category}.${screen}`,
          },
        ],
      });
      console.log('fileinfooo', files[i]);

      //Convert file into a data url
      const dataURL = URL.createObjectURL(files[i]);
      // //Sending file to google cloud platform
      const formData = new FormData();
      formData.append('video', files[i]);

      //filter out non-videos
      if (type === 'video/mp4' || type === 'video/quicktime') {
        //add it to videos
        _dispatch({
          type: 'NEW_SCREEN_VIDEO_CLOUD_LIBRSRY',
          payload: [
            ...newScreenCloudLibrary,
            {
              name: name,
              type: type,
              origName: name,
              dataURL: dataURL,
              formData: formData,
              saveToCloud: true,
            },
          ],
        });
        _dispatch({
          type: 'NEW_SCREEN_VIDEOS_CURRVID',
          payload: [
            { name: name, type: type, source: dataURL, formData: formData },
          ],
        });
      }
    }
  }

  const handleDrop = (files) => {
    setStep(2);
    if (!potentiallyUploading) {
      _dispatch({ type: 'POTENTIALLY_UPLOADING', payload: 0 });
    }

    //loop through all the dropped files
    for (let i = 0; i < files.length; ++i) {
      _dispatch({
        type: 'POTENTIALLY_UPLOADING',
        payload: potentiallyUploading + 1,
      });

      //Get video name
      const name = files[i].name;
      //Get file type (make sure it's a video)
      // files[i].type = "video/mp4"
      const type = files[i].type;
      //Convert file into a data url
      const dataURL = URL.createObjectURL(files[i]);
      // //Sending file to google cloud platform
      const formData = new FormData();
      formData.append('video', files[i]);

      _dispatch({
        type: 'ADD_SCREEN',
        payload: [
          ...loadScreen,
          {
            name: files[i].name,
            type: files[i].type,
            size: files[i].size / 1000000,
            route: `${category}.${screen}`,
          },
        ],
      });

      //filter out non-videos
      if (type === 'video/mp4' || type === 'video/quicktime') {
        // add it to videos
        _dispatch({
          type: 'NEW_SCREEN_VIDEO_CLOUD_LIBRSRY',
          payload: [
            ...newScreenCloudLibrary,
            {
              name: name,
              type: type,
              origName: name,
              dataURL: dataURL,
              formData: formData,
              saveToCloud: true,
            },
          ],
        });
        _dispatch({
          type: 'NEW_SCREEN_VIDEOS_CURRVID',
          payload: [
            { name: name, type: type, source: dataURL, formData: formData },
          ],
        });
      }
    }
  };

  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    vidRef.current.play();
  };

  const handlePauseVideo = () => {
    vidRef.current.pause();
  };

  const _deleteVideo = (idx) => {
    const _vidoes = newScreenCloudLibrary.filter((v, index) => index !== idx);
    const _loadScreen = loadScreen.filter((v, index) => index !== idx);

    _dispatch({
      type: 'NEW_SCREEN_VIDEO_CLOUD_LIBRSRY',
      payload: _vidoes,
    });
    _dispatch({
      type: 'ADD_SCREEN',
      payload: _loadScreen,
    });
    _dispatch({
      type: 'POTENTIALLY_UPLOADING',
      payload: potentiallyUploading - 1,
    });
  };

  const closeVideoDialog = () => {
    setShowUploadDialog(false);
    cleanNewScreenStates();
  };

  const cleanNewScreenStates = () => {
    _dispatch({
      type: 'NEW_SCREEN_FORM_TEMPLATE',
      payload: {
        ...newScreenFormTemplate,
        text_data: {
          title: '',
          body_text: '',
        },
        enabled: false,
        inputs: [],
      },
    });
    _dispatch({
      type: 'NEW_SCREEN_VIDEO_CLOUD_LIBRSRY',
      payload: [],
    });
    _dispatch({
      type: 'ADD_SCREEN',
      payload: [],
    });
    _dispatch({
      type: 'NEW_SCREEN_VIDEOS_CURRVID',
      payload: [],
    });
    _dispatch({
      type: 'NEW_SCREEN_IFRAME_OPTION',
      payload: {
        enabled: false,
        src: '',
        icon_color: '#ffffff',
        background_color: '#000000',
      },
    });
  };

  const _crateNewScreen = () => {
    _dispatch({
      type: 'NEW_VIDEO_TITLE',
      payload: tempNewVideoTitle,
    });
    _dispatch({
      type: 'SCREEN_TO_EDIT',
      payload: screen_id,
    });
    setShowUploadDialog(false);
  };

  {
    /* New Video Screen */
  }
  const NewVideo = () => {
    return (
      <div className="new-video">
        {step === 1 || newScreenCloudLibrary.length === 0 ? (
          <Dragger handleDrop={handleDrop}>
            <label className="step-first flex flex-col">
              <img
                className="vector"
                src={require('../../assets/folder-vector.svg').default}
              />
              <div className="slogn font s18 b5 c000">
                <span className="color">Drag & drop</span> your videos to upload
              </div>
              <input
                accept=".mp4, .mov, .webm"
                multiple
                style={{ display: 'none' }}
                name="video"
                id="contained-button-file"
                type="file"
                onChange={readFile}
              />
              <button
                className="cleanbtn upload-btn flex"
                onClick={() =>
                  document.getElementById('contained-button-file').click()
                }
              >
                <div className="txt font s14">More ways to upload</div>
                <img
                  className="ico"
                  src={require('../../assets/upload-icon.svg').default}
                />
              </button>
            </label>
            <div className="template-ftr flex aic">
              <img
                className="ico"
                src={require('../../assets/fill-video-icon.svg').default}
              />
              <div className="txt font s14 cfff">
                Or choose a sample from templates
              </div>
            </div>
          </Dragger>
        ) : (
          <div className="step-two flex flex-col">
            <div className="meta flex aic">
              <div className="tit font s20 b6 c000">
                My <span className="color">Videos</span>
              </div>
              <button
                onClick={() => setStep(1)}
                className="cleanbtn upload-btn flex aic"
              >
                <div className="font s14 c000 b5">More videos</div>
                <div className="ico icon-upload s18 c000" />
              </button>
            </div>
            <div className="wrap">
              {newScreenCloudLibrary.map((video, idx) => (
                <div className="video-card flex aic">
                  <div className="">
                    <video
                      ref={vidRef}
                      muted
                      loop
                      onMouseEnter={handlePlayVideo}
                      onMouseLeave={handlePauseVideo}
                      className="video"
                    >
                      <source src={video.dataURL} type={video.type} />
                      Your browser does not support HTML5 video.
                    </video>
                  </div>
                  <div className="lbl font s15 c000 wordwrap">{video.name}</div>
                  <div className="actions flex aic">
                    {/* <button className="cleanbtn icon-create icon"/> */}
                    <button
                      className="cleanbtn icon-delete2 icon"
                      onClick={() => _deleteVideo(idx)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="ftr flex aic">
              <button
                onClick={_crateNewScreen}
                className="cleanbtn create-btn font s15 anim"
              >
                Create
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  {
    /* New Iframe Screen */
  }
  const NewIframe = () => {
    const [iframeUrl, setIframeUrl] = useState('');

    const createIframe = () => {
      _dispatch({
        type: 'NEW_SCREEN_IFRAME_OPTION',
        payload: {
          ...newScreenIframeOption,
          enabled: true,
          src: iframeUrl,
        },
      });
      _dispatch({
        type: 'NEW_VIDEO_TITLE',
        payload: tempNewVideoTitle,
      });
      _dispatch({
        type: 'SCREEN_TO_EDIT',
        payload: screen_id,
      });
      setShowUploadDialog(false);
    };

    return (
      <div className="new-iframe flex flex-col">
        {step === 1 ? (
          <>
            <div className="block">
              <div className="icon">
                <IframeIcon />
              </div>
              <div className="tit font s17 b5">
                <span className="color">Enter the website</span> you want to
                embed into your tour
              </div>
            </div>
            <div className="feild flex aic">
              <input
                type="text"
                placeholder="iframe url"
                className="iput cleanbtn font s14 c333"
                value={iframeUrl}
                onChange={(e) => setIframeUrl(e.target.value)}
              />
              <button
                className="cleanbtn feild-btn flex aic font s14 b3"
                onClick={createIframe}
              >
                create
              </button>
            </div>
            <div className="template-ftr flex aic">
              <img
                className="ico"
                src={require('../../assets/fill-video-icon.svg').default}
              />
              <div className="txt font s14 cfff">
                Or choose a sample from templates
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="font">Ifram Form</div>
          </>
        )}
      </div>
    );
  };

  function setQuestions(updatedFormQuestions) {
    _dispatch({
      type: 'NEW_SCREEN_FORM_TEMPLATE',
      payload: {
        ...newScreenFormTemplate,
        inputs: updatedFormQuestions,
      },
    });
  }

  {
    /* New Form Screen */
  }
  const NewForm = () => {
    const [formData, setFormData] = useState({
      title: newScreenFormTemplate.text_data.title,
      subTitle: newScreenFormTemplate.text_data.body_text,
    });

    const usedUniqueTypes = newScreenFormTemplate?.inputs
      ?.map((question) => question?.type)
      ?.filter((type) => UNIQUE_TYPES.includes(type));

    // Handle add question button click
    function addQuestion() {
      console.log('formData qus...', formData);
      const existingIds = newScreenFormTemplate?.inputs?.map(
        (input) => input.id
      );
      let newId = undefined;
      while (!newId || existingIds.includes(newId)) {
        newId = Math.floor(Math.random() * 7237 * (Math.random() * 23));
      }

      const newQuestion = {
        label: '',
        type: 'text',
        id: newId,
        // Note that some types are locked to "required" or "not required" (text isn't)
        required: false,
        options: undefined,
      };
      _dispatch({
        type: 'NEW_SCREEN_FORM_TEMPLATE',
        payload: {
          ...newScreenFormTemplate,
          enabled: true,
          text_data: {
            ...newScreenFormTemplate.text_data,
            title: formData.title,
            body_text: formData.subTitle,
          },
          inputs: [...newScreenFormTemplate.inputs, newQuestion],
        },
      });
    }

    const createForm = () => {
      _dispatch({
        type: 'NEW_SCREEN_FORM_TEMPLATE',
        payload: {
          ...newScreenFormTemplate,
          enabled: true,
          text_data: {
            ...newScreenFormTemplate.text_data,
            title: formData.title,
            body_text: formData.subTitle,
          },
        },
      });
      _dispatch({
        type: 'NEW_VIDEO_TITLE',
        payload: tempNewVideoTitle,
      });
      _dispatch({
        type: 'SCREEN_TO_EDIT',
        payload: screen_id,
      });
      setShowUploadDialog(false);
    };

    return (
      <div className="new-form">
        {step === 1 ? (
          <Dragger handleDrop={handleDrop}>
            <label className="step-first flex flex-col">
              <img
                className="vector"
                src={require('../../assets/folder-vector.svg').default}
              />
              <div className="slogn font s18 b5 c000">
                <span className="color">Drag & drop</span> a video to be
                embedded behind your form
              </div>
              <input
                accept=".mp4, .mov, .webm"
                multiple
                style={{ display: 'none' }}
                name="video"
                id="contained-button-file"
                type="file"
                onChange={readFile}
              />
              <button
                className="cleanbtn upload-btn flex"
                onClick={() => setStep(2)}
              >
                <div className="txt font s15">Skip uploading a video</div>
                <div className="ico icon-arrow-up-right s20" />
              </button>
            </label>
            <div className="template-ftr flex aic">
              <img
                className="ico"
                src={require('../../assets/fill-video-icon.svg').default}
              />
              <div className="txt font s14 cfff">
                Or choose a sample from templates
              </div>
            </div>
          </Dragger>
        ) : (
          <>
            <div className="step-two flex flex-col">
              <div className="meta flex aic">
                <div className="tit font s20 b6 c000">
                  My <span className="color">Form</span>
                </div>
              </div>
              <div className="wrap">
                {newScreenCloudLibrary.map((video, idx) => (
                  <div className="video-card flex aic">
                    <div className="">
                      <video
                        ref={vidRef}
                        muted
                        loop
                        onMouseEnter={handlePlayVideo}
                        onMouseLeave={handlePauseVideo}
                        className="video"
                      >
                        <source src={video.dataURL} type={video.type} />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                    <div className="lbl font s15 c000 wordwrap">
                      {video.name}
                    </div>
                    <div className="actions flex aic">
                      {/* <button className="cleanbtn icon-create icon"/> */}
                      <button className="cleanbtn icon-delete1 icon" />
                    </div>
                  </div>
                ))}
                <div className="feild flex aic">
                  <input
                    className="cleanbtn iput font s14 c000"
                    placeholder="Form title"
                    defaultValue={formData.title}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="feild flex aic">
                  <input
                    className="cleanbtn iput font s14 c000"
                    placeholder="Form subtitle"
                    defaultValue={formData.subTitle}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        subTitle: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="new-question-wrap">
                  {newScreenFormTemplate?.inputs?.map((question, idx) => (
                    <NewQuestion
                      question={question}
                      usedUniqueTypes={usedUniqueTypes}
                      setQuestion={(updatedQuestion) => {
                        const temp = newScreenFormTemplate.inputs;
                        temp[idx] = updatedQuestion;
                        setQuestions(temp);
                      }}
                      deleteQuestion={() => {
                        const temp = newScreenFormTemplate.inputs.filter(
                          (_, i) => i !== idx
                        );
                        setQuestions(temp);
                      }}
                      key={question.id}
                    />
                  ))}
                </div>
                <button
                  className="cleanbtn add-q-btn font s14 b6 c000"
                  onClick={addQuestion}
                >
                  + Add new question
                </button>
              </div>
              <div className="ftr flex aic">
                <button
                  onClick={createForm}
                  className="cleanbtn create-btn font s15 anim"
                >
                  Create
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  {
    /* create Dialog New Vidoe || New Iframe || New Forme */
  }
  const UploadDialog = () => {
    return (
      <div className="upload-container rel">
        <div className="dialog-hdr flex aic stick">
          <button
            className="cleanbtn cross-btn fontr c333 anim"
            onClick={closeVideoDialog}
          >
            &times;
          </button>
          {selectedScreenType !== 'New Iframe' && (
            <div className="steps flex aic">
              <div
                className={`step font b5 flex aic ${step === 1 ? 'on' : ''} ${
                  step === 2 ? 'checked' : ''
                }`}
              >
                {step === 2 ? (
                  <span className="ico icon-check1 s12 cfff" />
                ) : (
                  <span>1</span>
                )}
              </div>
              <div className={`line ${step > 1 ? 'sho' : 'hid'}`} />
              <div
                className={`step font b5 flex aic ${step === 2 ? 'on' : ''}`}
              >
                2
              </div>
            </div>
          )}
          <button
            className={`cleanbtn next-btn icon-arrow-right s20 c333 ${
              step === 1 && newScreenCloudLibrary.length ? 'show' : 'hide'
            }`}
            onClick={() => setStep(2)}
          />
          {tempNewVideoTitle !== '' && newScreenCloudLibrary.length === 0 && (
            <button
              className="cleanbtn skip-btn flex font s14 c000"
              onClick={_crateNewScreen}
            >
              Skip Now
            </button>
          )}
        </div>
        <div className="upload-wrapper">
          {selectedScreenType === 'New Video' ? (
            <NewVideo />
          ) : selectedScreenType === 'New Iframe' ? (
            <NewIframe />
          ) : selectedScreenType === 'New Form' ? (
            <NewForm />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div>
      <button
        disabled={showProgressBar || previewTemplate.enablePreview}
        className={`cleanbtn sumary-row-item ${
          previewTemplate.enablePreview ? 'disable' : ' '
        }`}
        onClick={handleClickOpen}
      >
        <div className="item flex aic anim">
          <img
            className="ico"
            src={require('../../assets/videos/plus-icon.svg').default}
          />
        </div>
        <div className="ftr rel">
          <div className="lbl font s15 b5 c333">Create New</div>
        </div>
      </button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="xs"
      >
        <div className="new-screen-container">
          {!showUploadDialog ? (
            <div className="choose-screen-blk">
              <div className="hdr flex aic">
                <div className="title font s18 b6 c333">
                  <span className="color">Add new</span> in {categoryTitle}
                </div>
                <button
                  className="cleanbtn cross-btn fontr s30 c333 flex aic anim"
                  onClick={handleClose}
                >
                  &times;
                </button>
              </div>
              <div className="wrap flex flex-col">
                <div className="feild flex flex-col">
                  <div className="label font s14 b6 c333">Screen Title</div>
                  <TextField
                    value={tempNewVideoTitle}
                    onChange={(e) => setTempNewVideoTitle(e.target.value)}
                    required={true}
                    style={{ width: '100%' }}
                    variant="outlined"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        _dispatch({
                          type: 'NEW_VIDEO_TITLE',
                          payload: tempNewVideoTitle,
                        });
                        nextButtonRef.current.click();
                      }
                    }}
                    autoFocus
                  />
                </div>
                <div className="types">
                  <div className="label font s14 b6 c333">
                    Choose Screen Type
                  </div>
                  <div className="blocks">
                    {screenTypes.map((item, idx) => (
                      <div
                        key={idx}
                        className={`blk flex aic ${
                          selectedScreenType === item.label ? 'active' : ''
                        }`}
                        onClick={() => {
                          setSelectedScreenType(item.label);
                          setStep(1);
                          cleanNewScreenStates();
                        }}
                      >
                        <div className="ico flex aic">{item.icon}</div>
                        <div className="meta flex flex-col">
                          <div className="lbl font s14 c333">{item.label}</div>
                          <div className="txt font s13">{item.text}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="ftr flex aic">
                <button
                  ref={nextButtonRef}
                  className="cleanbtn next-btn anim font s15 cfff"
                  onClick={() => {
                    setShowUploadDialog(true);
                  }}
                  disabled={!tempNewVideoTitle}
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <UploadDialog />
          )}
        </div>
      </Dialog>
    </div>
  );
}

//Dragger copied directly from https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
class Dragger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  dropRef = React.createRef();

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter > 0) return;
    this.setState({ dragging: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    this.dragCounter = 0;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <>
        <div ref={this.dropRef} className="dragging-area">
          {this.state.dragging && (
            <div className="dragger-indicator">
              <div className="font s24 b5 c999">Drop Video Here</div>
            </div>
          )}
          {this.props.children}
        </div>
      </>
    );
  }
}

function getDefaultTitle(category, screen_keys) {
  var sample_title = category + ' video ' + (screen_keys.length + 1);
  // var sample_title_key = createScreenKey(sample_title);

  // while(screen_keys.includes(sample_title)){
  //   sample_title = sample_title + '_';
  // }

  return sample_title;
}
