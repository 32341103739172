import React, { useEffect, useReducer, useRef, useState } from 'react';
import VideoLoader from './VideoLoader';
import './VideoMagnet.css';

const Video = ({
  source: parentSource,
  isPlaying,
  setIsPlaying,
  onTimeUpdate,
  playbackRate,
  newVideoTime,
  setNewVideoTime,
  isMuted,
  playsInline,
  loop,
  roundedVideoPlayer,
  ...videoProps
}) => {
  // Whether the first or second <video> has (or is loading) the current source
  const [currentSourceFirst, setCurrentSourceFirst] = useState(true);

  // Track the current source (=== parentSource) and the previous source
  const [[source, oldSource], addNewSource] = useReducer(
    ([prevSource], newSource) => [newSource, prevSource],
    [parentSource]
  );
  useEffect(() => {
    // Without this check, this effect causes an unneeded transition when the component mounts
    if (parentSource !== source) {
      addNewSource(parentSource);
      setCurrentSourceFirst((x) => !x);
    }
  }, [parentSource, source]);

  // Callback refs might be more "best practice"-y to use here to prevent the
  // refs from being null as often, but they're still null at the very beginning
  const firstVideoRef = useRef();
  const secondVideoRef = useRef();
  // Since it's changed by a state (not just a static ref), empirically it
  // seems to be fine to use `video` as a useEffect dep, just may be null or undefined
  const video = (currentSourceFirst ? firstVideoRef : secondVideoRef).current;
  const oldVideo = (currentSourceFirst ? secondVideoRef : firstVideoRef)
    .current;

  const [firstVideoSource, secondVideoSource] = currentSourceFirst
    ? [source, oldSource]
    : [oldSource, source];

  // True when a video has loaded for the first time. Used to show a loading spinner
  // instead of just a white screen (mainly needed for inline layout)
  const [initialVideoLoaded, setInitialVideoLoaded] = useReducer(
    () => true,
    false
  );

  const handleTimeUpdate = () => {
    const { currentTime, duration } = video || {};
    onTimeUpdate(currentTime, duration);
  };

  // If source state is updated
  useEffect(() => {
    if (video) video.load();
  }, [video, source]);

  // If parent updates newVideoTime prop to change the video time
  useEffect(() => {
    if (video && video.readyState && newVideoTime >= 0) {
      video.currentTime = newVideoTime;
      setNewVideoTime(-1); // Reset newVideoTime since we updated the video time
    }
  }, [video, newVideoTime, setNewVideoTime]);

  // If parent updates isPlaying prop
  useEffect(() => {
    const effect = async () => {
      if (video) {
        if (isPlaying) {
          try {
            await video.play(); // Not all browsers return Promises
          } catch (error) {
            // Probably couldn't play because the user hasn't interacted with the page,
            // so make sure we show the "play" button by setting isPlaying
            console.error(error);
            setIsPlaying(false);
          }
        } else {
          video.pause();
        }
      }
    };
    effect();
  }, [video, isPlaying, setIsPlaying]);

  // If parent updates playbackRate prop
  useEffect(() => {
    if (video) {
      video.playbackRate = playbackRate;
    }
  }, [video, playbackRate]);

  const commonVideoProps = {
    ...videoProps,
    preload: 'auto',
    type: 'video/mp4',
    autoPlay: isPlaying,
    muted: isMuted,
    loop,
    playsInline, // Used for fullscreen on iOS
    onCanPlay: () => {
      if (!initialVideoLoaded) setInitialVideoLoaded();
      if (oldVideo) oldVideo.pause();
    },
    onEnded: () => setIsPlaying(false),
    onTimeUpdate: handleTimeUpdate,
  };

  return (
    <>
      {!initialVideoLoaded && <VideoLoader />}

      {/* Conditional rendering because one video source may be null before any page changes occur
          It should be possible to e.g. not render the first video when !currentSourceFirst and no videos
          are loading, but the logic for that is tricky and may not be smooth enough to prevent a black flicker */}
      {firstVideoSource && (
        <video
          ref={firstVideoRef}
          src={firstVideoSource}
          style={{
            zIndex: currentSourceFirst ? 1 : 0,
            borderBottomLeftRadius: roundedVideoPlayer ? '10px' : '0px',
            borderBottomRightRadius: roundedVideoPlayer ? '10px' : '0px',
          }}
          {...commonVideoProps}
        />
      )}
      {secondVideoSource && (
        <video
          ref={secondVideoRef}
          src={secondVideoSource}
          style={{
            zIndex: !currentSourceFirst ? 1 : 0,
            borderBottomLeftRadius: roundedVideoPlayer ? '10px' : '0px',
            borderBottomRightRadius: roundedVideoPlayer ? '10px' : '0px',
          }}
          {...commonVideoProps}
        />
      )}
    </>
  );
};

export default Video;
