import { Box, Grid } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import Paper from '@material-ui/core/Paper';
import 'c3/c3.css';
import React, { useState } from 'react';
import C3Chart from 'react-c3js';
import ContentLoader from '../../components/ContentLoader';
import SimpleAccordion from '../../components/SimpleAccordion';
import { useRequest } from '../../utils/request';
import Leads from './Leads';

const AnalyticsV = ({ form_id, form, metrics, getMetrics, days, setDays }) => {
  const [expanded, setExpanded] = React.useState({
    panel1: true,
    panel2: true,
    summarystats: true,
  });

  const handleChange = (panel) => (event, newExpanded) => {
    const newState = {
      ...expanded,
    };
    newState[panel] = !newState[panel];
    setExpanded(newState);

    console.log('newxpanded', newExpanded, panel, event);
  };

  const [template, setTemplates] = useState(null);
  const [fromToDate, setfromToDate] = useState(null);
  const [load, setLoad] = useState({
    interactions: true,
  });

  const poll_option_counts = {};

  for (var i in form.questions) {
    for (const j in form.questions[i].poll_options) {
      const poll_title = form.questions[i].poll_options[j].content;
      const poll_counts = form.questions[i].poll_options[j].total_count;
      poll_option_counts[poll_title] =
        poll_option_counts[poll_title] + poll_counts || poll_counts;
    }
  }

  const hourly_counts = {}; // Track Views / leads throughout the day

  for (var i = 0; i <= 23; i++) {
    hourly_counts[i.toString().padStart(2, '0')] = 0;
  }
  console.log('hourly counts: ', hourly_counts);

  // async function getContacts(real_form_id, real_status = "", limit = "250", offset = 0, minNumberOfDays = null) {

  //     var contacts = []
  //     var offsetTrack = 0

  //     var res = await makeRequestI('/getcontacts' + '?form_id=' + String(real_form_id) + '&limit=' + String(limit) + '&offset=' + String(offset) + '&status=' + String(real_status), "GET", null, null);
  //     contacts = contacts.concat(res.results)
  //     console.log(offsetTrack, " OFFSET contacts updated | contacts length", contacts.length, contacts[contacts.length - 1].created_at, res)

  //     for (var contact in contacts) {
  //         var local_hour = moment.utc(contact.created_at).local().format('HH')
  //         hourly_counts[local_hour] = hourly_counts[local_hour] + 1;
  //         console.log(local_hour, " hourly count test")
  //     }
  //     console.log("new contacts:  hourly count", hourly_counts)

  // if ( hourly_counts[local_hour] ) {
  //     hourly_counts[local_hour] = hourly_counts[local_hour] + 1;
  // } else {
  //     hourly_counts[local_hour] = 1;
  // }
  // while (contacts.length > 0 && minNumberOfDays !== null && res.next !== null && moment.duration(moment(contacts[contacts.length - 1].created_at).diff(moment(), 'days') >= minNumberOfDays)) {
  //     offsetTrack = offsetTrack + 100
  //     res = await makeRequestI('/getcontacts' + '?form_id=' + String(real_form_id) + '&limit=' + String(limit) + '&offset=' + String(offset) + '&status=' + String(real_status), "GET", null, null);
  //     contacts = contacts.concat(res.results)
  //     console.log(offsetTrack, " OFFSET contacts updated | contacts length", contacts.length, contacts[contacts.length - 1].created_at, contacts)
  // }

  // return contacts
  // }

  // useEffect(() => {
  //     const newcontacts = getContacts(form_id, "", "100", 0, 5)
  // var local_hour = moment.utc(utcTime).local().format('HH');
  // console.log(newcontacts, " | contacts updated | ", newcontacts.length)
  // }, [])

  // const classes = useStyles();

  const [loadingI, errorI, queryDataI, makeRequestI] = useRequest({
    loading: false,
  });
  const [loadingA, errorA, queryDataA, makeRequestA] = useRequest({
    loading: false,
  });

  function isEmpty(obj) {
    for (const o in obj) if (o) return false;
    return true;
  }

  return (
    <div className="videomagnet-analytics">
      <Box padding={2}>
        <ContentLoader
          loading={loadingI || isEmpty(poll_option_counts)}
          error={errorI}
        >
          <Box>
            <NativeSelect
              value={days}
              onChange={(e) => {
                setDays(e.target.value);
                getMetrics(e.target.value);
                console.log(e.target, 'new event change', days);
              }}
              inputProps={{
                name: 'report time frame',
                id: 'uncontrolled-native',
              }}
            >
              <option value={365}>Summary Stats</option>
            </NativeSelect>
            {/* // <option value={90}>Last 90 Days</option>
              // <option value={14}>Last 14 Days</option>
              // <option value={30}>Last 30 Days</option>
              // <option value={45}>Last 45 Days</option>
              // <option value={60}>Last 60 Days</option> */}
            <br />
          </Box>
          <div className="states-blk flex flex-col">
            {/* over the past ${days} days */}
            <div className="state-title font s16 b6">{`Summary Stats`}</div>
            <div className="states-items flex aic flex-col lg:flex-row items-start">
              <div className="state-item flex aic w-full">
                <div className="flex aic ico icon-crosshair s20" />
                <div className="flex flex-col">
                  <div className="lbl font s16 b5 c333">{`${
                    metrics.totals.landed
                  } Virtual tour${metrics.totals.landed != 1 && 's'}`}</div>
                  <div className="txt font s13 c999"></div>
                </div>
              </div>

              <div className="state-item flex aic w-full">
                <div className="flex aic ico icon-users s20" />
                <div className="flex flex-col">
                  <div className="lbl font s16 b5 c333">{`${
                    metrics.totals.completed
                  } Virtual Lead${metrics.totals.completed != 1 && 's'}`}</div>
                  <div className="txt font s13 c999"></div>
                </div>
              </div>

              <div className="state-item flex aic w-full">
                <div className="flex aic ico icon-users s20" />
                <div className="flex flex-col">
                  <div className="lbl font s16 b5 c333">
                    Lease Count coming soon
                  </div>
                  <div className="txt font s13 c999">
                    Note leases sourced in total
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SimpleAccordion title="Virtual Tour and Leads">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                  <Box padding={2}>
                    <h3>{form.title} Report</h3>
                    {days == 0 && <div>date picker shows up</div>}
                    <C3Chart
                      data={{
                        x: 'x',
                        columns: [
                          ['x'].concat(metrics.data.map((obj) => obj.id)),
                          ['Virtual Tours'].concat(
                            metrics.data.map((obj) => obj.landed)
                          ),
                          ['Leads'].concat(
                            metrics.data.map((obj) => obj.completed)
                          ),
                        ],
                      }}
                      axis={{
                        x: {
                          type: 'timeseries',
                          show: true,
                          tick: {
                            // this also works for non timeseries data
                            format: '%b %d, %Y',
                          },
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Grid>

              <br />
            </Grid>
          </SimpleAccordion>

          <SimpleAccordion title="Common Amenities and Floor plans">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <br />

                <Paper>
                  <C3Chart
                    style={{ height: '100%' }}
                    data={{
                      names: {
                        // name of each serie
                        data1: 'Interactions',
                      },
                      // colors: {
                      //   data1: blue,
                      //   data2: pink,
                      // },
                      columns: [
                        // each columns data
                        ['data1'].concat(
                          Object.keys(poll_option_counts).map((val, idx) => {
                            return poll_option_counts[val];
                          })
                        ),
                      ],

                      types: {
                        data1: 'bar',
                      },
                    }}
                    axis={{
                      x: {
                        type: 'category',
                        // name of each category
                        categories: Object.keys(poll_option_counts).map(
                          function (value) {
                            return value.substring(0, 14);
                          }
                        ),
                      },
                      rotated: true,
                    }}
                    color={{
                      pattern: ['#467fcf'],
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </SimpleAccordion>
        </ContentLoader>
        <br />
        <ContentLoader loading={loadingA} error={errorA}>
          <Leads
            tableTitle="Recent Activity"
            getContactsUrl="./Activity.json"
            leadsPerPage={20}
            form_id={form_id}
          />
        </ContentLoader>
      </Box>
    </div>
  );
};

export default AnalyticsV;
