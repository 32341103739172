import MaterialTable from '@material-table/core';
import { Accordion, Grid, IconButton, Snackbar } from '@material-ui/core';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RefreshIcon from '@material-ui/icons/Refresh';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import ContentLoader from '../../components/ContentLoader';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import { creategooglesheet } from '../../utils/base';
import { get, post, useRequest } from '../../utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} keyboard-shortcut="add-icon-handler" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function CustomStepper({ activity }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(-1);

  function handleStep(index) {
    if (activeStep === index) {
      setActiveStep(-1);
    } else {
      setActiveStep(index);
    }
  }

  return (
    <Stepper activeStep={activeStep} nonLinear orientation="vertical">
      {activity.map((action, idx) => (
        <Step key={idx}>
          <StepButton
            onClick={() => {
              handleStep(idx);
            }}
            active={true}
          >
            {action.tour_type === 'tour_page'
              ? action.name + ' viewed custom tour home page'
              : action.url
              ? action.url
              : action.category_title + ' - ' + action.screen_title}
            {'  '}
            {action.form_data && (
              <div className="badge font s14 cfff">FORM SUBMITTED</div>
            )}
            {action.type === 'close_tour' && (
              <div className="badge font s14 cfff">CLOSED</div>
            )}
            {action.type === 'open_tour' && (
              <div className="badge font s14 cfff">OPENED</div>
            )}
          </StepButton>
          <StepContent>
            <div className={classes.actionsContainer}>
              {action.type === 'open_tour' && (
                <>
                  Tour opened from{' '}
                  <a href={action.url} target="_blank">
                    {action.url}
                  </a>
                </>
              )}
              {action.screen_title && (
                <>
                  <b>Screen title: </b>
                  {action.route && (
                    <a
                      href={
                        window.location.href.split('?')[0] +
                        '?back=#' +
                        action.route[0] +
                        '.' +
                        action.route[1]
                      }
                      target="_blank"
                    >
                      {action.screen_title}
                    </a>
                  )}
                  <br />
                </>
              )}
              {action.category_title && (
                <>
                  <b>Category title: </b>
                  <a href={window.location.href.split('?')[0]} target="_blank">
                    {action.category_title}
                  </a>{' '}
                  <br />
                </>
              )}
              {action.watch_time && (
                <>
                  <b>Watch time: </b>
                  {action.watch_time.toFixed(2)} seconds
                  <br />
                </>
              )}
              {action.form_data && (
                <>
                  <b>Form data: </b>
                  {Object.entries(action.form_data).map(([key, value]) => (
                    <>
                      <br />
                      {key}: {value}
                    </>
                  ))}{' '}
                  <br />
                </>
              )}
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

const LeadActionPanel = (props) => {
  const { leadData, groupVisitors, leads, setLeads } = props;
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  const [leased, setLeased] = useState(leadData?.leased);

  console.log('lets explore this person data: ', leadData);
  async function handleLeased() {
    const response = await post('/handle/leased', {
      visitor_uuid: leadData.visitor_uuid,
      leased: !leased,
    });
    if (response.error) {
      console.log(response.error);
    } else {
      setSnackbarMessage(
        'Successfully updated ' +
          (leadData?.lead?.name ? leadData?.lead?.name + "'s " : '') +
          'leased status'
      );
      const temp = leads;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].visitor_uuid === leadData.visitor_uuid) {
          temp[i].leased = !leased;
        }
      }
      setLeased(!leased);
      setLeads(temp);
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarMessage ? true : false}
        autoHideDuration={6000}
        onClose={() => setSnackbarMessage()}
        message={snackbarMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => window.location.reload()}
            >
              <RefreshIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      <ContentLoader loading={loading} error={error}>
        <Grid justify="center" container spacing={6}>
          <Grid item xs={4}>
            <br />
            <DebouncedTextField
              label="Name"
              disabled
              value={leadData?.name}
              variant="outlined"
              fullWidth
            />{' '}
            <br />
            <br />
            <DebouncedTextField
              label="Email"
              value={leadData?.email}
              disabled
              variant="outlined"
              fullWidth
            />{' '}
            <br />
            <br />
            <DebouncedTextField
              label="Phone"
              value={leadData?.phone}
              disabled
              variant="outlined"
              fullWidth
            />{' '}
            <br />
            <br />
            {leadData?.lead?.leased && (
              <DebouncedTextField
                label="Leased Date"
                value={leadData?.leased_created}
                disabled
                variant="outlined"
                fullWidth
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={leased}
                  onChange={() => {
                    handleLeased();
                  }}
                />
              }
              label="Leased"
            />
            <br />
          </Grid>

          <Grid item xs={7}>
            {groupVisitors ? (
              <>
                <br />
                <Accordion defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    Visit details for {leadData?.name}
                  </AccordionSummary>
                  {leadData?.details?.tour_path &&
                    leadData?.details?.tour_path.map((visit) => (
                      <AccordionDetails>
                        {visit[0] && visit[1] && (
                          <>
                            <b>Screen: </b>
                            {
                              <a
                                href={
                                  window.location.href
                                    .split('?')[0]
                                    .replace('analytics', 'videos') +
                                  '?back=#' +
                                  visit[0] +
                                  '.' +
                                  visit[1]
                                }
                                target="_blank"
                              >
                                {`${visit[0]}.${visit[1]}`}
                              </a>
                            }
                            <br />
                          </>
                        )}
                      </AccordionDetails>
                    ))}
                </Accordion>
              </>
            ) : (
              <CustomStepper activity={leadData.activity} />
            )}
          </Grid>
        </Grid>
      </ContentLoader>
    </>
  );
};

const SimpleLeads = ({ magnet_uuid, name }) => {
  const leadsPerPage = 10;
  const [typeSelection, setTypeSelection] = useState(1);
  const [groupSelection, setGroupSelection] = useState(1);
  const [loading, error, data, makeRequest] = useRequest({ loading: false });
  const [leads, setLeads] = useState();

  useEffect(() => {
    get_lead_data();
  }, [magnet_uuid, typeSelection, groupSelection]);

  async function get_lead_data() {
    if (magnet_uuid) {
      // const inDataLeads = leads2;

      const inDataLeads = await get(`/leads?magnet_uuid=${magnet_uuid}`, {
        auth: true,
      });
      console.log('leads indataLeads = ', inDataLeads);
      setLeads(inDataLeads);
    }
  }

  if (!leads) {
    return (
      <>
        <ContentLoader />
      </>
    );
  }

  return (
    <div id="visit-visitor-tables">
      <ContentLoader
        loading={!leads}
        error={
          leads.error
            ? 'Unable to get activity data at this time. If this issue persists please contanct us.'
            : ''
        }
      >
        <div id="printMe">
          <MaterialTable
            localization={{
              toolbar: { searchPlaceholder: 'Search' },
            }}
            icons={tableIcons}
            options={{
              headerStyle: {
                backgroundColor: '#01579b',
                color: '#FFF',
              },
              rowStyle: {
                backgroundColor: '#EEE',
              },
              filtering: true,
              pageSize: Math.min(leadsPerPage, leads?.leads?.length) || 0,
              pageSizeOptions: [5, 10, 20, leads?.leads?.length || 0],
            }}
            columns={[
              {
                title: groupSelection ? 'Visits' : 'Steps',
                field: 'steps',
                render: (rowData) => {
                  let count = 0;
                  let additonal = 0;
                  // console.log('leads ', rowData);
                  return (
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={4}>
                          <div
                            className="font flex flex-col aic"
                            style={{
                              justifyContent: 'center',
                              width: '2rem',
                              height: '2rem',
                              fontSize: '.875rem',
                              background: '#6574cd',
                              color: '#adb5bd',
                            }}
                          >
                            {rowData.details?.tour_path?.length}
                          </div>
                        </Grid>
                        <Grid item xs={8}>
                          {rowData?.details?.tour_path?.map((tour_step) => {
                            if (tour_step[0] && tour_step[1] && count < 5) {
                              count += 1;
                              return (
                                <>
                                  <div className="badge font s14 cfff">
                                    {tour_step[0].length + tour_step[1].length >
                                    20 ? (
                                      <>
                                        {`${tour_step[0]}.${tour_step[1]}`.slice(
                                          0,
                                          17
                                        )}
                                        ...
                                      </>
                                    ) : (
                                      `${tour_step[0]}.${tour_step[1]}`
                                    )}
                                  </div>
                                </>
                              );
                            } else if (tour_step[0] && tour_step[1]) {
                              additonal += 1;
                            }
                          })}
                          {additonal ? (
                            <div className="badge font s14 cfff info">
                              +{additonal} more
                            </div>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                    </>
                  );
                },
                customSort: (a, b) => {
                  return (
                    a.details?.tour_path?.length - b.details?.tour_path?.length
                  );
                },
                customFilterAndSearch: (term, rowData) => {
                  if (parseInt(term) === rowData.details?.tour_path?.length) {
                    return true;
                  }
                  for (let i = 0; i < rowData.details?.tour_path?.length; i++) {
                    if (
                      rowData.details?.tour_path[i].screen_title
                        ?.toLowerCase()
                        .includes(term.toLowerCase()) ||
                      rowData.details?.tour_path[i].category_title
                        ?.toLowerCase()
                        .includes(term.toLowerCase())
                    ) {
                      return true;
                    }
                  }
                  return false;
                },
              },
              {
                title: 'Name',
                field: 'name',
                render: (rowData) => {
                  return (
                    <>
                      {rowData?.name ||
                        rowData?.details?.name ||
                        'Visitor ' + rowData.visitor_uuid}
                    </>
                  );
                },
                customSort: (a, b) => {
                  if (a.lead?.name === undefined) return 1;
                  if (b.lead?.name === undefined) return -1;
                  return a?.name > b?.name ? 1 : -1;
                },
                customFilterAndSearch: (term, rowData) =>
                  rowData.lead?.name
                    ?.toLowerCase()
                    .includes(term.toLowerCase()) ||
                  (!rowData.lead?.name &&
                    ('Visitor ' + rowData.visitor_uuid)
                      .toLowerCase()
                      .includes(term.toLowerCase())),
              },
              {
                title: 'Email',
                field: 'email',
                render: (rowData) => {
                  return <>{rowData?.email}</>;
                },
                customSort: (a, b) => {
                  if (a?.email === undefined) return 1;
                  if (b?.email === undefined) return -1;
                  return a?.email < b?.email ? 1 : -1;
                },
                customFilterAndSearch: (term, rowData) =>
                  rowData?.email?.toLowerCase().includes(term.toLowerCase()),
              },
              {
                title: 'Visit',
                field: 'visited_at',
                render: (rowData) => {
                  const updateTimestamp = Date.parse(rowData.time);
                  return (
                    <>
                      <div className="badge font s14 cfff info">
                        <ScheduleIcon style={{ height: '15px' }} />{' '}
                        {moment(updateTimestamp).format(
                          'MMMM Do YYYY, h:mm:ss a'
                        )}
                      </div>
                      <div className="badge font s14 cfff info">
                        <LocationOnIcon style={{ height: '15px' }} />{' '}
                        {rowData?.visitor?.location?.city
                          ? rowData?.visitor?.location?.city +
                            ', ' +
                            rowData?.visitor?.location?.state
                          : ''}
                      </div>
                    </>
                  );
                },
                customSort: (a, b) => {
                  return moment(Date.parse(a.time)).diff(
                    moment(Date.parse(b.time))
                  );
                },
                customFilterAndSearch: (term, rowData) =>
                  rowData.time
                    .toLowerCase()
                    .includes(term.replace('/', '-').toLowerCase()) ||
                  moment(Date.parse(rowData.time))
                    .format('MMMM Do YYYY, h:mm:ss a')
                    .toLowerCase()
                    .includes(term.toLowerCase()) ||
                  (
                    rowData?.visitor?.location?.city +
                    ', ' +
                    rowData?.visitor?.location?.state
                  )
                    .toLowerCase()
                    .includes(term.toLowerCase()),
                defaultSort: 'desc',
              },
              {
                title: 'Actions',
                field: 'actions',
                render: (rowData) => {
                  return (
                    <>
                      <div className="badge font s14 cfff danger">LEAD</div>{' '}
                      {rowData?.leased && (
                        <div className="badge font s14 cfff danger">LEASED</div>
                      )}
                    </>
                  );
                },
                customSort: (a, b) => {
                  if (a?.leased) return -1;
                  return 1;
                },
                customFilterAndSearch: (term, rowData) =>
                  (rowData && 'lead'.includes(term.toLowerCase())) ||
                  (rowData.lead?.leased &&
                    'leased'.includes(term.toLowerCase())),
              },
            ]}
            data={leads.leads}
            title={
              <div className="simple-leads-title flex aic">
                <Typography>Show {name}'s &nbsp; Leads</Typography>
                <button
                  onClick={() => creategooglesheet(magnet_uuid)}
                  className="cleanbtn google-sheet-btn flex aic"
                >
                  <div className=" ico icon-users s22 c000" />
                  <div className="font s14 c333">
                    <b>
                      {leads?.leads && leads?.leads?.length > 0
                        ? `${leads?.leads?.length}`
                        : 'N/A'}
                    </b>
                  </div>
                </button>
              </div>
            }
            detailPanel={(rowData) => {
              return (
                <LeadActionPanel
                  leadData={rowData.rowData}
                  leads={leads}
                  setLeads={setLeads}
                  magnet_uuid={magnet_uuid}
                  groupVisitors={groupSelection}
                />
              );
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </div>
      </ContentLoader>
    </div>
  );
};

export default SimpleLeads;
