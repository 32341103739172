import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MagnetContext from '../../components/VideoMagnet/MagnetContext';
import VideoMagnetTheme from '../../components/VideoMagnet/theme';
import { replaceUserInfo } from '../../components/VideoMagnet/util';
import VideoMagnet from '../../components/VideoMagnet/VideoMagnet';
import './PreviewVideoMagnet.css';

const EMPTY_FUNC = () => {}; // No-op placeholder for VideoMagnet callbacks

const PreviewVideoMagnet = ({
  uuid,
  category: _categoryKey,
  screen: _screenKey,
  inline = true,
  singleScreen = true, // Whether or not the VideoMagnet is locked to the given screen
  src, // Override current video source
  // fileType, // Unused props, just in case :D
  // isEncoding,
  // setIsEncoding,
  buttons,
  centerButtons,
  bottomButtons,
  caption,
  location,
  formTemplate,
  iframeOptions,
  shareInfo,
  onClose = EMPTY_FUNC,
}) => {
  const { template } = useSelector((state) => state.getMagnet);

  const [categoryKey, setCategoryKey] = useState(_categoryKey);
  const [screenKey, setScreenKey] = useState(_screenKey);

  useEffect(() => {
    setCategoryKey(_categoryKey);
  }, [_categoryKey]);

  useEffect(() => {
    setScreenKey(_screenKey);
  }, [_screenKey]);

  const getScreenByRoute = (category, screen) =>
    template?.categories?.[category]?.screens?.[screen];

  const getNextVideo = ([category, screen]) =>
    getScreenByRoute(category, screen)?.video;

  const getNewPage = ([newCategoryKey, newScreenKey]) => {
    setCategoryKey(newCategoryKey);
    setScreenKey(newScreenKey);
  };

  // `template` contains the most recent version of the template from the backend
  // However, the user may change things locally without pushing them to the backend (in
  // `buttons`, `caption`, `location`, etc), so we need to merge those into the screen from the template
  const templateScreen =
    template?.categories?.[categoryKey]?.screens?.[screenKey];
  if (!templateScreen && screenKey !== 'new') {
    return null;
  }

  // We only want to use updatedKeys on the initial screen
  const updatedKeys =
    categoryKey === _categoryKey && screenKey === _screenKey
      ? {
          caption: caption || templateScreen?.caption,
          location_card: location || templateScreen?.location_card,
          links: buttons || templateScreen?.links,
          centerLinks: centerButtons || templateScreen?.centerLinks,
          bottomLinks: bottomButtons || templateScreen?.bottomLinks,
          form: formTemplate || templateScreen?.form,
          iframe: iframeOptions || templateScreen?.iframe,
          share_info: shareInfo || templateScreen?.shareInfo,
          video: src || templateScreen?.video,
        }
      : {};
  const screen = { ...templateScreen, ...updatedKeys };

  /////
  //const caption = screen ? screen.caption : ''
  const location_card = screen ? screen.location_card : '';
  const _links = screen ? screen.links : [];

  let textData = { caption, location_card };
  const textDataReplaced = textData
    ? Object.fromEntries(
        Object.entries(textData).map(([key, value]) => [
          key,
          replaceUserInfo(value),
        ])
      )
    : {};
  const { caption: title, location_card: locationCard } = textDataReplaced;

  const links = _links
    ? _links?.map((link) => ({
        ...link,
        name: replaceUserInfo(link.name),
      }))
    : [];

  /////

  return (
    <MagnetContext.Provider
      value={{
        uuid,
        currentCategory: categoryKey,
        currentScreen: screenKey,
        startCategory: categoryKey,
        startScreen: screenKey,
      }}
    >
      <div style={VideoMagnetTheme}>
        <VideoMagnet
          show={true}
          inline={inline}
          inlineCTA={undefined}
          showLinksInline={true}
          screen={screen}
          magnetSettings={template?.magnetSettings || {}}
          getNextVideo={singleScreen ? EMPTY_FUNC : getNextVideo}
          getNewPage={singleScreen ? EMPTY_FUNC : getNewPage}
          startTour={EMPTY_FUNC}
          restartTour={EMPTY_FUNC}
          onClose={onClose}
          primaryColor={'black'}
          links={links}
          title={title}
          locationCard={locationCard}
        />
      </div>
    </MagnetContext.Provider>
  );
};
export default PreviewVideoMagnet;
