import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppSidebar, Header } from '../../components';
import { listCommunities } from '../../store/actions/communitiesActions';
import { get } from '../../utils/request';

const AnalyticsList = () => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedpage, setselectedpage] = useState(0);
  const [itemPerpage, setItemPerpage] = useState(25);
  const navigate = useHistory();

  const { data, communityLoading, callError } = useSelector(
    (state) => state.listCommunities
  );
  const { communities, error, status } = data;
  console.log('communities', communities);
  const { magnetId } = useSelector((state) => state.magnetStates);
  const [mod_communities, setmod_comminities] = useState('');
  const [org_communities, setorg_communities] = useState('');
  const [componentloaded, setcomponentloaded] = useState(false);
  console.log('mod_communities', mod_communities);
  useEffect(() => {
    if (communities) {
      let the_communities = [];
      communities.map((item) => {
        the_communities.push({ loading: true, item: item, queryinfo: {} });
      });
      setmod_comminities([...the_communities]);
      setorg_communities([...the_communities]);
      startfetching([...the_communities]);
      setcomponentloaded(true);
    }
  }, [communities]);
  useEffect(() => {
    if (org_communities) {
      handlepagination({ selected: 0 });
    }
  }, [org_communities]);
  const _dispatch = useDispatch();
  useEffect(() => {
    _dispatch(listCommunities());
  }, []);
  const getanalyicsbigquery = async (query) => {
    try {
      console.log('res1', query);
      const { data } = await axios.get(
        'https://api.leasemagnets.com/run_sqlquery_inbigquery?querystring=' +
          query
      );
      console.log('goot data', data.res);
      return data;
    } catch (error) {
      console.log('errror', error);
    }
  };
  const startfetching = (the_communities) => {
    let fetchcounter = 0;
    const fetchanalytics = async (communityid) => {
      const res = await get(`/magnets?queryByCommunity=${communityid}`, {
        auth: false,
      });
      if (res?.magnets) {
        console.log('resssss1', res?.magnets[0]?.uuid);
        //setMagnetUuid(res.magnets[0].uuid);
        let res1 = await getanalyicsbigquery(
          "SELECT SUM(CASE WHEN `default.events`.`event_type` LIKE '%button_click%' THEN 1 END) AS click_count, COUNT(DISTINCT user_anonymous_id) AS visitor_count," +
            "SUM(CASE WHEN `default.events`.`event_type` LIKE '%form_submission%' THEN 1 END) AS lead_count,max(_timestamp) AS recent_click FROM `default.events`" +
            'WHERE `default.events`.`magnet_uuid` = ' +
            `'${res?.magnets[0]?.uuid}'`
        );
        console.log('res1', res1.res[0]);
        the_communities[fetchcounter]['queryinfo'] = {
          totalleads: res1.res[0][2],
          totaltours: res1.res[0][0],
          totalvisitors: res1.res[0][1],
          last_event: res1.res[0][3],
        };
      }

      //the_communities[fetchcounter]["queryinfo"] = {totalleads :32 , totaltours : 33,totalvisitors : 2342, last_event : new Date()}
      the_communities[fetchcounter]['loading'] = false;
      setmod_comminities([...the_communities]);
      fetchcounter++;
      if (fetchcounter < the_communities.length) {
        fetchanalytics(the_communities[fetchcounter]?.item?.id);
      }
    };
    fetchanalytics(the_communities[fetchcounter]?.item?.id);
  };

  const goRoute = () => {
    navigate.push('/community/analyticspage');
  };
  const [search, setsearch] = useState('');
  let [paginationdata, setpagination] = useState({});
  const handlepagination = (event) => {
    let end = (event.selected + 1) * itemPerpage;
    let start = end - itemPerpage;
    setpagination({ start, end });
  };
  useEffect(() => {
    handlepagination({ selected: 0 });
  }, [itemPerpage]);
  // useEffect(() => {
  //   if(mod_communities) {
  //     startfetching()
  //   }
  // },[mod_communities])

  // const handlepagination = (event) => {
  //   let end = (event.selected + 1) * itemPerpage
  //   let start = end - itemPerpage
  //   console.log('event',event,start,end)
  //   const newFilter = org_communities.filter((item,index) => {
  //     console.log('start < index < end',{start,index,end},start < index < end)
  //    if(index > start && index < end){
  //      return true
  //    }
  //   });
  //    console.log('event',newFilter)
  //    setmod_comminities(newFilter);
  //    //setmod_filter(newFilter);
  //    //setfetchcounter(0)
  //    pageswitched = Math.random()
  //    startfetching(newFilter)
  // }
  // useEffect(() => {
  //   if(itemPerpage && componentloaded){
  //     handlepagination({selected : 0})
  //   }
  // },[itemPerpage])

  const handleFilter = (e) => {
    console.log('search', e);
    const newFilter = mod_communities.filter((item) =>
      item?.item?.name?.toLowerCase().includes(e.toLowerCase())
    );
    if (e === '') {
      setmod_comminities(org_communities);
      //setItemPerpage(org_communities.length);
    } else if (newFilter.length === 0) {
      setmod_comminities([]);
    } else {
      setmod_comminities(newFilter);
      //setmod_filter(newFilter);
      //   console.log(newFilter, "filterDemo");
    }
    //setfetchcounter(0)
    startfetching(newFilter);
  };

  const TableRow = ({ item }) => {
    return (
      <tr onClick={goRoute} className=" hover:bg-blue-50 cursor-pointer w-full">
        <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
          <p>{item?.item?.name}</p>
        </td>
        <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
          <div className=" flex gap-1">
            <p className=" text-lg">
              {item.loading
                ? 'loading...'
                : item?.queryinfo?.totaltours
                ? item?.queryinfo?.totaltours
                : 0}
            </p>
            {/* <p className=" text-xs">+31%</p> */}
          </div>
        </td>
        <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
          <div className=" flex gap-1">
            <p className=" text-lg">
              {item.loading
                ? 'loading...'
                : item?.queryinfo?.totalleads
                ? item?.queryinfo?.totalleads
                : 0}
            </p>
            {/* <p className=" text-xs flex ">
              <IoMdArrowDropup className="text-pr w-4 h-4" /> +31%
            </p> */}
          </div>
        </td>

        {/* <td className="px-4 py-3 text-gray-600 font-medium whitespace-nowrap">
              <p>Special Offer: $250 Off any floor plan</p>
            </td> */}

        <td className="px-4 py-3 text-gray-600 font-medium  whitespace-nowrap">
          <div className=" flex gap-1">
            <p className=" text-lg">
              {item.loading
                ? 'loading...'
                : item?.queryinfo?.totalvisitors
                ? item?.queryinfo?.totalvisitors
                : 0}
            </p>
            {/* <p className=" text-xs">+31%</p> */}
          </div>
        </td>
        <td className="px-4 py-3 text-gray-600 font-medium  whitespace-nowrap flex items-center gap-4">
          <p className=" text-gray-500">
            {item.loading ? 'loading...' : item?.queryinfo?.last_event}
          </p>
        </td>
      </tr>
    );
  };

  return (
    <div className=" w-full">
      <AppSidebar />
      <div className="manage-community sidebar-space">
        <Header>
          {/* <div className="community-hdr flex aic">
            <div className="left flex aic">
              {communities?.length ? (
                <div className="title font s18 b7 black">{`${
                  communities?.length !== 1 ? 'Communities' : 'Community'
                } (${communities?.length})`}</div>
              ) : (
                <div className="title holder" />
              )}
              <Link
                to="/signup/apartments"
                className="cleanbtn add-category font s14 b5 cfff flex aic"
              >
                <span className="ico icon-plus s16 cfff" />
                <span>Add Community</span>
              </Link>
            </div>
   
          </div> */}
        </Header>

        <div className=" w-full mt-3 px-4">
          <>
            <h1 className=" text-2xl pb-4">Analytics</h1>
            <div className=" w-full flex items-center gap-3 justify-between pt-3">
              <p>{/* Last 30 days */}</p>
              <div className=" flex items-center relative">
                <input
                  onChange={(e) => setsearch(e.target.value)}
                  style={{ padding: '6px' }}
                  type="text"
                  placeholder="Search by community"
                  className=" w-full border text-xs bg-transparent rounded-sm font-semibold"
                />

                <AiOutlineSearch className=" text-gray-500 z-10 absolute top-2 right-1 cursor-pointer" />
              </div>
            </div>

            <div className=" w-full    mt-4 overflow-x-scroll lg:overflow-hidden border rounded-md">
              <table className="min-w-full ">
                <thead className="bg-gray-50 border-b">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-2 text-left    tracking-wider"
                    >
                      Community Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 text-left  tracking-wider"
                    >
                      Tours
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 text-left  tracking-wider"
                    >
                      Leads & Appointments
                    </th>
                    {/* <th
                      scope="col"
                      className="px-6 py-2 text-left  tracking-wider"
                    >
                      Additional Details
                    </th> */}
                    <th
                      scope="col"
                      className="px-6 py-2 text-left  tracking-wider"
                    >
                      Visitors
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-2 text-left  tracking-wider"
                    >
                      Last tracked event
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white  ">
                  {mod_communities &&
                    mod_communities.map((item, index) => {
                      if (search) {
                        if (
                          item?.item?.name
                            ?.toLowerCase()
                            .includes(search.toLowerCase())
                        ) {
                          return <TableRow item={item} />;
                        }
                      } else if (
                        index > paginationdata.start &&
                        index < paginationdata.end
                      ) {
                        return <TableRow item={item} />;
                      }
                    })}
                </tbody>
              </table>
            </div>
            <div className=" flex items-center justify-between paginate_react mt-3">
              <div className=" flex items-center gap-2">
                <select
                  value={itemPerpage}
                  onChange={(e) => {
                    setItemPerpage(e.target.value);
                  }}
                  className=" border p-1 text-sm bg-transparent rounded-md"
                  name=""
                  id=""
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={org_communities.length}>All</option>
                </select>
                <p className=" text-gray-700">communities per page</p>
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlepagination}
                pageRangeDisplayed={4}
                pageCount={Math.ceil(org_communities.length / itemPerpage)}
                previousLabel="<"
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsList;
