import { jitsuClient } from '@jitsu/sdk-js';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import {
  getMagnetId,
  getUserId,
  getUserInfo,
  getVisitId,
  setUserId,
  setUserInfo,
  setVisitId,
} from './util';
// Used to keep track of metadata required for Events
// Having this global thing here is pretty bad, but the alternative
// is passing a lot of parameters around just for analytics or a Context
export const analyticsMetadata = {
  route: null,
  videoTime: 0,
};

// This should be kept in sync with EventType in models.py on the backend
const EVENT_TYPE = Object.freeze({
  OPEN_TOUR: 'open_tour',
  BUTTON_CLICK: 'button_click',
  FORM_SUBMISSION: 'form_submission',
  CLOSE_TOUR: 'close_tour',
});

// Map EVENT_TYPEs to Google Analytics events
const GA_EVENT = Object.freeze({
  open_tour: 'Open Tour',
  button_click: 'Button Click',
  form_submission: 'Form Submission',
  close_tour: 'Close Tour',
});

const gaEventLabel = (type, details) => {
  if (type === EVENT_TYPE.BUTTON_CLICK) {
    return details.to;
  }
  if (type === EVENT_TYPE.FORM_SUBMISSION) {
    return details.form_route;
  }
  if (type === EVENT_TYPE.CLOSE_TOUR) {
    return details.last_route;
  }
  return undefined;
};

// Map EVENT_TYPEs to Facebook Pixel events
const PIXEL_EVENT = Object.freeze({
  open_tour: 'OpenTour',
  button_click: 'ButtonClick',
  form_submission: 'FormSubmission',
  close_tour: 'CloseTour',
});

export const trackExternal = async (obj) => {
  const { type, intent, details } = obj;
  ReactPixel.trackCustom(intent, details);
};

const track = async (type, details) => {
  console.log('event ', type, track, details);
  // Add current lead UUID to `details`
  const { leadUUID } = getUserInfo();
  // eslint-disable-next-line no-param-reassign
  if (leadUUID) details.lead_uuid = leadUUID;
  const jitsu = jitsuClient({
    key: 'js.oj1e1srp87oijohgjo9fyr.9b4uph2lmcwy2a9khx9o6o',
    tracking_host: 'https://t.jitsu.com',
  });
  // const {track} =  useJitsu()
  jitsu.track(type, {
    visit_uuid: await getVisitId(),
    visitor_uuid: await getUserId(),
    magnet_uuid: details.form_data.uuid || getMagnetId(),
    event_type: type,
    to: details?.to?.join('.'),
    from: details?.from?.join('.'),
    details,
  });
  // It's OK to fire GA or Pixel events even if it isn't initialized
  ReactGA.event({
    category: 'Tour Event',
    action: GA_EVENT[type],
    label: gaEventLabel(type, details),
    ...('video_time' in details && { value: details.video_time }),
  });
  ReactPixel.trackCustom(PIXEL_EVENT[type], details);

  const res = await axios.post('https://api.leasemagnets.com/event', {
    visit_uuid: await getVisitId(),
    visitor_uuid: await getUserId(),
    magnet_uuid: details.form_data.uuid || getMagnetId(),
    url: window.location.href,
    event_type: type,
    details,
  });
  console.log('ressss', res);

  if (res.visit_uuid) {
    setVisitId(res.visit_uuid);
  }

  if (res.visitor_uuid) {
    setUserId(res.visitor_uuid);
  }
  return res.data;
};

export const startVisit = async (magnetTemplate) => {
  if ('gaPropertyId' in magnetTemplate) {
    // Note that react-ga only supports "UA-" (Universal Analytics) IDs,
    // not "G-" (Google Analytics 4) IDs. Google Analytics does have an
    // option to create both a UA and a GA4 ID for the same project though
    ReactGA.initialize(magnetTemplate.gaPropertyId, {
      gaOptions: {
        siteSpeedSampleRate: 100,
        // userId?
      },
    });
    // We could use custom URLs here to track movement through the screens
    // instead of using ButtonClick events
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  if ('fbPixelId' in magnetTemplate) {
    ReactPixel.init(magnetTemplate.fbPixelId, {
      autoConfig: true,
      debug: false,
    });
    ReactPixel.pageView();
  }
  // getVisitId() will fetch a new visit UUID if one isn't found in cookies
  await getVisitId();
};

export const startTour = () => track(EVENT_TYPE.OPEN_TOUR, {});

export const closeTour = () => {
  track(EVENT_TYPE.CLOSE_TOUR, {
    last_route: analyticsMetadata.route,
    video_time: analyticsMetadata.videoTime,
  });
};
// Send a CLOSE_TOUR event when the page is reloaded or closed
window.addEventListener('beforeunload', closeTour, false);

export const buttonClick = (from, to) => {
  const res = track(EVENT_TYPE.BUTTON_CLICK, {
    from,
    to,
    video_time: analyticsMetadata.videoTime,
  });
  return res;
};

export const formSubmission = async (formData) => {
  const res = await track(EVENT_TYPE.FORM_SUBMISSION, {
    form_data: formData,
    form_route: analyticsMetadata.route,
    video_time: analyticsMetadata.videoTime,
  });
  const newLeadUUID = res.lead_uuid;
  if (newLeadUUID) {
    setUserInfo({
      leadUUID: newLeadUUID,
      // Some of these may be undefined, that's OK
      name: formData.lead_name,
      email: formData.lead_email,
      phone: formData.lead_phone,
    });
  }
  return res;
};
