import React from 'react';
import { useSelector } from 'react-redux';
import { AppSidebar, Header } from '../../components';
import ComunityBilling from './ComunityBilling';
const Billing = () => {
  const { data, communityLoading, callError } = useSelector(
    (state) => state.listCommunities
  );
  const { communities, error, status } = data;

  return (
    <>
      <AppSidebar />
      <div className="manage-community sidebar-space">
        <Header>
          {/* <div className="community-hdr flex aic">
            <div className="left flex aic">
              {communities?.length ? (
                <div className="title font s18 b7 black">{`${
                  communities?.length !== 1 ? 'Communities' : 'Community'
                } (${communities?.length})`}</div>
              ) : (
                <div className="title holder" />
              )}
              <Link
                to="/signup/apartments"
                className="cleanbtn add-category font s14 b5 cfff flex aic"
              >
                <span className="ico icon-plus s16 cfff" />
                <span>Add Community</span>
              </Link>
            </div>
   
          </div> */}
        </Header>

        <div className=" w-full mt-3 px-4">
          <h1 className=" text-xl font-semibold text-gray-700">Billings</h1>
          <div className=" mt-5 w-full grid-cols-1 grid lg:grid-cols-4 gap-10">
            <div className=" lg:col-span-3">
              <div>
                <ComunityBilling />
              </div>
              <div className=" mt-5">
                <ComunityBilling />
              </div>
            </div>
            <div>
              <div className="shadow-md bg-white border">
                <div className="   p-4">
                  <h3 className=" pb-3 border-b font-medium text-black">
                    Total upcoming Billing Amount{' '}
                  </h3>
                  <div className=" mt-3 w-full flex items-center justify-between text-xl pb-3 border-b">
                    <p className=" text-gray-500 font-light text-lg">
                      Community 1
                    </p>
                    <p className=" font-medium text-black">$50.00</p>
                  </div>
                  <div className=" mt-3 w-full flex items-center justify-between text-xl pb-3 border-b">
                    <p className=" text-gray-500 font-light text-lg">
                      Community 2
                    </p>
                    <p className=" font-medium text-black">$50.00</p>
                  </div>
                  <div className=" mt-3 w-full flex items-center justify-between text-xl pb-3 border-b">
                    <p className=" text-gray-500 font-light text-lg">
                      Community 3
                    </p>
                    <p className=" font-medium text-black">$50.00</p>
                  </div>
                  <div className=" mt-3 w-full flex items-center justify-between text-xl pb-2">
                    <p className=" text-gray-500 font-light text-lg">Total</p>
                    <p className=" font-medium text-black text-3xl">$150.00</p>
                  </div>
                </div>
                <div className=" w-full bg-pr text-white flex items-center text-xl cursor-pointer hover:bg-blue-500 justify-center py-3">
                  Pay now
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Billing;
