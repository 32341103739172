import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { formSubmission } from './api';
import './common.css';
import { ContactCard } from './ContactCard';
import './Form.css';
import ShareButtons from './ShareButtons';
import { getUserInfo, replaceUserInfo } from './util';

const Form = ({ uuid, formTemplate, shareInfo, onFormClose }) => {
  const { register, handleSubmit, errors } = useForm();
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(null);
  const [formData, setFormData] = useState(null); // Save form data after submission

  // We could also replace text for form element labels/placeholders
  let textData = formTemplate.text_data;
  textData = textData
    ? Object.fromEntries(
        Object.entries(textData).map(([key, value]) => [
          key,
          replaceUserInfo(value),
        ])
      )
    : {};
  const {
    title,
    body_text: bodyText,
    submit_success: submitSuccessText,
    submit_fail: submitFailText,
    submit_body: submitBodyText,
  } = textData;

  const userInfo = getUserInfo(); // For pre-filling form data

  const reactHookFormclassName = {
    text: 'text-input',
    email: 'text-input',
    phone: 'text-input',
    lead_name: 'text-input',
    lead_email: 'text-input',
    lead_phone: 'text-input',
    date: 'text-input',
    radio: '',
    checkbox: '',
  };

  const reactHookFormInputType = {
    text: 'text',
    email: 'email',
    phone: 'tel',
    lead_name: 'text',
    lead_email: 'email',
    lead_phone: 'tel',
    date: 'date',
    radio: 'radio',
    checkbox: 'checkbox',
  };

  const getReactHookFormRef = (input) => {
    // Apparently you can also make 'required' the string error message
    const registerOptions = { required: input.required === true };
    switch (input.type) {
      case 'email':
      case 'lead_email':
        registerOptions.pattern = /^\S+@\S+$/i;
        break;
      case 'phone':
      case 'lead_phone':
        // https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
        registerOptions.pattern =
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        break;
      default:
        break;
    }
    return register(registerOptions);
  };

  const getErrorMessage = (input) => {
    const defaultErrMsg = 'Error';
    switch (errors[input.label].type) {
      case 'required':
        return `Error: '${input.label}' is required`;
      case 'pattern':
        switch (input.type) {
          case 'email':
          case 'lead_email':
            return 'Error: invalid email address';
          case 'phone':
          case 'lead_phone':
            return 'Error: invalid phone number';
          case 'date':
            return 'Error: invalid date';
          default:
            return defaultErrMsg;
        }
      case 'validate':
        switch (input.type) {
          case 'date':
            return 'Error: date should be today or later';
          default:
            return defaultErrMsg;
        }
      default:
        return defaultErrMsg;
    }
    // "maxLength" is another error type that might be needed
  };

  const onSubmit = async (submitData) => {
    if (submitInProgress) return;
    setSubmitInProgress(true);

    const data = { tour_time: new Date(), source: 'video', uuid };
    // Map from labels to IDs (e.g. "Phone Number" to "phone")
    formTemplate.inputs.forEach((input) => {
      data[input.id] =
        submitData[input.label in submitData ? input.label : input.id];
    });

    setIsSubmitted(true);
    setSubmitSuccessful(true);
    setFormData(data);
    data['actions'] = formTemplate?.actions;
    const res = await formSubmission(data);
    setSubmitSuccessful(!res.error);

    setSubmitInProgress(false);
  };

  // Can't just check for `formTemplate.opacity` to set opacity to default 1.0, since 0 is falsy
  // Might check that a video is available (so that we don't show a transparent form with no background)
  const opacity =
    formTemplate.opacity === undefined ? 1.0 : formTemplate.opacity;
  const textColor = formTemplate.text_color || 'var(--text-color)';

  return (
    <div
      className="form-container"
      style={{
        backgroundColor: opacity ? `rgba(255, 255, 255, ${opacity})` : '',
        color: textColor,
      }}
      onClick={(e) => e.stopPropagation()} // Don't play/pause background video
    >
      <div className="form-container-inner with-scrollbar">
        {!isSubmitted ? (
          <>
            <div className="text-block">
              <h1>{title}</h1>
              <p>{bodyText}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {formTemplate.inputs.map((input) => {
                const inputRef = getReactHookFormRef(input);
                return (
                  <div key={input.id}>
                    <label htmlFor={input.id} className="text-block bold-text">
                      {input.label}
                    </label>
                    {input.options?.length > 0 ? (
                      /* List of question options */
                      input.options.map((option) => (
                        <label
                          key={`${input.id}-${option}`}
                          htmlFor={`${input.id}-${option}`}
                          className="text-block"
                        >
                          <input
                            id={`${input.id}-${option}`}
                            name={input.label}
                            value={option}
                            className={reactHookFormclassName[input.type]}
                            type={reactHookFormInputType[input.type]}
                            // Use same ref for all options
                            ref={inputRef}
                          />
                          {option}
                        </label>
                      ))
                    ) : (
                      /* A question with a single input box */
                      <input
                        id={input.id}
                        className={reactHookFormclassName[input.type]}
                        type={reactHookFormInputType[input.type]}
                        name={input.label}
                        // Prefill form elements if we already have the info
                        defaultValue={
                          userInfo[input.id] ||
                          (input.id.startsWith?.('lead_')
                            ? userInfo[input.id.substring(5)]
                            : '')
                        }
                        ref={inputRef}
                      />
                    )}
                    {errors[input.label] && (
                      <p className="text-block error-text">
                        {getErrorMessage(input)}
                      </p>
                    )}
                  </div>
                );
              })}
              <br />
              <center>
                <input
                  type="submit"
                  className="form-submit-button main-action-button hover-outline"
                  value={submitInProgress ? 'Submitting...' : 'Submit'}
                  disabled={submitInProgress}
                />
              </center>
            </form>
          </>
        ) : formTemplate.contact?.enabled ? (
          <ContactCard contactInfo={formTemplate.contact} />
        ) : (
          <>
            <div className="text-block">
              <h2>
                Thanks{formData?.name && ` ${formData.name?.split(' ')[0]}`}!
              </h2>
              <p>{submitSuccessful ? submitSuccessText : submitFailText}</p>
              <p>{submitBodyText}</p>
            </div>
            <br />
            <center>
              <img src="https://i.imgur.com/BzyrcVZ.gif" />
              <br />
              <br />
              <br />
              <ShareButtons shareInfo={shareInfo} />
              <br />
              <input
                className="form-submit-button main-action-button hover-outline"
                type="button"
                value="Head Back"
                onClick={onFormClose}
              />
            </center>
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
