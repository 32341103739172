// Material UI imports
import {
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React from 'react';
// Tabler imports
import DebouncedTextField from '../../../components/Reusable/DebouncedTextField';
import AnswerChoices from './AnswerChoices';
import './Settings.css';

// Question type options
const TYPE_OPTIONS = {
  radio: {
    label: 'Radio (single select)',
    placeholder: 'Question: select one answer',
  },
  checkbox: {
    label: 'Checkbox (multiple select)',
    placeholder: 'Question: select multiple answers',
  },
  date: {
    label: 'Date',
    placeholder: 'Pick a date (e.g. move-in date)',
  },
  email: {
    label: 'Email',
    placeholder: 'Your email',
  },
  phone: {
    label: 'Phone number',
    placeholder: 'Your phone',
  },
  text: {
    label: 'Text box',
    placeholder: 'Your question',
  },
  // Special types to indicate that lead info should be captured
  lead_name: {
    label: 'Lead name',
    placeholder: 'Capture a lead name',
  },
  lead_email: {
    label: 'Lead email',
    placeholder: 'Capture a lead email',
  },
  lead_phone: {
    label: 'Lead phone number',
    placeholder: 'Capture a lead phone number',
  },
};

// These types have the "required" switch locked to true or false
// If question type is in here, "required" switch is disabled
const REQUIRED_LOCKED_TYPES = {
  radio: true,
  checkbox: false,
  lead_name: true,
  lead_email: true,
  lead_phone: true,
};

// Can only have one instance of each of these types per form
export const UNIQUE_TYPES = ['lead_name', 'lead_email', 'lead_phone'];

export default function NewQuestion({
  question,
  setQuestion,
  deleteQuestion,
  question_idx,
  usedUniqueTypes,
}) {
  const { id, label, type, required, options: answers } = question;

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={1}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">
              <br />
            </div>
            <IconButton color="secondary" onClick={deleteQuestion}>
              <HighlightOffIcon fontSize="small" />
            </IconButton>
          </div>
        </Grid>

        <Grid item xs={10} sm={5}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Question Label</div>
            <DebouncedTextField
              id="question-label"
              placeholder={TYPE_OPTIONS[type]?.placeholder}
              variant="outlined"
              size="small"
              value={label}
              onChange={(newValue) =>
                setQuestion({ ...question, label: newValue })
              }
              style={{ width: 'calc(100%)' }}
            />
          </div>
        </Grid>

        <Grid item xs={8} sm={4}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Question Type</div>
            <TextField
              select
              id="question-type"
              variant="outlined"
              defaultValue={type}
              size="small"
              onChange={(event) =>
                setQuestion({
                  ...question,
                  type: event.target.value,
                  options: undefined,
                  // Some types are locked as required or not
                  required:
                    event.target.value in REQUIRED_LOCKED_TYPES
                      ? REQUIRED_LOCKED_TYPES[event.target.value]
                      : required,
                  // Unique types should have ID set as their type
                  id: UNIQUE_TYPES.includes(type) ? type : id,
                })
              }
              style={{ width: 'calc(100%)' }}
            >
              {Object.entries(TYPE_OPTIONS).map(([type, { label }]) => (
                <MenuItem
                  key={type}
                  value={type}
                  disabled={usedUniqueTypes.includes(type)}
                >
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Grid>

        <Grid item xs={4} sm={2}>
          <div className="form-group flex flex-col">
            <div className="feild-title font s15 b6 c000">Required</div>
            {/* TODO: Could just hide the required switch instead of disabling? */}

            <Switch
              checked={
                type in REQUIRED_LOCKED_TYPES
                  ? REQUIRED_LOCKED_TYPES[type]
                  : required
              }
              disabled={type in REQUIRED_LOCKED_TYPES}
              onChange={(event) =>
                setQuestion({
                  ...question,
                  required:
                    type in REQUIRED_LOCKED_TYPES
                      ? REQUIRED_LOCKED_TYPES[type]
                      : event.target.checked,
                })
              }
              name="question-is-required"
              color="secondary"
            />
          </div>
        </Grid>
      </Grid>

      {(type === 'radio' || type === 'checkbox') && (
        <AnswerChoices
          question_idx={question_idx}
          answers={answers}
          updateAnswers={(newAnswers) => {
            setQuestion({ ...question, options: newAnswers });
          }}
        />
      )}
    </div>
  );
}
