import React from 'react';
import './VideoMagnetProgress.css';

const VideoMagnetProgress = ({ progress, setProgress }) => {
  const seekProgress = (event) => {
    // Use currentTarget to get progress-bar (not smaller progress-bar-filled)
    setProgress(event.nativeEvent.offsetX / event.currentTarget.offsetWidth);
    event.stopPropagation();
  };

  return (
    <div className="progress-container">
      <div
        className="_progress-bar"
        onClick={seekProgress}
        onMouseUp={seekProgress} // It's almost like seeking! :D
      >
        <div
          className="progress-filled"
          style={{ flexBasis: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default VideoMagnetProgress;
