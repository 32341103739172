import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import CreateReview from '../components/createReview/CreateReview';
import TheCustomTour from '../components/CustomTour/TheCustomTour';
// Views
import {
  CustomTour,
  InstaTour,
  ManagementCo,
  Property,
  VideomagnetDetails,
} from '../views';
import { Survey } from '../views/reviews/Question';
import BookingSchedule from '../views/tour-magnet-tabs/BookingSchedule';
import ChampionDialog from '../views/tour-magnet-tabs/ChampionDialog';
import ChampionDialogEdit from '../views/tour-magnet-tabs/ChampionDialogEdit';
import ReferralsDialog from '../views/tour-magnet-tabs/ReferralsDialog';
import ShareDashboard from '../views/tour-magnet-tabs/ShareDashboard';
import Testimonials from '../views/tour-magnet-tabs/Testimonials';
import Tourversity from '../views/TourversityTYG';
import UploadVideoImport from '../views/upload-video-import/UploadVideoImport';
import UploadVideoImportRecord from '../views/upload-video-import/UploadVideoImportRecord';
const UnprotectedRoutes = ({ authenticated }) => {
  const location = useLocation();
  return (
    <>
      <Route path="/videoembed" exact>
        missing management_id
      </Route>
      <Route path="/videoembed/:management_id" exact>
        <ManagementCo />
      </Route>
      <Route path="/videoembed/community/:form_id" exact>
        <VideomagnetDetails />
      </Route>
      <Route path="/tour" exact>
        <CustomTour customHomepage="/tour" />
      </Route>
      <Route path="/share/analytics" exact>
        <ShareDashboard />
      </Route>
      <Route path="/share" exact>
        <CustomTour customHomepage="/share" />
      </Route>
      <Route path="/customtour" exact>
        <TheCustomTour />
      </Route>
      <Route path="/scheduler" exact>
        <BookingSchedule />
      </Route>
      <Route path="/instashare" exact>
        <InstaTour />
      </Route>
      <Route path="/dmg" exact>
        <ManagementCo
          searchFilter="DMG"
          customHomepage="/dmg"
          showCount={false}
        />
      </Route>
      <Route path="/survey/:community_id?" exact>
        <Survey />
      </Route>
      <Route path="/surveys/:community_id?/:community_name_url?" exact>
        <CreateReview fullHeight={true} />
      </Route>
      <Route path="/referrers/community/:id" exact>
        <ReferralsDialog />
      </Route>
      <Route path="/referrers/champions/community/:id" exact>
        <ChampionDialog />
      </Route>
      <Route
        path="/referrers/champions/community/:id/champion/:userId/edit"
        exact
      >
        <ChampionDialogEdit />
      </Route>
      <Route path="/partner/:management_id">
        <ManagementCo customHomepage="/partner" showCount={false} />
      </Route>
      <Route path="/properties/:route" exact>
        <Property />
      </Route>
      <Route path="/testimonials/:form_id" exact>
        <Testimonials />
      </Route>
      <Route
        path="/upload-video-import/:magnet_id_param/:screen_id_param"
        exact
      >
        <UploadVideoImport />
      </Route>
      <Route
        path="/upload-video-import/:magnet_id_param/:screen_id_param/record"
        exact
      >
        <UploadVideoImportRecord />
      </Route>
      <Route path="/esteem">
        <Redirect to="/survey/7" />
      </Route>
      <Route path="/learn" exact>
        <Tourversity />
      </Route>
    </>
  );
};

export default UnprotectedRoutes;
