import { Button, Grid, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ContentLoader from '../../components/ContentLoader';
import AccessLevelWrapper from '../../components/Reusable/AccessLevelWrapper';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import CustomHeader from '../../components/Reusable/CustomHeader';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import notification from '../../components/Reusable/Notification';
import firebase from '../../utils/base';
import { post, useRequest } from '../../utils/request';
import TeamPagination from './TeamPagination';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const MagnetTeam = ({ name, community_id, url, title }) => {
  const [NotificationBar, setNotification] = notification();
  const classes = useStyles();

  const [magnetTemplate, setMagnetTemplate] = useState({});
  const [magnetUuid, setMagnetUuid] = useState('');
  const [magnetType, setMagnetType] = useState('');

  const [loading, error, data, makeRequest] = useRequest({ loading: true });

  const [magSettings, setMagSettings] = useState({
    location: '',
    features: [],
    communityName: '',
    website: '',
    phone: '',
    email: '',
    customBranding: {
      img: '',
      url: '',
    },
    backgroundImg: '',
    agents: [], // each agent object in array contains name, email, image, and access_level (access_level is one of "user", "property", "company")
    preferredSettings: '',
  });

  const getMagnet = async () => {
    const res = await makeRequest(
      `/magnets?queryByCommunity=${community_id}`,
      'GET',
      null,
      { auth: false }
    );
    console.log(res.magnets);

    let magnetObj = res?.magnets[0]?.magnet_details.template;
    setMagnetUuid(res?.magnets[0]?.uuid);
    setMagnetType(res?.magnets[0]?.magnet_type);
    setMagnetTemplate(res?.magnets[0]?.magnet_details.template);

    let tempDict = {};

    if (magnetObj && 'magnetSettings' in magnetObj) {
      let magSettingKeys = Object.keys(magSettings);

      for (let k of magSettingKeys) {
        if (
          magnetObj.magnetSettings &&
          Object.keys(magnetObj.magnetSettings).includes(k)
        ) {
          tempDict[k] = magnetObj.magnetSettings[k];
        } else {
          tempDict[k] = magSettings[k];
        }
      }
      setMagSettings(tempDict);
    }
  };
  useEffect(() => {
    getMagnet();
  }, [community_id]);

  const [addUserAgent, setAddUserAgent] = useState(false);

  const [newUserAgents, setNewUserAgents] = useState([]);
  const [deleteUserAgents, setDeleteUserAgents] = useState(null);

  const [addPropertyManager, setAddPropertyManager] = useState(false);
  const [propertyManagerName, setPropertyManagerName] = useState('');
  const [propertyManagerEmail, setPropertyManagerEmail] = useState('');
  const [propertyManagerImage, setPropertyManagerImage] = useState('');
  const [newPropertyManagers, setNewPropertyManagers] = useState([]);
  const [deletePropertyManagers, setDeletePropertyManagers] = useState([]);

  const [editPropertyManager, setEditPropertyManager] = useState(false);
  const [editPropertyManagerIndex, setEditPropertyManagerIndex] = useState(-1);
  const [editPropertyManagerName, setEditPropertyManagerName] = useState('');
  const [editPropertyManagerEmail, setEditPropertyManagerEmail] = useState('');
  const [editPropertyManagerImage, setEditPropertyManagerImage] = useState('');
  const [valueEdit, setValueEdit] = useState('');
  const [value, setValue] = React.useState('property');
  const [newUser, setNewUser] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const handleChange = () => {
    setEditModal(false);
  };

  function handleUserAgentDelete(index) {
    const curr_agent = magSettings.agents[index];
    let exists = false;
    newUserAgents.map((agent_, index_) => {
      if (agent_ === curr_agent) {
        let currentNewUserAgents = newUserAgents;
        currentNewUserAgents.splice(index_, 1);
        setNewUserAgents(currentNewUserAgents);
        let currentAgents = magSettings.agents;
        currentAgents.splice(index, 1);
        setMagSettings({ ...magSettings, agents: currentAgents });
        exists = true;
      }
    });

    if (exists) {
      setAddUserAgent(false);
      return;
    }

    let currentAgents = magSettings.agents;

    // currentDeleteUserAgents.push(currentAgents[index]);
    updateSettings(currentAgents[index], 'remove');
    currentAgents.splice(index, 1);
    setMagSettings({ ...magSettings, agents: currentAgents });
  }

  function handlePropertyManagerAdd(override, name, email, image) {
    const currentAgents = magSettings.agents;
    const agent = {
      name: propertyManagerName,
      email: propertyManagerEmail,
      image: propertyManagerImage,
      access_level: value,
    };
    if (override) {
      agent.name = name;
      agent.email = email;
      agent.image = image;
    }

    if (!agent.name || !agent.email) {
      alert('field can not be blank');
      return;
    }
    if (!ValidateEmail(agent.email)) {
      return;
    }

    // error testing: check for repeats
    let exists = false;
    for (var i = 0; i < currentAgents.length; i++) {
      if (currentAgents[i].email === agent.email) {
        if (currentAgents[i].access_level === agent.access_level) {
          exists = true;
          alert('A user with the same email address has already been added.');
          break;
        } else if (currentAgents[i].access_level === 'user') {
          const response_ = window.confirm(
            'A user with this email address exists in the system as a Leasing Agent. Promote user to Property Manager?'
          );
          if (response_) {
            handleUserAgentDelete(i);
          } else {
            exists = true;
            break;
          }
        } else {
          // access_level === "company"
          alert(
            'A user with this email address exists as an admin in the system.'
          );
          exists = true;
          break;
        }
      }
    }

    if (exists) {
      setPropertyManagerName('');
      setPropertyManagerEmail('');
      setPropertyManagerImage('');
      setAddPropertyManager(false);
      return;
    }
    console.log('agent', agent);
    updateSettings(agent, 'add');
    currentAgents.push(agent);
    setMagSettings({ ...magSettings, agents: currentAgents });
    setAddPropertyManager(false);
    setPropertyManagerName('');
    setPropertyManagerEmail('');
    setPropertyManagerImage('');
    setEditModal(false);
  }
  function handlePropertyManagerEdit() {
    const old_agent = magSettings.agents[editPropertyManagerIndex];
    const new_agent = {
      name: editPropertyManagerName,
      email: editPropertyManagerEmail,
      image: editPropertyManagerImage,
      access_level: 'property',
    };
    if (old_agent['email'] !== new_agent['email']) {
      handleUserAgentDelete(editPropertyManagerIndex);
      handlePropertyManagerAdd(
        true,
        editPropertyManagerName,
        editPropertyManagerEmail,
        editPropertyManagerImage
      );
    } else {
      magSettings.agents[editPropertyManagerIndex]['name'] = new_agent['name'];
      magSettings.agents[editPropertyManagerIndex]['image'] =
        new_agent['image'];
      setEditPropertyManager(false);
    }
    setEditPropertyManagerName('');
    setEditPropertyManagerEmail('');
    setEditPropertyManagerImage('');
    setEditModal(false);
  }

  function updateSettings(user, todo) {
    // const tempAdd = [...newPropertyManagers];
    // const tempRemove = [...deleteUserAgents];
    // console.log("tempAdd", tempAdd)
    // tempAdd.forEach((newUser) =>
    //
    // );
    console.log('newUser', user);
    if (todo === 'add' && user) {
      firebase.createUser(user, community_id, name);
    }
    if (todo === 'remove' && user) {
      firebase.removeAccess(user, community_id);
    }
    // tempRemove.forEach((deleteUser) =>

    // );

    post('/magnets/template/settings', {
      magnet_uuid: magnetUuid,
      magnetSettings: magSettings,
      magnet_type: magnetType,
    }).then((data) => {
      setNotification(`Settings updated`, 'success');
      setNewPropertyManagers([]);
      setDeletePropertyManagers([]);
      setNewUserAgents([]);
      setDeleteUserAgents([]);
      // setSubmit(true);
    });

    console.log('just posted the magnet', {
      magnet_uuid: magnetUuid,
      template: {
        ...magnetTemplate,
        magnetSettings: magSettings,
      },
      magnet_type: magnetType,
    });
  }

  const [currentItems, setCurrentItems] = useState([]);

  const [itemOffset, setItemOffset] = useState(0);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.

  const [deleteModal, setDeleteModal] = useState(false);
  const handleClose = () => {
    setDeleteModal(false);
  };
  const [deleteUserIndex, setdeleteUserIndex] = useState();

  const [uAccessLevel, setUAccessLevel] = useState('user');
  function ValidateEmail(email) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    alert('You have entered an invalid email address!');
    return false;
  }
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Team"
      />
      {loading ? (
        <div className="magnent-team-page flex flex-col">
          <div className="hdr">
            <div className="tit holder" />
            <div className="lbl holder" />
          </div>
          <div className="team-wrap flex aic">
            <div className="item holder" />
            <div className="item holder" />
            <div className="item holder" />
          </div>
          <div className="hdr">
            <div className="tit holder" />
            <div className="lbl holder" />
          </div>
          <div className="bt holder" />
        </div>
      ) : (
        <>
          {error ? (
            <div className="font s15 b5 red t-c flex aic">{error}</div>
          ) : (
            <div className="magnent-team-page flex flex-col">
              <ContentLoader>
                <Grid container spacing={1}>
                  <AccessLevelWrapper
                    access_level_clearance="user"
                    community_id={community_id}
                    setUAccessLevel={setUAccessLevel}
                  >
                    <div className=" flex flex-col gap-2 w-full">
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Assign Community Managers
                      </Typography>

                      <Typography variant="caption">
                        Please Note that Community Managers can assign and
                        delete Community Agents
                      </Typography>

                      <div className="w-full mt-4 grid grid-cols-1  border divide-y overflow-scroll px-2">
                        <div
                          style={{ background: '#FAFCFE' }}
                          className=" w-full  grid grid-cols-4 gap-x-5"
                        >
                          <h5 className=" w-full text-center text-gray-800 text-sm py-2">
                            Name
                          </h5>
                          <h5 className=" w-full text-center text-gray-800 text-sm py-2">
                            Email
                          </h5>
                          <h5 className=" w-full text-center text-gray-800 text-sm py-2">
                            Access
                          </h5>
                          <h5 className=" w-full text-center text-gray-800 text-sm py-2">
                            Actions
                          </h5>
                        </div>
                        {currentItems.map((agent, index) => (
                          <div className=" w-full  grid grid-cols-4 py-2 gap-x-5">
                            <div className=" flex items-center justify-center px-2 gap-2">
                              {agent?.image && (
                                <img
                                  className=" w-6 h-6 rounded-full object-cover"
                                  src={agent.image}
                                  alt=""
                                />
                              )}
                              {console.log('agent name log TYG', agent)}
                              {typeof agent?.name === 'string' && (
                                <h5 className=" hidden lg:block text-xs text-gray-500">
                                  {agent?.name}
                                </h5>
                              )}
                              {/* <h5 className=" block lg:hidden text-xs text-gray-500">
                                {agent?.name.slice(0, 4) + '...'}
                              </h5> */}
                            </div>
                            <h5 className=" hidden lg:block  text-gray-500 text-xs text-center w-full py-2">
                              {agent?.email}
                            </h5>
                            {/* <h5 className=" block lg:hidden  text-gray-500 text-xs text-center w-full py-2">
                              {agent?.email.slice(0, 8) + '...'}
                            </h5> */}
                            <h5 className="  text-gray-500 text-xs text-center w-full py-2">
                              {agent?.access_level}
                            </h5>

                            {uAccessLevel !== 'user' && (
                              <>
                                {uAccessLevel === 'property' && (
                                  <>
                                    {agent?.access_level !== 'global' &&
                                    agent?.access_level !== 'company' &&
                                    agent?.access_level !== 'property' ? (
                                      <h5 className="  text-pr text-xs text-center w-full py-2">
                                        <span
                                          className=" cursor-pointer"
                                          onClick={() => {
                                            setAddPropertyManager(false);
                                            setEditPropertyManager(true);
                                            setEditPropertyManagerIndex(
                                              itemOffset + index
                                            );
                                            setEditPropertyManagerName(
                                              agent.name
                                            );
                                            setEditPropertyManagerEmail(
                                              agent.email
                                            );
                                            setEditPropertyManagerImage(
                                              agent.image
                                            );
                                            setValueEdit(agent.access_level);
                                            setEditModal(true);
                                          }}
                                        >
                                          Edit
                                        </span>
                                        |
                                        <span
                                          className=" cursor-pointer"
                                          onClick={() => {
                                            setdeleteUserIndex(
                                              itemOffset + index
                                            );
                                            setDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </h5>
                                    ) : (
                                      <h5 className="  text-gray-400 text-xs text-center w-full py-2">
                                        <span>Edit</span>|<span>Delete</span>
                                      </h5>
                                    )}
                                  </>
                                )}
                                {uAccessLevel === 'company' && (
                                  <>
                                    {agent?.access_level !== 'global' &&
                                    agent?.access_level !== 'company' ? (
                                      <h5 className="  text-pr text-xs text-center w-full py-2">
                                        <span
                                          className=" cursor-pointer"
                                          onClick={() => {
                                            setAddPropertyManager(false);
                                            setEditPropertyManager(true);
                                            setEditPropertyManagerIndex(
                                              itemOffset + index
                                            );
                                            setEditPropertyManagerName(
                                              agent.name
                                            );
                                            setEditPropertyManagerEmail(
                                              agent.email
                                            );
                                            setEditPropertyManagerImage(
                                              agent.image
                                            );
                                            setValueEdit(agent.access_level);
                                            setEditModal(true);
                                          }}
                                        >
                                          Edit
                                        </span>
                                        |
                                        <span
                                          className=" cursor-pointer"
                                          onClick={() => {
                                            setdeleteUserIndex(
                                              itemOffset + index
                                            );
                                            setDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                        </span>
                                      </h5>
                                    ) : (
                                      <h5 className="  text-gray-400 text-xs text-center w-full py-2">
                                        <span>Edit</span>|<span>Delete</span>
                                      </h5>
                                    )}
                                  </>
                                )}
                                {uAccessLevel === 'global' && (
                                  <>
                                    <h5 className="  text-pr text-xs text-center w-full py-2">
                                      <span
                                        className=" cursor-pointer"
                                        onClick={() => {
                                          setAddPropertyManager(false);
                                          setEditPropertyManager(true);
                                          setEditPropertyManagerIndex(
                                            itemOffset + index
                                          );
                                          setEditPropertyManagerName(
                                            agent.name
                                          );
                                          setEditPropertyManagerEmail(
                                            agent.email
                                          );
                                          setEditPropertyManagerImage(
                                            agent.image
                                          );
                                          setValueEdit(agent.access_level);
                                          setEditModal(true);
                                        }}
                                      >
                                        Edit
                                      </span>
                                      |
                                      <span
                                        className=" cursor-pointer"
                                        onClick={() => {
                                          setdeleteUserIndex(
                                            itemOffset + index
                                          );
                                          setDeleteModal(true);
                                        }}
                                      >
                                        Delete
                                      </span>
                                    </h5>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </div>

                      <AnimatedDialog
                        open={deleteModal}
                        onClose={handleClose}
                        fullWidth
                        maxWidth="xs"
                      >
                        <div className=" bg-white p-4 rounded-md">
                          <h1 className=" text-lg font-medium text-gray-900  ">
                            Delete{' '}
                          </h1>
                          <p className="  py-3 text-gray-400">
                            Are you confirm you want to delete the user
                          </p>
                          <div className=" flex items-center justify-end gap-2 w-full mt-2">
                            <Button
                              onClick={() => setDeleteModal(false)}
                              variant="outlined"
                            >
                              No
                            </Button>
                            <Button
                              onClick={() => {
                                handleUserAgentDelete(deleteUserIndex);
                                setDeleteModal(false);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Yes
                            </Button>
                          </div>
                        </div>
                      </AnimatedDialog>
                      <AnimatedDialog
                        open={editModal}
                        onClose={handleChange}
                        fullWidth
                        maxWidth="md"
                      >
                        <div className=" grid grid-cols-1 lg:grid-cols-3 gap-4 p-5">
                          <div className=" w-full">
                            <img
                              src={
                                propertyManagerImage ||
                                editPropertyManagerImage ||
                                'https://ssu.ca/wp-content/uploads/2020/08/default-profile.png'
                              }
                              alt="leasing agent"
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                          <div className=" lg:col-span-2 flex flex-col gap-3">
                            <DebouncedTextField
                              value={
                                addPropertyManager
                                  ? propertyManagerName
                                  : editPropertyManagerName
                              }
                              onChange={(newValue) => {
                                addPropertyManager
                                  ? setPropertyManagerName(newValue)
                                  : setEditPropertyManagerName(newValue);
                              }}
                              label="Name *"
                              placeholder="John Doe"
                              fullWidth
                            />
                            <DebouncedTextField
                              value={
                                addPropertyManager
                                  ? propertyManagerEmail
                                  : editPropertyManagerEmail
                              }
                              onChange={(newValue) => {
                                addPropertyManager
                                  ? setPropertyManagerEmail(newValue)
                                  : setEditPropertyManagerEmail(newValue);
                              }}
                              label="Email Address *"
                              placeholder="johndoe@gmail.com"
                              fullWidth
                            />
                            <DebouncedTextField
                              value={
                                addPropertyManager
                                  ? propertyManagerImage
                                  : editPropertyManagerImage
                              }
                              onChange={(newValue) => {
                                addPropertyManager
                                  ? setPropertyManagerImage(newValue)
                                  : setEditPropertyManagerImage(newValue);
                              }}
                              label="Profile Image"
                              placeholder=""
                              fullWidth
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  addPropertyManager
                                    ? handlePropertyManagerAdd()
                                    : handlePropertyManagerEdit();
                                }
                              }}
                            />

                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="gender"
                                name="gender1"
                                value={addPropertyManager ? value : valueEdit}
                                onChange={
                                  addPropertyManager
                                    ? (e) => setValue(e.target.value)
                                    : (e) => setValueEdit(e.target.value)
                                }
                              >
                                {/* {console.log("uAccesslevel", uAccessLevel)} */}
                                {uAccessLevel === 'global' && (
                                  <FormControlLabel
                                    value="company"
                                    control={<Radio />}
                                    className=" text-gray-500 text-sm"
                                    label="Company"
                                  />
                                )}
                                <FormControlLabel
                                  value="property"
                                  control={<Radio />}
                                  className=" text-gray-500 text-sm"
                                  label="Property Manager"
                                />
                                <FormControlLabel
                                  value="user"
                                  control={<Radio />}
                                  className=" text-gray-500 text-sm"
                                  label="Community Agents"
                                />
                              </RadioGroup>
                            </FormControl>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  addPropertyManager
                                    ? handlePropertyManagerAdd()
                                    : handlePropertyManagerEdit();
                                }}
                                fullWidth
                              >
                                {addPropertyManager
                                  ? `Add New `
                                  : 'Save Edits '}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </AnimatedDialog>

                      <div className=" w-full flex  items-end justify-between">
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setAddPropertyManager(true);
                              setEditModal(true);
                            }}
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            + Add Property Manager & Community Agents
                          </Button>
                        </div>

                        <div>
                          <TeamPagination
                            setCurrentItems={setCurrentItems}
                            mainData={magSettings}
                            data={magSettings.agents}
                            itemOffset={itemOffset}
                            setItemOffset={setItemOffset}
                          />
                        </div>
                      </div>
                    </div>
                  </AccessLevelWrapper>

                  <Grid item xs={12}>
                    <br />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={updateSettings}
                    >
                      Update Team
                    </Button>
                    <NotificationBar />
                  </Grid> */}
                </Grid>
              </ContentLoader>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MagnetTeam;
