import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BsCheck2All, BsFillPersonPlusFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AccessLevelWrapper from '../../components/Reusable/AccessLevelWrapper';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import CustomHeader from '../../components/Reusable/CustomHeader';
import Notification from '../../components/Reusable/Notification';
import RouteInput from '../../components/Reusable/RouteInput';
import { getMagnetData } from '../../store/actions/magnetActions';
import { uploadFile } from '../../utils/files';
import { get, post } from '../../utils/request';
import { generateRoutes } from '../manage-magnets/ManageMagnet';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const TalkAndTriggers = ({ name, url, community_id, title }) => {
  const _dispatch = useDispatch();
  const { template, magnetData } = useSelector((state) => state.getMagnet);
  const [routes, setRoutes] = useState(null);
  useEffect(() => {
    if (Object.keys(template).length > 0 && template.constructor === Object) {
      setRoutes(generateRoutes(template));
      return;
    }
    _dispatch(getMagnetData(community_id));
  }, []);

  useEffect(() => {
    if (Object.keys(template).length > 0 && template.constructor === Object) {
      setRoutes(generateRoutes(template));
    }
  }, [template]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Triggers"
      />

      {ChatbotCustomizer(community_id, routes, magnetData)}
    </>
  );
};

export default TalkAndTriggers;

function ChatbotCustomizer(community_id, routes, magnetData) {
  const chatbotRef = useRef();
  const [chatbot, setchatbot] = useState([]);

  const [magnetUuid, setMagnetUuid] = useState();
  const [defaultConfig, setDefaultConfig] = useState();

  const [valueIndex, setValueIndex] = useState(null);
  const [endDate, setEndDate] = useState('');
  const [condition, setCondition] = useState([
    {
      type: 'tourPixelHistory',
      route: '',
      operator: '',
      value: [],
    },
  ]);
  React.useEffect(() => {
    setMagnetUuid(magnetData?.magnets?.[0]?.uuid);

    let tempConfig =
      magnetData?.magnets?.[0]?.magnet_details?.template?.default_config;

    setchatbot(tempConfig?.chatbot || []);
    setDefaultConfig(tempConfig);

    // loading condition from database
  }, [magnetData]);

  useEffect(() => {
    console.log('valueIndex', chatbot[valueIndex]?.condition?.[0]);
    chatbot &&
      setCondition(
        chatbot[valueIndex]?.condition?.[0] || [
          {
            type: 'tourPixelHistory',
            route: '',
            operator: '',
            value: [],
          },
        ]
      );
  }, [valueIndex]);

  // Add Bubble Row
  const addBubbleRow = (index) => {
    let currentBubble = chatbot;
    currentBubble[index].buttons.push({ label: '', route: '' });

    setchatbot([...currentBubble]);
  };

  const editBubbleRowValues = (bubbleIndex, buttonIndex, e, isPathInput) => {
    var urlRegexExp = /(^(http|https|www):\/\/)/gi;
    var urlReg = new RegExp(urlRegexExp);

    let currentBubble = chatbot;

    if (isPathInput) {
      if (typeof e === 'string' && e?.match(urlReg)) {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          ...currentBubble[bubbleIndex].buttons[buttonIndex],
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          href: e,
        };
        delete currentBubble[bubbleIndex].buttons[buttonIndex].route;
      } else {
        currentBubble[bubbleIndex].buttons[buttonIndex] = {
          ...currentBubble[bubbleIndex].buttons[buttonIndex],
          label: currentBubble[bubbleIndex].buttons[buttonIndex].label,
          route: e?.route,
        };
        delete currentBubble[bubbleIndex].buttons[buttonIndex].href;
      }
      return;
    }
    if (e.target.name === 'message') {
      currentBubble[bubbleIndex].text = e.target.value;
    } else if (e.target.name === 'button-name') {
      currentBubble[bubbleIndex].buttons[buttonIndex] = {
        ...currentBubble[bubbleIndex].buttons[buttonIndex],
        label: e.target.value,
        route: currentBubble[bubbleIndex].buttons[buttonIndex].route,
      };
    } else {
      return null;
    }
    setchatbot([...currentBubble]);
  };

  // Delete Bubble Row
  const deleteBubbleRow = (bubbleIndex, buttonIndex) => {
    let currentBubble = [...chatbot];
    currentBubble[bubbleIndex].buttons.splice(buttonIndex, 1);
    setchatbot([...currentBubble]);
  };

  const addNewChatBubble = () => {
    setchatbot([
      ...chatbot,
      {
        type: 'Basic',
        text: '',
        condition: [],
        buttons: [{ label: '', route: '', href: '' }],
      },
    ]);
  };

  const deleteChatBubble = (idxToDelete) => {
    let currentchatbot = chatbot;
    currentchatbot.splice(idxToDelete, 1);
    setchatbot([...currentchatbot]);
  };

  const onClickLink = (link) => {
    if (link.route) {
      const newRoute = link.route.split('.');
      // onClick(newRoute);
    } else if (link.href) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href, '_blank').focus();
    } else if (link.href_direct) {
      // This creates an inconsistency in the "linked list" of Events, which
      // each have a "to" and a "from" route. We could fire another Event here
      // from `link.href` to `currentRoute`, or when processing Events, use the
      // previous `from` if previous `to` is not equal to current `from`
      window.open(link.href_direct, '_self').focus();
    }
  };

  function copy(e, ref) {
    setChatBotCopied(true);
    ref.current.select();
    document.execCommand('copy');
    e.target.focus();
    onCopyText();
  }

  const onCopyText = () => {
    setTimeout(() => {
      setChatBotCopied(false);
    }, 1000);
  };
  const classes = useStyles();
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const saveChatbot = async () => {
    const data = {
      community_id,
      magnet_uuid: magnetUuid,
    };

    post(
      '/magnets/template/updateMagnet/dynamic/defaultEmbed',
      {
        magnet_uuid: magnetUuid,
        default_config: {
          ...defaultConfig,
          chatbot,
        },
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.

        toast(`Successfully Saved chatbot`, {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      })
      .catch(() => {
        toast('No internet connection found. App is running in offline mode.', {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
      });
  };

  const [chatBotCopied, setChatBotCopied] = useState(false);
  const [conditionModal, setconditionModal] = useState(false);
  const handleCloseSignOutDialog = () => {
    setconditionModal(false);
  };
  const [startDate, setStartDate] = useState('');
  const [showjs, setShowjs] = useState({});

  // const addDateInchatBot = () => {
  //   let copyChatbot = chatbot;

  //   console.log('Above Dates', { startDate, endDate });

  //   copyChatbot[valueIndex].condition = {
  //     dateStart: startDate,
  //     dateEnd: endDate,
  //   };
  //   setchatbot([...copyChatbot]);

  //   setconditionModal(false);
  // };

  const [progress, setProgress] = useState(false);

  const handleUpload = async (file) => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        community_id,
        'magnetuid/' + guidGenerator()
      );
      setProgress(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };
  const [buttonIndex, setButtonIndex] = useState(0);
  const [idx, setIdx] = useState(0);
  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      let imageUrl = await handleUpload(e.target.files[0]);

      let copytemporaryChatbot = chatbot;
      if (copytemporaryChatbot[buttonIndex].buttons[idx]?.picArray) {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray.push({
          img: imageUrl,
          alt: '',
        });
      } else {
        copytemporaryChatbot[buttonIndex].buttons[idx].picArray = [
          { img: imageUrl, alt: '' },
        ];
      }

      setchatbot([...copytemporaryChatbot]);
    }
  }
  function addSubtitles(index, e, idx) {
    let copytemporaryChatbot = chatbot;

    copytemporaryChatbot[index].buttons[idx].subTitle = e.target.value;

    setchatbot([...copytemporaryChatbot]);
  }
  function addjs(index, idx, e) {
    let copytemporaryChatbot = chatbot;

    copytemporaryChatbot[index].buttons[idx].js = e.target.value;

    setchatbot([...copytemporaryChatbot]);
  }

  const deleteSelectedImage = (item, index, idx, pic_idx) => {
    const filterdImageArrray = chatbot[index].buttons[idx].picArray.filter(
      (pic, l_index) => pic_idx != l_index
    );
    let copyAgainChatbot = chatbot;
    copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray;
    console.log(
      (copyAgainChatbot[index].buttons[idx].picArray = filterdImageArrray)
    );
    setchatbot([...copyAgainChatbot]);
    // setchatbot(chatbot[index].buttons[idx].picArray = filterdImageArrray);
  };
  function guidGenerator() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }
  const [activeSubtitle, setActiveSubtitle] = useState({});

  const [operator, setOperator] = useState();

  const addConditon = () => {
    setCondition([
      ...condition,
      {
        type: condition?.[condition.length - 1]?.type,
        route: '',
        operator: '',
        value: [],
      },
    ]);
  };
  const removeCondtion = (ind) => {
    setCondition(condition.filter((item, index) => ind !== index));
  };
  const [magSettings, setMagSettings] = useState({
    specialOffer: '',
  });
  const [gaPropertyId, setGaPropertyId] = useState();
  const [startRoute, setStartRoute] = useState();
  const [fbPixelId, setFbPixelId] = useState();
  const [magnetType, setMagnetType] = useState();
  const getMagnet = async () => {
    const res = await get(
      `/magnets?queryByCommunity=${community_id}`,
      'GET',
      null,
      { auth: false }
    );

    let _magnetObj = res.magnets[0].magnet_details.template;
    setStartRoute(_magnetObj.start_route);
    setGaPropertyId(_magnetObj.gaPropertyId);
    setFbPixelId(_magnetObj.fbPixelId);
    setMagnetType(res.magnets[0].magnet_type);

    let tempDict = {};

    if (_magnetObj && 'magnetSettings' in _magnetObj) {
      let magSettingKeys = Object.keys(magSettings);

      for (let k of magSettingKeys) {
        if (
          _magnetObj.magnetSettings &&
          Object.keys(_magnetObj.magnetSettings).includes(k)
        ) {
          tempDict[k] = _magnetObj.magnetSettings[k];
        } else {
          tempDict[k] = magSettings[k];
        }
      }
      setMagSettings(tempDict);
    }
  };
  const [NotificationBar, setNotification] = Notification();
  function updateSettings() {
    post('/magnets/template/settings', {
      magnet_uuid: magnetUuid,
      magnetSettings: magSettings,
      start_route: startRoute,
      gaPropertyId: gaPropertyId,
      fbPixelId: fbPixelId,
      magnet_type: magnetType,
    }).then((data) => {
      setNotification(`Settings updated`, 'success');
      location.reload();
    });
  }

  useEffect(() => {
    getMagnet();
  }, []);

  const [valueArr, setValueArr] = useState([]);

  const handleAddChip = (val) => {
    setValueArr([...valueArr, val]);
  };

  const handleDeleteChip = (chip, selectedIndex, idx) => {
    let fvalue = condition;
    let nvalue = fvalue[idx].value.filter(
      (item, ind) => selectedIndex !== ind && item !== chip
    );
    fvalue[idx].value = nvalue;
    // console.log("nvalue", nvalue)
    setCondition([...fvalue]);
  };

  console.log('chatbotTest', chatbot);

  const updateCodition = (val, ind, property) => {
    console.log('condition updated', val);
    let copyCondtionArray = condition;

    copyCondtionArray[ind][property] = val;

    setCondition([...copyCondtionArray]);
  };

  return (
    <div className="chatbot-setting-dialog flex min-h-screen">
      <AnimatedDialog
        open={conditionModal}
        onClose={() => {
          handleCloseSignOutDialog();
        }}
        fullWidth
        maxWidth="md"
      >
        <div className=" p-4">
          <h4 className=" pb-3 text-xl font-semibold">Conditionally Trigger</h4>
          {/* <div className=" grid grid-cols-2 gap-2">
            <div className=" w-full">
              <p className=" text-sm">Start date</p>
              {chatbot?.[valueIndex]?.condition?.dateStart}
              <input
                className=" py-2 px-2 bg-transparent outline-none  w-full border rounded-md"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                type="datetime-local"
                name=""
                id=""
              />
            </div>
            <div>
              <p className=" text-sm">End date</p>
              <input
                className=" py-2 px-2 bg-transparent outline-none  w-full border rounded-md"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                type="datetime-local"
                name=""
                id=""
              />
            </div>
          </div> */}
          {/* <div className=" py-2 flex items-center justify-end gap-3">
            <button
              onClick={handleCloseSignOutDialog}
              className=" border px-2 py-2 text-sm rounded-md"
            >
              Cancel
            </button>
            {startDate !== '' || endDate !== '' ? (
              <button
                onClick={addDateInchatBot}
                className=" border-2 border-pr px-2 py-2 text-sm rounded-md  bg-pr text-gray-100 "
              >
                Save
              </button>
            ) : (
              <button
                disabled
                className=" cursor-not-allowed border-2 border-pr px-2 py-2 text-sm rounded-md  bg-pr text-gray-100 "
              >
                Save
              </button>
            )}
          </div> */}

          {routes &&
            condition &&
            condition?.map((item, ind) => (
              <div
                key={item.id}
                className={
                  condition[ind]?.type === 'tourPixelHistory'
                    ? ' grid grid-cols-6 lg:grid-cols-12 items-center gap-2 py-1'
                    : 'grid grid-cols-6 lg:grid-cols-9 items-center gap-2 py-1'
                }
              >
                {/* <div></div> */}
                <div className=" col-span-3">
                  <h2 className=" text-lg pl-5">Type</h2>
                </div>
                {condition[ind]?.type === 'tourPixelHistory' && (
                  <div className=" col-span-3">
                    <h2 className=" text-lg">Route</h2>
                  </div>
                )}

                <div
                  className={` ${
                    condition[ind]?.type === 'layout'
                      ? 'col-span-3'
                      : 'col-span-2'
                  }`}
                >
                  <h2 className=" text-lg">Operator</h2>
                </div>
                {condition[ind]?.type !== 'layout' && (
                  <div className=" col-span-3">
                    <h2 className=" text-lg">Value</h2>
                  </div>
                )}

                <div>
                  <h2 className=" text-base"></h2>
                </div>
                <div className=" col-span-3 flex items-center gap-2">
                  <AiOutlineClose
                    onClick={() => removeCondtion(ind)}
                    className=" w-7 h-7 cursor-pointer text-gray-500"
                  />
                  <FormControl variant="outlined" className=" w-full">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={condition[ind]?.type}
                      className=" w-full"
                      onChange={(e) => {
                        updateCodition(e.target.value, ind, 'type');
                        e.target.value === 'url' &&
                          updateCodition([], ind, 'value');

                        e.target.value.length > 0 &&
                          e.target.value !== 'url' &&
                          updateCodition('', ind, 'value');
                      }}
                    >
                      <MenuItem value="tourPixelHistory">
                        Tour Pixel History
                      </MenuItem>
                      <MenuItem value="url">Url</MenuItem>
                      <MenuItem value="date">Date</MenuItem>
                      <MenuItem value="layout">Layout</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {condition[ind]?.route &&
                  condition[ind]?.type === 'tourPixelHistory' && (
                    <div className=" col-span-3">
                      {console.log(
                        'condition[ind].route',
                        ind,
                        condition[ind].route,
                        routes
                      )}
                      <RouteInput
                        placeholder="Route"
                        routes={routes}
                        value={condition[ind].route}
                        onChange={(value) => {
                          updateCodition(value?.route || value, ind, 'route');
                        }}
                      />
                    </div>
                  )}
                <div className=" col-span-2">
                  {condition[ind]?.type === 'tourPixelHistory' && (
                    <FormControl variant="outlined" className=" w-full">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={condition[ind].operator}
                        onChange={(e) =>
                          updateCodition(e.target.value, ind, 'operator')
                        }
                        className=" w-full"
                      >
                        <MenuItem value=">=">{'>='}</MenuItem>
                        <MenuItem value=">">{'>'}</MenuItem>
                        <MenuItem value="<">{'<'}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {condition[ind]?.type === 'url' && (
                    <FormControl variant="outlined" className=" w-full">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={condition[ind].operator}
                        onChange={(e) =>
                          updateCodition(e.target.value, ind, 'operator')
                        }
                        className=" w-full"
                      >
                        <MenuItem value="includes">includes</MenuItem>
                        <MenuItem value="excludes">excludes</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {condition[ind]?.type === 'layout' && (
                    <FormControl variant="outlined" className=" w-full">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={condition[ind].operator}
                        onChange={(e) =>
                          updateCodition(e.target.value, ind, 'operator')
                        }
                        className=" w-full"
                      >
                        <MenuItem value="isMobile">isMobile</MenuItem>
                        <MenuItem value="isDesktop">isDesktop</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  {condition[ind]?.type === 'date' && (
                    <FormControl variant="outlined" className=" w-full">
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={condition[ind].operator}
                        onChange={(e) => {
                          updateCodition(e.target.value, ind, 'operator');
                        }}
                        className=" w-full"
                      >
                        <MenuItem value="<=">{'<='}</MenuItem>
                        <MenuItem value=">=">{'>='}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className=" col-span-3">
                  {condition[ind]?.type === 'url' && (
                    <ChipInput
                      variant="outlined"
                      className="my-3"
                      fullWidth
                      blurBehavior="add"
                      value={
                        Array.isArray(condition[ind].value)
                          ? condition[ind].value.map((item) => item)
                          : []
                      }
                      onAdd={(chip) => {
                        console.log('adding chip', chip);
                        updateCodition(
                          [...condition[ind].value, chip],
                          ind,
                          'value'
                        );
                      }}
                      onDelete={(chip, index) =>
                        handleDeleteChip(chip, index, ind)
                      }
                    />
                  )}
                  {condition[ind]?.type === 'date' && (
                    <div className=" col-span-3">
                      <input
                        className=" py-2 px-2 bg-transparent outline-none  w-full border rounded-md"
                        onChange={(e) =>
                          updateCodition(e.target.value, ind, 'value')
                        }
                        value={
                          Array.isArray(condition[ind].value)
                            ? ''
                            : condition[ind].value
                        }
                        type="datetime-local"
                        name=""
                        id=""
                      />
                    </div>
                  )}
                  {condition[ind]?.type === 'tourPixelHistory' && (
                    <div className=" col-span-3">
                      <TextField
                        id="outlined-basic"
                        type="number"
                        value={
                          Array.isArray(condition[ind].value)
                            ? ''
                            : condition[ind].value
                        }
                        onChange={(e) =>
                          updateCodition(e.target.value, ind, 'value')
                        }
                        variant="outlined"
                      />
                    </div>
                  )}
                </div>
                <p>and</p>
              </div>
            ))}
          <div className=" flex items-center justify-between mt-3">
            <button
              onClick={() => addConditon()}
              className=" flex items-center gap-1 text-lg bg-pr  px-2 py-1 rounded-md text-white"
            >
              <AiOutlinePlus className=" w-5 h-5" />
              Add
            </button>
            <button
              onClick={() => {
                let copyChatbot = chatbot;
                copyChatbot[valueIndex].condition = [condition];
                setchatbot([...copyChatbot]);
                saveChatbot();
              }}
              className=" flex items-center gap-1 text-lg bg-pr  px-2 py-1 rounded-md text-white"
            >
              Save
            </button>
          </div>
        </div>
      </AnimatedDialog>
      <div className="ls flex flex-col ">
        <div className="special-offer-dialog bg-gray-100 p-2 rounded-md flex flex-col">
          <p className=" pb-2 font-medium">🎉 Manage Special Offer</p>
          <div className="field">
            <input
              type="text"
              placeholder="What promotion / special offer would you like to run?"
              className="iput font"
              value={magSettings?.specialOffer}
              onChange={(e) =>
                setMagSettings({ ...magSettings, specialOffer: e.target.value })
              }
            />
            <span className=" text-gray-500 font-medium text-sm">
              This will be accessible by the route: "_promotions_.latest" or by
              typing "Special Offer [Latest]" into any button input field.
              Clearing the special will remove any specifics from the tour!
            </span>
          </div>
          <div className=" flex items-center justify-end">
            <button
              onClick={updateSettings}
              className="border px-3 py-2 text-sm font-semibold flex items-center gap-1 rounded-md bg-pr text-white"
            >
              <BsCheck2All className=" w-5 h-5" />
              <span>Save</span>
            </button>
          </div>
          <NotificationBar />
        </div>
        <div className="bubbles-list">
          {chatbot.length === 0 && (
            <div
              onClick={addNewChatBubble}
              className=" cursor-pointer w-full h-24 m-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 font-medium text-sm capitalize border-2 border-dashed rounded-md flex flex-col items-center justify-center"
            >
              <p className="ico icon-plus s15 text-xl"></p>{' '}
              <p>Add your first bubble</p>
            </div>
          )}
          {chatbot.length !== 0 &&
            chatbot.map((bubble, index) => (
              <div key={index} className="bubble flex flex-col">
                <div className="bubble-content">
                  <div className="bubble-content-hdr ">
                    <div className="meta">
                      <div className="title font s15 b6 black">{`Type: ${bubble.type}`}</div>
                      <input
                        type="text"
                        name="message"
                        className="disc-input font"
                        placeholder="Type Message..."
                        defaultValue={bubble.text}
                        onChange={(e) => {
                          editBubbleRowValues(index, '', e);
                        }}
                      />
                    </div>
                    <div className="rit">
                      <button
                        onClick={() => addBubbleRow(index)}
                        className="hdr-btn font"
                      >
                        <span className="ico icon-plus b6 s15"></span>
                        <span>Add new</span>
                      </button>
                    </div>
                  </div>

                  <div className="variables-wrapper">
                    {chatbot[index].buttons.map((item, idx) => (
                      <div key={idx} className=" flex items-start gap-2 mb-2">
                        <div className=" h-full pt-3 relative flex items-center justify-center">
                          <button className="drag-btn icon-apps4 black" />
                        </div>
                        <div className="   flex-col pt-1 pr-2 rounded-lg flex items-center justify-center">
                          {/* <p
                              style={{ fontSize: '11px' }}
                              className=" pb-1 text-gray-700 w-36"
                            >
                              Add Featured Image(s)
                            </p> */}
                          <div className=" flex items-center gap-1">
                            {item?.picArray && (
                              <div className=" flex items-center">
                                {item?.picArray.map((item, ind) => (
                                  <div
                                    key={ind}
                                    className={
                                      ind !== 0
                                        ? ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative -ml-3'
                                        : ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative'
                                    }
                                  >
                                    <img
                                      className=" w-full h-full"
                                      style={{
                                        objectFit: 'cover',
                                      }}
                                      src={item.img}
                                      alt=""
                                    />
                                    <div
                                      onClick={() =>
                                        deleteSelectedImage(
                                          item,
                                          index,
                                          idx,
                                          ind
                                        )
                                      }
                                      className=" img_hover cursor-pointer absolute top-0 left-0 w-full h-full flex items-center justify-center"
                                    >
                                      <IoMdClose />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                            {/* {console.log(index)} */}
                            <input
                              type="file"
                              onChange={(e) => {
                                onSelectImage(e);
                              }}
                              accept="image/*"
                              className=" hidden"
                              name="picture"
                              id="picture"
                            />
                            <label
                              onClick={() => {
                                setIdx(idx);
                                setButtonIndex(index);
                              }}
                              htmlFor="picture"
                              className={
                                progress === false
                                  ? ' p-2 cursor-pointer rounded-full bg-pr text-white '
                                  : ' p-2 cursor-pointer rounded-full bg-pr text-white animate-spin opacity-30'
                              }
                            >
                              <BsFillPersonPlusFill className=" w-5 h-5" />
                            </label>
                          </div>
                        </div>

                        <div className="field flex flex-col gap-2">
                          <input
                            type="text"
                            name="button-name"
                            placeholder="Button title"
                            className="iput font s14 cleanbtn"
                            onFocus={() => setActiveSubtitle({ index, idx })}
                            defaultValue={item.label}
                            value={item.label}
                            onChange={(e) => {
                              editBubbleRowValues(index, idx, e);
                            }}
                          />
                          {activeSubtitle?.index === index &&
                            activeSubtitle?.idx === idx && (
                              <input
                                type="text"
                                name="subtitle"
                                onFocus={() =>
                                  setActiveSubtitle({ index, idx })
                                }
                                placeholder="Add subtitle"
                                className=" w-full py-1 px-2 border rounded-md  bg-gray-100 text-sm"
                                defaultValue={item?.subTitle}
                                value={item?.subTitle}
                                onChange={(e) => {
                                  addSubtitles(index, e, idx);
                                }}
                              />
                            )}
                        </div>

                        <div className="field flex flex-col">
                          <RouteInput
                            routes={routes}
                            onFocus={() => {
                              setShowjs({ index, idx });
                            }}
                            value={item?.route || item?.href}
                            onChange={(value) => {
                              editBubbleRowValues(index, idx, value, true);
                            }}
                          />
                          <AccessLevelWrapper
                            access_level_clearance="global"
                            community_id={community_id}
                          >
                            {
                              <textarea
                                onChange={(e) => addjs(index, idx, e)}
                                className=" border p-1 rounded-md"
                                placeholder="Enter Js"
                                name=""
                                id=""
                                defaultValue={item?.js}
                                value={item?.js}
                                cols="10"
                                rows="5"
                              ></textarea>
                            }
                          </AccessLevelWrapper>
                        </div>

                        <div>
                          {/* <button
                            onClick={() => deleteVariable(idx)}
                            className="cleanbtn del-btn icon-create c333 s20"
                        /> */}
                          <button
                            onClick={() => deleteBubbleRow(index, idx)}
                            className="cleanbtn del-btn icon-delete1 lightBlack s20"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-3">
                    <button
                      onClick={() => addBubbleRow(index)}
                      className="mr-4"
                    >
                      <span className="ico icon-plus b6 s16"></span>
                      <span>Add new row</span>
                    </button>

                    <button
                      onClick={() => deleteChatBubble(index)}
                      className=""
                    >
                      <span className="ico icon-delete1 b6 s16"></span>
                      <span>Delete All</span>
                    </button>

                    <button
                      onClick={() => {
                        setValueIndex(index);
                        chatbot &&
                          // setCondition(
                          //   chatbot[valueIndex]?.condition?.[0] || [
                          //     {
                          //       type: 'tourPixelHistory',
                          //       route: '',
                          //       operator: '',
                          //       value: [],
                          //     },
                          //   ]
                          // );
                          // setStartDate(chatbot?.[index]?.condition?.dateStart);
                          // setEndDate(chatbot?.[index]?.condition?.dateEnd);
                          setconditionModal(true);
                      }}
                      className="ml-4"
                    >
                      <span className="ico icon-magic-wand b6 s16"></span>
                      <span>Customize trigger</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="flex aic  items-center justify-between pl-2 w-full">
          <button
            onClick={addNewChatBubble}
            className=" border px-3 py-2 text-sm font-semibold hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500"
          >
            <span className="ico icon-plus s15 pr-1"></span>
            <span>Add bubble</span>
          </button>
          <button
            onClick={saveChatbot}
            className="border px-3 py-2 text-sm font-semibold flex items-center gap-1 hover:bg-gray-100 hover:text-gray-800 rounded-md text-gray-500"
          >
            <BsCheck2All className=" w-5 h-5" />
            <span>Save Chatbot</span>
          </button>
        </div>
      </div>
      <div className="rs flex flex">
        <div className="chatbot-preview">
          <div className="preview-hdr flex aic">
            <div className="font tit black s14">Chatbot Public Preview</div>
          </div>
          <div className="chatbot-bubbles">
            {chatbot?.length > 0 &&
              chatbot?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`initial-message-bubble ${
                      item.buttons.length === 1 && !item.text
                        ? 'transparent'
                        : ''
                    }`}
                  >
                    {/* {item.title && (
                      <div className="message-title">{item.title}</div>
                    )} */}

                    {item.text && (
                      <div className="message-text">{item.text}</div>
                    )}
                    {item.users && (
                      <div className="support-block">
                        {item.users.map((user, index) => (
                          <div className="user">
                            <div
                              className="image"
                              style={{
                                backgroundImage: `url(${user.img})`,
                              }}
                            />
                            <div className="name">{user.name}</div>
                          </div>
                        ))}
                      </div>
                    )}

                    {item.buttons.length > 0 && (
                      <div className="bubble-buttons">
                        {item.buttons.map((button) => (
                          <button
                            onClick={() => onClickLink(button)}
                            className={`bubble-btn ${
                              button.fullWidth ? 'full' : ''
                            }`}
                          >
                            <div className=" flex items-center w-full gap-3">
                              {button?.picArray && (
                                <div className="flex items-center ">
                                  {button.picArray.map((item, ind) => (
                                    <img
                                      key={ind}
                                      src={item.img}
                                      className={
                                        ind === 0
                                          ? ' w-7 h-7 rounded-full'
                                          : ' w-7 h-7 -ml-1 rounded-full'
                                      }
                                      style={{ objectFit: 'cover' }}
                                      alt=""
                                    />
                                  ))}
                                </div>
                              )}

                              <div className="flex flex-col">
                                <p> {button.label}</p>
                                {button.subTitle && (
                                  <p className=" text-xs text-gray-400">
                                    {button.subTitle}
                                  </p>
                                )}
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>

        <textarea
          ref={chatbotRef}
          className="copy-snippet"
          readOnly
          value={`<script src="https://embed.tour.video/leasemagnets.js"></script> <script>LeaseMagnets({ uuid: "${magnetUuid}}", layout: "left", primaryColor: "#F26522", startScreen: "founders.bloom", buttonLabel: "", desktopWidth: 40, chatbot: ${JSON.stringify(
            chatbot
          )} })</script>`}
        />
        <button className="copy-btn rel" onClick={(e) => copy(e, chatbotRef)}>
          Copy chatbot code
          <div
            className={`copied font s14 b4 cfff anim ${
              chatBotCopied ? 'sho' : 'hid'
            }`}
          >
            Copied!
          </div>
        </button>
        <button
          className="preview-btn rel	"
          onClick={(e) =>
            window.open(
              `https://embed.tour.video/?uuid=${magnetUuid}`,
              '_blank'
            )
          }
        >
          Preview code
          <div
            className={`copied font s14 b4 cfff anim ${
              chatBotCopied ? 'sho' : 'hid'
            }`}
          >
            Copied!
          </div>
        </button>
      </div>
    </div>
  );
}
