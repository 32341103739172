import React from 'react';

function SidebarEmbedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 23 15"
    >
      <path
        fill="#446888"
        d="M19.633.33H2.807A2.41 2.41 0 00.404 2.734v9.615a2.41 2.41 0 002.403 2.403h16.826a2.41 2.41 0 002.404-2.403V2.734A2.41 2.41 0 0019.633.33zm-3.605 7.812l-1.803 1.803 1.803 1.803v1.803l-3.606-3.606 3.605-3.606v1.803zm1.201 5.409v-1.803l1.803-1.803-1.803-1.803V6.34l3.606 3.605-3.606 3.605z"
      ></path>
    </svg>
  );
}

export default SidebarEmbedIcon;
