import { IconButton } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { uploadFile } from '../../utils/files';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
}));
const ManageTeamMembers = ({
  addopen,
  setAddopen,
  initialTeamState = [],
  saveCommunityTeam,
  community_id,
}) => {
  const classes = useStyles();

  const [teamname, setteamname] = useState('');
  const [teamemail, setteamemail] = useState('');
  const [teameditindex, setteameditindex] = useState('');

  const [team, setTeam] = useState(initialTeamState || []);
  const [file, setFile] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  // useCalback:
  // updateDirectualCommunity(id) {
  // }
  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      setFile(e.target.files[0]);
    }
  }

  const handleUpload = async () => {
    let id = teameditindex !== '' ? teameditindex : team.length;

    try {
      const url = await uploadFile(
        file,
        setProgress,
        community_id,
        `community/${community_id}/team-member/profile/` + id.toString()
      );

      setPhotoUrl(url);
      setUploadedUrl(url);

      setLoading(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const addMember = async () => {
    setLoading(true);
    try {
      let url = '';
      if (file) {
        url = await handleUpload();
        console.log('url======', { url, file });
      }
      if (teameditindex != '') {
        let tempteam = team;
        tempteam[teameditindex - 1] = {
          name: teamname,
          email: teamemail,
          pic:
            url ||
            uploadedUrl ||
            `https://ui-avatars.com/api/?name=${encodeURI(teamname)}` ||
            '',
          phone: '',
          touchpoints: {},
          id: teameditindex,
        };
        setTeam([...tempteam]);
        setteamname('');
        setteamemail('');
        setteameditindex('');
        setFile(null);
        setLoading(false);
        setPhotoUrl(false);
        setUploadedUrl(false);
      } else {
        let tempteam = [
          ...team,
          {
            name: teamname,
            email: teamemail,
            pic:
              url ||
              uploadedUrl ||
              `https://ui-avatars.com/api/?name=${encodeURI(teamname)}` ||
              '',
            phone: '',
            touchpoints: {},
            id: teameditindex,
          },
        ];
        setTeam([...tempteam]);
        setteamname('');
        setteamemail('');
        setteameditindex('');
        setFile(null);
        setLoading(false);
        setPhotoUrl(false);
        setUploadedUrl(false);
      }
    } catch (e) {
      console.log('error', e.message);
      setLoading(false);
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={addopen}
      onClose={() => setAddopen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={addopen}>
        <div className={classes.paper + ' w-full lg:w-2/4 bg-white'}>
          <div className=" flex items-center justify-between">
            <p className=" font-medium text-sm">
              Manage team members / Add team member(s)
            </p>
            <IoMdClose
              onClick={() => setAddopen(false)}
              className=" cursor-pointer w-5 h-5"
            />
          </div>
          <h6 className=" font-medium pt-3">Team Members</h6>
          <div className=" mt-2 grid grid-cols-1 lg:grid-cols-6">
            <p className=" font-bold text-sm w-full text-gray-900 ">Name</p>
            <p className=" font-bold text-sm w-full text-gray-900 ">Email</p>
            <p className=" font-bold text-sm w-full text-gray-900 ">Profile</p>
            {/* <p className=" font-bold text-sm w-full text-gray-900 ">Profile</p> */}
            <p className=" font-bold text-sm w-full text-gray-900 ">
              Profile Stats
            </p>
            <p className=" font-bold text-sm w-full text-gray-900 ">
              Total reviews
            </p>
            <p className=" font-bold text-sm w-full text-gray-900 text-center">
              Action
            </p>
          </div>
          {team.map((item, ind) => (
            <div className=" mt-2 grid grid-cols-1 lg:grid-cols-6">
              <p className=" text-sm w-full text-gray-800 ">
                {item.name?.slice(0, 11)}...
              </p>
              <p className=" text-sm w-full text-gray-800 ">
                {item.email?.slice(0, 11)}...
              </p>
              <div className=" text-sm w-full text-gray-800 ">
                <div
                  key={ind}
                  className={
                    ' w-10 h-10 cursor-pointer img_hover_container rounded-full overflow-hidden relative -ml-3'
                  }
                >
                  <img
                    className=" w-full h-full"
                    style={{ objectFit: 'cover' }}
                    src={
                      item?.pic ||
                      `https://ui-avatars.com/api/?name=${encodeURI(
                        item?.name
                      )}`
                    }
                    alt=""
                  />
                  <div
                    // onClick={() => deleteSelectedImage(item, index, idx)}
                    className="img_hover cursor-pointer absolute top-0 left-0 w-full h-full flex items-center justify-center"
                  >
                    x
                  </div>
                </div>
              </div>
              {/* <p className=" text-sm w-full text-gray-800 ">profile</p> */}
              <a
                href={item.link}
                target="_blank"
                className=" text-sm w-full text-gray-800 "
              >
                {item?.link?.slice(0, 20)}...
              </a>
              <p className=" text-sm w-full text-gray-800 ">
                {item?.stats?.reviews}
              </p>
              <p className=" text-sm w-full text-gray-800 text-center">
                <div class="inline-flex">
                  <button
                    onClick={() => {
                      setteamname(item.name);
                      setteamemail(item.email);
                      setteameditindex(ind + 1);
                      setPhotoUrl(item?.pic);
                    }}
                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      let tempteam = team.slice();
                      tempteam.splice(ind, 1);
                      setTeam([...tempteam]);
                    }}
                    class="ml-0.5 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
                  >
                    Delete
                  </button>
                </div>
              </p>
            </div>
          ))}
          <div class=" w-full grid grid-cols-1 lg:grid-cols-2 mt-3 gap-3">
            <div className=" flex justify-center items-center border-2 border-dashed rounded-md">
              <div className="mb-1">
                {
                  <img
                    className="w-24 h-24  rounded-full object-cover object-center"
                    src={
                      photoUrl ||
                      'https://i1.pngguru.com/preview/137/834/449/cartoon-cartoon-character-avatar-drawing-film-ecommerce-facial-expression-png-clipart.jpg'
                    }
                    alt="Avatar Upload"
                  />
                }
              </div>
              <label className="cursor-pointer">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <PhotoCamera fontSize="large" />
                </IconButton>
                <input
                  onChange={onSelectImage}
                  accept="image/*"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>
            <div>
              <input
                value={teamname}
                onChange={(e) => setteamname(e.target.value)}
                class="mb-2 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="text"
                placeholder="Username"
              />
              <input
                value={teamemail}
                onChange={(e) => setteamemail(e.target.value)}
                class="mb-2 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Email"
              />

              <div className=" flex flex-col  gap-2">
                <button
                  onClick={addMember}
                  className="bg-transparent w-full hover:bg-blue-200 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                >
                  {loading
                    ? 'Loading...'
                    : teameditindex != ''
                    ? 'Edit Team member'
                    : 'Add Team member'}
                </button>
                <button
                  className="bg-transparent w-full hover:bg-blue-200 text-blue-700 font-semibold hover:text-white  py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                  onClick={() => {
                    saveCommunityTeam(team);
                    setAddopen(false);
                  }}
                >
                  {' '}
                  Save{' '}
                </button>
              </div>
            </div>
          </div>

          <a href="https://giftbit.com" target="_blank">
            <div className="mt-3 bg-gray-300 hover:bg-blue-200 cursor-pointer text-blue-700 font-semibold  py-2 px-4 border border-blue-500 hover:border-transparent rounded">
              🥳 Reward your top performing team members for awesome work 💰
            </div>
          </a>
        </div>
      </Fade>
    </Modal>
  );
};

export default ManageTeamMembers;
