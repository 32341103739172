import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import './ShareButtons.css';

const SHARE_ICON_PROPS = { borderRadius: '8px' };

const ShareButtons = ({ shareInfo, url }) =>
  shareInfo?.url ? (
    <div
      className="share-buttons"
      onClick={(e) => e.stopPropagation()} // Don't play/pause video in background
    >
      <EmailShareButton
        url={shareInfo?.url}
        subject={shareInfo?.text}
        body={shareInfo?.text}
      >
        <EmailIcon {...SHARE_ICON_PROPS} bgStyle={{ fill: '#BB001B' }} />
      </EmailShareButton>
      <FacebookShareButton
        url={shareInfo?.url}
        quote={shareInfo?.text}
        hashtag={shareInfo?.hashtag}
      >
        <FacebookIcon {...SHARE_ICON_PROPS} />
      </FacebookShareButton>
      <TwitterShareButton
        url={shareInfo?.url}
        title={shareInfo?.text}
        hashtags={[shareInfo?.hashtag]}
      >
        <TwitterIcon {...SHARE_ICON_PROPS} />
      </TwitterShareButton>
      <WhatsappShareButton url={shareInfo?.url} title={shareInfo?.text}>
        <WhatsappIcon {...SHARE_ICON_PROPS} />
      </WhatsappShareButton>
    </div>
  ) : url ? (
    <div
      className="share-buttons"
      onClick={(e) => e.stopPropagation()} // Don't play/pause video in background
    >
      <EmailShareButton
        url={url}
        subject={`Check out ${url}`}
        body={`Check out ${url}`}
      >
        <EmailIcon {...SHARE_ICON_PROPS} bgStyle={{ fill: '#BB001B' }} />
      </EmailShareButton>
      <FacebookShareButton url={url} quote={`Check out ${url}`}>
        <FacebookIcon {...SHARE_ICON_PROPS} />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={`Check out ${url}`}>
        <TwitterIcon {...SHARE_ICON_PROPS} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={`Check out ${url}`}>
        <WhatsappIcon {...SHARE_ICON_PROPS} />
      </WhatsappShareButton>
    </div>
  ) : null;

export default ShareButtons;
