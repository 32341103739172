import React from 'react';
import './common.css';
import './Iframe.css';

const Iframe = ({ src, backgroundColor }) => (
  <div className="iframe-container" style={{ backgroundColor }}>
    <iframe
      frameBorder="0"
      src={src}
      allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
    />
  </div>
);

export default Iframe;
