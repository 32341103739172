import { Divider, Typography } from '@material-ui/core';
import MatGrid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import aset from '../../assets/authenticaiton/aset.png';
import cardinal from '../../assets/authenticaiton/cardinal.png';
import desai from '../../assets/authenticaiton/desai.png';
import deso from '../../assets/authenticaiton/deso.png';
import independance from '../../assets/authenticaiton/independance.png';
import lakers from '../../assets/authenticaiton/lakers.png';
import m from '../../assets/authenticaiton/m.png';
import peak from '../../assets/authenticaiton/peak.png';
import pie from '../../assets/authenticaiton/pie.png';
import pnoe from '../../assets/authenticaiton/pnoe.png';
import saas from '../../assets/authenticaiton/saas.png';
import social from '../../assets/authenticaiton/social.png';
import firebase, { auth, google_provider } from '../../utils/base';
import { font } from '../../utils/constants';
import './signin.css';

export const SignIn = () => {
  // User Input States
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  // Validate Inputs
  const isValid = !email || !password;

  const onSignIn = (e) => {
    e.preventDefault();

    if (isValid) {
      console.log("Input fields couldn't be empty");
      return;
    }

    firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then((user) => console.log(user))
      .catch((err) => setError(err.message));
  };

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(google_provider)
      .then((result) => {
        var token = result.credential.accessToken;
        var user = result.user;
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error.credential;
        setError(errorMessage);
      });
  };

  const emailPattern = /^\S+@\S+\.\S+/;

  const [showSignin, setShowSignin] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowSignin(false);
      setIsMobile(true);
    }
  }, []);

  const history = useHistory();
  return (
    <div className="SignIn min-h-screen">
      <div
        className=" flex items-center justify-center flex-col-reverse  lg:grid grid-cols-1 lg:grid-cols-2"
        style={{ width: '70vw' }}
        classes={{ root: 'center_container' }}
      >
        <div
          className=" flex items-center justify-center flex-col gap-2"
          style={{ display: showSignin && isMobile ? 'none' : 'flex' }}
        >
          {/* <img
            alt="phone showing demo tour video"
            style={{ width: '75%' }}
            src={signuppic}
          /> */}
          <Typography
            variant={isMobile ? 'h6' : 'h5'}
            align="center"
            style={{
              fontWeight: 900,
              fontFamily: font,
              marginTop: '20px',
              lineHeight: '38px',
            }}
          >
            Join the <span className="blue-highlight">400+</span> <br />{' '}
            businesses that
            {/* <span className="blue-highlight">
              {' '}
             
            </span> */}
          </Typography>
          {/* <p className=" font-medium text-lg pt-4 pb-1">
            Join the 400+ businesses that trust us with their tour.
          </p> */}
          <div className=" w-full flex items-center justify-center ">
            <div className=" grid grid-cols-4 items-center gap-x-6 gap-y-4">
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={lakers} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={saas} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={social} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={deso} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={desai} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={independance} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img
                  src={m}
                  className=" p-3"
                  style={{ objectFit: 'cover' }}
                  alt=""
                />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={peak} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={pie} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={pnoe} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={cardinal} style={{ objectFit: 'cover' }} alt="" />
              </a>
              <a
                href="http://gogle.com"
                target="_blank"
                className=" cursor-pointer"
              >
                <img src={aset} style={{ objectFit: 'cover' }} alt="" />
              </a>
            </div>
          </div>
          <Typography
            variant={isMobile ? 'h6' : 'h5'}
            align="center"
            style={{
              fontWeight: 900,
              fontFamily: font,
              marginTop: '15px',
              lineHeight: '35px',
            }}
          >
            trust us with their tour.
          </Typography>
        </div>

        <div className=" flex items-center mt-32 lg:mt-0 justify-center flex-col gap-2">
          <form onSubmit={onSignIn}>
            <MatGrid container item xs={12} direction="column">
              <Typography
                variant={isMobile ? 'h5' : 'h4'}
                style={{ color: '#262E37', fontWeight: 900, fontFamily: font }}
              >
                Welcome Back!{' '}
              </Typography>
              <Typography
                variant={isMobile ? 'subtitle2' : 'subtitle1'}
                style={{ color: '#262E37', fontFamily: font, marginTop: '5px' }}
              >
                Please log in to your account.{' '}
              </Typography>{' '}
              <br />
              <input
                type="text"
                name="login"
                id="login"
                tabIndex="1"
                className="text-input"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder="email"
                style={{
                  backgroundColor:
                    email && emailPattern.test(email)
                      ? 'rgb(226, 244, 253)'
                      : '#F3F3F4',
                }}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                name="login"
                id="login"
                tabIndex="1"
                className="text-input"
                autoCorrect="off"
                autoCapitalize="off"
                placeholder="password"
                style={{
                  backgroundColor: password ? 'rgb(226, 244, 253)' : '#F3F3F4',
                }}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </MatGrid>
            <MatGrid
              container
              item
              xs={12}
              direction="row"
              justify="flex-end"
              style={{ marginTop: '10px' }}
            >
              <Typography variant="caption">
                <a href="/resetpassword" className="special-link-red">
                  Forgot password?
                </a>
              </Typography>
            </MatGrid>
            <MatGrid
              container
              item
              xs={12}
              direction="row"
              justify="flex-start"
              style={{ display: error ? 'flex' : 'none' }}
            >
              <Typography variant="caption" style={{ color: 'red' }}>
                {error}
              </Typography>
            </MatGrid>

            <MatGrid container item xs={12} justify="space-between">
              <button type="submit" className="button signin w-button">
                Sign In
              </button>
              <button
                type="button"
                className="button create w-button"
                onClick={() => history.push('/signup')}
              >
                Create Account
              </button>
            </MatGrid>

            <MatGrid
              container
              item
              xs={12}
              justify="center"
              style={{ marginTop: '30px' }}
            >
              <Divider style={{ width: '100%' }} />
              <Typography
                variant="caption"
                style={{
                  marginTop: '-10px',
                  background: 'white',
                  padding: '0 5px',
                }}
              >
                or
              </Typography>
              <button
                type="button"
                className="button google flex items-center justify-center"
                onClick={signInWithGoogle}
                style={{ display: 'flex' }}
              >
                {' '}
                <img
                  alt="Google logo"
                  style={{
                    height: '15px',
                    marginRight: '15px',
                    width: '15px',
                    objectFit: 'contain',
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1200px-Google_%22G%22_Logo.svg.png"
                />{' '}
                Sign In with Google
              </button>
            </MatGrid>
          </form>
        </div>
      </div>
    </div>
  );
};
