import {
  Backdrop,
  Fab,
  Fade,
  IconButton,
  makeStyles,
  Modal,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { MdModeEditOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadFile } from '../../utils/files';
import ChampionDialog from '../../views/tour-magnet-tabs/ChampionDialog';
import AddChampionMember from '../AddChampionMember';
import AnimatedDialog from '../Reusable/AnimatedDialog';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
  },
}));
const ManageChampionMembers = ({
  community_id,
  addopen2,
  setAddopen2,
  initialChampionState,
  saveCommunityChampions,
  badges,
  updateChampionSetting,
  communityName,
  championHeaderTitle,
  championHeaderSubtitle,
}) => {
  const classes = useStyles();

  const [championname, setchampionname] = useState('');
  const [championemail, setchampionemail] = useState('');
  const [selectedBadges, setSelectedBadges] = React.useState([]);

  const [championeditindex, setchampioneditindex] = useState('');
  const [openaddchampionModal, setopenaddchampionModal] = useState(false);
  const [file, setFile] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(false);
  const [champions, setChampions] = useState(initialChampionState || []);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(false);
  let communitybadges = badges
    ? Object.keys(badges).map((key) => {
        return {
          type: key,
          names: badges[key].map((item) => item.subcategory),
        };
      })
    : [];
  const reformatedBadges = communitybadges.reduce((akku, row) => {
    const badgeRows = [];
    row.names.map((name) => {
      badgeRows.push({
        type: row.type,
        name: name,
      });
    });
    akku = akku.concat(badgeRows);
    return akku;
  }, []);

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      setFile(e.target.files[0]);
    }
  }

  const handleUpload = async () => {
    let id = championeditindex !== '' ? championeditindex : champions.length;

    try {
      const url = await uploadFile(
        file,
        setProgress,
        community_id,
        `community/${community_id}/champions/profile/` + id.toString()
      );

      setPhotoUrl(url);
      setUploadedUrl(url);

      setLoading(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };
  const toastNotification = (message, type) => {
    toast(message, {
      position: 'top-right',
      type: type,
      autoClose: 5000,
    });
  };

  const addMember = async () => {
    setLoading(true);
    try {
      let url = '';
      if (file) {
        url = await handleUpload();
        console.log('url======', { url, file });
      }
      if (championeditindex !== '') {
        let tempchampions = champions;
        tempchampions[championeditindex - 1] = {
          name: championname,
          email: championemail,
          badges: selectedBadges,
          profile: url || uploadedUrl || '',
          id: championeditindex,
        };
        setChampions([...tempchampions]);
        setchampionname('');
        setchampionemail('');
        setchampioneditindex('');
        setSelectedBadges([]);
        setFile(null);
        setLoading(false);
        setPhotoUrl(false);
        setUploadedUrl(false);
      } else {
        let tempchampions = [
          ...champions,
          {
            name: championname,
            email: championemail,
            badges: selectedBadges,
            profile: url || uploadedUrl || '',
            id: champions.length,
          },
        ];
        setChampions([...tempchampions]);
        setchampionname('');
        setchampionemail('');
        setchampioneditindex('');
        setSelectedBadges([]);
        setFile(null);
        setLoading(false);
        setPhotoUrl(false);
        setUploadedUrl(false);
      }
    } catch (e) {
      console.log('error');
      setLoading(false);
    }
  };

  console.log('subBadges', selectedBadges);

  const EditReviewModal = (open, setOpen) => {
    return (
      <AnimatedDialog
        open={openaddchampionModal}
        title="Edit Review Settings"
        onClose={() => setopenaddchampionModal(false)}
      >
        <button
          onClick={() => setopenaddchampionModal(false)}
          type="button"
          className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span className="sr-only">Close menu</span>
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <AddChampionMember />
      </AnimatedDialog>
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={addopen2}
      onClose={() => setAddopen2(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={addopen2}>
        <div className=" h-screen overflow-y-scroll lg:overflow-hidden m-1 mb-4   w-full lg:w-5/6 bg-white p-4">
          <div className="flex items-center justify-end">
            <IoMdClose
              onClick={() => setAddopen2(false)}
              className=" cursor-pointer w-7 h-7"
            />
          </div>
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-7">
            <div>
              <ChampionDialog
                badges={communitybadges}
                champions={champions}
                customId={community_id}
                customCommunityName={communityName}
                championHeaderTitleP={championHeaderTitle}
                championHeaderSubtitleP={championHeaderSubtitle}
              />
            </div>
            <ChampionSettingModal
              updateChampionSetting={updateChampionSetting}
              communitybadges={communitybadges}
              setopenaddchampionModal={setopenaddchampionModal}
              openaddchampionModal={openaddchampionModal}
              championHeaderTitleP={championHeaderTitle}
              championHeaderSubtitleP={championHeaderSubtitle}
            />
            <div>
              <div>
                <div className=" flex items-center justify-between">
                  <p className=" font-medium text-sm">
                    Manage Champion program
                  </p>
                </div>
                <p
                  onClick={() => setopenaddchampionModal(true)}
                  className=" flex flex-end items-center gap-1 cursor-pointer"
                >
                  Settings <FiSettings />
                </p>
                <div className=" w-full mt-3 border rounded-sm py-2 flex items-center justify-between px-2">
                  <p className=" break-all">
                    {window.location.origin.toString()}
                    /referrers/champions/community/{community_id}
                  </p>
                  <a
                    href={
                      window.location.origin.toString() +
                      `/referrers/champions/community/${community_id}`
                    }
                    target="_blank"
                  >
                    <HiOutlineExternalLink className=" w-6 h-6 cursor-pointer" />
                  </a>
                </div>

                <h6 className=" font-medium pt-3">Members</h6>
                <div className=" mt-2 grid grid-cols-4">
                  <p className=" font-bold text-sm w-full text-gray-900 ">
                    Name
                  </p>
                  <p className=" font-bold text-sm w-full text-gray-900 ">
                    Email
                  </p>
                  <p className=" font-bold text-center text-sm w-full text-gray-900 ">
                    Profile
                  </p>
                  <p className=" font-bold text-sm w-full text-gray-900 text-center">
                    Action
                  </p>
                </div>
                {champions.map((item, ind) => (
                  <div className=" mt-2 grid grid-cols-4">
                    <p className=" text-sm w-full text-gray-800 ">
                      {item.name?.slice(0, 11)}...
                    </p>
                    <p className=" text-sm w-full text-gray-800 ">
                      {item.email?.slice(0, 11)}...
                    </p>
                    <Link
                      to={`/referrers/champions/community/${community_id}/champion/${ind}/edit`}
                    >
                      <div
                        key={ind}
                        className={
                          ' cursor-pointer w-full flex items-center justify-center img_hover_container rounded-full overflow-hidden relative '
                        }
                      >
                        <img
                          className="  w-10 h-10 rounded-full"
                          style={{ objectFit: 'cover' }}
                          src={
                            item?.profile ||
                            `https://ui-avatars.com/api/?name=${encodeURI(
                              item?.name
                            )}`
                          }
                          alt=""
                        />
                        <div className="img_hover cursor-pointer absolute top-0 left-0 w-full h-full flex items-center justify-center">
                          x
                        </div>
                      </div>
                    </Link>
                    <p className=" text-sm w-full text-gray-800 text-center">
                      <div className="inline-flex">
                        <button
                          onClick={() => {
                            setchampionname(item.name);
                            setchampionemail(item.email);
                            setSelectedBadges(item?.badges || []);
                            setUploadedUrl(item?.profile);
                            setPhotoUrl(item?.profile);
                            setchampioneditindex(ind + 1);
                          }}
                          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded-l"
                        >
                          <MdModeEditOutline />
                        </button>
                        <button
                          onClick={() => {
                            let tempchampions = champions.slice();
                            tempchampions.splice(ind, 1);
                            setChampions([...tempchampions]);
                          }}
                          className="ml-0.5 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-2 rounded-r"
                        >
                          <AiOutlineDelete />
                        </button>
                      </div>
                    </p>
                  </div>
                ))}
                <div className=" w-full">
                  <div className=" w-full grid grid-cols-1 lg:grid-cols-2 gap-3 mt-4">
                    <div className=" flex justify-center border-2 items-center h-full w-full border-dashed py-2 lg:py-0">
                      <div className="mb-1">
                        {
                          <img
                            className="w-24 h-24 border  rounded-full object-cover object-center"
                            src={
                              photoUrl ||
                              'https://i1.pngguru.com/preview/137/834/449/cartoon-cartoon-character-avatar-drawing-film-ecommerce-facial-expression-png-clipart.jpg'
                            }
                            alt="Avatar Upload"
                          />
                        }
                      </div>
                      <label className="cursor-pointer">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera fontSize="large" />
                        </IconButton>
                        <input
                          onChange={onSelectImage}
                          accept="image/*"
                          type="file"
                          className="hidden"
                        />
                      </label>
                    </div>

                    <div>
                      <input
                        value={championname}
                        onChange={(e) => setchampionname(e.target.value)}
                        className="mb-2 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        placeholder="Username"
                      />
                      <input
                        value={championemail}
                        onChange={(e) => setchampionemail(e.target.value)}
                        className="mb-2 appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        placeholder="Email"
                      />
                      <div className="py-0 mb-2">
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="multiple-limit-tags"
                          options={reformatedBadges}
                          value={selectedBadges}
                          onChange={(event, newValue) => {
                            setSelectedBadges(newValue);
                          }}
                          groupBy={(option) => option?.type}
                          getOptionLabel={(option) => option?.name}
                          // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Badges"
                              placeholder="Choose Bades"
                            />
                          )}
                        />
                      </div>

                      <button
                        onClick={addMember}
                        className="bg-transparent  hover:bg-blue-200 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        {loading
                          ? 'Loading...'
                          : championeditindex != ''
                          ? 'Edit member'
                          : 'Add member'}
                      </button>
                    </div>
                  </div>

                  {championeditindex && (
                    <Fab
                      onClick={() => {
                        setchampioneditindex('');
                        setchampionname('');
                        setchampionemail('');
                        setchampioneditindex('');
                        setSelectedBadges([]);
                        setFile(null);
                        setPhotoUrl(false);
                        setUploadedUrl(false);
                      }}
                      className={'mx-3'}
                      size={'small'}
                      color="primary"
                      aria-label="add"
                    >
                      <CloseIcon />
                    </Fab>
                  )}
                </div>
                <div className="flex">
                  <div
                    className="mt-4 mr-4"
                    onClick={() => {
                      saveCommunityChampions(champions);
                      setAddopen2(false);
                    }}
                  >
                    Save
                  </div>
                  <div className="mt-4" onClick={() => setAddopen2(false)}>
                    Close
                  </div>
                </div>

                <a href="https://giftbit.com" target="_blank">
                  <div className="mt-3 bg-gray-300 hover:bg-blue-200 cursor-pointer text-blue-700 font-semibold  py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    🥳 Reward your top performing team members for awesome work
                    💰
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ManageChampionMembers;

const ChampionSettingModal = ({
  openaddchampionModal,
  setopenaddchampionModal,
  communitybadges,
  updateChampionSetting,
  championHeaderTitleP,
  championHeaderSubtitleP,
}) => {
  const [badges, setBadges] = useState([]);
  const [subBadges, setSubBadges] = useState([]);
  const [category, setCategory] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [championHeaderTitle, setChampionHeaderTitle] = useState('');
  const [championHeaderSubtitle, setChampionHeaderSubtitle] = useState('');

  console.log('ChampionSettingModal', badges);

  useEffect(() => {
    setBadges(communitybadges);
  }, [communitybadges]);
  useEffect(() => {
    championHeaderTitleP && setChampionHeaderTitle(championHeaderTitleP);
    championHeaderSubtitleP &&
      setChampionHeaderSubtitle(championHeaderSubtitleP);
  }, [championHeaderTitleP, championHeaderSubtitleP]);

  const handleAddChip = (val) => {
    setSubBadges([...subBadges, val]);
  };

  const handleDeleteChip = (chip, selectedIndex) => {
    setSubBadges(subBadges.filter((item, index) => index !== selectedIndex));
  };

  const handleAdd = () => {
    setBadges([
      ...badges,
      {
        type: category,
        names: subBadges,
      },
    ]);
    setSubBadges([]);
    setCategory('');
  };
  const handleEdit = () => {
    let editBadges = badges;
    editBadges[editIndex] = {
      type: category,
      names: subBadges,
    };
    setBadges([...editBadges]);
    setSubBadges([]);
    setCategory('');
    setEditIndex(null);
  };
  const handleDelete = (selectedIndex) => {
    setBadges(badges.filter((item, index) => index !== selectedIndex));
  };

  const handleSave = () => {
    let championSettings = badges.map((item) => {
      return {
        [item?.type]: item.names.map((subcategory) => {
          return { subcategory };
        }),
      };
    });
    const convertIntoObj = Object.assign({}, ...championSettings);
    console.log('save championSettings', convertIntoObj);

    let headersText = {
      championHeaderTitle,
      championHeaderSubtitle,
    };

    updateChampionSetting(convertIntoObj, headersText);
    setopenaddchampionModal(false);
  };

  return (
    <AnimatedDialog
      onClose={() => setopenaddchampionModal(false)}
      open={openaddchampionModal}
      maxWidth="md"
    >
      <div className="edit-user-dialog">
        <div className="hdr flex aic">
          <div className="left flex aic">
            <div className="title font black">Champion Setting</div>
          </div>
          <div className="right flex aic">
            <div
              className="cross-btn font"
              onClick={() => setopenaddchampionModal(false)}
            >
              &times;
            </div>
          </div>
        </div>
        <div className="mx-2 my-2">
          {badges &&
            badges.map((item, index) => (
              <div
                key={index}
                className="mx-2 flex justify-between  items-center flex-wrap"
              >
                <p className="font-small leading-tight text-2xl mt-0 mb-2 text-dark my-1">
                  {item?.type}
                </p>
                <div className="mx-4 flex">
                  {item?.names &&
                    item?.names.map((text, indx) => (
                      <div key={indx} className="mx-2 my-1">
                        <span className="px-4 py-2 rounded-full text-gray-500 bg-gray-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-gray-300 transition duration-300 ease">
                          {text}
                        </span>
                      </div>
                    ))}
                </div>
                <div className="flex my-1">
                  <IconButton
                    onClick={() => {
                      setSubBadges(badges[index]?.names || []);
                      setCategory(badges[index].type || '');
                      setEditIndex(index);
                      console.log('index', index);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            ))}

          <div className="my-2 mx-2 ">
            <TextField
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              fullWidth
              label="category name"
              variant="outlined"
            />

            <ChipInput
              variant="outlined"
              placeholder="Add Badges"
              className="my-3"
              fullWidth
              blurBehavior="add"
              value={subBadges}
              onAdd={(chip) => subBadges.length < 10 && handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
            />

            {editIndex !== null ? (
              <Fab onClick={handleEdit} color="primary" aria-label="add">
                <EditIcon />
              </Fab>
            ) : (
              <Fab
                onClick={handleAdd}
                color="primary"
                aria-label="add"
                disabled={badges.length > 2 || !category}
              >
                <AddIcon />
              </Fab>
            )}
          </div>
          <div className="my-2 mx-2">
            <TextField
              value={championHeaderTitle}
              onChange={(e) => setChampionHeaderTitle(e.target.value)}
              fullWidth
              label="Header Title"
              variant="outlined"
              className="my-2"
            />
            <TextField
              value={championHeaderSubtitle}
              onChange={(e) => setChampionHeaderSubtitle(e.target.value)}
              fullWidth
              label="Header Subtitle"
              variant="outlined"
              className="my-2"
            />
          </div>
          <button
            onClick={handleSave}
            className="bg-pr mx-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full cursor-pointer "
          >
            Save
          </button>
        </div>
      </div>
    </AnimatedDialog>
  );
};
