import React, { useEffect, useRef, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsCameraVideoFill, BsGlobe2 } from 'react-icons/bs';
import { CgMenuGridO } from 'react-icons/cg';
import { HiArrowRight } from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import c1 from '../assets/home/c1.png';
import c2 from '../assets/home/c2.png';
import c3 from '../assets/home/c3.png';
import c4 from '../assets/home/c4.png';
import c5 from '../assets/home/c5.png';
import c6 from '../assets/home/c6.png';
import google from '../assets/home/google.png';
import ss from '../assets/home/ss.png';
import t1 from '../assets/home/t1.png';
import t2 from '../assets/home/t2.jpeg';
import t3 from '../assets/home/t3.jpeg';
import t4 from '../assets/home/t4.jpeg';
import t5 from '../assets/home/t5.jpeg';
import t6 from '../assets/home/t6.png';
import tl1 from '../assets/home/tl1.png';
import tl2 from '../assets/home/tl2.png';
import tl3 from '../assets/home/tl3.png';
import tl4 from '../assets/home/tl4.png';
import tl5 from '../assets/home/tl5.svg';
import tl6 from '../assets/home/tl6.png';
import track from '../assets/home/track.png';
import tt from '../assets/home/tt.png';
import GlobNavbar from '../components/GlobNavbar';

const Home = () => {
  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', `https://embed.tour.video/leasemagnets.js`);
    s.setAttribute('async', 'async');
    s.setAttribute('defer', 'defer');

    // s.addEventListener(
    //   'load',
    //   () =>
    //     window.LeaseMagnets &&
    //     window.LeaseMagnets({
    //       uuid: '0d8aa956-2d8d-4254-b018-15c1672c5d9a',
    //       primaryColor: '#4285f4',
    //     })
    // );
    s.addEventListener('error', (e) => console.error(e));

    document.body.appendChild(s);

    return () => {
      window.destroyLeaseMagnets && window.destroyLeaseMagnets();
      document.body.removeChild(s); // Clean up
    };
  }, []);
  const history = useHistory();
  const [active, setActive] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [tactive, setTactive] = useState(false);

  const openScheduleTour = () => {
    window.setLeaseMagnetsOpen(true);
    window.setLeaseMagnetsScre('thank_you.customer_support');
  };

  const communitySliderData = [
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655083024/Brand%20Agent%20Mugshots/Marshall%20Louisville%20-%20Bryce.png',
      caption: '25k Tours',
      communityName: 'Marshall Louisville',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141998/039-the_marshall_louisville_7_20_2020-internetquality_result_uatfof.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655084266/Brand%20Agent%20Mugshots/Hawks_Landing_-_Alex_s4v1rx.png',
      caption: '1.4k International Tours',
      communityName: 'Hawks Landing',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655144118/5bf47a057e8680.26594087778_cj5vxo.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655084693/Brand%20Agent%20Mugshots/Campus_Walk_-_Haley_tlyqfi.png',
      caption: '$70k+ Revenue Generated from Toru',
      communityName: 'Campus Walk',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143786/5f12048951fce3.10347949895_rkjyga.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085097/Brand%20Agent%20Mugshots/Latitude_-_Bri_Anna_wqjgui.png',
      caption: '21k Tours',
      communityName: 'Latitude',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143730/5e62ab48abb332.43947600854_jd0d3b.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085639/Brand%20Agent%20Mugshots/Noble_2500_-_Zac_b1pbiy.png',
      caption: '$85k Revenue Generated',
      communityName: 'Noble 2500',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143677/NOB_Exterior_2K_spksk6_lmg5xy.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655086010/Brand%20Agent%20Mugshots/CastleRock_San_Marcos_-_Kiara_p5mslg.png',
      caption: '15k Tours + $300k Revenue Generated',
      communityName: 'CastleRock San Marcos',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143615/625da1f4b79446.32908626135_tetzrg.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655086409/Brand%20Agent%20Mugshots/Paloma_-_Miranda_tcxe4b.png',
      caption: '3k Tours +  $220k Revenue Generated',
      communityName: 'Paloma West Midtown',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143560/603812302e0b73.51177528925_c7hi1j.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655087084/Brand%20Agent%20Mugshots/One_Park_-_Taryn_opdnaw.png',
      caption: '7.4k Tours + $850k Revenue Generated',
      communityName: 'One Park',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143522/One-Park-Condos-DMG-Investments_zqdzch.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655087284/Brand%20Agent%20Mugshots/Lodges_at_777_-_Taylor_l1okvc.png',
      caption: '18k Tours',
      communityName: 'Lodges at 777',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143465/pool2_y9jy52.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655087850/Brand%20Agent%20Mugshots/HWH_-_Kerri_nrbstd.png',
      caption: '12k Tours + $550k Revenue Generated',
      communityName: 'HWH Luxury Living',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143435/community-amenities_ixpxu5.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655088633/Brand%20Agent%20Mugshots/Douglas_-_Kaila_yzn0vr.png',
      caption: '125% increase in conversion',
      communityName: 'Douglas Heights',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143398/5f5f947526b515.79558872329_bklu00.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655089783/Brand%20Agent%20Mugshots/Arba_-_Amy_xrmkh5.png',
      caption: '150% increase in conversion',
      communityName: 'Arba',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143366/5f4420df516003.24687965551_ebdwuf.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655089656/Brand%20Agent%20Mugshots/The_Rowan_-_Karitza_mkkfci.png',
      caption: '$129k in Revenue generated',
      communityName: 'The Rowan',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143305/rockland-3_v43vos.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655089289/Brand%20Agent%20Mugshots/The_Rockland_mfqxuv.png',
      caption: '120% Conversion Rate',
      communityName: 'The Rockland',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143305/rockland-3_v43vos.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655088986/Brand%20Agent%20Mugshots/The_Hue_-_Sam_fprgrp.png',
      caption: '$40k Revenue Generated',
      communityName: 'The Hue',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143264/6283a5a96fc603.51172537792_g8gios.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655096786/Brand%20Agent%20Mugshots/AOC_-_Leset_uuklce.png',
      caption: '5.5k Tours + $40k in Leadgen',
      communityName: 'The Academy on Charles',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143216/0001_0_Tenant-Placement-Baltimore-2-Edit-1-562.5x0-c-default_bcp926.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655097353/Brand%20Agent%20Mugshots/The_VIllages_Chandler_-_Andrew_y1am3x.png',
      caption: '6.2k Tours + $241k in Leadgen',
      communityName: 'The Village at Chandler Crossings',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141919/the-village-at-chandler-crossings-east-lansing-mi-building-photo_nm67in.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655097571/Brand%20Agent%20Mugshots/Edge_on_Euclid_-_Jacob_wjm6oh.png',
      caption: '6.2k Tours + 340 5-Star Testimonials',
      communityName: 'The Edge on Euclid',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143123/5c756332559276.63022649488_phtrkl.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085400/WXJi5BW_vgc3e6.jpg',
      caption: '$400k Revenue from Tour',
      communityName: 'Desai Accelerator',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143090/Desai-new-space-2_fxhgt5.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655096128/HCC_-_Tacara_mgysmu.png',
      caption: '$200k Revenue from Tour',
      communityName: 'HCC College',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143050/Large-Brandon_2018-001529_thf9fp.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655099031/Forward_Medical_quw70u.png',
      caption: '$240k generated + 340 Reviews',
      communityName: 'Forward Medical',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143019/why-forward-body-scanner-male-doctor-female-member-min_tacedd.webp',
      tourLink: '',
    },
    {
      Bonus:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655085332/angelaTYG_loknqp.png',
      agent:
        'https://coursereport-s3-production.global.ssl.fastly.net/uploads/school/logo/408/original/Bloom_Institute_of_Technology_logo.png',
      caption: '210% Conversion Rate & Hired 2 Folks',
      communityName: 'Lambda School/ Bloomtech',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142865/617b1ac6907260434b4793bb_615de902d7456701a5899fcf_5e4c592dccf29d1e8548503b_higher-starting-salary-02_nicwsd.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://is3-ssl.mzstatic.com/image/thumb/Purple124/v4/b1/42/67/b14267fc-6c0b-e555-2cef-dbca440d1140/source/512x512bb.jpg',
      caption: '150% increase in Conversion',
      communityName: 'PNOE',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142821/PNOE_Cover_rlsjc8_cybj4k.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10442.png',
      caption: '8k Onboarded',
      communityName: 'DESO',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142781/DESO_Cover_v81txv_njqijb.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655100533/Creator.App_-_Kevin_k6nutl.png',
      caption: '140%+ Onboarding increase + 215.7k Followers',
      communityName: 'Creator.app',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142749/Creator.App_Cover_qoidph_drqhaf.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/sshwc4pe2wfsfsm102id',
      caption: '210% increase in conversion',
      communityName: 'RAMP',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142710/ramp-og-all-pages_vss8z8.webp',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655101468/WeWork_Mugshot_ktju6g.png',
      caption: 'Onboarded 15k new Engineers',
      communityName: 'WeWork',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142676/Web_150DPI-DSCF1370_lnzzw6.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655102122/UMich_-_Mugshot_ae0tgz.png',
      caption: '124%+ in Conversion',
      communityName: 'University of Michigan',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142643/UMich_-_Cover_dfbv3k_dldp7c.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655102768/Saastr_Mug_noca16.png',
      caption: 'Onboarded 7k Community',
      communityName: 'SaaStr',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142604/SaaStr-Home-Featured_lcfbui.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655103105/Brand%20Agent%20Mugshots/X_Chicago_-_Edmund_yqfa5f.png',
      caption: '150% in Conversion + $440k in Sales',
      communityName: 'X Communities',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141934/x-chicago-chicago-il-primary-photo_dgsnqk.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655103726/Brand%20Agent%20Mugshots/Two_East_Oak_bsqhr8.png',
      caption: '12k Tours + $440k in Sales',
      communityName: 'Two East Oak',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141940/two-east-oak-chicago-il-primary-photo_xpnyv3.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655103841/YC_Mug_tcaykh.png',
      caption: '150% in Onboarding Conversion',
      communityName: 'YC',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142555/80-50fb305324734ba4162e0e275dc1ff18486cd0d661aa58c36fc5b911b718b60f_dn04nm.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655104767/Brand%20Agent%20Mugshots/IV_Oxford_Mug_gu0bjt.png',
      caption: '$1.2m in Tour-driven Sales',
      communityName: 'Independence Village Oxford',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142445/IV_Oxford_Cover_s5xvjh_heir2q.png',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655106327/Brand%20Agent%20Mugshots/The_George_Mug_twntlg.png',
      caption: '4k Tours and $800k in Tour-driven Sales',
      communityName: 'The George',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655142379/MI_AnnArbor_TheGeorge_p0943625_49_DSC0478_1_PhotoGallery_iyamqv.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent:
        'https://res.cloudinary.com/dlmowqiof/image/upload/v1655106533/Brand%20Agent%20Mugshots/Park_Place_Mug_upgmin.png',
      caption: '2k Tours and $750k in Tour-driven Sales',
      communityName: 'Park Place',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655141946/park-place-northville-northville-mi-primary-photo_rnswzg.jpg',
      tourLink: '',
    },
    {
      Bonus: '',
      agent: 'https://speechify.com/wp-content/uploads/2022/05/Cliff.png',
      caption: '89%+ in Conversion',
      communityName: 'Speechify',
      cover:
        'https://res.cloudinary.com/dlmowqiof/image/upload/c_scale,w_500/v1655143118/Speechify_Cover_gdzyyl.png',
      tourLink: '',
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const executeScroll = () => ourResultsRefTYG.current.scrollIntoView();
  const ourResultsRefTYG = useRef(null);

  const [person, setPerson] = useState(false);
  React.useEffect(() => {
    let header = document.getElementById('showheader');
    document.getElementById('header_box').onmouseover = function callheader() {
      header.style.display = 'block';
    };

    document.getElementById('showheader').onmouseover = function callheader() {
      header.style.display = 'block';
    };
    document.getElementById('showheader').onmouseout = function callheader() {
      header.style.display = 'none';
    };

    document.getElementById('header_box').onmouseout = function callheader() {
      header.style.display = 'none';
    };
  }, []);

  let testimonials = [
    {
      name: 'Brian Nalezny',
      position: 'Regional Manager, PeakMade',
      review:
        'Tour is easy to work with and extremely receptive. They took my suggestion to add a Mandarin tour option and immediately came back with an updated product for us. We are in a beneficial partnership that has translated to an immediate increase in leads at properties where we use the tool',
      image: t1,
      cimg: tl1,
    },
    {
      name: 'Andrea Arbuckle',
      position: 'Regional Leasing/Marketing Manager, Landmark Properties',
      review:
        "Easy to use and effective. I feel better knowing that I'm in control of who can see my data.",
      image: t2,
      cimg: tl2,
    },
    {
      name: 'Amanda Kopko',
      position: 'McKinney Properties',
      review:
        '...This is what we use Tour for. It helps with the introduction to our website and allows someone browsing apartments at 1 am to feel like they are actually walking in the door. It gives them an in person experience before they even pick up the phone.',
      image: t3,
      cimg: tl3,
    },
    {
      name: 'KrisAnn Kizerr',
      position: 'Director, Pierce Education Properties',
      review:
        '“Tour is a great way to offer a creative solution to that problem. We now have properties where 30-40% of the people that sign have never toured in person. Their first time seeing the property will be move-in day.”',
      image: t4,
      cimg: tl4,
    },
    {
      name: 'David Ramos',
      position: 'DMG Investments',
      review:
        '“Huge fan of what you guys are building and think there’s a lot of fit outside of the property management space. I could see luxury realtors/brokers using the tool!”',
      image: t5,
      cimg: tl5,
    },
    {
      name: 'TjChambers',
      position: 'Chambers REA / EVP at Asset Living',
      review:
        '“These guys are building the tour video digital marketing platform of the future — I’ve recommended them to countless clients already!”',
      image: t6,
      cimg: tl6,
    },
  ];

  return (
    <div className=" w-full min-h-screen relative overflow-x-hidden">
      <div className=" w-full bg-black  relative z-30">
        <div className="container relative">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <h1 className="font_uber py-4 text-2xl cursor-pointer text-white">
                RPM Joy
              </h1>
              <div className=" relative  pl-10 flex items-center justify-center gap-8">
                <Link
                  to="/"
                  id="header_box"
                  className=" flex items-center py-4  relative justify-center gap-1 text-white font-light "
                >
                  <CgMenuGridO /> Product
                </Link>
                <Link
                  onClick={() => executeScroll()}
                  to="#ourResultsTYG"
                  className=" flex items-center py-4 justify-center gap-1 text-white font-light "
                >
                  Results
                </Link>
              </div>
            </div>
            <div className=" flex items-center py-4 gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div id="showheader" className=" absolute w-full  top-16 left-0">
            <GlobNavbar />
          </div>
          {toggle && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={
            !toggle
              ? {
                  height: '100vh',
                  borderTop: '1px solid rgba(255,255,255,0.3)',
                  background: '#000',
                  overflowY: 'scroll',
                  zIndex: '9999999999',
                }
              : { height: '0px', overflow: ' hidden' }
          }
          className="fixed top-0  bg-black left-0 transition-all  ease-linear   duration-300  w-full block lg:hidden"
        >
          {toggle === false && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
          <div className=" px-5">
            <div
              onClick={() => setTactive(!tactive)}
              className=" flex cursor-pointer items-center justify-between w-full pb-3 text-3xl     gap-1 text-white font_uber pt-10"
            >
              Product
              {tactive === false ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
            {tactive && (
              <div className=" w-full mt-3">
                <div className=" w-full  shadow-2xl h-full flex flex-col py-4 gap-10">
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      USE CASES
                    </h6>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img src={tt} className=" w-7 h-7 object-cover" alt="" />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Tour & Triggers{' '}
                        </h6>
                        <p className="text-xs text-gray-200">
                          Make it easy to build an embeddable & shareable tour
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--engagement-bc48a36991ab0b915c148bd61c6c1d06.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Trust & Testimonials
                        </h6>
                        <p className="text-xs text-gray-200">
                          Build more trust through testimonials
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--marketing-0fc6ec0aeab50485489ad1f6da1a1e5d.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Targeting & Retargeting
                        </h6>
                        <p className="text-xs text-gray-200">
                          Create targeted specials and online campaigns
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      LEARN
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="https://tourversity.com"> Welcome Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="https://tourversity.com"> Tour Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="https://tourversity.com"> Analytics</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="https://tourversity.com"> Retargeting</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="https://tourversity.com">
                        {' '}
                        CTA’s and Triggers
                      </Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="https://tourversity.com"> Integrations</Link>
                    </h6>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      INDUSTRIES
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/home"> Real Estate</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Universities</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Senior living & Healthcare</Link>
                    </h6>
                  </div>

                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      WATCH A DEMO
                    </h6>
                    <div className=" mt-3">
                      <img src={ss} className=" h-20 object-cover " alt="" />
                    </div>
                    <p className=" text-gray-200 text-sm py-2">
                      Learn from a professional what makes <br />
                      Tour work behind the scenes
                    </p>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform font-medium flex items-center gap-24 transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <Link to="#" onClick={() => openScheduleTour()}>
                        {' '}
                        BOOK A DEMO
                      </Link>
                      <HiArrowRight className=" w-5 h-5" />
                    </h6>
                  </div>
                </div>
              </div>
            )}
            <Link
              onClick={() => executeScroll()}
              style={{ borderTop: '1px solid rgba(255,255,255,0.3)' }}
              to="#ourResultsTYG"
              className=" flex items-center  text-3xl d justify-between pt-3  gap-1 text-white font_uber "
            >
              Results
              <p></p>
            </Link>
          </div>
        </div>
      </div>
      <div className=" container">
        <div className=" relative w-full h-full mt-10 rounded-2xl overflow-hidden">
          {/* <img src="https://res.cloudinary.com/kingdomkid/image/upload/v1655051910/build_tour_sample_TYG_2_uwvm0a.png"
        className=" relative "
        width="100%"
        style={{ objectFit: 'cover', height: '669.71px' }}
         /> */}
          <video
            className=" relative rounded-2xl"
            width="100%"
            style={{ objectFit: 'cover', height: '630px' }}
            loop={true}
            autoPlay={true}
            muted={true}
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/leasemagnets---dummy-db.appspot.com/o/staticfilesTYG%2FrpmBannerTYG.mp4?alt=media&token=51c0028e-c5cd-453d-8326-c8eef46159bd"
              type="video/mp4"
            />
          </video>

          <div
            className=" absolute top-0 left-0 z-10 w-full rounded-2xl overflow-hidden h-full flex items-center justify-start flex-col"
            style={{
              backgroundImage:
                'linear-gradient(270deg, rgba(0, 0, 0, 0.4) 10%,rgba(0, 0, 0, 0.8) 99.56%)',
            }}
          >
            <div className=" absolute top-40 md:top-52 left-0 w-full h-full z-20 ">
              <div className=" container pl-4 lg:pl-0 ">
                <h1 className=" text-4xl text-white font_uber text-left">
                  Build a scalable RPM program® <br />
                  that scales with your clinic
                </h1>
                <p className=" text-white text-sm pt-2">
                  <h2 className="text-lg mt-[-15px] mb-2	">
                    that both  {' '}
                    <span className=" underline">you and your patients love</span>!
                  </h2>
                </p>

                <div className=" flex items-start lg:items-center lg:gap-3 text-white text-xl font_uber flex-col lg:flex-row">
                  <Link to="/book-demo">
                    <button className=" mt-4 px-3 text-sm py-3 flex items-center gap-2 hover:bg-hvr bg-pr rounded-sm text-white">
                      <BsCameraVideoFill /> Book personalized 1-1 demo
                    </button>
                  </Link>
                  <Link to="/signin">
                    <button className=" mt-4 px-3 font-light text-sm text-black py-3 flex items-center gap-2 hover:bg-gray-100 bg-gray-50 rounded-sm ">
                      <img
                        src={google}
                        className=" w-6 h-6 object-cover"
                        alt=""
                      />{' '}
                      Try RPM Joy for 30 days
                    </button>
                  </Link>
                </div>
                <div className=" mt-16 flex items-center gap-4">
                  <div className=" flex items-center gap-0">
                    {communitySliderData.slice(0, 6).map((item, ind) => (
                      <div
                        className={
                          ind !== 0
                            ? ' w-7 h-7 rounded-full relative overflow-hidden  -ml-1'
                            : ' w-7 h-7 overflow-hidden relative rounded-full '
                        }
                      >
                        <img
                          className=" w-full h-full object-cover"
                          src={item?.agent}
                          alt=""
                        />
                        <div
                          className=" absolute top-0 left-0 w-full h-full rounded-full z-20"
                          style={{ background: 'rgba(0,0,0,0.3)' }}
                        ></div>
                      </div>
                    ))}
                  </div>
                  <p className=" text-white">
                    Loved by 200+ patients and clinics
                  </p>
                </div>
              </div>
            </div>

            {/* <iframe
            width="1200px"
            height="800px"
            style={{ marginRght: '30px', marginTop: '120px' }}
            className="w-50 mr-30 mt-30 rounded-md hidden lg:block"
            src="https://embed.tour.video/?uuid=0d8aa956-2d8d-4254-b018-15c1672c5d9a&inline=true&screen=intro.main_intro_2&inlineCTA=Take%20a%20tour&hideButtons=false&tourMsg=undefined&primaryColor=%234399DF"
          /> */}
            {/* <div className=" px-2 block lg:hidden w-full py-10 absolute z-30 -bottom-36 left-0">
            <Slider {...settings2}>
              {[1, 2, 4, 5, 6, 7, 8, 8].map((item) => (
                <div className=" relative  overflow-hidden  border rounded-sm w-full">
                  <div className=" relative hvr_parent">
                    <img
                      src="https://assets.website-files.com/6114bc308fac2d36f9df818c/611525f6cd52a76e755963c3_one-park-full-p-500.jpeg"
                      className="min_h_pic hvr_pic"
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                      }}
                      alt=""industries
                    />
                    <div className=" w-full h-full cursor-pointer absolute top-0 left-0 hvr_bg z-10"></div>
                    <p className=" absolute left-0 text-center w-full z-20  text-white text-sm font-light hvr_text ">
                      Lorem ipsum dolor sit amet{' '}
                    </p>
                    <div className=" w-full flex absolute top-1 left-1 z-20 items-center justify-between pl-2 py-1">
                      <div className=" flex items-center gap-2 cursor-pointer text-white">
                        <h5 className=" text-white text-lg ">Card title</h5>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div> */}
          </div>
        </div>
      </div>

      <div className=" container">{TourFunctions()}</div>

      <div className=" container px-2 lg:px-0  ">
        {/* ------------------------ */}
        {/* <div className=' w-full p-10 mt-10 lg:mt-20 section_bg"'>
          <h1 className=" font_uber text-4xl pt-10 font-semibold">
            Gather your clips, then simply edit and share
          </h1>
          <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 pt-16">
            <div>
              <button className=" text-xs sm_bg px-2 py-1 font_uber font-semibold rounded-xl text-pr ">
                Ask
              </button>
              <h6 className=" text-gray-500 font-medium  py-2 ">
                Add your questions and send to one - or many - with a single
                link
              </h6>
              <div className=" mt-1">
                <img
                  className=" w-full h-full object-cover"
                  src="https://uploads-ssl.webflow.com/624a8047186ec7b0bd415684/6285d36daf03d343583e61f5_Ask%20away-p-500.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <button className=" text-xs sm_bg px-2 py-1 font_uber font-semibold rounded-xl text-pr ">
                Collect
              </button>
              <h6 className=" text-gray-500 font-medium  py-2 ">
                Review your video responses and transcripts
              </h6>
              <div className=" mt-1">
                <img
                  className=" w-full h-full object-cover"
                  src="https://uploads-ssl.webflow.com/624a8047186ec7b0bd415684/6285d387d338c34d458093d7_Take%20your%20pick-p-500.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <button className=" text-xs sm_bg px-2 py-1 font_uber font-semibold rounded-xl text-pr ">
                Edit
              </button>
              <h6 className=" text-gray-500 font-medium  py-2 ">
                Trim your videos or stitch them together to create a playlist
              </h6>
              <div className=" mt-1">
                <img
                  className=" w-full h-full object-cover"
                  src="https://uploads-ssl.webflow.com/624a8047186ec7b0bd415684/6285d3afb99d1d641b360c86_Edit%20to%20perfection-p-500.png"
                  alt=""
                />
              </div>
            </div>
            <div>
              <button className=" text-xs sm_bg px-2 py-1 font_uber font-semibold rounded-xl text-pr ">
                Share
              </button>
              <h6 className=" text-gray-500 font-medium  py-2 ">
                Share your Vouches via a link, embed code or an integration
              </h6>
              <div className=" mt-1">
                <img
                  className=" w-full h-full object-cover"
                  src="https://uploads-ssl.webflow.com/624a8047186ec7b0bd415684/6285d6f3bd3a907b7990509d_Agree%20on%20best%20candidates-p-500.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className=" bg-gray-100 rounded-xl ">
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10 lg:mt-20 pt-5 px-5">
            <h1 data-aos="fade-up" className=" text-5xl font_uber">
              The definition of <br /> the modern tour <br /> is changing.
            </h1>
            <p data-aos="fade-up" className=" font_uber text-lg pt-3">
              The modern labor market dictates its own terms. <br /> Today, to
              be a competitive specialist requires more <br /> than professional
              skills.
            </p>
          </div>
          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-10 mt-20 mb-10  rounded-md ">
            <div className=" px-5 pb-10 lg:pb-0">
              <div
                className=" relative h-96 "
                style={{ borderLeft: '1px dashed rgba(30,30,47,0.6)' }}
              >
                <div className=" absolute -top-3 -left-10 h-full w-full">
                  <div className=" flex items-center gap-3">
                    <div className=" bg-gray-200 p-2 rounded-full">
                      <div className=" bg-white h-16 w-16 shadow-sm flex items-center justify-center rounded-full">
                        <img
                          className=" h-6 w-6 object-cover"
                          src="https://assets.website-files.com/617fa48948c7ab24b715140e/617fa550b1eff7681300db93_ico_skills-leadership.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <h1 className=" font_uber text-2xl text-gray-700">
                        24x7 Tour
                      </h1>
                      <p className=" text-sm  text-gray-600 ">
                        Fully commited to <br /> the success company.
                      </p>
                    </div>
                  </div>
                  <div className=" flex items-center gap-3 mt-20">
                    <div className=" bg-gray-200 p-2 rounded-full">
                      <div className=" bg-white h-16 w-16 shadow-sm flex items-center justify-center rounded-full">
                        <img
                          className=" h-6 w-6 object-cover"
                          src="https://assets.website-files.com/617fa48948c7ab24b715140e/617fa5500e03d92bc1747cb2_ico_skills-responsibility.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <h1 className=" font_uber text-2xl text-gray-700">
                        Trust and Testimonials
                      </h1>
                      <p className=" text-sm  text-gray-600">
                        Employees will always <br /> be my top priority
                      </p>
                    </div>
                  </div>
                  <div className=" flex items-center gap-3 mt-20">
                    <div className=" bg-gray-200 p-2 rounded-full">
                      <div className=" bg-white h-16 w-16 shadow-sm flex items-center justify-center rounded-full">
                        <img
                          className=" h-6 w-6 object-cover"
                          src="https://assets.website-files.com/617fa48948c7ab24b715140e/617fa5506ec5691cba88bb5b_ico_skills-flexibility.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <h1 className="font_uber text-2xl text-gray-700">
                        Flexibility
                      </h1>
                      <p className="  text-sm  text-gray-600">
                        The ability to switch is <br /> an important skill
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" lg:col-span-2 relative">
                       
              <iframe
                className=" relative rounded-2xl"
                width="100%"
                style={{ objectFit: 'cover', height: '800px' }}
                src="https://embed.tour.video/?uuid=0d8aa956-2d8d-4254-b018-15c1672c5d9a&inline=true&screen=intro.main_intro_2&inlineCTA=Take%20a%20tour&hideButtons=false&tourMsg=undefined&primaryColor=%234399DF"
              />
              <div className=" absolute -top-16 right-10 bg-pr hidden lg:flex w-2/3 z-20 p-10 divide-x gap-10 text-white rounded-md items-center ">
                <div className=" flex items-center gap-3">
                  <h1 className=" text-4xl font_uber">850k+</h1>
                  <p className=" text-sm">
                    Tours <br /> delivered
                  </p>
                </div>
                <div className=" flex items-center gap-3 pl-0">
                  <h1 className=" text-4xl font_uber ml-4">$4.1M</h1>
                  <p className=" text-sm">
                    Sales
                    <br /> enabled
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
   </div>
      <div className=" bg-black mt-20 py-16">
        <h1 className=" text-center text-3xl text-white w-full font_uber">
          Book a demo with us & <br />
           let's plan your  RPM program <br />
        </h1>
        <p className=" text-sm pt-2 text-white text-center ">
        to enable better patient outcomes and more revenue for your clinic
        </p>
        <div className=" flex  justify-center items-center lg:gap-3 text-white text-xl  font_uber w-full flex-col lg:flex-row">
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2 hover:bg-hvr bg-pr rounded-sm text-white">
            <BsCameraVideoFill /> Book personalized 1-1 demo
          </button>
          <button className=" mt-4 px-3 font-thin text-sm py-3 flex items-center gap-2 hover:bg-gray-800 bg-black rounded-sm text-white">
            <img src={google} className=" w-6 h-6 object-cover" alt="" /> Build
            your tour for free
          </button>
        </div>
        <div className=" container pt-16 pb-4">
          <div className=" grid grid-cols-1 gap-5 pl-5 lg:pl-0 lg:grid-cols-4">
            <div className=" lg:col-span-2">
              <h1 className=" text-2xl text-white">RPM Joy</h1>
              <p className=" text-gray-400 text-sm">39135 Zofia Avenue</p>
              <p className=" text-gray-400 text-sm">
                Sterling Heights MI 48313
              </p>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">🏢 Business</h6>
              <Link className=" text-gray-400 text-sm font_uber mt-2" to="/">
                Terms and conditions
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Reviews
              </Link>
              <Link className=" text-gray-400 text-sm font_uber" to="/">
                Contact
              </Link>
            </div>
            <div className=" flex flex-col items-start">
              <h6 className=" text-white font-medium font_uber">👋 Support</h6>
              <p className=" text-gray-400 text-sm pt-2">
                📞 +1 (586) 258-8588
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function TourFunctions() {
    const [imgActive, setImgActive] = useState([]);

    return (
      <div className=" pt-10 pb-6 px-3 lg:px-0">
        {/* <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-4">
    <div
      onMouseOver={() => setImgActive(0)}
      className={
        imgActive === 0
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 0
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Concierge not Chatbot
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 0 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
    <div
      onMouseOver={() => setImgActive(3)}
      className={
        imgActive === 3
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 3
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Targeting & Tour Analytics
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 3 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
    <div
      onMouseOver={() => setImgActive(1)}
      className={
        imgActive === 1
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 1
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Targeting & Tour Analytics
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 1 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
    <div
      onMouseOver={() => setImgActive(2)}
      className={
        imgActive === 2
          ? ' px-3 py-6 border rounded-2xl cursor-pointer'
          : ' px-3 py-6 hover:border cursor-pointer rounded-2xl'
      }
    >
      <button
        className={
          imgActive === 2
            ? ' sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
            : ' bg-gray-100 text-gray-500 font_uber text-sm px-2 font-semibold py-1 rounded-3xl'
        }
      >
        Targeting & Tour Analytics
      </button>
      <p className=" text-gray-500 text-xs pt-3">
        Deliver a 1:1 personally guided tour with your best leasing
        consultant, right from your homepage.
      </p>
      {imgActive === 2 && (
        <div className="flex items-center justify-between text-sm pt-5 font-medium">
          Start converting more of your visitors
          <HiOutlineArrowRight className="w-5 h-5" />
        </div>
      )}
    </div>
          </div> */}

        <h1 className=" font_uber text-3xl">
          Preventative remote patient monitoring  is changing, and it breaks down into 4 simple steps.
        </h1>
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 pt-4">
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Explore
            </button>
            <p className=" text-gray-500 pt-2">
              Find the RPM health tools that match your patients current needs
            </p>
          </div>
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Collect
            </button>
            <p className=" text-gray-500 pt-2">
              Automatically collect and track the status with equipment shipped to your patients home
            </p>
          </div>
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Analyze
            </button>
            <p className=" text-gray-500 pt-2">
              Analyze and share trends with your doctor and staff to monitor a patients trends easily 
            </p>
          </div>
          <div>
            <button className=" sm_bg text-pr font_uber text-sm px-2 font-semibold py-1 rounded-3xl">
              Reimburse
            </button>
            <p className=" text-gray-500 pt-2">
              Share logs of your patients report with insurance to claim monthly reimbursements
            </p>
          </div>
        </div>

        {/* <div>
        {imgActive === 0 && <img className='transition-opacity ease-in duration-700 opacity-100 "' src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515249/Group_113_1_bbf1oc.png'} alt="" />}
        {imgActive === 1 && <img src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515023/Group_107_z5avdz.png'} alt="" />}
        {imgActive === 2 && <img src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656515008/Group_109_emiky0.png'} alt="" />}
        {imgActive === 3 && <img src={'https://res.cloudinary.com/dlmowqiof/image/upload/v1656514993/Group_112_gvajzn.png'} alt="" />}
      </div> */}
      </div>
    );
  }
};

export default Home;
