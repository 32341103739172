import axios from 'axios';
import { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { API_HOST } from '../../utils/request';

const SCOPE = ['https://www.googleapis.com/auth/driveconst'];

export default function GPickerButton({ cloudLibrary, setCloudLibrary }) {
  const [videos, setVideos] = useState([]);

  const [openPicker, data, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  // const temp1 = gpick();
  const handleOpenPicker = () => {
    openPicker({
      clientId:
        '615679282659-rja4q91f2mr68h3bka1i4aqiaijjnoe0.apps.googleusercontent.com',
      developerKey: 'AIzaSyBpcXP_H0aa66uK6yqTD-Llozo1NUNVsOY',
      viewId: 'PDFS',
      // token: token, // pass oauth token in case you already have one
      // showUploadView: true,
      // showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      setIncludeFolders: false,
      // disableDefaultView: true,
      viewMimeTypes: [
        'application/vnd.google-apps.video',
        'application/vnd.google-apps.folder',
        'application/vnd.google-apps.photo',
      ],
      // customViews: customViewsArray, // custom view
    });
  };
  // https://stackoverflow.com/questions/59294233/google-drive-picker-api-show-folders-and-certain-mime-types-files

  async function uploadtoDrive(video) {
    try {
      const response = await axios({
        method: 'POST',
        url: API_HOST + '/upload-drive',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          fileID: video.id,
          mimeType: video.mimeType,
          filename: video.name,
          access_token: authResponse.access_token,
        },
      });
      console.log(response);
      //add it to videos
      setCloudLibrary((prevList) => [
        ...prevList,
        {
          name: video.name,
          type: video.mimeType,
          origName: video.name,
          dataURL: response.data + '?',
          formData: { video: response.data + '?' },
          saveToCloud: true,
        },
      ]);
    } catch (e) {
      console.log('error = ', e);
    }
  }
  useEffect(() => {
    // do anything with the selected/uploaded files
    if (data) {
      setVideos([]);
      data.docs.map((video) => {
        console.log(video);
        setVideos((prevVideos) => [...prevVideos, video]);
      });
    }
  }, [data]);

  useEffect(() => {
    if (videos) {
      videos.map((video) => {
        uploadtoDrive(video);
      });
    }
  }, [videos]);
  return (
    <>
      <button
        className="cleanbtn google-sheet-btn flex aic"
        onClick={() => handleOpenPicker()}
      >
        <img
          className="img"
          src={require('../../assets/google-drive.png').default}
        />
        <div className="font s14 b5 c333">Upload From Google Drive</div>
      </button>
    </>
  );
}
