import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import EditButton from '../../components/Reusable/EditButton';
import GPickerButton from '../../components/Reusable/GPickerButton';
import SimpleAccordion from '../../components/SimpleAccordion';
import { API_HOST } from '../../utils/request';
import './DragAndDrop.css';
import Gallery from './Gallery';
import PreviewVideoMagnet from './PreviewVideoMagnet';
//wave.video API key
const ApiKey = 'e22b7760dc19085e5ae07a55';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

export default function DragAndDrop({
  currVid,
  setCurrVid,
  screen,
  category,
  setFileToUpload,
  handleUpdateProjectID,
  projectID,
  setProjectID,
  definedProjectID,
  setDefinedProjectID,
  isEncoding,
  setIsEncoding,
  buttons,
  centerButtons,
  bottomButtons,
  caption,
  location,
  formTemplate,
  iframeOptions,
  shareInfo,
  cloudLibrary,
  setCloudLibrary,
  cloudLibraryProgress,
  handlePushCloudLibrary,
}) {
  const {
    screenToEdit,
    loadScreen,
    magnetId,
    magnetType,
    potentiallyUploading,
  } = useSelector((state) => state.magnetStates);
  const { template } = useSelector((state) => state.getMagnet);

  const _dispatch = useDispatch();
  // All Uploaded videos
  const [value, setValue] = useState('upload'); // current tab, upload or edit
  const [alert, setAlert] = useState();

  // Set videos and currVid based on the template  if the screen is already created
  useEffect(() => {
    if (screen !== 'new') {
      setCurrVid([
        {
          source:
            template &&
            template['categories'][category]['screens'][screen]['video'],
          type: 'video/mp4',
          formData: '',
        },
      ]);
    }

    if (screen === 'new') {
      setCurrVid([
        {
          source:
            'https://storage.googleapis.com/leasemagnets-video-tyg/black-TYG.mp4',
          type: 'video/mp4',
          formData: '',
        },
      ]);
    }
  }, []);

  function fileExists(inFileData) {
    setAlert();
    if (
      cloudLibrary.some((element) => {
        return inFileData === element.dataURL;
      })
    ) {
      setAlert({
        text: 'ERROR: Video already uploaded',
        severity: 'error',
      });
      return true;
    } else {
      return false;
    }
  }

  // Keep track of current main video (selected video)
  // const [currVid, setCurrVid] = useState([]);

  // Convert uploaded file to data URL
  function readFile(e) {
    e.preventDefault();
    // let reader = new FileReader();
    const files = e.target.files;

    if (!potentiallyUploading) {
      _dispatch({ type: 'POTENTIALLY_UPLOADING', payload: 0 });
    }

    //loop through all the dropped files
    for (let i = 0; i < files.length; ++i) {
      //Get video name
      const name = files[i].name;
      const type = files[i].type;
      _dispatch({
        type: 'ADD_SCREEN',
        payload: [
          ...loadScreen,
          {
            name: files[i].name,
            type: files[i].type,
            size: files[i].size / 1000000,
            route: `${category}.${screen}`,
          },
        ],
      });

      //Convert file into a data url
      const dataURL = URL.createObjectURL(files[i]);
      // //Sending file to google cloud platform
      const formData = new FormData();
      formData.append('video', files[i]);

      //filter out non-videos
      if (type === 'video/mp4' || type === 'video/quicktime') {
        //add it to videos
        setCloudLibrary((prevList) => [
          ...prevList,
          {
            name: name,
            type: type,
            origName: name,
            dataURL: dataURL,
            formData: formData,
            saveToCloud: true,
          },
        ]);

        setCurrVid([
          { name: name, type: type, source: dataURL, formData: formData },
        ]);
      }
    }
  }

  // Difference between FileReader and URL createObjectURL https://stackoverflow.com/questions/31742072/filereader-vs-window-url-createobjecturl
  // Convert dropped files into blobs
  async function handleDrop(files) {
    if (!potentiallyUploading) {
      _dispatch({ type: 'POTENTIALLY_UPLOADING', payload: 0 });
    }

    //loop through all the dropped files
    for (let i = 0; i < files.length; ++i) {
      _dispatch({
        type: 'POTENTIALLY_UPLOADING',
        payload: potentiallyUploading + 1,
      });

      //Get video name
      const name = files[i].name;
      //Get file type (make sure it's a video)
      // files[i].type = "video/mp4"
      const type = files[i].type;
      //Convert file into a data url
      const dataURL = URL.createObjectURL(files[i]);
      // //Sending file to google cloud platform
      const formData = new FormData();
      formData.append('video', files[i]);

      _dispatch({
        type: 'ADD_SCREEN',
        payload: [
          ...loadScreen,
          {
            name: files[i].name,
            type: files[i].type,
            size: files[i].size / 1000000,
            route: `${category}.${screen}`,
          },
        ],
      });

      //filter out non-videos
      if (
        type === 'video/mp4' ||
        type === 'video/webm' ||
        type === 'video/quicktime'
      ) {
        //add it to videos
        setCloudLibrary((prevList) => [
          ...prevList,
          {
            name: name,
            type: type,
            origName: name,
            dataURL: dataURL,
            formData: formData,
            saveToCloud: true,
          },
        ]);

        setCurrVid([
          { name: name, type: type, source: dataURL, formData: formData },
        ]);
      }
    }
  }

  const load = (apiKey) => {
    (function (d, w, e, c, a) {
      const cf = (w[c] = w[c] || {});
      cf.apiKey = a;
      const sc = d.createElement(e);
      const s = d.getElementsByTagName(e)[0];
      sc.async = !0;
      sc.src = cf.embed || 'https://wave.video/editor/embed.js';
      s.parentNode.insertBefore(sc, s);
    })(document, window, 'script', 'waveconfig', apiKey);
  };

  const EmbedButton = ({
    apiKey,
    size = 'large',
    color = 'dark',
    format = 'youtube_horizontal',
    title = 'Edit Project',
    onError,
    onPublish,
    padding = '0px',
    projectid = '',
    datanavigationtarget = 'edit',
  }) => {
    const buttonCallbackName = 'onWavePublish';
    window[buttonCallbackName] = onPublish;

    useEffect(() => {
      if (!apiKey) onError('No API key');

      window.waveconfig = {
        apiKey,
        // some config here
      };
      load(apiKey);
    }, [apiKey]);

    return (
      <button
        style={{ padding: '9px', margin: '8px' }}
        className={`wave-video-button ${size} ${color}`}
        data-video-format={format}
        data-publish-callback={buttonCallbackName}
        data-video-id={projectid}
        data-navigation-target={datanavigationtarget}
      >
        {title}
      </button>
    );
  };

  const [url, setUrl] = useState('');
  const screenObj = template?.categories[category].screens[screen];

  // Handle upload by url
  function handleUploadByURL() {
    // Validate url is valid format - .mp4/.mov
    let validUrl;
    try {
      validUrl = new URL(url);
      if (
        !validUrl.pathname.toLowerCase().includes('.mp4') &&
        !validUrl.pathname.toLowerCase().includes('.mov') &&
        !validUrl.pathname.toLowerCase().includes('.webm')
      ) {
        throw TypeError('url must be a video');
      }
    } catch (_) {
      setUrl('');
      return;
    }
    // Add it to videos
    if (!fileExists(url)) {
      setCloudLibrary((prevList) => [
        ...prevList,
        {
          name: url.substring(url.lastIndexOf('/') + 1),
          type: 'video/mp4',
          origName: '.mp4',
          dataURL: url,
          formData: { video: url },
          saveToCloud: true,
        },
      ]);
      setCurrVid([
        {
          name: url.substring(url.lastIndexOf('/') + 1),
          type: 'video/mp4',
          source: url,
          formData: { video: url },
        },
      ]);
    }

    // Clear input value
    setUrl('');
  }

  // Checks progress of encoding on this video
  const checkEncodingProgress = (interval, coconut_id, original_src) => {
    axios
      .get(`${API_HOST}/encoding/job/${coconut_id}`)
      .then(({ data }) => {
        console.log(
          'CHECKING encoding progress function!' +
            data.job +
            ': This video has a coconut_id',
          coconut_id
        );

        console.log(data);
        if (data.job?.progress === '100%') {
          console.log(
            'output url from coconut video url: ',
            data.job.outputs
            //data.job.output_urls['mp4:1080p']
          );
          let the_video = '';
          let the_img = '';
          let the_gif = '';
          data.job.outputs?.map((x) => {
            if (x.format === 'jpg:300x') {
              the_img = x.urls[0];
            } else if (x.format === 'mp4:1080p') {
              the_video = x.url;
            } else if (x.format === 'gif:500x') {
              the_gif = x.urls[0];
            }
          });

          const updatedCloud = cloudLibrary?.map((x) => {
            if (x.dataURL === original_src) {
              //console.log('iterate x: ', x);
              return {
                ...x,
                video: the_video,
                gif: the_gif,
                img: the_img,
              };
            }
            return x;
          });
          _dispatch({
            type: 'updateScreen',
            payload: {
              screen_key: screen,
              category_key: category,
              magnet_id: magnetId,
              magnet_type: magnetType,
              screen: {
                ...screenObj,
                cloudLibrary: updatedCloud,
                video: the_video,
                gif: the_gif,
                img: the_img,
              },
            },
          });

          // _dispatch({
          //   type: 'updateScreen',
          //   payload: {
          //     screen_key: screen,
          //     category_key: category,
          //     screen: {
          //       ...screenObj,
          //       cloudLibrary: updatedCloud,
          //       video: data.job.output_urls['mp4:1080p'],
          //     },
          //   },
          // });
          // console.log('updated cloudLibrary obj: ', {
          //   ...screenObj,
          //   cloudLibrary: updatedCloud,
          // });

          // setIsEncoding(false);
          clearInterval(interval);
        } else {
          console.log('Job not done yet:', data.job.progress);
          // setIsEncoding(true);
        }
      })
      .catch((error) => {
        console.log('remember', error);
        // setIsEncoding(false);
        clearInterval(interval);
      });
  };

  // Periodically check encoding status
  useEffect(() => {
    let src = currVid[0] ? currVid[0]?.source : '';
    console.log(currVid, 'lets check src');
    if (src) {
      const urlParams = new URL(src).searchParams;
      console.log('urlParams', urlParams);
      const coconut_Id = urlParams.get('coconut_id');
      console.log('coconut_id_s1', coconut_Id);
      if (coconut_Id) {
        let coconut_id = urlParams.get('coconut_id');
        const interval = setInterval(
          () => checkEncodingProgress(interval, coconut_id, src),
          5000,
          coconut_id
        );
        console.log('coconut_id_s2', coconut_id);
        //checkEncodingProgress(interval, coconut_id, src); // fire this immediately upon page load
      } else {
        console.log("This video doesn't have a coconut_id", src);
        // setIsEncoding(false);
      }
    }
  }, [currVid]);

  return (
    <>
      <div className="" style={{ textAlign: 'left' }}>
        {currVid.length > 0 && (
          <div
            style={{
              position: 'relative',
              height: '75vh',
              width: '95%',
            }}
          >
            <PreviewVideoMagnet
              uuid={magnetId}
              screen={screen}
              category={category}
              inline={true} // Don't wanna autoplay, since the user maybe hasn't interacted with the document yet
              fileType={currVid[0].type}
              src={currVid[0].source}
              isEncoding={isEncoding}
              setIsEncoding={setIsEncoding}
              buttons={buttons}
              centerButtons={centerButtons}
              bottomButtons={bottomButtons}
              caption={caption}
              location={location}
              formTemplate={formTemplate}
              iframeOptions={iframeOptions}
              shareInfo={shareInfo}
            />
            {/* BUG FIX / To update videos use src directly or .load() on update To update video  https://stackoverflow.com/questions/29291688/video-displayed-in-reactjs-component-not-updating */}
          </div>
        )}
      </div>

      <Tabs
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        centered
      >
        <Tab
          value="upload"
          label={
            <div>
              <CloudUploadIcon />
              {'  '}UPLOAD
            </div>
          }
          {...a11yProps('upload')}
        />
        <Tab
          value="edit"
          label={
            <div>
              <MovieCreationIcon />
              {'  '}EDIT
            </div>
          }
          {...a11yProps('edit')}
        />
      </Tabs>
      <TabPanel value={value} index="upload">
        <div draggable="true" className="dashed center">
          <Dragger handleDrop={handleDrop}>
            <br />
            <h3>Drag Videos to Upload</h3>
            <input
              style={{ display: 'none' }}
              multiple
              name="video"
              id="icon-button-file"
              type="file"
              accept=".mp4, .mov"
              onChange={readFile}
            />
            <label htmlFor="icon-button-file">
              <div class="folder">
                <div class="folder__back">
                  <div class="paper"></div>
                  <div class="paper"></div>
                  <div class="paper"></div>
                  <div class="folder__front"></div>
                  <div class="folder__front right"></div>
                </div>
              </div>
            </label>
            <br />
            <input
              accept=".mp4, .mov, .webm"
              multiple
              style={{ display: 'none' }}
              name="video"
              id="contained-button-file"
              type="file"
              onChange={readFile}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<SearchIcon />}
              >
                Browse Files
              </Button>
            </label>
            <br />
          </Dragger>
        </div>

        <div className="upload-url-container">
          <DebouncedTextField
            label="Upload video by URL"
            id="upload-by-url"
            InputProps={{
              endAdornment: (
                <IconButton color="primary" onClick={handleUploadByURL}>
                  <PublishIcon />
                </IconButton>
              ),
            }}
            value={url}
            onChange={setUrl}
            variant="outlined"
            fullWidth
          />
          <div className="demo-text encode-text">
            <i>*.mov files will need extra time to encode</i>
          </div>

          <div className="advanced-upload-btns ">
            <button
              className="cleanbtn record-new-video-btn flex aic"
              onClick={() => {
                // window.open(
                //   'https://www.recordjoy.com/box-video/embed',
                //   '_blank'
                // );
                _dispatch({
                  type: 'SHOW_SCREEN_RECORDING',
                  payload: true,
                });
              }}
            >
              {/* <img
              className="img"
              src={require('../../assets/google-drive.png').default}
            /> */}
              <div className="record-icon" />
              <div className="font s14 b5 c333">Record new video</div>
            </button>
            <div className="flex aic">
              <GPickerButton
                cloudLibrary={cloudLibrary}
                setCloudLibrary={setCloudLibrary}
              />
            </div>
          </div>
          {/* Upload Import Video Button */}
          <Link
            to={`/upload-video-import/${magnetId}/${screenToEdit}`}
            target="_blank"
          >
            <div className="upload-import-vid-btn font s15 b5 c000">
              Upload Import Video
            </div>
          </Link>
        </div>
      </TabPanel>

      <TabPanel value={value} index="edit">
        <div className="center">
          {definedProjectID ? (
            <EmbedButton
              apiKey={ApiKey}
              padding="4px"
              projectid={projectID}
              // datanavigationtarget="edit"

              onPublish={(_, res) => {
                console.log('onPublish res = ', res);
                if ((res && res.videoUrl) !== undefined) {
                  handleUpdateProjectID(res.videoId);

                  // add video to the local videos with default not uploading to cloud
                  setCloudLibrary((prevList) => [
                    ...prevList,
                    {
                      name: res.name,
                      type: 'video/mp4',
                      origName: '.mp4',
                      dataURL: res.videoUrl,
                      formData: { video: res.videoUrl },
                      saveToCloud: true,
                    },
                  ]);

                  // Auto select current video
                  setCurrVid([
                    {
                      name: res.name,
                      type: 'video/mp4',
                      source: res.videoUrl,
                      formData: { video: res.videoUrl },
                    },
                  ]);
                }
              }}
            />
          ) : (
            <EmbedButton
              apiKey={ApiKey}
              color="default"
              title={<> New Video </>}
              padding="4px"
              projectid={projectID}
              datanavigationtarget=""
              onPublish={(_, res) => {
                console.log('onPublish res = ', res);

                if ((res && res.videoUrl) !== undefined) {
                  handleUpdateProjectID(res.videoId);

                  // add video to the local videos with default not uploading to cloud
                  setCloudLibrary((prevList) => [
                    ...prevList,
                    {
                      name: res.name,
                      type: 'video/mp4',
                      origName: '.mp4',
                      dataURL: res.videoUrl,
                      formData: { video: res.videoUrl },
                      saveToCloud: true,
                    },
                  ]);

                  // Auto select current video
                  setCurrVid([
                    {
                      name: res.name,
                      type: 'video/mp4',
                      source: res.videoUrl,
                      formData: { video: res.videoUrl },
                    },
                  ]);
                } else {
                  handleUpdateProjectID();
                }
              }}
            />
          )}
          <div>
            <br />
            <EditButton
              label={
                <div>
                  Project ID: {projectID}
                  <EditIcon />
                </div>
              }
              placeholder={'New Project ID'}
              initialValue={projectID}
              setState={(newProjectID) =>
                handleUpdateProjectID(newProjectID, true)
              }
            />
          </div>
        </div>
      </TabPanel>

      <div className="center" style={{ marginBottom: '200px' }}>
        <br />
        <SimpleAccordion
          title=""
          titleCoded={
            <>
              <b>My Videos</b>
            </>
          }
          startOpen={true}
        >
          <Grid container spacing={3}>
            <p style={{ padding: '0px 10px', textAlign: 'left' }}>
              Click to select a video, or click the save icon to store to the
              cloud
            </p>
            <br />
            {alert && (
              <Alert style={{ width: '100%' }} severity={alert.severity}>
                {alert.text}
              </Alert>
            )}
            {cloudLibrary?.map((video, idx) => (
              <Gallery
                setCurrVid={setCurrVid}
                currVid={currVid}
                video={video}
                videoIndex={idx}
                key={idx.toString() + '-' + video.name}
                magnetType={magnetType}
                magnetId={magnetId}
                updateCloud={() => {
                  setAlert();
                  const temp = cloudLibrary;
                  // remove from cloud
                  if (temp[idx].saveToCloud) {
                    temp[idx].saveToCloud = false;
                    setCloudLibrary(temp);
                    setAlert({
                      text: 'Video removed from cloud library queue',
                      severity: 'success',
                    });
                  }
                  // add to cloud
                  else if (
                    temp.filter((video) => video.saveToCloud).length < 20
                  ) {
                    temp[idx].saveToCloud = true;
                    setCloudLibrary(temp);
                    setAlert({
                      text: 'Video added to cloud library queue',
                      severity: 'success',
                    });
                  } else {
                    setAlert({
                      text: 'ERROR: Cloud library full',
                      severity: 'error',
                    });
                  }
                }}
                updateVidName={(newName) => {
                  const temp = cloudLibrary;
                  temp[idx].name = newName;
                  setCloudLibrary(temp);
                }}
                progress={
                  idx === cloudLibraryProgress?.idx
                    ? cloudLibraryProgress?.progress
                    : 0
                }
                deletefile={async () => {
                  setAlert();
                  console.log(
                    'video url = ',
                    video.dataURL,
                    currVid[0].dataURL
                  );
                  if (
                    screen !== 'new' &&
                    video.dataURL !== currVid[0].source &&
                    video.dataURL !==
                      template['categories'][category]['screens'][screen][
                        'video'
                      ]
                  ) {
                    try {
                      const response = await axios({
                        method: 'POST',
                        url:
                          API_HOST + `/delete_file?old_file=` + video.dataURL,
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      });
                      console.log(response);
                      if (response?.data?.status === 'success') {
                        const temp = cloudLibrary.filter(
                          (video, i) => i !== idx
                        );
                        setCloudLibrary(temp);
                        handlePushCloudLibrary(temp);
                      }
                    } catch (e) {
                      console.log('error = ', e);
                    }
                  } else if (screen === 'new') {
                    const temp = cloudLibrary.filter((video, i) => i !== idx);
                    setCloudLibrary(temp);
                    handlePushCloudLibrary(temp);
                  } else {
                    setAlert({
                      text: 'ERROR: Cannot delete current video',
                      severity: 'error',
                    });
                  }
                }}
              />
            ))}
          </Grid>
        </SimpleAccordion>
      </div>
    </>
  );
}

//Dragger copied directly from https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
class Dragger extends React.Component {
  state = {
    drag: false,
  };
  dropRef = React.createRef();
  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };
  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }
  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', this.handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  render() {
    return (
      <div
        style={{ display: 'inline-block', position: 'relative' }}
        ref={this.dropRef}
      >
        {this.state.drag && (
          <div
            style={{
              border: 'dashed grey 4px',
              backgroundColor: 'rgba(255,255,255,.95)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                left: 0,
                textAlign: 'center',
                color: 'grey',
                fontSize: 36,
              }}
            >
              <div>Drop Video Here</div>
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}
