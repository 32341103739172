import { useEffect, useRef, useState } from 'react';
import { BiUser } from 'react-icons/bi';
import { BsGlobe2 } from 'react-icons/bs';
import {
  HiArrowRight,
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
} from 'react-icons/hi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { MdClose, MdKeyboardArrowRight } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import MagnetContext from '../../components/VideoMagnet/MagnetContext';
import VideoMagnetTheme from '../../components/VideoMagnet/theme';
import VideoMagnet from '../../components/VideoMagnet/VideoMagnet';
import { get } from '../../utils/request';
import GlobNavbar from '../GlobNavbar';
import VideoSidebar from './VideoSidebar';
const EMPTY_FUNC = () => {};

const TheCustomTour = () => {
  // Sidebar should not be open on mobile devices by default
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    /*() =>
    window?.innerHeight < 768 ? false : true*/
    true
  );

  // Get query parameters
  const query = new URLSearchParams(window.location.search);

  // Magnet uuid
  const magnet_id = query.get('magnet_uuid');
  // Template params
  const videosQueryParams = query.get('share')?.split(',');

  // Video template
  const [template, setTemplate] = useState();
  const [screens, setScreens] = useState();
  const [videosLength, setVideosLength] = useState([]);
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);
  const videoRef = useRef();
  const _links = screens ? screens.links : [];
  const links = _links
    ? _links?.map((link) => ({
        ...link,
        name: replaceUserInfo(link.name),
      }))
    : [];

  const [categoryKey, setCategoryKey] = useState('intro');
  const [screenKey, setScreenKey] = useState('main');
  useEffect(() => {
    if (screens) {
      setCategoryKey();
    }
  }, [screens]);

  // useEffect(() => {
  //   setScreenKey(_screenKey);
  // }, [_screenKey]);
  const getNextVideo = ([category, screen]) =>
    getScreenByRoute(category, screen)?.video;
  // Get video template from backend via GET request
  function getVideoTemplate() {
    get(`/magnets/template?magnet_uuid=${magnet_id}`, { auth: false })
      .then((data) => {
        const extractedTemplateData = data.magnet.magnet_details.template;
        setTemplate(extractedTemplateData);
        setScreens(() => {
          let tempScreens = videosQueryParams.map((videoParam) => {
            const videoParamSeparated = videoParam.split('.');
            const videoParamCategoryName = videoParamSeparated[0];
            const videoParamScreenName = videoParamSeparated[1];
            const extractedScreenData =
              extractedTemplateData?.categories[videoParamCategoryName]
                ?.screens[videoParamScreenName];
            if (extractedScreenData) return extractedScreenData;
          });
          return tempScreens;
        });
        setVideosLength(() => {
          let tempScreens = videosQueryParams.map((videoParam) => {
            const videoParamSeparated = videoParam.split('.');
            const videoParamCategoryName = videoParamSeparated[0];
            const videoParamScreenName = videoParamSeparated[1];
            const extractedScreenData =
              extractedTemplateData?.categories[videoParamCategoryName]
                ?.screens[videoParamScreenName];
            if (extractedScreenData) return extractedScreenData;
          });
          return tempScreens;
        });
      })
      .catch((error) => {
        console.log('error@getVideoTemplate: ', error.message);
      });
  }

  console.log('screens', screens);

  useEffect(() => {
    getVideoTemplate();
  }, []);

  //videoRef?.current?.play();

  document.onkeydown = checkKey;

  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode == '38') {
      if (currentScreenIndex !== 0) {
        setCurrentScreenIndex(currentScreenIndex - 1);
      }
    } else if (e.keyCode == '40') {
      if (currentScreenIndex + 1 < videosLength?.length) {
        setCurrentScreenIndex(currentScreenIndex + 1);
      }
    } else if (e.keyCode == '37') {
      if (currentScreenIndex !== 0) {
        setCurrentScreenIndex(currentScreenIndex - 1);
      }
    } else if (e.keyCode == '39') {
      // right arrow
      if (currentScreenIndex + 1 < videosLength?.length) {
        setCurrentScreenIndex(currentScreenIndex + 1);
      }
    }
  }
  const [toggle, setToggle] = useState(false);
  const [tactive, setTactive] = useState(false);
  return (
    <div className=" h-full lg:h-screen overflow-scroll lg:overflow-hidden">
      <div className=" w-full bg-black  relative z-30">
        <div className="container relative">
          <div className=" items-center justify-between hidden lg:flex">
            <div className=" flex items-center">
              <Link to="/">
                <div>
                  <h1 className="font_uber py-2 text-2xl inline items-center cursor-pointer text-white">
                    {/* leasemag
              <img src={noto_magnet} className="w-4 mt-1 h-4" alt="" />
              ets */}
                    Tour
                  </h1>
                </div>
              </Link>
            </div>
            <div className=" flex items-center py-2 gap-4">
              <Link
                to="/"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BsGlobe2 /> EN
              </Link>
              <Link
                to="/signin"
                className=" flex items-center text-sm justify-center gap-1 text-white font-light "
              >
                <BiUser /> Sign In
              </Link>
              <Link
                to="/signup"
                className=" flex items-center hover:bg-white font-semibold hover:text-black text-sm justify-center gap-1 bg-white font-light text-black py-2  px-2"
              >
                Sign Up
              </Link>
            </div>
          </div>
          <div id="showheader" className=" absolute w-full  top-16 left-0">
            <GlobNavbar />
          </div>
          {toggle && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={
            !toggle
              ? {
                  height: '100vh',
                  borderTop: '1px solid rgba(255,255,255,0.3)',
                  background: '#000',
                  overflowY: 'scroll',
                  zIndex: '9999999999',
                }
              : { height: '0px', overflow: ' hidden' }
          }
          className="fixed top-0  bg-black left-0 transition-all  ease-linear   duration-300  w-full block lg:hidden"
        >
          {toggle === false && (
            <div className=" items-center justify-between flex lg:hidden px-2 py-3">
              <div className=" flex items-center">
                <h1 className="font_uber text-2xl cursor-pointer text-white">
                  Tour
                </h1>
              </div>
              <div className=" flex items-center gap-2">
                <Link
                  to="/"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BsGlobe2 className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signin"
                  className=" flex items-center text-sm justify-center gap-1 text-white font-light "
                >
                  <BiUser className=" cursor-pointer text-white w-5 h-5" />
                </Link>
                <Link
                  to="/signup"
                  className=" flex items-center rounded-3xl hover:bg-white hover:text-black text-xs justify-center gap-1 bg-white font-light text-black py-2  px-2"
                >
                  Sign Up
                </Link>
                {toggle ? (
                  <VscThreeBars
                    onClick={() => setToggle(false)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                ) : (
                  <MdClose
                    onClick={() => setToggle(true)}
                    className=" cursor-pointer text-white w-5 h-5"
                  />
                )}
              </div>
            </div>
          )}
          <div className=" px-5">
            <div
              onClick={() => setTactive(!tactive)}
              className=" flex cursor-pointer items-center justify-between w-full pb-3 text-3xl     gap-1 text-white font_uber pt-10"
            >
              Product
              {tactive === false ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
            {tactive && (
              <div className=" w-full mt-3">
                <div className=" w-full  shadow-2xl h-full flex flex-col py-4 gap-10">
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      USE CASES
                    </h6>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img src={tt} className=" w-7 h-7 object-cover" alt="" />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Tour & Triggers{' '}
                        </h6>
                        <p className="text-xs text-gray-200">
                          Make it easy to build an embeddable & shareable tour
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--engagement-bc48a36991ab0b915c148bd61c6c1d06.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Trust & Testimonials
                        </h6>
                        <p className="text-xs text-gray-200">
                          Build more trust through testimonials
                        </p>
                      </div>
                    </div>
                    <div className=" mt-3 flex items-center gap-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <img
                        src="https://www.intercom.com/_next/static/images/solutions--marketing-0fc6ec0aeab50485489ad1f6da1a1e5d.svg"
                        className=" w-7 h-7 object-cover"
                        alt=""
                      />
                      <div>
                        <h6 className=" font_cwb text-sm text-white">
                          Targeting & Retargeting
                        </h6>
                        <p className="text-xs text-gray-200">
                          Create targeted specials and online campaigns
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      LEARN
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-3 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Welcome Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Tour Videos</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Analytics</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Retargeting</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> CTA’s and Triggers</Link>
                    </h6>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform transition-all cursor-pointer duration-200 ease-linear hover:translate-x-0">
                      <Link to="/"> Integrations</Link>
                    </h6>
                  </div>
                  <div className=" lg:pl-20">
                    <h6 className=" font_cwb text-sm font-semibold text-white">
                      WATCH A DEMO
                    </h6>
                    <div className=" mt-3">
                      <img src={ss} className=" h-20 object-cover " alt="" />
                    </div>
                    <p className=" text-gray-200 text-sm py-2">
                      Learn from a professional what makes <br />
                      Tour work behind the scenes
                    </p>
                    <h6 className=" font_cwb text-sm text-white mt-2 transform font-medium flex items-center gap-24 transition-all cursor-pointer duration-200 ease-linear hover:translate-x-3">
                      <Link to="/"> BOOK A DEMO</Link>
                      <HiArrowRight className=" w-5 h-5" />
                    </h6>
                  </div>
                </div>
              </div>
            )}
            <Link
              style={{ borderTop: '1px solid rgba(255,255,255,0.3)' }}
              to="/results"
              className=" flex items-center  text-3xl d justify-between pt-3  gap-1 text-white font_uber "
            >
              Results
              <p></p>
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full h-screen relative flex">
        {/* <input type="text" className=' hidden' onKeyDown={ handleKeyDown } /> */}
        {/* Button to open sidebar */}
        <button
          className="md:hidden block shadow-sm pointer-cursor absolute bottom-5 left-5 w-10 h-10 rounded-full border"
          onClick={() => setIsSidebarOpen(true)}
        >
          <MdKeyboardArrowRight className="w-10 h-10" />
        </button>
        {/* VideoSidebar */}
        {isSidebarOpen && (
          <div className="absolute z-10 md:static md:w-52 w-full h-full">
            <VideoSidebar
              currentVideoIndex={currentScreenIndex}
              screensCollection={screens}
              onClose={() => setIsSidebarOpen(false)}
              onVideoChange={(currentVideoIndex) =>
                setCurrentScreenIndex(currentVideoIndex)
              }
            />
          </div>
        )}
        {/* The Actual Video Being Played */}
        <div className="flex-1 p-4 flex flex-col gap-2 items-center justify-center bg-white h-full">
          {screens && (
            <p className="text-lg font-bold">
              {screens[currentScreenIndex]?.title}
            </p>
          )}
          {screens && (
            <div
              style={{
                position: 'relative',
                height: '75vh',
                width: '95%',
              }}
            >
              <MagnetContext.Provider
                value={{
                  uuid: magnet_id,
                  currentCategory: categoryKey,
                  currentScreen: screenKey,
                  startCategory: categoryKey,
                  startScreen: screenKey,
                }}
              >
                <div style={VideoMagnetTheme}>
                  <VideoMagnet
                    show={true}
                    inline={true}
                    inlineCTA={undefined}
                    showLinksInline={false}
                    screen={{ ...screens[currentScreenIndex], links: [] }}
                    magnetSettings={template?.magnetSettings || {}}
                    getNextVideo={true ? EMPTY_FUNC : getNextVideo}
                    getNewPage={true ? EMPTY_FUNC : getNewPage}
                    startTour={EMPTY_FUNC}
                    restartTour={EMPTY_FUNC}
                    onClose={() => {}}
                    primaryColor={'black'}
                    links={[]}
                    title={'asdas'}
                    //locationCard={locationCard}
                  />
                </div>
              </MagnetContext.Provider>
            </div>
          )}
          <div className=" mt-4 w-full flex flex-wrap items-center justify-between pl-4">
            <div className="flex items-center gap-2 ">
              <div
                style={{
                  gridTemplateColumns: `repeat(${videosLength?.length}, 1fr)`,
                  height: '6px',
                }}
                className=" grid w-96  bg-gray-200"
              >
                {videosLength.slice(0, currentScreenIndex + 1).map((item) => (
                  <div
                    style={{ height: '6px' }}
                    className=" w-full  bg-black"
                  ></div>
                ))}
              </div>
              <p className=" text-gray-700 text-sm">
                {currentScreenIndex + 1 + '/' + videosLength?.length}
              </p>
            </div>
            <div className="flex items-center gap-4">
              {currentScreenIndex !== 0 ? (
                <div
                  onClick={() => setCurrentScreenIndex(currentScreenIndex - 1)}
                  className=" rounded-2xl cursor-pointer  "
                >
                  <HiOutlineArrowLeft className=" w-6 h-6 cursor-pointer text-black " />
                </div>
              ) : (
                <div className="  rounded-2xl">
                  <HiOutlineArrowLeft className=" w-6 h-6  text-gray-500  " />
                </div>
              )}
              {currentScreenIndex + 1 < videosLength?.length ? (
                <div
                  onClick={() => setCurrentScreenIndex(currentScreenIndex + 1)}
                  className=" rounded-2xl cursor-pointer  "
                >
                  <HiOutlineArrowRight className=" w-6 h-6 cursor-pointer text-black " />
                </div>
              ) : (
                <div className="  rounded-2xl   ">
                  <HiOutlineArrowRight className=" w-6 h-6  text-gray-500 " />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheCustomTour;
